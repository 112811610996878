import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {LandingPageCreateResponse, LandingPageDownloadResponse, LandingPageResponse} from './landing-page-builder.model';

@Injectable({
  providedIn: 'root'
})
export class LandingPageBuilderService {

  url = 'landing_pages/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * List all build page
   */
  list(): Observable<LandingPageResponse> | any {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get(url);
  }

  /**
   * Create new landing page
   * @param data
   */
  add(data): Observable<LandingPageCreateResponse> | any {
    const url = `${this.resource.url}${this.url}add`;

    return this.http.Post(url, data);
  }

  /**
   * Delete landing page
   */
  delete(id: number): any {
    const url = `${this.resource.url}${this.url}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Download landing page archive
   * @param id
   */
  download(id: number): Observable<LandingPageDownloadResponse> | any {
    const url = `${this.resource.url}${this.url}download/${id}`;

    const data = {};
    return this.http.Post<LandingPageDownloadResponse>(url, data);
  }
}
