import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

const script = document.createElement('script');
script.innerHTML = `!function(e,o,n){window.HSCW=o,window.HS=n,n.beacon=n.beacon||{};
    var t=n.beacon;t.userConfig={},t.readyQueue=[],t.config=function(e){this.userConfig=e},
      t.ready=function(e){this.readyQueue.push(e)},
      o.config={docs:{enabled:!0,baseUrl:"https://vineasx.helpscoutdocs.com/"},
        contact:{enabled:!0,formId:"d17121c0-819a-11e8-8d65-0ee9bb0328ce"}};
    var r=e.getElementsByTagName("script")[0],c=e.createElement("script");c.type="text/javascript",
      c.async=!0,c.src="https://djtflbt20bdde.cloudfront.net/",
      r.parentNode.insertBefore(c,r)}(document,window.HSCW||{},window.HS||{});`;

document.body.appendChild(script);
