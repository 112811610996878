import {Component, Input, OnInit} from '@angular/core';
import {ShopifyProductData} from '../shopify-store-builder.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shopify-error-modal',
  templateUrl: './shopify-error-modal.component.html',
  styleUrls: ['./shopify-error-modal.component.css']
})
export class ShopifyErrorModalComponent implements OnInit {

  @Input() record: ShopifyProductData;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
