import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private Auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.logoutUser();
  }

  /**
   * logout user by clearing credentials saved in local storage
   * redirect user to login page after logout
   */
  logoutUser() {
    this.Auth.clearCredentials();
    this.router.navigate(['/auth/login']);
    return false;
  }

}
