import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(
    public Auth: AuthService,
    public router: Router
  ) { }

  /**
   * this method is used to check if user is authenticated or not
   * if user is not authenticated, is redirected to login page
   */
  canActivate(): Observable<boolean> | boolean {
    // if userValid is true and user is not authenticated,
    // probably access token has been expired,
    // refresh token
    if (!this.Auth.userValid()) {
      return this._cannotActivate();
    } else if (this.Auth.isAuthenticated()) {
      return true;
    } else {
      return this._cannotActivate();
    }
  }

  private _cannotActivate(): boolean {
    this.router.navigate(['/auth/login']);
    return false;
  }
}
