<div class="modal-header">
  <h4 class="modal-title">Delete Client Account</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-danger">
        <strong>NOTE:</strong> Are you sure want to delete client account: {{ clientAccount?.client.name }}?
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="deleteClientNow()" [disabled]="deleting">
    <span *ngIf="!deleting; else deleting_loader">
      <i class="fa fa-trash-o"></i> Delete client account
    </span>
    <ng-template #deleting_loader>
      <i class="fa fa-spinner fa-spin"></i> Deleting client account...
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

