import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {AdminLayoutRoutes} from './admin-layout.routing';
import {FormsModule} from '@angular/forms';
import {ScraperModule} from '../../scraper/scraper.module';
import {AccountModule} from '../../account/account.module';
import {ClientAccountModule} from '../../client-account/client-account.module';
import {VirtualAccountModule} from '../../virtual-account/virtual-account.module';
import {FavouriteProductsModule} from '../../favourite-products/favourite-products.module';
import {LandingPageBuilderModule} from '../../landing-page-builder/landing-page-builder.module';
import {FbAdsBuilderModule} from '../../fb-ads-builder/fb-ads-builder.module';
import {ShopifyStoreBuilderModule} from '../../shopify-store-builder/shopify-store-builder.module';
import {TimelineModule} from '../../timeline/timeline.module';
import {SavedSearchesModule} from '../../saved-searches/saved-searches.module';
import {PopularProductsModule} from '../../popular-products/popular-products.module';
import {DashboardModule} from '../../dashboard/dashboard.module';
import {TutorialModule} from '../../tutorial/tutorial.module';
import {ComponentsModule} from '../../components/components.module';
import {AdminLayoutComponent} from './admin-layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    ComponentsModule,
    ScraperModule,
    AccountModule,
    ClientAccountModule,
    VirtualAccountModule,
    FavouriteProductsModule,
    LandingPageBuilderModule,
    FbAdsBuilderModule,
    ShopifyStoreBuilderModule,
    TimelineModule,
    SavedSearchesModule,
    PopularProductsModule,
    DashboardModule,
    TutorialModule
  ],
  declarations: [AdminLayoutComponent]
})
export class AdminLayoutModule { }
