import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {NotificationService} from '../../components/notification-service/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  password_hash: string;
  user_id_hash: string;

  passwordResetForm: FormGroup;
  passwordResetFormSubmitted = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit() {
    /**
     * Get url parameters
     */
    this.activatedRoute.params.subscribe(
      param => {
        this.password_hash = param['password_hash'];
        this.user_id_hash = param['user_id_hash'];
      }
    );

    /**
     * Initialize form
     */
    this.passwordResetForm = this.formBuilder.group({
      password: new FormControl('', [
        Validators.required
      ]),
      password_confirm: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.passwordResetForm.controls;
  }

  onSubmit() {
    this.passwordResetFormSubmitted = true;

    if (this.passwordResetForm.invalid) {
      this.passwordResetFormSubmitted = false;
      return;
    }

    const data = this.passwordResetForm.value;
    const url = this.password_hash + '/' + this.user_id_hash;
    this.authService.resetPassword(url, data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Reset Password'
          });
        } else {
          this.notificationService.success({
            message: 'Password successfully changed. Login with new password',
            title: 'Reset Password'
          });
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }

}
