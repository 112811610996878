import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private filterData;
  private totalProductItems;

  /**
   * @constructor
   */
  constructor() { }

  /**
   * Apply filter on the products and return
   * @param filterData
   * @param products
   */
  filter(filterData, products) {
    this.filterData = filterData;
    this.totalProductItems = products;

    // store filtered products
    const product_filtered = [];

    /******************************
     * Filter on min price
     ******************************/
    if (this.filterData.price_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.pricing.price < this.filterData.price_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.pricing.price >= this.filterData.price_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /*********************************
     * Filter on max price
     *********************************/
    if (this.filterData.price_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.pricing.price >= this.filterData.price_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.pricing.price <= this.filterData.price_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /*******************************
     * Filter on total sold min
     *******************************/
    if (this.filterData.total_sold_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.orders_num < this.filterData.total_sold_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.orders_num >= this.filterData.total_sold_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /******************************
     * Filter on total sold max
     *******************************/
    if (this.filterData.total_sold_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.orders_num > this.filterData.total_sold_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.orders_num <= this.filterData.total_sold_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /******************************
     * Filter on discount min
     ******************************/
    if (this.filterData.discount_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.pricing.discount_rate < this.filterData.discount_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.pricing.discount_rate > this.filterData.discount_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /*****************************
     * Filter on discount max
     *****************************/
    if (this.filterData.discount_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.pricing.discount_rate > this.filterData.discount_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.pricing.discount_rate < this.filterData.discount_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /********************************
     * Filter on review count min
     ********************************/
    if (this.filterData.review_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.rating.count < this.filterData.review_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.rating.count > this.filterData.review_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /********************************
     * Filter on review count max
     ********************************/
    if (this.filterData.review_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.rating.count > this.filterData.review_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.rating.count < this.filterData.review_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /*******************************************
     * Filter on estimate unit per month min
     *******************************************/
    if (this.filterData.estimate_unit_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.unit_per_month < this.filterData.estimate_unit_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.unit_per_month > this.filterData.estimate_unit_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /********************************************
     * Filter on estimate unit per month max
     ********************************************/
    if (this.filterData.estimate_unit_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.unit_per_month > this.filterData.estimate_unit_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.unit_per_month < this.filterData.estimate_unit_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /*******************************************
     * Filter on estimate cost per month min
     *******************************************/
    if (this.filterData.estimate_cost_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.pricing.cost_per_month < this.filterData.estimate_cost_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.pricing.cost_per_month > this.filterData.estimate_cost_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /********************************************
     * Filter on estimate cost per month max
     *********************************************/
    if (this.filterData.estimate_cost_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.pricing.cost_per_month > this.filterData.estimate_cost_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.pricing.cost_per_month < this.filterData.estimate_cost_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /***********************************************
     * Filter on recommended selling price min
     ***********************************************/
    if (this.filterData.recommended_price_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.custom.recommended_selling_price < this.filterData.recommended_price_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.custom.recommended_selling_price > this.filterData.recommended_price_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /******************************************
     * Filter on recommended selling price max
     ******************************************/
    if (this.filterData.recommended_price_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.custom.recommended_selling_price > this.filterData.recommended_price_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.custom.recommended_selling_price < this.filterData.recommended_price_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /***********************************************
     * Filter on estimated revenue min
     ***********************************************/
    if (this.filterData.estimate_revenue_min) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.custom.estimate_revenue < this.filterData.estimate_revenue_min) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.custom.estimate_revenue > this.filterData.estimate_revenue_min) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /******************************************
     * Filter on estimated revenue max
     ******************************************/
    if (this.filterData.estimate_revenue_max) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (product_filtered[item].properties.custom.estimate_revenue > this.filterData.estimate_revenue_max) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (this.totalProductItems[item].properties.custom.estimate_revenue < this.filterData.estimate_revenue_max) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }


    /****************************************
     * Filter on star rating
     ****************************************/
    if (this.filterData.rating_star_count) {
      if (product_filtered.length > 0) {
        /**
         * apply on already filtered
         */
        for (const item in product_filtered) {
          if (product_filtered.hasOwnProperty(item)) {
            if (parseInt(product_filtered[item].properties.rating.percent_value, 10) === this.filterData.rating_star_count) {
              // remove item from the list
              product_filtered.splice(product_filtered.indexOf(item), 1);
            }
          }
        }
      } else {
        for (const item in this.totalProductItems) {
          if (this.totalProductItems.hasOwnProperty(item)) {
            if (parseInt(this.totalProductItems[item].properties.rating.percent_value, 10) === this.filterData.rating_star_count) {
              product_filtered.push(this.totalProductItems[item]);
            }
          }
        }
      }
    }

    /*******************************************
     * R E T U R N  F I L T E R E D  I T E M S
     *******************************************/
    return product_filtered;
  }
}
