<div class="modal-header">
  <h4 class="modal-title">Landing Page Builder</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body template-list">

  <div class="row">

    <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="!landing_page_templates_loaded">
      <div class="loader-small"></div>
    </div>

    <div class="col-md-4" *ngFor="let t of lPTemplates; let id = index;">
      <div class="card" (click)="setSelected(t.id)">
        <div class="card-title" [style.background-color]="t.id === selected_template ? 'red' : 'black'">
          {{ t.title }} <span class="text-success selected-template" *ngIf="t.id === selected_template"><i class="fas fa-check"></i> </span>
        </div>
        <div class="card-body choose-img">
          <img src="{{ t.preview_image_url }}" width="100" height="100" alt="{{ t.title }}">
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="activeModal.close(selected_template)">
    Select Template
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

