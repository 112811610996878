import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopifyStoreBuilderService} from '../shopify-store-builder.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-shopify-credential-modal',
  templateUrl: './add-shopify-credential-modal.component.html',
  styleUrls: ['./add-shopify-credential-modal.component.css']
})
export class AddShopifyCredentialModalComponent implements OnInit {

  shopifyCredentialForm: FormGroup;
  shopifyCredentialFormSubmitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private shopifyStoreBuilderService: ShopifyStoreBuilderService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    /**
     * Initialize form
     */
    this.shopifyCredentialForm = this.formBuilder.group({
      app_name: new FormControl('', [
        Validators.required
      ]),
      store_url: new FormControl('', [
        Validators.required
      ]),
      api_key: new FormControl('', [
        Validators.required
      ]),
      api_password: new FormControl('', [
        Validators.required
      ]),
      shared_secret: new FormControl('', [
        Validators.required
      ]),
      storefront_access_token: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.shopifyCredentialForm.controls;
  }

  onSubmit() {
    this.shopifyCredentialFormSubmitted = true;
    if (this.shopifyCredentialForm.invalid) {
      this.shopifyCredentialFormSubmitted = false;
      return;
    }

    const data = this.shopifyCredentialForm.value;

    this.shopifyStoreBuilderService.addCredential(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Add shopify credential'
          });
        } else {
          this.notificationService.success({
            message: 'Shopify credential added successfully',
            title: 'Shopify credential added'
          });
          this.activeModal.close();
        }

        this.shopifyCredentialFormSubmitted = false;
      }
    );
  }

}
