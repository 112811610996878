<div class="container-fluid">
  <div class="row">
    <div class="col-md-5 bg-img-1">
      <div class="m-t-set">
        <div class="">
          <div class="auth card">
            <div class="row">
              <div class="col-md-12">
                <div class="title">
                  <p>Login to Your Account</p>
                </div>
                <div class="form-div">
                  <form [formGroup]="loginForm" (submit)="loginUser()" #formDir="ngForm">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="email">Email Address</label>
                          <input formControlName="email" type="email" class="form-control" id="email" placeholder="Email" autocomplete="true">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="password">Account Password</label>
                          <input formControlName="password" type="password" class="form-control" id="password" placeholder="*******" autocomplete="false">
                        </div>
                      </div>
                    </div>
                    <div class="title2">
                      <button type="submit" class="btn btn-theme" [disabled]="!formDir.valid || submitted">
                        <span *ngIf="!submitted; else loading">Login to SpyCom</span>
                        <ng-template #loading>
                          Please wait...
                        </ng-template>
                      </button>
                    </div>
                  </form>
                  <div class="btn-align">

                    <p class="font-16">Forgot your account details? <a routerLink="/auth/forgot-password">Click here</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 bg-img-2">
      <div class="content">
        <img src="../../../assets/img/spycom-logo-final.png" alt="logo" width="300">
        <div class="text-content">
          <h1>Uncover 1000s Of Profitable</h1>
          <h2>Niches & Low Competition Products On AliExpress…</h2>
          <p>…It’s Super Easy-To-Use</p>
        </div>
      </div>
    </div>
  </div>

</div>
