<div class="row">
  <div class="col-md-12">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="box-panel">
            <div class="row">
              <div class="col-md-3" align="center">
                <img src="../../../assets/img/Forma%201.svg" />
              </div>
              <div class="col-md-9">
                <h6 style="color: #fff;"># OF RESULTS</h6>
                <hr class="card-sepr">
                <p class="set">{{ processedItems?.length }} / {{ getProductsLength() }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-panel">
            <div class="row">
              <div class="col-md-3" align="center">
                <img src="../../../assets/img/Group%202.svg" />
              </div>
              <div class="col-md-9">
                <h6 style="color: #fff;">AVERAGE MONTHLY SALES</h6>
                <hr class="card-sepr">
                <p class="set">{{ averageTotalSold }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-panel">
            <div class="row">
              <div class="col-md-3" align="center">
                <img src="../../../assets/img/Group3.svg" />
              </div>
              <div class="col-md-9">
                <h6 style="color: #fff;">AVERAGE REVIEWS</h6>
                <hr class="card-sepr">
                <p class="set">{{ averageTotalReviews }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-t-25">
  <div class="col-md-12">
    <div class="row">

      <div class="col-md-12 text-center">
        <h6>
          <button (click)="openFilterModal()" class="btn btn-warning btn-round">
            <i class="material-icons">
              filter_list
            </i> Filter
          </button>
        </h6>
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="filterData">
  <div class="col-md-12 text-center">
    <div class="filter-title">Filters </div>
    <div class="filter-data">
      <div *ngFor="let f of getFilterDataFormatted() | keyvalue">

        <div class="filter-item" *ngIf="f.value && f.key !== 'rating'">
          <span class="filter-key">{{ formatToReadableString(f.key) }}</span>:
          <span class="filter-value">
          <span *ngIf="f.value.min || f.value.min === 0">{{ f.value.min }}</span> - <span *ngIf="f.value.max">{{ f.value.max }}</span>
        </span>
        </div>
        <div class="filter-item" *ngIf="f.key === 'rating'">
          <span class="filter-key">{{ formatToReadableString(f.key) }} </span>:
          <span class="filter-value">
          <ngb-rating [rate]="f.value" max="5" [readonly]="true"></ngb-rating>
        </span>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <div class="card m-t-15 m-b-0">
      <div class="card-data-style">
        <div class="row">
          <div class="col-md-6">
            <h4 class="highlight-text">Seller Top Products </h4>
          </div>
          <div class="col-md-6 text-right">

            <button class="btn btn-info btn-round btn-for-table" type="button" (click)="openGlobalProfitPercentageModal()" *ngIf="totalProductItems">
              <i class="fas fa-percent"></i> Profit Percent <span *ngIf="global_profit_percentage">: {{ global_profit_percentage }}</span>
            </button>

            <button class="btn btn-info btn-round btn-for-table" type="button" (click)="saveSearchResults()" [disabled]="saving_search" *ngIf="totalProductItems">
              <span *ngIf="!saving_search; else saving_search_loader">
                <i class="fas fa-save"></i> Save Search
              </span>
              <ng-template #saving_search_loader>
                <span>
                  <i class="fas fa-spin fa-spinner"></i> Saving...
                </span>
              </ng-template>
            </button>

          </div>
        </div>
      </div>
      <div class="card-body">

        <div class="m-t-set text-center top-seller-loading" *ngIf="loading_content">
          <div class="loader-small"></div>
        </div>

        <div class="table-responsive table-fixed" *ngIf="!loading_content">
          <table class="table table-bar " [mfData]="processedItems" #mf="mfDataTable" [mfRowsOnPage]="1000">
            <thead>

            <tr>
              <th class="text-center"><i class="fa fa-bars" aria-hidden="true"></i></th>
              <th>Action</th>
              <th container="body" ngbPopover="Title of the Product" triggers="mouseenter:mouseleave" >
                Product Title
                <span>
                  <a href="javascript:void(0);" class="filter-up"><i class="material-icons">arrow_drop_up</i></a>
                <a href="javascript:void(0);" class="filter-down"><i class="material-icons">arrow_drop_down</i></a>
                </span>
              </th>
              <th container="body" ngbPopover="Product ID" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="info.product_id"> Product ID </mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Number of orders placed in last 6 months" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.orders_num">Total Sold</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="View country wise shipping details of the product" triggers="mouseenter:mouseleave">
                Shipping
              </th>

              <th container="body" ngbPopover="View description of the product" triggers="mouseenter:mouseleave">
                Description
              </th>

              <th container="body" ngbPopover="Price of the product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.pricing.price">Price</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Standard unit of the product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.pricing.unit">Unit</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Discount rate on product price" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.pricing.discount_rate">Discount rate</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Price info if order is placed in bulk" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.pricing.bulk_price_info">Bulk price info</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Recommended selling price based on profit percent" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.custom.recommended_selling_price">Recommended Selling Price</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Enter profit percent to calculate recommended selling price" triggers="mouseenter:mouseleave">
                Profit Percentage
              </th>

              <th container="body" ngbPopover="Unit per month sold (for last 6 months)" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.unit_per_month">Est. unit/month</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Average cost per month (for last 6 months)" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.pricing.cost_per_month">Est. cost/month</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Estimated revenue of the product (for last 6 months)" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.custom.estimate_revenue">Est. revenue/month</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Rating of the product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.rating.percent_value">Product Rating %</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Total number of reviews" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.rating.count">Number of Reviews</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Packaging Size" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.packaging_details.Package_Size">Package Size</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Packaging Weight" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.packaging_details.Package_Weight">Package Weight</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Packaging Unit Type" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.packaging_details.Unit_Type">Package Unit Type</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Seller Store name" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.store_info.name">Seller Name</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Seller store address" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.store_info.address">Seller Address</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Seller id" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.store_info.seller_id">Seller ID</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Seller store id" triggers="mouseenter:mouseleave" >
                <mfDefaultSorter by="properties.store_info.store_id">Store ID</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Store active on aliexpress since" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="properties.store_info.store_time">Store Time</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Available attributes of the product" triggers="mouseenter:mouseleave">
                Attributes
                <span>
                  <a href="javascript:void(0);" class="filter-up"><i class="material-icons">arrow_drop_up</i></a>
                <a href="javascript:void(0);" class="filter-down"><i class="material-icons">arrow_drop_down</i></a>
                </span>
              </th>
            </tr>

            </thead>
            <tbody>

            <ng-container *ngIf="processedItems.length > 0">

              <tr *ngFor="let p of mf.data; let id = index">

                <td>
                  {{ id+1 }}
                </td>
                <td class="text-center">
                  <div class="ads-option">
                    <i class="fa fa-plus-circle" aria-hidden="true" appStickyPopover [popoverTitle]="additional" [autoClose]="true" data-placement="right"></i>
                    <ng-template #additional>
                      <button type="button" (click)="downloadReviews(id, p.info.product_id, p.properties.store_info.seller_id)" title="Download All Reviews" class="icon-option-style">
                        <span *ngIf="!ifDownloadReviewsInQueue(id); else reviewLoading">
                          <img src="../../../assets/img/rev.png" alt="review">
                        </span>
                        <ng-template #reviewLoading>
                          <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
                        </ng-template>
                      </button>
                      <button type="button" title="download All Images Per Product" (click)="downloadImages(id)" class="icon-option-style">
                      <span *ngIf="!ifDownloadImagesInQueue(id); else downloadImageLoading">
                        <img src="../../../assets/img/img-download.png" alt="download image">
                      </span>
                        <ng-template #downloadImageLoading>
                          <i class="fas fa-sync-alt fa-spin"></i>
                        </ng-template>
                      </button>

                      <button type="button" title="Favorite" class="icon-option-style" (click)="addToFavourite(id)">
                      <span *ngIf="!ifAddToFavouriteInQueue(id); else favouriteLoading">
                        <img src="../../../assets/img/favorite.svg" alt="favorite">
                      </span>
                        <ng-template #favouriteLoading>
                          <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
                        </ng-template>
                      </button>

                      <button type="button" title="Create Fb Ads" class="icon-option-style" (click)="openFbAdBuilderModal(id)">
                        <img src="../../../assets/img/create-fb-ads.png" alt="create fb ads">
                      </button>

                      <button type="button" title="Create Shopify Stores" class="icon-option-style" (click)="openCreateShopifyModal(id)">
                        <img src="../../../assets/img/create-shopify-stores.png" alt="shopify">
                      </button>

                      <button type="button" title="Create Landing Page" class="icon-option-style" (click)="openLandingPageBuilderModal(id)">
                        <img src="../../../assets/img/create-landing-page.png" alt="landing page">
                      </button>

                      <button type="button" title="Refresh Product" (click)="refreshSingleProduct(id)" class="icon-option-style" [disabled]="ifRefreshInQueue(id)">
                        <span *ngIf="!ifRefreshInQueue(id); else refreshing">
                          <img src="../../../assets/img/refresh.svg" alt="refresh">
                        </span>
                        <ng-template #refreshing>
                          <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
                        </ng-template>
                      </button>
                    </ng-template>

                  </div>
                </td>

                <td class="text-one" appStickyPopover [popoverTitle]="popTitle" [autoClose]="true" data-placement="right">
                  <ng-template #popTitle>
                    <a href="{{ p.info.url }}" target="_blank">{{ p.info.title }}</a> <br>
                    <hr class="pop-line">
                    <img src="{{ p.info.images.main }}" width="200" alt="{{ p.info.title }}" />
                  </ng-template>
                  <ng-template #popContent>
                    <img src="{{ p.info.images.main }}" width="200" alt="{{ p.info.title }}" />
                  </ng-template>
                  <a>
                    {{ p.info.title }}
                  </a>
                </td>

                <td>
                  {{ p.info.product_id }}
                </td>

                <td>
                  <span class="" *ngIf="p.properties.orders_num; else template_na">
                    {{ p.properties.orders_num }}
                  </span>
                </td>

                <td>
                  <button type="button" (click)="openShippingDetailModal(p.info.product_id)" title="Country wise shipping details" class="btn btn-link text-success">
                    View Shipping
                  </button>
                </td>

                <td>
                  <button (click)="openViewDescriptionModal(id)" title="View Description" class="btn btn-link">
                    View Description
                  </button>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.pricing.price; else template_na">
                    {{ p.properties.pricing.currency }} {{ p.properties.pricing.price }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.pricing.unit; else template_na">
                    {{ p.properties.pricing.unit }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.pricing.discount_rate; else template_na">
                    {{ p.properties.pricing.discount_rate }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.pricing.bulk_price_info; else template_na">
                    {{ p.properties.pricing.bulk_price_info }}
                  </span>
                </td>

                <td>
                  <span *ngIf="p.properties.custom.recommended_selling_price; else template_na">
                    {{ p.properties.custom.recommended_selling_price }}
                  </span>
                </td>

                <td>
                  <input type="number" title="profit percentage" (change)="getProfitPercent(id, profit_perc.value)" (keyup)="getProfitPercent(id, profit_perc.value)" [(ngModel)]="p.properties.custom.profit_percentage" #profit_perc>
                </td>

                <td>
                  <span *ngIf="p.properties.unit_per_month; else template_na">
                    {{ p.properties.unit_per_month }}
                  </span>
                </td>

                <td>
                  <span *ngIf="p.properties.pricing.cost_per_month; else template_na">
                    {{ p.properties.pricing.cost_per_month }}
                  </span>
                </td>

                <td>
                  {{ p.properties.custom.estimate_revenue }}
                </td>

                <td>
                  <div class="ratings">
                    <div class="empty-stars"></div>
                    <div class="full-stars" [ngStyle]="{'width': p.properties.rating.percent + '%'}"></div>
                  </div>
                  ({{ p.properties.rating.percent_value }})
                </td>

                <td>
                  <span class="" *ngIf="p.properties.rating.count; else template_na">
                    {{ p.properties.rating.count }}
                    <i class="fas fa-thumbs-up text-success" *ngIf="p.properties.rating.count"></i>
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.packaging_details.Package_Size; else template_na">
                    {{ p.properties.packaging_details.Package_Size }}
                  </span>
                </td>
                <td>
                  <span class="" *ngIf="p.properties.packaging_details.Package_Weight; else template_na">
                    {{ p.properties.packaging_details.Package_Weight }}
                  </span>
                </td>
                <td>
                  <span class="" *ngIf="p.properties.packaging_details.Unit_Type; else template_na">
                    {{ p.properties.packaging_details.Unit_Type }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.store_info.link; else template_na">
                    <a href="{{ p.properties.store_info.link }}" target="_blank">
                      {{ p.properties.store_info?.name }}
                    </a>
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.store_info.address; else template_na">
                    {{ p.properties.store_info?.address }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.store_info.seller_id; else template_na">
                    {{ p.properties.store_info?.seller_id }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.store_info.store_id; else template_na">
                    {{ p.properties.store_info?.store_id }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="p.properties.store_info.store_time; else template_na">
                    {{ p.properties.store_info?.store_time }}
                  </span>
                </td>

                <td>
                  <ng-container *ngIf="p.properties.attributes; else template_na">
                    <div class="attributes" *ngFor="let at of p.properties.attributes | keyvalue">
                      <span class="attribute-type">{{ at.key }}</span>
                      <span class="attribute-items" *ngFor="let v of at.value">
                        <span class="attribute-items color-pallet" *ngIf="at.key === 'color'; else default_attr" [style]="{'background-color': v}"></span>
                        <ng-template #default_attr>
                          <span class="attribute-items">{{ v }}</span>
                        </ng-template>
                    </span>
                    </div>
                  </ng-container>
                </td>

              </tr>

            </ng-container>

            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>

</div>

<ng-template #template_na>
  <span class="text-danger">N/A</span>
</ng-template>
