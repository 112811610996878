<div class="modal-header">
  <h4 class="modal-title">Add new client account</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="addClientForm" (submit)="onSubmit()" id="add-client-form" #formDir="ngForm" *ngIf="!membership_checking">

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-client-name">Client Name</label>
              <input formControlName="name" type="text" class="form-control" id="input-client-name">
            </div>
            <span class="error-msg text-danger" *ngIf="f['name'] && f['name'].errors?.required">Name is required</span>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-client-email">Client Email</label>
              <input formControlName="email" type="email" class="form-control" id="input-client-email">
            </div>
            <span class="error-msg text-danger" *ngIf="f['email'] && f['email'].errors?.required">Email is required</span>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-client-password">Client Password</label>
              <input formControlName="password" type="password" class="form-control" id="input-client-password">
            </div>
            <span class="error-msg text-danger" *ngIf="f['password'] && f['password'].errors?.required">Password is required</span>
          </div>
        </div>

      </form>

      <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="membership_checking">
        <div class="loader-small"></div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer" *ngIf="!membership_checking">
  <button class="btn btn-success" type="submit" form="add-client-form" [disabled]="formDir && !formDir.valid || addClientFormSubmitted">
    <span *ngIf="!addClientFormSubmitted; else saving_loader">
      <i class="fa fa-save"></i> Save
    </span>
    <ng-template #saving_loader>
      <i class="fa fa-spinner fa-spin"></i> Saving...
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

