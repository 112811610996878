
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <div class="row">
          <div class="col-md-6">
            <h4 class="card-title">Facebook Ads</h4>
            <p class="card-category">Facebook Ads created using FB Ads Builder</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead class="text-warning">
          <th>Product Name</th>
          <th>Template Used</th>
          <th>Created on</th>
          <th>Action</th>
          </thead>
          <tbody>

          <tr *ngFor="let c of fbAds; let id = index">
            <td>{{ c.product_title }}</td>
            <td>{{ c.fb_ad_template.title }}</td>
            <th>{{ c.created | date:"medium" }}</th>
            <td>
              <button type="button" class="btn btn-success" (click)="downloadAd(c.id)" [disabled]="ifDownloading(c.id)">
                <span *ngIf="!ifDownloading(c.id); else downloading">
                  <i class="fas fa-download"></i> Download
                </span>
                <ng-template #downloading>
                  <span>
                    <i class="fas fa-spin fa-spinner"></i> Downloading...
                  </span>
                </ng-template>
              </button>

              <button type="button" class="btn btn-info" (click)="assignToClient(c.id)" *ngIf="!is_client">
                Assign to Client
              </button>

              <button type="button" class="btn btn-danger" (click)="deleteFbAd(c.id)" [disabled]="ifDeleting(c.id)" *ngIf="!is_client">
                <span *ngIf="!ifDeleting(c.id); else deleting">
                  <i class="fas fa-trash"></i> Delete
                </span>
                <ng-template #deleting>
                  <span>
                    <i class="fas fa-spin fa-spinner"></i> Deleting...
                  </span>
                </ng-template>
              </button>

            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
