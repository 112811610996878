import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {Options} from 'ng5-slider';

@Component({
  selector: 'app-filter-scraper-modal',
  templateUrl: './filter-scraper-modal.component.html',
  styleUrls: ['./filter-scraper-modal.component.scss']
})

export class FilterScraperModalComponent implements OnInit {

  @Input() filterData;
  @Input() productData: any;

  /**
   * Slider filters
   */
    // price slider
  slider_price_min = 0;
  slider_price_max = 100;
  slider_price_value_min = 0;
  slider_price_value_max = 100;
  slider_price_option: Options;

  // total sold slider
  slider_total_sold_min = 0;
  slider_total_sold_max = 100;
  slider_total_sold_value_min = 0;
  slider_total_sold_value_max = 100;
  slider_total_sold_option: Options;

  // discount slider
  slider_discount_min = 0;
  slider_discount_max = 100;
  slider_discount_value_min = 0;
  slider_discount_value_max = 100;
  slider_discount_option: Options;

  // rsp selling slider
  slider_recommended_selling_price_min = 0;
  slider_recommended_selling_price_max = 100;
  slider_recommended_selling_price_value_min = 0;
  slider_recommended_selling_price_value_max = 100;
  slider_recommended_selling_price_option: Options;

  // review slider
  slider_review_min = 0;
  slider_review_max = 100;
  slider_review_value_min = 0;
  slider_review_value_max = 100;
  slider_review_option: Options;

  // estimate unit slider
  slider_estimate_unit_min = 0;
  slider_estimate_unit_max = 100;
  slider_estimate_unit_value_min = 0;
  slider_estimate_unit_value_max = 100;
  slider_estimate_unit_option: Options;

  // estimate cost slider
  slider_estimate_cost_min = 0;
  slider_estimate_cost_max = 100;
  slider_estimate_cost_value_min = 0;
  slider_estimate_cost_value_max = 100;
  slider_estimate_cost_option: Options;

  // estimate revenue slider
  slider_estimate_revenue_min = 0;
  slider_estimate_revenue_max = 100;
  slider_estimate_revenue_value_min = 0;
  slider_estimate_revenue_value_max = 100;
  slider_estimate_revenue_option: Options;

  // rating star count
  rating_star_count = 0;

  /**
   * Constructor of class
   * @constructor
   * @param activeModal
   * @param formBuilder
   */
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit() {
    /**
     * Process products data to calculate maximum values for each filter element
     */
    this._processMaximumValues();

    // this.filterForm = this.formBuilder.group({
    //   price_min: new FormControl(),
    //   price_max: new FormControl(),
    //   total_sold_min: new FormControl(),
    //   total_sold_max: new FormControl(),
    //   discount_min: new FormControl(),
    //   discount_max: new FormControl()
    // });

    // this._setForm();

    this._setSliderValues();
    this._initializeOptions();
  }

  /**
   * Initialize ng5 slider options
   * @private
   */
  private _initializeOptions() {
    // price slider
    this.slider_price_option = {
      floor: this.slider_price_min,
      ceil: this.slider_price_max,
      step: 1,
      noSwitching: true
    };

    // total sold slider
    this.slider_total_sold_option = {
      floor: this.slider_total_sold_min,
      ceil: this.slider_total_sold_max,
      step: 1,
      noSwitching: true
    };

    // discount slider
    this.slider_discount_option = {
      floor: this.slider_discount_min,
      ceil: this.slider_discount_max,
      step: 1,
      noSwitching: true
    };

    // selling slider
    this.slider_recommended_selling_price_option = {
      floor: this.slider_recommended_selling_price_min,
      ceil: this.slider_recommended_selling_price_max,
      step: 1,
      noSwitching: true
    };

    // review slider
    this.slider_review_option = {
      floor: this.slider_review_min,
      ceil: this.slider_review_max,
      step: 1,
      noSwitching: true
    };

    // estimate unit
    this.slider_estimate_unit_option = {
      floor: this.slider_estimate_unit_min,
      ceil: this.slider_estimate_unit_max,
      step: 1,
      noSwitching: true
    };

    // estimate cost
    this.slider_estimate_cost_option = {
      floor: this.slider_estimate_cost_min,
      ceil: this.slider_estimate_cost_max,
      step: 1,
        noSwitching: true
    };

    // estimate revenue unit
    this.slider_estimate_revenue_option = {
      floor: this.slider_estimate_revenue_min,
      ceil: this.slider_estimate_revenue_max,
      step: 1,
      noSwitching: true
    };

  }

  onSubmit() {
    // const data = this.filterForm.value;

    // console.log(data);
    const filterData = {};
    if (this.slider_price_value_min > this.slider_price_min || this.slider_price_value_max < this.slider_price_max) {
      filterData['price_min'] = this.slider_price_value_min;
      filterData['price_max'] = this.slider_price_value_max;
    }
    if (this.slider_total_sold_value_min > this.slider_total_sold_min || this.slider_total_sold_value_max < this.slider_total_sold_max) {
      filterData['total_sold_min'] = this.slider_total_sold_value_min;
      filterData['total_sold_max'] = this.slider_total_sold_value_max;
    }
    if (this.slider_discount_value_min > this.slider_discount_min || this.slider_discount_value_max < this.slider_discount_max) {
      filterData['discount_min'] = this.slider_discount_value_min;
      filterData['discount_max'] = this.slider_discount_value_max;
    }
    if (this.slider_recommended_selling_price_value_min > this.slider_recommended_selling_price_min ||
      this.slider_recommended_selling_price_value_max < this.slider_recommended_selling_price_max) {
      filterData['recommended_price_min'] = this.slider_recommended_selling_price_value_min;
      filterData['recommended_price_max'] = this.slider_recommended_selling_price_value_max;
    }
    if (this.slider_review_value_min > this.slider_review_min || this.slider_review_value_max < this.slider_review_max) {
      filterData['review_min'] = this.slider_review_value_min;
      filterData['review_max'] = this.slider_review_value_max;
    }
    if (this.slider_estimate_unit_value_min > this.slider_estimate_unit_min ||
      this.slider_estimate_unit_value_max < this.slider_estimate_unit_max) {
      filterData['estimate_unit_min'] = this.slider_estimate_unit_value_min;
      filterData['estimate_unit_max'] = this.slider_estimate_unit_value_max;
    }
    if (this.slider_estimate_cost_value_min > this.slider_estimate_cost_min ||
      this.slider_estimate_cost_value_max < this.slider_estimate_cost_max) {
      filterData['estimate_cost_min'] = this.slider_estimate_cost_value_min;
      filterData['estimate_cost_max'] = this.slider_estimate_cost_value_max;
    }
    if (this.slider_estimate_revenue_value_min > this.slider_estimate_revenue_min ||
      this.slider_estimate_revenue_value_max < this.slider_estimate_revenue_max) {
      filterData['estimate_revenue_min'] = this.slider_estimate_revenue_value_min;
      filterData['estimate_revenue_max'] = this.slider_estimate_revenue_value_max;
    }
    if (this.rating_star_count > 0) {
      filterData['rating_star_count'] = this.rating_star_count;
    }
    // {
    //   price_min: this.slider_price_value_min,
    //   price_max: this.slider_price_value_max,
    //   total_sold_min: this.slider_total_sold_value_min,
    //   total_sold_max: this.slider_total_sold_value_max,
    //   discount_min: this.slider_discount_value_min,
    //   discount_max: this.slider_discount_value_max,
    //   recommended_price_min: this.slider_recommended_selling_price_value_min,
    //   recommended_price_max: this.slider_recommended_selling_price_value_max,
    //   review_min: this.slider_review_value_min,
    //   review_max: this.slider_review_value_max,
    //   estimate_unit_min: this.slider_estimate_unit_value_min,
    //   estimate_unit_max: this.slider_estimate_unit_value_max,
    //   estimate_cost_min: this.slider_estimate_cost_value_min,
    //   estimate_cost_max: this.slider_estimate_cost_value_max,
    //   estimate_revenue_min: this.slider_estimate_revenue_value_min,
    //   estimate_revenue_max: this.slider_estimate_revenue_value_max,
    //   rating_star_count: this.rating_star_count,
    // };

    // console.log(filterData);
    this.activeModal.close(filterData);

  }

  // _setForm() {
  //   console.log(this.filterData);
  //   if (this.filterData) {
  //     console.log(this.filterData);
  //     this.filterForm.setValue({
  //       price_min: this.filterData.price_min ? this.filterData.price_min : 0,
  //       price_max: this.filterData.price_max ? this.filterData.price_max : '',
  //       total_sold_min: this.filterData.total_sold_min ? this.filterData.total_sold_min : 0,
  //       total_sold_max: this.filterData.total_sold_max ? this.filterData.total_sold_max : '',
  //       discount_min: this.filterData.discount_min ? this.filterData.discount_min : 0,
  //       discount_max: this.filterData.discount_max ? this.filterData.discount_max : ''
  //     });
  //   }
  // }

  /**
   * Set slider values
   * @private
   */
  _setSliderValues() {
      // price
      this.slider_price_value_min = this.filterData && this.filterData.price_min ? this.filterData.price_min : 0;
      this.slider_price_value_max = this.filterData && this.filterData.price_max ? this.filterData.price_max : this.slider_price_max;

      // total sold
      this.slider_total_sold_value_min = this.filterData && this.filterData.total_sold_min ? this.filterData.total_sold_min : 0;
      this.slider_total_sold_value_max = this.filterData && this.filterData.total_sold_max ?
        this.filterData.total_sold_max : this.slider_total_sold_max;

      // discount
      this.slider_discount_value_min = this.filterData && this.filterData.discount_min ? this.filterData.discount_min : 0;
      this.slider_discount_value_max = this.filterData && this.filterData.discount_max ?
        this.filterData.discount_max : this.slider_discount_max;

      // recommended selling price
      this.slider_recommended_selling_price_value_min = this.filterData && this.filterData.recommended_price_min ?
        this.filterData.recommended_price_min : 0;
      this.slider_recommended_selling_price_value_max = this.filterData && this.filterData.recommended_price_max ?
        this.filterData.recommended_price_max : this.slider_recommended_selling_price_max;

      // review
      this.slider_review_value_min = this.filterData && this.filterData.review_min ? this.filterData.review_min : 0;
      this.slider_review_value_max = this.filterData && this.filterData.review_max ? this.filterData.review_max : this.slider_review_max;

      // estimate unit per month
      this.slider_estimate_unit_value_min = this.filterData && this.filterData.estimate_unit_min ? this.filterData.estimate_unit_min : 0;
      this.slider_estimate_unit_value_max = this.filterData && this.filterData.estimate_unit_max ?
        this.filterData.estimate_unit_max : this.slider_estimate_unit_max;

      // estimate cost per month
      this.slider_estimate_cost_value_min = this.filterData && this.filterData.estimate_cost_min ? this.filterData.estimate_cost_min : 0;
      this.slider_estimate_cost_value_max = this.filterData && this.filterData.estimate_cost_max ?
        this.filterData.estimate_cost_max : this.slider_estimate_cost_max;

      // estimate revenue per month
      this.slider_estimate_revenue_value_min = this.filterData && this.filterData.estimate_revenue_min ?
        this.filterData.estimate_revenue_min : 0;
      this.slider_estimate_revenue_value_max = this.filterData && this.filterData.estimate_revenue_max ?
        this.filterData.estimate_revenue_max : this.slider_estimate_revenue_max;

      // rating star count
      this.rating_star_count = this.filterData && this.filterData.rating_star_count ?
        this.filterData.rating_star_count : 0;
  }

  /**
   * Process and find maximum values for each filter element
   * @private
   */
  private _processMaximumValues() {
    let slider_price_max = -1;
    let slider_total_sold_max = -1;
    let slider_discount_max = -1;
    let slider_review_max = -1;
    let slider_estimate_unit_max = -1;
    let slider_estimate_cost_max = -1;
    let slider_estimate_revenue_max = -1;
    let slider_recommended_selling_price = -1;

    for (const item in this.productData) {
      if (this.productData.hasOwnProperty(item)) {
        /**
         * process price
         */
        const item_price = this.productData[item].properties.pricing.discount_rate;
        if (item_price > slider_price_max) {
          slider_price_max = item_price;
        }

        /**
         * process total sold
         */
        const item_total_sold = this.productData[item].properties.orders_num;
        if (item_total_sold > slider_total_sold_max) {
          slider_total_sold_max = item_total_sold;
        }

        /**
         * process discount
         */
        const item_discount = this.productData[item].properties.pricing.discount_rate;
        if (item_discount > slider_discount_max) {
          slider_discount_max = item_discount;
        }

        /**
         * process review count
         */
        const item_review_count = this.productData[item].properties.rating.count;
        if (item_review_count > slider_review_max) {
          slider_review_max = item_review_count;
        }

        /**
         * process estimated cost per month
         */
        const item_estimated_cost_month = this.productData[item].properties.pricing.cost_per_month;
        if (item_estimated_cost_month > slider_estimate_cost_max) {
          slider_estimate_cost_max = item_estimated_cost_month;
        }

        /**
         * process estimated unit per month
         */
        const item_estimated_unit_month = this.productData[item].properties.unit_per_month;
        if (item_estimated_unit_month > slider_estimate_unit_max) {
          slider_estimate_unit_max = item_estimated_unit_month;
        }

        /**
         * process estimated revenue per month
         */
        const item_estimated_revenue_month = this.productData[item].properties.pricing.estimate_revenue_per_month;
        if (item_estimated_revenue_month > slider_estimate_revenue_max) {
          slider_estimate_revenue_max = item_estimated_revenue_month;
        }

        /**
         * process recommended selling price
         */
        slider_recommended_selling_price = 100;
      }
    }

    /**
     * Set slider max values
     */
    if (slider_price_max > -1) {
      this.slider_price_max = slider_price_max;
    }

    /**
     * Set total sold slider max value
     */
    if (slider_total_sold_max > -1) {
      this.slider_total_sold_max = slider_total_sold_max;
    }

    /**
     * Set discount slider max value
     */
    if (slider_discount_max > -1) {
      this.slider_discount_max = slider_discount_max;
    }

    /**
     * Set review slider max value
     */
    if (slider_review_max > -1) {
      this.slider_review_max = slider_review_max;
    }

    /**
     * Set estimate unit slider max value
     */
    if (slider_estimate_unit_max > -1) {
      this.slider_estimate_unit_max = slider_estimate_unit_max;
    }

    /**
     * Set estimate cost max value
     */
    if (slider_estimate_cost_max > -1) {
      this.slider_estimate_cost_max = slider_estimate_cost_max;
    }

    /**
     * Set estimate revenue slider max value
     */
    if (slider_estimate_revenue_max > -1) {
      this.slider_estimate_revenue_max = slider_estimate_revenue_max;
    }

    /**
     * Set recommended selling price max value
     */
    if (slider_recommended_selling_price > -1) {
      this.slider_recommended_selling_price_max = slider_recommended_selling_price;
    }
  }
}
