import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ScraperService} from '../scraper.service';
import {FilterScraperModalComponent} from '../filter-scraper-modal/filter-scraper-modal.component';
import {FilterService} from '../filter.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductShippingModalComponent} from '../product-shipping-modal/product-shipping-modal.component';
import {CreateLandingPageModalComponent} from '../../landing-page-builder/create-landing-page-modal/create-landing-page-modal.component';
import {CreateFbAdModalComponent} from '../../fb-ads-builder/create-fb-ad-modal/create-fb-ad-modal.component';
import {
  CreateShopifyStoreModalComponent
} from '../../shopify-store-builder/create-shopify-store-modal/create-shopify-store-modal.component';
import {ProductDescriptionModalComponent} from '../product-description-modal/product-description-modal.component';
import {NotificationService} from '../../components/notification-service/notification.service';
import {FavouriteProductsService} from '../../favourite-products/favourite-products.service';
import {ScraperUtilsService} from '../scraper-utils.service';
import {GlobalProfitPercentageModalComponent} from '../global-profit-percentage-modal/global-profit-percentage-modal.component';
import {SavedSearchesService} from '../../saved-searches/saved-searches.service';
import {FormBuilder} from '@angular/forms';
import {RollbarErrorHandlerService} from '../../errors/rollbar-error-handler.service';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import * as StackTraceParser from 'error-stack-parser';

@Component({
  selector: 'app-seller-products',
  templateUrl: './seller-products.component.html',
  styleUrls: ['./seller-products.component.css']
})
export class SellerProductsComponent implements OnInit {

  seller_id;
  productUrls: Array<any> = [];
  processedItems: Array<any> = [];
  totalProductItems: Array<any> = [];

  filterData: any;

  totalSoldSum = 0;
  averageTotalSold = 0;
  totalReviews = 0;
  averageTotalReviews = 0;
  global_profit_percentage: number;
  saving_search = false;
  private downloadReviewsIndexList: Array<any> = [];
  private favouriteProductIndexList: Array<any> = [];
  private refreshingSingleProductIndexList: Array<any> = [];
  private downloadImagesIndexList: Array<any> = [];
  loading_content = true;
  pageInfo: any;
  initial_loader = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private scraperService: ScraperService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private favouriteProductService: FavouriteProductsService,
    private scraperUtilsService: ScraperUtilsService,
    private savedSearchService: SavedSearchesService,
    private formBuilder: FormBuilder,
    private rollbarService: RollbarErrorHandlerService,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      param => {
        this.seller_id = param['seller_id'];
      }
    );

    this._getSellerProducts();
  }

  private _getSellerProducts() {
    this.scraperService.fetchTopSellerProducts(this.seller_id).subscribe(
      res => {
        const response = this.scraperService.processTopSellerProducts(res.contents);
        this.productUrls = response.products;
        this.pageInfo = response.meta;

        if (response.products.length < 1) {
          this.notificationService.error({
            message: 'We could not retrieve any product for the given seller. Try again after sometime',
            title: 'No products found'
          });

          /**
           * Prepare error to send to rollback
           */
          const errorToSend = this._addContextInfo(
            'No products found for seller: ' + this.seller_id,
            res,
            response
          );

          // Log to rollbarService
          this.rollbarService.post(errorToSend);

          return false;
        }

        this._processItems(this.productUrls);
      }
    );
    //   .subscribe(
    //   res => {
    //     console.log(res);
    //   }
    // );
    // console.log(this.totalProductItems);
    // this._applyFilter();
  }

  /**
   * Add extra context data with the error message to send to server
   *
   * @param title
   * @param error
   * @param response
   * @private
   */
  private _addContextInfo(title, error, response) {
    // You can include context details here (usually coming from other services: UserService...)
    const name = title || null;
    const appId = 'vega6: SpyCom-Angular';
    const user = 'fakeuser';
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const server_response = error.error || null;
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);

    const response_data = response || null;

    return {name, appId, user, time, id, location, url, status, message, server_response, response_data, stack};
  }

  /**
   * Open filter modal
   */
  openFilterModal() {
    const filterModal = this.modalService.open(FilterScraperModalComponent, {size: 'lg'});
    filterModal.componentInstance.productData = this.totalProductItems;
    filterModal.componentInstance.filterData = this.filterData;
    filterModal.result.then(
      res => {
        this.filterData = res;
        this._applyFilter();
      }, () => {}
    );
  }

  /**
   * Filter data
   * @private
   */
  private _applyFilter() {

    if (!this.filterData) {
      this.processedItems = this.totalProductItems;
      // console.log(this.totalProductItems);

      this._calculateAverageMonthlySales(this.totalProductItems);
      this._calculateAverageReviews(this.totalProductItems);

    } else {

      /**
       * Apply filter on the total items
       */
      const product_filtered = this.filterService.filter(this.filterData, this.totalProductItems);

      this._calculateAverageMonthlySales(product_filtered);
      this._calculateAverageReviews(product_filtered);
      this.processedItems = product_filtered;

    }
    this.getFilterDataFormatted();
  }

  /**
   * Get filter data formatted by combining min-max under single key
   */
  getFilterDataFormatted() {
    const filter_dict = {};
    if (this.filterData) {
      for (const filter in this.filterData) {
        if (this.filterData.hasOwnProperty(filter)) {

          // add ratings who has min and max pair
          if (filter.includes('_min') || filter.includes('_max')) {
            const filter_dict_key = filter.replace('_min', '').replace('_max', '');
            if (!filter_dict.hasOwnProperty(filter_dict_key)) {
              filter_dict[filter_dict_key] = {
                'min': 0,
                'max': 0
              };
            }
            if (filter.includes('_min')) {
              filter_dict[filter_dict_key]['min'] = this.filterData[filter];
            }
            if (filter.includes('_max')) {
              filter_dict[filter_dict_key]['max'] = this.filterData[filter];
            }
          }

          // add star rating
          if (filter.includes('star_count')) {
            const filter_dict_key = 'rating';
            if (!filter_dict.hasOwnProperty(filter_dict_key)) {
              filter_dict[filter_dict_key] = 0;
            }
            filter_dict[filter_dict_key] = this.filterData[filter];
          }

        }
      }
    }
    return filter_dict;
  }

  /**
   * Calculate average monthly sales of the displayed products.
   * Assuming sale data for 6 months
   * @param product_filtered
   * @private
   */
  private _calculateAverageMonthlySales(product_filtered: Array<any>) {
    // console.log(product_filtered);
    let sum = 0;
    let average = 0;
    for (const item in product_filtered) {
      if (product_filtered.hasOwnProperty(item)) {
        sum += parseInt(product_filtered[item].properties.orders_num, 10);
        average = Math.floor(sum / 6);
      }
    }
    if (sum > this.totalSoldSum) {
      this.totalSoldSum = sum;
    }
    if (average > this.averageTotalSold) {
      this.averageTotalSold = average;
    }
  }

  /**
   * Calculate average reviews
   * Assuming review data for 6 months
   * @param product_filtered
   * @private
   */
  private _calculateAverageReviews(product_filtered) {
    let sum = 0;
    let average = 0;
    for (const item in product_filtered) {
      if (product_filtered.hasOwnProperty(item)) {
        sum += parseInt(product_filtered[item].properties.rating.count, 10);
        average = Math.floor(sum / 6);
      }
    }
    if (sum > this.totalReviews) {
      this.totalReviews = sum;
    }
    if (average > this.averageTotalReviews) {
      this.averageTotalReviews = average;
    }
  }
  /**
   * Refresh single product
   * @param product_index
   */
  refreshSingleProduct(product_index: number) {
    this.refreshingSingleProductIndexList.push(product_index);

    const item = this.processedItems[product_index];
    this.scraperService.scrapSingle(item.info.url).subscribe(
      res => {
        if (res.status.http_code === 200) {
          const properties = this.scraperService.processSingleProduct(res.contents);

          const p_item = {};
          p_item['info'] = item.info;
          p_item['properties'] = properties;

          p_item['info']['product_id'] = this.scraperService.getProductIdFromUrl(item.info.url);

          p_item['properties']['shipping_new'] = '';

          this.processedItems[product_index] = p_item;

          // Remove product item index from the list
          const index = this.refreshingSingleProductIndexList.indexOf(product_index, 0);
          if (index > -1) {
            this.refreshingSingleProductIndexList.splice(index, 1);
          }

        }
      }
    );
  }


  /**
   * Get total products length
   */
  getProductsLength() {
    if (this.totalProductItems) {
      return this.totalProductItems.length;
    } else {
      return 0;
    }
  }

  /**
   * Download product images
   * @param product_index
   */
  downloadImages(product_index: number) {
    // add product_index to the index list
    this.downloadImagesIndexList.push(product_index);

    const images = this.processedItems[product_index].properties.images;
    this.scraperService.downloadImages(images).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Download Images'
          });
        } else {
          const img_zip_url = res.data.url;
          // window.open(img_zip_url);

          const img_name = img_zip_url.split('/').pop();
          const a = document.createElement('a');
          a.href = img_zip_url;
          a.download = img_name;
          document.body.appendChild(a);
          a.click();
        }


        // Remove product item index from the list
        const index = this.downloadImagesIndexList.indexOf(product_index, 0);
        if (index > -1) {
          this.downloadImagesIndexList.splice(index, 1);
        }
      }
    );
  }

  /**
   * Download Reviews
   * @param id
   * @param product_id
   * @param seller_id
   */
  downloadReviews(id: number, product_id: string | any, seller_id: string | any) {
    // add index to the index list
    this.downloadReviewsIndexList.push(id);

    this.scraperService.scrapReviews(product_id, seller_id).subscribe(
      res => {
        // console.log(res.contents);
        const feeds = this.scraperService.scrapReviewItems(res.contents);

        this.scraperService.downloadReviews(feeds).subscribe(
          res2 => {
            /**
             * Notify user of the changed email successfully
             */
            if (res2.status === false) {
              const messages = res2.error.errors.message;
              this.notificationService.error({
                message: messages,
                title: 'Error: Download Reviews'
              });
            } else {
              const file_url = res2.data.url;
              // window.open(file_url);
              const file_name = file_url.split('/').pop();
              const a = document.createElement('a');
              a.href = file_url;
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
            }
          }
        );

        // Remove product item index from the list
        const index = this.downloadReviewsIndexList.indexOf(id, 0);
        if (index > -1) {
          this.downloadReviewsIndexList.splice(index, 1);
        }

      }
    );
  }

  /**
   * Add product to favourite
   * @param id
   */
  addToFavourite(id) {
    // add index to add to favourite index list
    this.favouriteProductIndexList.push(id);

    const item = this.processedItems[id];
    this.favouriteProductService.add(item).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Add Favourite'
          });
        } else {
          this.notificationService.success({
            message: 'Product added to favourite list',
            title: 'Product marked favourite'
          });
        }




        // Remove product item index from the list
        const index = this.favouriteProductIndexList.indexOf(id, 0);
        if (index > -1) {
          this.favouriteProductIndexList.splice(index, 1);
        }
      }
    );
  }


  /**
   * Get profit percent calculated
   * @param product_index
   * @param value
   */
  getProfitPercent(product_index: number, value) {
    const rsp = this.scraperUtilsService.calculateRecommendedSellingPrice(value, this.processedItems[product_index]);
    const estimate_revenue = this.scraperUtilsService.calculateEstimateRevenue(rsp, this.processedItems[product_index]);
    this.processedItems[product_index].properties.custom.profit_percentage = value;
    this.processedItems[product_index].properties.custom.recommended_selling_price = rsp;
    this.processedItems[product_index].properties.custom.estimate_revenue = estimate_revenue;
  }

  /**
   * Check if item already in query to refresh
   * @param product_index
   */
  ifRefreshInQueue(product_index: number) {
    const index = this.refreshingSingleProductIndexList.indexOf(product_index, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadReviewsInQueue(id) {
    const index = this.downloadReviewsIndexList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadImagesInQueue(id) {
    const index = this.downloadImagesIndexList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in add to favourite queue
   * @param id
   */
  ifAddToFavouriteInQueue(id) {
    const index = this.favouriteProductIndexList.indexOf(id, 0);
    return index > -1;
  }
  /**
   * Open shipping detail modal
   * @param product_id
   */
  openShippingDetailModal(product_id: number) {
    const shippingDetailModal = this.modalService.open(ProductShippingModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    shippingDetailModal.componentInstance.product_id = product_id;
    shippingDetailModal.result.then(() => {}, () => {});
  }


  /**
   * Open create landing page builder modal
   * @param product_index
   */
  openLandingPageBuilderModal(product_index: number) {
    const landingPageBuilderModal = this.modalService.open(CreateLandingPageModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    landingPageBuilderModal.componentInstance.product = this.processedItems[product_index];
    landingPageBuilderModal.result.then(() => {}, () => {});
  }

  /**
   * Open fbAdBuilderModal
   * @param id
   */
  openFbAdBuilderModal(id) {
    const fbAdBuilderModal = this.modalService.open(CreateFbAdModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    fbAdBuilderModal.componentInstance.product = this.processedItems[id];
    fbAdBuilderModal.result.then(() => {}, () => {});
  }

  openCreateShopifyModal(id) {
    const createShopifyStore = this.modalService.open(CreateShopifyStoreModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    createShopifyStore.componentInstance.product = this.processedItems[id];
    createShopifyStore.componentInstance.searchTerm = this.seller_id;
    createShopifyStore.result.then(() => {}, () => {});
  }

  /**
   * Open view description modal
   * @param id
   */
  openViewDescriptionModal(id) {
    const product = this.processedItems[id];
    const viewDescriptionModal = this.modalService.open(ProductDescriptionModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    viewDescriptionModal.componentInstance.product = product;
    viewDescriptionModal.result.then(() => {}, () => {});
  }

  /**
   * Format to human readable string
   * @param key
   */
  formatToReadableString(key: string) {
    return this.scraperUtilsService.formatToReadableString(key);
  }

  /**
   * Open global profit percentage modal
   */
  openGlobalProfitPercentageModal() {
    const modal = this.modalService.open(GlobalProfitPercentageModalComponent, {centered: true});
    modal.componentInstance.global_profit_percentage = this.global_profit_percentage;
    modal.result.then(res => {
      this.global_profit_percentage = res;
      this._calculateRecommendedSellingPrice();
    }, () => {});
  }

  /**
   * Calculate recommended selling price based on global profit percentage
   * @private
   */
  private _calculateRecommendedSellingPrice() {

    for (const item of this.totalProductItems) {
      const rsp = this.scraperUtilsService.calculateRecommendedSellingPrice(this.global_profit_percentage, item);
      const estimate_revenue = this.scraperUtilsService.calculateEstimateRevenue(rsp, item);
      item.properties.custom.recommended_selling_price = rsp;
      item.properties.custom.profit_percentage = this.global_profit_percentage;
      item.properties.custom.estimate_revenue = estimate_revenue;
    }

    this._applyFilter();

  }

  /**
   * Saved searched data
   */
  saveSearchResults() {
    this.saving_search = true;
    const json_data = JSON.stringify(this.totalProductItems);
    this.savedSearchService.add(this.seller_id, json_data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Saved Search'
          });
        } else {
          this.notificationService.success({
            message: 'Search result saved',
            title: 'Search Saved'
          });
        }
        this.saving_search = false;
      }
    );
  }

  /**
   * Process single items
   * @param productUrls
   * @private
   */
  private _processItems(productUrls: Array<any>) {
    // console.log('processing each items');
    productUrls.forEach(async (e) => {
      const res = await this.scraperService.scrapSingle(e.url).toPromise();
      if (res.status.http_code === 200) {
        const properties = this.scraperService.processSingleProduct(res.contents);

        // console.log(properties);
        const p_item = {};
        p_item['info'] = e;
        p_item['properties'] = properties;
        p_item['info']['product_id'] = this.scraperService.getProductIdFromUrl(e.url);
        p_item['properties']['shipping_new'] = '';

        this.initial_loader = false;
        this.totalProductItems.push(p_item);
        this._applyFilter();
      }

      if (this.productUrls.length === this.processedItems.length) {
        this.loading_content = false;
      }
    });
  }
}
