import { Injectable } from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {TokenService} from './token.service';
import {AuthToken, AuthTokenResponse, AuthUserData} from './auth.model';
import {AccountResponse} from '../account/account.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  cachedRequests: Array<HttpRequest<any>> = [];

  /**
   * AuthService constructor
   *
   * @param resource ResourceProviderService object
   * @param http HttpClient object
   * @param token TokenService Object
   */
  constructor(
    private resource: ResourceProviderService,
    private http: HttpClient,
    private token: TokenService
  ) { }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void {
    // retry the requests. this method can be
    // called after token is refreshed
  }

  /**
   * returns access token from token service
   */
  public accessToken(): string {
    return this.token.accessToken();
  }

  /**
   * Get authentication token credentials like access token, token secret, expires in
   * from the server for the authenticated user
   *
   * Method sends formData with client_id and client_secret and other parameters to
   * the server to authenticate user
   *
   * @param data
   */
  getAuthToken(data: any): Observable<AuthTokenResponse> {
    // const authFormData = new FormData();
    //
    // console.log('email', email);
    // console.log('password', password);
    //
    // // authFormData.append('grant_type', this.resource.grant_type);
    // // authFormData.append('client_id', this.resource.client_key);
    // // authFormData.append('client_secret', this.resource.client_secret);
    // // authFormData.append('scope', this.resource.scope);
    // authFormData.append('email', email);
    // authFormData.append('password', password);

    // return this.http.post<AuthToken>(this.resource.url + 'users/token', authFormData);
    return this.http.post<AuthTokenResponse>(this.resource.url + 'users/token', data);
  }

  /**
   * Method set credentials using token service and
   * stores in local storage of the browser
   *
   * @param response Response data returned from server of AuthToken object type
   */
  setCredentials(response: AuthToken): void {
    this.token.setCredentials(response);
  }

  /**
   * Method used to clear credentials stored in the local storage of the browser.
   * Method is called to logout the user
   */
  clearCredentials(): void {
    // console.log('clearing credentials');
    this.token.clearCredentials();
  }

  /**
   * Check for if user is authenticated
   * If user is logged in and authenticated, there must be access token entry
   * in local storage.
   *
   * Method returns true if auth key in local storage is not null and token
   * is not expired
   */
  isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    // console.log('checking isAuthenticated()');
    return !this.token.isTokenExpired();
  }

  /**
   * return true if user is valid
   *
   * @returns {boolean}
   */
  userValid(): boolean {
    return this.token.userValid();
  }

  /**
   * return token type: Bearer
   *
   * @returns {string}
   */
  tokenType(): string {
    return this.token.tokenType();
  }

  /**
   * Reset password of user
   * @param url
   * @param data
   */
  resetPassword(url: any, data: any): any {
    return this.http.post(this.resource.url + 'users/resetPassword/' + url, data);
  }

  /**
   * Forgot password
   * @param data
   */
  forgotPassword(data: any): any {
    return this.http.post(this.resource.url + 'users/forgotPassword', data);
  }

  /**
   * Set Auth user data
   * @param userData
   */
  setAuthUser(userData: AccountResponse) {
    console.log('called auth service set user');
    this.token.setUserData(userData);
  }

  /**
   * Get auth user data
   */
  getAuthUser(): AuthUserData {
    return this.token.getUserData();
  }

  /**
   * If user is super user
   */
  isSuperUser(): boolean {
    return this.token.isSuperUser();
  }

  /**
   * If user is client account
   */
  isClient(): boolean {
    return this.token.isClient();
  }

  /**
   * If user is virtual assistant
   */
  isVirtual(): boolean {
    return this.token.isVirtual();
  }
}
