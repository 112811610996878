import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ScraperService} from '../../scraper/scraper.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {CreateFbAdModalComponent} from '../../fb-ads-builder/create-fb-ad-modal/create-fb-ad-modal.component';
import {
  CreateShopifyStoreModalComponent
} from '../../shopify-store-builder/create-shopify-store-modal/create-shopify-store-modal.component';
import {ProductDescriptionModalComponent} from '../../scraper/product-description-modal/product-description-modal.component';
import {ProductShippingModalComponent} from '../../scraper/product-shipping-modal/product-shipping-modal.component';
import {CreateLandingPageModalComponent} from '../../landing-page-builder/create-landing-page-modal/create-landing-page-modal.component';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.css']
})
export class ProductDetailModalComponent implements OnInit {

  @Input() product_url: string;
  productDetail: any;
  downloading_reviews = false;
  downloading_images = false;
  creating_fb_ad = false;
  creating_shopify = false;
  view_description = false;
  open_shipping = false;
  creating_landing_page = false;

  product_loading = true;

  constructor(
    public activeModal: NgbActiveModal,
    private scraperService: ScraperService,
    protected notificationService: NotificationService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    const res = await this.scraperService.scrapSingle(this.product_url).toPromise();
    if (res.status.http_code === 200) {
      const properties = this.scraperService.processSingleProduct(res.contents);

      const p_item = {};
      p_item['properties'] = properties;

      p_item['info'] = [];
      p_item['info']['product_id'] = this.scraperService.getProductIdFromUrl(this.product_url);
      p_item['info']['title'] = properties['name'];
      p_item['info']['images'] = [];
      p_item['info']['url'] = this.product_url;
      p_item['info']['images']['main'] = properties['images'][0];

      p_item['properties']['shipping_new'] = '';

      this.productDetail = p_item;

      this.product_loading = false;
    } else {
      this.notificationService.error({
        title: 'Could not get content',
        message: `Could not get content of ${this.product_url}`
      });
      this.activeModal.close();
    }
  }

  /**
   * Download Reviews
   * @param product_id
   * @param seller_id
   */
  downloadReviews(product_id: string | any, seller_id: string | any) {
    // add index to the index list
    this.downloading_reviews = true;

    this.scraperService.scrapReviews(product_id, seller_id).subscribe(
      res => {
        // console.log(res.contents);
        const feeds = this.scraperService.scrapReviewItems(res.contents);

        this.scraperService.downloadReviews(feeds).subscribe(
          res2 => {
            /**
             * Notify user of the changed email successfully
             */
            if (res2.status === false) {
              const messages = res2.error.errors.message;
              this.notificationService.error({
                message: messages,
                title: 'Error: Download Reviews'
              });
            } else {
              const file_url = res2.data.url;
              // window.open(file_url);
              const file_name = file_url.split('/').pop();
              const a = document.createElement('a');
              a.href = file_url;
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
            }

            this.downloading_reviews = false;
          }
        );

      }
    );
  }

  /**
   * Download product images
   */
  downloadImages() {
    // add product_index to the index list
    this.downloading_images = true;

    const images = this.productDetail.properties.images;
    this.scraperService.downloadImages(images).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Download Images'
          });
        } else {
          const img_zip_url = res.data.url;
          // window.open(img_zip_url);

          const img_name = img_zip_url.split('/').pop();
          const a = document.createElement('a');
          a.href = img_zip_url;
          a.download = img_name;
          document.body.appendChild(a);
          a.click();
        }

        this.downloading_images = false;

      }
    );
  }

  /**
   * Open fbAdBuilderModal
   */
  openFbAdBuilderModal() {
    this.creating_fb_ad = true;

    const fbAdBuilderModal = this.modalService.open(CreateFbAdModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    fbAdBuilderModal.componentInstance.product = this.productDetail;
    fbAdBuilderModal.result.then(() => {
      this.creating_fb_ad = false;
    }, () => {
      this.creating_fb_ad = false;
    });
  }

  /**
   * Open Shopify modal
   */
  openCreateShopifyModal() {
    this.creating_shopify = true;

    const createShopifyStore = this.modalService.open(CreateShopifyStoreModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    createShopifyStore.componentInstance.product = this.productDetail;
    createShopifyStore.componentInstance.searchTerm = '';
    createShopifyStore.result.then(() => {
      this.creating_shopify = false;
    }, () => {
      this.creating_shopify = false;
    });
  }


  /**
   * Open view description modal
   */
  openViewDescriptionModal() {
    this.view_description = true;

    const product = this.productDetail;
    const viewDescriptionModal = this.modalService.open(ProductDescriptionModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    viewDescriptionModal.componentInstance.product = product;
    viewDescriptionModal.result.then(() => {
      this.view_description = false;
    }, () => {
      this.view_description = false;
    });
  }


  /**
   * Open shipping detail modal
   */
  openShippingDetailModal() {
    this.open_shipping = true;

    const shippingDetailModal = this.modalService.open(ProductShippingModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    shippingDetailModal.componentInstance.product_id = this.productDetail.info.product_id;
    shippingDetailModal.result.then(() => {
      this.open_shipping = false;
    }, () => {
      this.open_shipping = false;
    });
  }


  /**
   * Open create landing page builder modal
   */
  openLandingPageBuilderModal() {
    this.creating_landing_page = true;

    const landingPageBuilderModal = this.modalService.open(CreateLandingPageModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    landingPageBuilderModal.componentInstance.product = this.productDetail;
    landingPageBuilderModal.result.then(() => {
      this.creating_landing_page = false;
    }, () => {
      this.creating_landing_page = false;
    });
  }
}
