<div class="modal-header">
  <h4 class="modal-title">Global Profit Percentage</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="text-info text-center">
        Based on this provided global profit percentage, recommended selling price of the product is calculated
        <br>
        <br>
      </div>
        <input type="number" [(ngModel)]="global_profit_percentage" class="form-control" placeholder="Enter Profit Percentage">
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="onSubmit()">
      <i class="fas fa-percent"></i> Submit
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

