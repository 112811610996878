
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Created landing page</h4>
        <p class="card-category">Here is your created landing page</p>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead class="text-warning">
          <th></th>
          <th>Title</th>
          <th>Sell Price</th>
          <th>Template Used</th>
          <th>Created on</th>
          <th>Action</th>
          </thead>
          <tbody>

          <tr *ngFor="let p of landingPages; let id = index">
            <td>
              <img src="{{ p.logo }}" width="50" height="50" alt="{{ p.title }}">
            </td>
            <td>{{ p.title }}</td>
            <td>{{ p.sell_price }}</td>
            <th>{{ p.landing_page_template.title }}</th>
            <td>{{ p.created | date:"mediumDate" }}</td>
            <td>
              <button type="button" class="btn btn-success" (click)="downloadLandingPage(p.id)">
                <i class="fas fa-download"></i> Download
              </button>

              <button type="button" class="btn btn-info" (click)="assignToClient(p.id)" *ngIf="!is_client">
                Assign to Client
              </button>

              <button type="button" class="btn btn-danger" (click)="deleteLandingPage(p.id)" *ngIf="!is_client">
                <i class="fas fa-trash"></i> Delete
              </button>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
