import { Injectable } from '@angular/core';
import {AuthToken, AuthUserData} from './auth.model';
import {AccountResponse} from '../account/account.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private authToken: AuthToken;

  // key name to store auth credentials in local storage
  private local_storage_auth_key = 'authCredentials';
  private local_storage_user_data = 'authUserData';

  /**
   *
   */
  constructor(
  ) {
    this.localStorage();
  }

  /**
   * set credentials to local storage in json stringify string
   * this function also set authToken variable with saved data to access from other
   * methods to use while making request, etc
   *
   * @param authResponse
   */
  setCredentials(authResponse: AuthToken): void {
    localStorage.setItem(this.local_storage_auth_key, JSON.stringify({
      token: authResponse.token,
      expires_in: authResponse.expires_in,
      generate_time: Date.now() / 1000,             // Date.now() will return time in milliseconds which need to be converted to seconds
      user_valid: true,
      token_type: authResponse.token_type
    }));

    this.authToken = JSON.parse(localStorage.getItem(this.local_storage_auth_key));
  }

  /**
   * this method clears the credentials saved in the local storage
   * and set auth key with null,
   * this method is called during logout of user
   */
  clearCredentials(): void {
    localStorage.setItem(this.local_storage_auth_key, null);
    this.authToken = null;

    // Delete auth user data
    localStorage.setItem(this.local_storage_user_data, null);
    // console.log('credentials cleared');
  }

  /**
   * check if saved access token is expired or not,
   * this method returns true if authToken is set to null, or
   * the access token is expired
   */
  isTokenExpired(): boolean {
    // if authToken is null,
    // return true
    if (this.authToken === null) {
      return true;
    }

    // console.log('authToken obj is not null');
    // console.log(this.accessToken());

    // if token is expired,
    // return true;
    return this.generateTime() + this.expiresIn() < Date.now() / 1000;
  }

  /**
   * this method set authToken variable with the content of local stored access token
   * credentials
   */
  localStorage() {
    this.authToken = JSON.parse(localStorage.getItem(this.local_storage_auth_key));
  }

  /**
   * return access token
   */
  accessToken(): string {
    return this.authToken ? this.authToken.token : null;
  }

  /**
   * return expires_in
   */
  expiresIn(): number {
    return this.authToken ? this.authToken.expires_in : null;
  }
  /**
   * return generate_time
   *
   * @returns {number}
   */
  generateTime(): number {
    return this.authToken ? this.authToken.generate_time : null;
  }

  /**
   * return user_valid
   * @returns {boolean}
   */
  userValid(): boolean {
    return this.authToken ? this.authToken.user_valid : false;
  }

  /**
   * return token_type
   */
  tokenType(): string {
    return this.authToken ? this.authToken.token_type : null;
  }

  /**
   * Get user profile and store in local storage
   */
  setUserData(userData: AccountResponse): void {
    localStorage.setItem(this.local_storage_user_data, JSON.stringify({
      id: userData.data.id,
      name: userData.data.name,
      email: userData.data.email,
      is_client: userData.data.is_client,
      is_virtual: userData.data.is_virtual_assistant,
      is_super_admin: userData.data.super_admin
    }));
  }

  /**
   * Get authenticated user data
   */
  getUserData(): AuthUserData {
    return JSON.parse(localStorage.getItem(this.local_storage_user_data));
  }

  /**
   * Check if user is super user
   */
  isSuperUser(): boolean {
    const userData = this.getUserData();
    return userData.is_super_admin;
  }

  /**
   * Check if user account is a client account
   */
  isClient(): boolean {
    const userData = this.getUserData();
    return userData.is_client;
  }

  /**
   * Check if user account is a virtual assistant account
   */
  isVirtual(): boolean {
    const userData = this.getUserData();
    return userData.is_virtual;
  }
}
