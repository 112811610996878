import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ScraperService} from '../scraper.service';

@Component({
  selector: 'app-product-description-modal',
  templateUrl: './product-description-modal.component.html',
  styleUrls: ['./product-description-modal.component.css']
})
export class ProductDescriptionModalComponent implements OnInit {

  @Input() product;

  descriptionContent: string;
  loading_description = false;

  constructor(
    public activeModal: NgbActiveModal,
    private scraperService: ScraperService
  ) { }

  ngOnInit() {
    /**
     * fetch description content
     */
    this._getDescription();
  }

  /**
   * Get description of the product
   * @private
   */
  private _getDescription() {
    this.loading_description = true;

    const desc_page_url = this.product ? this.product.properties.description_page_url : '';

    this.scraperService.getDescription(desc_page_url).subscribe(
      res => {
        this.descriptionContent = res.contents;
        // console.log(res);
        this.loading_description = false;
      }
    );
  }
}
