import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListShopifyCredentialsComponent } from './list-shopify-credentials/list-shopify-credentials.component';
import { DeleteShopifyCredentialModalComponent } from './delete-shopify-credential-modal/delete-shopify-credential-modal.component';
import {RouterModule} from '@angular/router';
import {ShopifyStoreBuilderRouting} from './shopify-store-builder.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddShopifyCredentialModalComponent} from './add-shopify-credential-modal/add-shopify-credential-modal.component';
import { ListShopifyStoreComponent } from './list-shopify-store/list-shopify-store.component';
import { DeleteShopifyStoreModalComponent } from './delete-shopify-store-modal/delete-shopify-store-modal.component';
import { CreateShopifyStoreModalComponent } from './create-shopify-store-modal/create-shopify-store-modal.component';
import { DeleteShopifyProductModalComponent } from './delete-shopify-product-modal/delete-shopify-product-modal.component';
import { ShopifyErrorModalComponent } from './shopify-error-modal/shopify-error-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ShopifyStoreBuilderRouting),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ListShopifyCredentialsComponent,
    DeleteShopifyCredentialModalComponent,
    AddShopifyCredentialModalComponent,
    ListShopifyStoreComponent,
    DeleteShopifyStoreModalComponent,
    CreateShopifyStoreModalComponent,
    DeleteShopifyProductModalComponent,
    ShopifyErrorModalComponent
  ],
  entryComponents: [
    DeleteShopifyCredentialModalComponent,
    AddShopifyCredentialModalComponent,
    DeleteShopifyStoreModalComponent,
    CreateShopifyStoreModalComponent,
    DeleteShopifyProductModalComponent,
    ShopifyErrorModalComponent
  ]
})
export class ShopifyStoreBuilderModule { }
