<div class="modal-header">
  <h4 class="modal-title">Add new shopify credential</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-info">
        Tutorial to created Shopify App can be found <a href="https://help.shopify.com/en/api/getting-started/making-your-first-request" target="_blank"> here</a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="shopifyCredentialForm" (submit)="onSubmit()" id="add-shopify-credential-form" #ngForm="ngForm">

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-app-name">App Name</label>
              <input formControlName="app_name" class="form-control" id="input-app-name">
            </div>
            <span class="error-msg text-danger" *ngIf="f['app_name'] && f['app_name'].errors?.required">App name is required</span>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-store-url">Store URL</label>
              <input formControlName="store_url" class="form-control" id="input-store-url" placeholder="mystore.myshopify.com">
            </div>
            <span class="error-msg text-danger" *ngIf="f['store_url'] && f['store_url'].errors?.required">Store URL is required</span>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-api-key">API Key</label>
              <input formControlName="api_key" class="form-control" id="input-api-key">
            </div>
            <span class="error-msg text-danger" *ngIf="f['api_key'] && f['api_key'].errors?.required">API Key is required</span>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-api-password">API Password</label>
              <input formControlName="api_password" type="password" class="form-control" id="input-api-password">
            </div>
            <span class="error-msg text-danger" *ngIf="f['api_password'] && f['api_password'].errors?.required">API Password is required</span>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-shared-secret">Shared Secret</label>
              <input formControlName="shared_secret" class="form-control" id="input-shared-secret">
            </div>
            <span class="error-msg text-danger" *ngIf="f['shared_secret'] && f['shared_secret'].errors?.required">Shared Secret is required</span>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="bmd-label-floating" for="input-storefront-access-token">Storefront Access Token</label>
              <input formControlName="storefront_access_token" class="form-control" id="input-storefront-access-token">
            </div>
            <span class="error-msg text-danger" *ngIf="f['storefront_access_token'] && f['storefront_access_token'].errors?.required">Storefront Access Token is required</span>
          </div>
        </div>

      </form>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="submit" form="add-shopify-credential-form" [disabled]="!ngForm.valid || shopifyCredentialFormSubmitted">
    <span *ngIf="!shopifyCredentialFormSubmitted; else saving_loader">
      <i class="fa fa-save"></i> Save
    </span>
    <ng-template #saving_loader>
      <i class="fa fa-spinner fa-spin"></i> Saving...
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

