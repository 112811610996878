import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {FavouriteProductSingleResponse, FavouriteProductsResponse} from './favourite-products.model';
import {CustomErrorResponse} from '../errors/error-response.model';

@Injectable({
  providedIn: 'root'
})
export class FavouriteProductsService {

  url = 'favourite_products/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * Add product to favourite
   * @param item
   */
  add(item: any): any {
    const url = `${this.resource.url}${this.url}add`;
    const data = {
      'name': item.info.title,
      'product_key': item.info.product_id,
      'content': JSON.stringify(item)
    };
    return this.http.Post(url, data);
  }

  /**
   * List all favourite marked products
   */
  list(): Observable<FavouriteProductsResponse> | any {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get<FavouriteProductsResponse>(url);
  }

  /**
   * Delete favourite marked product
   * @param id
   */
  delete(id: number): any {
    const url = `${this.resource.url}${this.url}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Update content of favourite product in server
   * @param id
   * @param content
   */
  updateContent(id: number, content: string): Observable<FavouriteProductSingleResponse> | any {
    const url = `${this.resource.url}${this.url}updateContent/${id}`;

    const data = {
      content: content
    };
    return this.http.Post<FavouriteProductSingleResponse>(url, data);
  }
}
