import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialComponent } from './tutorial/tutorial.component';
import {RouterModule} from '@angular/router';
import {TutorialRoutes} from './tutorial.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(TutorialRoutes)
  ],
  declarations: [
    TutorialComponent
  ]
})
export class TutorialModule { }
