import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ClientActionsService} from '../client-actions.service';
import {ClientAccountService} from '../client-account.service';
import {ClientAccountData} from '../client-account.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../components/notification-service/notification.service';

@Component({
  selector: 'app-assign-to-client-modal',
  templateUrl: './assign-to-client-modal.component.html',
  styleUrls: ['./assign-to-client-modal.component.css']
})
export class AssignToClientModalComponent implements OnInit {

  @Input() itemId: number;
  @Input() type: string;

  loading_clients = true;

  clients: Array<ClientAccountData>;

  assignForm: FormGroup;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private clientActions: ClientActionsService,
    private clientAccountService: ClientAccountService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    /**
     * Get list of clients created
     */
    this.clientAccountService.list().subscribe(
      res => {
        this.clients = res.data;
        this.loading_clients = false;
      }
    );

    /**
     * Initiate form
     */
    this.assignForm = this.fb.group({
      client_account_id: new FormControl('', [
        Validators.required
      ])
    });
  }

  /**
   * Handle form submit
   */
  onSubmit() {
    this.submitted = true;

    if (this.assignForm.invalid) {
      this.submitted = false;
      return;
    }

    const data = this.assignForm.value;

    switch (this.type) {
      case 'favourite_products':
        data['favourite_product_id'] = this.itemId;
        this.clientActions.addFavouriteProduct(data).subscribe(
          res => {
            this._processResponse(res);
          }
        );
        break;
      case 'fb_ads':
        data['fb_ad_id'] = this.itemId;
        this.clientActions.addFbAd(data).subscribe(
          res => {
            this._processResponse(res);
          }
        );
        break;
      case 'landing_page':
        data['landing_page_id'] = this.itemId;
        this.clientActions.addLandingPage(data).subscribe(
          res => {
            this._processResponse(res);
          }
        );
        break;
      case 'saved_search':
        data['saved_search_id'] = this.itemId;
        this.clientActions.addSavedSearch(data).subscribe(
          res => {
            this._processResponse(res);
          }
        );
        break;
      case 'shopify_store':
        data['shopify_product_id'] = this.itemId;
        this.clientActions.addShopifyProduct(data).subscribe(
          res => {
            this._processResponse(res);
          }
        );
        break;
      default:
        this.notificationService.error({
          title: 'Unknown service',
          message: 'Unknown service type detected'
        });
        this.activeModal.close();
    }
  }

  /**
   * Process response
   * @param res
   * @private
   */
  private _processResponse(res) {
    /**
     * Notify user of the changed email successfully
     */
    if (res.status === false) {
      const messages = res.error.errors.message;
      this.notificationService.error({
        message: messages,
        title: 'Error: Assign to Client'
      });
    } else {
      this.notificationService.success({
        message: 'Record assigned to the client account successfully',
        title: 'Assigned to Client'
      });
      this.activeModal.close();
    }
  }
}
