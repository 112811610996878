import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {FavouriteProductSingleResponse, FavouriteProductsResponse} from '../favourite-products/favourite-products.model';
import {FbAdsResponse, FbAdsSingleResponse} from '../fb-ads-builder/fb-ads.model';
import {LandingPageResponse, LandingPageSingleResponse} from '../landing-page-builder/landing-page-builder.model';
import {SavedSearchesResponse, SavedSearchSingleResponse} from '../saved-searches/saved-searches.model';
import {ShopifyProductSingleResponse, ShopifyProductsResponse} from '../shopify-store-builder/shopify-store-builder.model';

@Injectable({
  providedIn: 'root'
})
export class ClientActionsService {

  private client_favourite_products_endpoint = 'client_favourite_products/';
  private client_fb_ads_endpoint = 'client_fb_ads/';
  private client_landing_pages_endpoint = 'client_landing_pages/';
  private client_saved_searches_endpoint = 'client_saved_searches/';
  private client_shopify_products_endpoint = 'client_shopify_products/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * List all favourite products of the client user
   */
  listFavouriteProducts(): Observable<FavouriteProductsResponse> {
    const url = `${this.resource.url}${this.client_favourite_products_endpoint}`;

    return this.http.Get(url);
  }

  /**
   * List all fb ads of the client user
   */
  listFbAds(): Observable<FbAdsResponse> {
    const url = `${this.resource.url}${this.client_fb_ads_endpoint}`;

    return this.http.Get(url);
  }

  /**
   * List all landing pages of the client user
   */
  listLandingPages(): Observable<LandingPageResponse> {
    const url = `${this.resource.url}${this.client_landing_pages_endpoint}`;

    return this.http.Get(url);
  }

  /**
   * List all saved searches of the client user
   */
  listSavedSearches(): Observable<SavedSearchesResponse> {
    const url = `${this.resource.url}${this.client_saved_searches_endpoint}`;

    return this.http.Get(url);
  }

  /**
   * List all shopify products of the user
   */
  listShopifyProducts(): Observable<ShopifyProductsResponse> {
    const url = `${this.resource.url}${this.client_shopify_products_endpoint}`;

    return this.http.Get(url);
  }

  /**
   * Add new item to the favourite products to the client user
   * @param data
   */
  addFavouriteProduct(data): Observable<FavouriteProductSingleResponse> | any {
    const url = `${this.resource.url}${this.client_favourite_products_endpoint}add`;

    return this.http.Post(url, data);
  }

  /**
   * Add new Fb ad to the client user
   * @param data
   */
  addFbAd(data): Observable<FbAdsSingleResponse> | any {
    const url = `${this.resource.url}${this.client_fb_ads_endpoint}add`;

    return this.http.Post(url, data);
  }

  /**
   * Add new landing page to the client user
   * @param data
   */
  addLandingPage(data): Observable<LandingPageSingleResponse> | any {
    const url = `${this.resource.url}${this.client_landing_pages_endpoint}add`;

    return this.http.Post(url, data);
  }

  /**
   * Add new saved search record to the client user
   * @param data
   */
  addSavedSearch(data): Observable<SavedSearchSingleResponse> | any {
    const url = `${this.resource.url}${this.client_saved_searches_endpoint}add`;

    return this.http.Post(url, data);
  }

  /**
   * Add shopify product to the client user
   * @param data
   */
  addShopifyProduct(data): Observable<ShopifyProductSingleResponse> | any {
    const url = `${this.resource.url}${this.client_shopify_products_endpoint}add`;

    return this.http.Post(url, data);
  }

  /**
   * Delete client assigned favourite product
   * @param id
   */
  deleteFavouriteProduct(id: number): any {
    const url = `${this.resource.url}${this.client_favourite_products_endpoint}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Delete fb ad assigned to the client user
   * @param id
   */
  deleteFbAd(id: number) {
    const url = `${this.resource.url}${this.client_fb_ads_endpoint}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Delete landing page assigned to the client user
   * @param id
   */
  deleteLandingPage(id: number) {
    const url = `${this.resource.url}${this.client_landing_pages_endpoint}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Delete saved search assigned to the client user
   * @param id
   */
  deleteSavedSearch(id: number): any {
    const url = `${this.resource.url}${this.client_saved_searches_endpoint}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Delete shopify product assigned to the client user
   * @param id
   */
  deleteShopifyProduct(id: number): any {
    const url = `${this.resource.url}${this.client_shopify_products_endpoint}delete/${id}`;

    return this.http.Delete(url);
  }

}
