import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-global-profit-percentage-modal',
  templateUrl: './global-profit-percentage-modal.component.html',
  styleUrls: ['./global-profit-percentage-modal.component.css']
})
export class GlobalProfitPercentageModalComponent implements OnInit {

  @Input() global_profit_percentage: number;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  /**
   * Handle submit of form
   */
  onSubmit() {
    this.activeModal.close(this.global_profit_percentage);
  }

}
