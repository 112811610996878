import { Component, OnInit } from '@angular/core';
import {LandingPageData} from '../landing-page-builder.model';
import {LandingPageBuilderService} from '../landing-page-builder.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {AssignToClientModalComponent} from '../../client-account/assign-to-client-modal/assign-to-client-modal.component';
import {NgbModal, NgbModalConfig, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../auth/auth.service';
import {ClientActionsService} from '../../client-account/client-actions.service';

@Component({
  selector: 'app-list-landing-page',
  templateUrl: './list-landing-page.component.html',
  styleUrls: ['./list-landing-page.component.css']
})
export class ListLandingPageComponent implements OnInit {

  landingPages: Array<LandingPageData>;

  is_client = false;

  constructor(
    private landingPageService: LandingPageBuilderService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private authService: AuthService,
    private clientActions: ClientActionsService
  ) { }

  ngOnInit() {
    // if use is client
    this.is_client = this.authService.isClient();

    this._getLandingPages();
  }

  private _getLandingPages() {
    if (this.is_client) {
      // Get for client
      this.clientActions.listLandingPages().subscribe(
        res => {
          this.landingPages = res.data;
        }
      );
    } else {
      // Get for user
      this.landingPageService.list().subscribe(
        res => {
          this.landingPages = res.data;
        }
      );
    }
  }

  /**
   * Delete landing page
   * @param id
   */
  deleteLandingPage(id: number) {
    this.landingPageService.delete(id).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Landing pages'
          });
        } else {
          this.notificationService.success({
            message: 'Landing page deleted successfully',
            title: 'Landing page deleted'
          });
          this._getLandingPages();
        }
      }
    );
  }

  downloadLandingPage(id: number) {
    this.landingPageService.download(id).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Creating Landing page'
          });
        } else {
          if (res.url) {
            const file_url = res.url;
            // window.open(file_url);
            const file_name = file_url.split('/').pop();
            const a = document.createElement('a');
            a.href = file_url;
            a.download = file_name;
            document.body.appendChild(a);
            a.click();
          } else {
            this.notificationService.error({
              message: 'Archive could not be downloaded',
              title: 'Error: Download Landing page'
            });
          }
        }
      }
    );
  }

  /**
   * Assign item to the client account
   * @param id
   */
  assignToClient(id: number) {
    const assignToClient = this.modalService.open(AssignToClientModalComponent, {size: 'lg'});
    assignToClient.componentInstance.itemId = id;
    assignToClient.componentInstance.type = 'landing_page';
    assignToClient.result.then(() => {}, () => {});
  }
}
