import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import {RouterModule} from '@angular/router';
import {DashboardRoutes} from './dashboard.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataTableModule} from 'angular-6-datatable';
import {SharedModule} from '../shared/shared.module';
import { ProductDetailModalComponent } from './product-detail-modal/product-detail-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(DashboardRoutes),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DataTableModule,
    SharedModule
  ],
  declarations: [
    DashboardComponent,
    ProductDetailModalComponent
  ],
  entryComponents: [
    ProductDetailModalComponent
  ]
})
export class DashboardModule { }
