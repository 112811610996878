import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {FbAdsResponse} from './fb-ads.model';

@Injectable({
  providedIn: 'root'
})
export class FbAdsBuilderService {

  url = 'fb_ads/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * Download image path
   * @param productImagePath
   */
  getImagePath(productImagePath: string): any {
    const url = `${this.resource.url}${this.url}downloadImage`;

    const data = {
      image: productImagePath
    };
    return this.http.Post(url, data);
  }

  /**
   * Add base64 data and fb ad builder record
   * @param data
   */
  add(data: any): any {
    const url = `${this.resource.url}${this.url}add`;

    return this.http.Post(url, data);
  }

  /**
   * List all created ads
   */
  list(): Observable<FbAdsResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get<FbAdsResponse>(url);
  }

  /**
   * Delete fb ads
   * @param id
   */
  delete(id: number): any {
    const url = `${this.resource.url}${this.url}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Download the already created fb ad
   * @param id
   */
  download(id: number): any {
    const url = `${this.resource.url}${this.url}downloadAd/${id}`;

    return this.http.Get(url);
  }
}
