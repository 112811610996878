import {Component, Input, OnInit} from '@angular/core';
import {ShopifyStoreBuilderService} from '../shopify-store-builder.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopifyCredentialData} from '../shopify-store-builder.model';
import {NotificationService} from '../../components/notification-service/notification.service';

@Component({
  selector: 'app-delete-shopify-credential-modal',
  templateUrl: './delete-shopify-credential-modal.component.html',
  styleUrls: ['./delete-shopify-credential-modal.component.css']
})
export class DeleteShopifyCredentialModalComponent implements OnInit {

  @Input() credential_id: number;
  deleting = false;

  shopifyCredential: ShopifyCredentialData;

  constructor(
    private shopifyStoreBuilderService: ShopifyStoreBuilderService,
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    /**
     * Get Shopify Credential Detail
     */
    this._getShopifyCredential();
  }

  /**
   * Get shopify credential data
   * @private
   */
  private _getShopifyCredential() {
    this.shopifyStoreBuilderService.getCredential(this.credential_id).subscribe(
      res => {
        this.shopifyCredential = res.data;
      }
    );
  }

  /**
   * Delete credential now
   */
  deleteCredentialNow() {
    this.deleting = true;


    this.shopifyStoreBuilderService.deleteCredential(this.credential_id).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        let messages = '';
        if (res.status === false) {
          if (res.data) {
            if (res.data.message) {
              messages = res.data.message;
            }
          } else if (res.error) {
            messages = res.error.errors.message;
          } else {
            messages = 'Unknown error';
          }
          this.notificationService.error({
            message: messages,
            title: 'Error: Shopify Credential Delete'
          });

          this.deleting = false;
        } else {
          this.notificationService.success({
            message: 'Shopify Credential deleted successfully',
            title: 'Shopify credential deleted'
          });
          this.activeModal.close();
        }
      }
    );
  }
}
