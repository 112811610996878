<div class="modal-header">
  <h4 class="modal-title">Filter Products</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="row padding-20">
        <div class="col-md-4">
          <label>Price</label>
          <ng5-slider [(value)]="slider_price_value_min" [(highValue)]="slider_price_value_max" [options]="slider_price_option"></ng5-slider>
        </div>
        <div class="col-md-4">
          <label>Total Sold</label>
          <ng5-slider [(value)]="slider_total_sold_value_min" [(highValue)]="slider_total_sold_value_max" [options]="slider_total_sold_option"></ng5-slider>
        </div>
        <div class="col-md-4">
          <label>Discount</label>
          <ng5-slider [(value)]="slider_discount_value_min" [(highValue)]="slider_discount_value_max" [options]="slider_discount_option"></ng5-slider>
        </div>
      </div>
      <div class="row padding-20">
        <div class="col-md-6">
          <label>Recommended Selling Price </label>
          <ng5-slider [(value)]="slider_recommended_selling_price_value_min" [(highValue)]="slider_recommended_selling_price_value_max" [options]="slider_recommended_selling_price_option"></ng5-slider>
        </div>

        <div class="col-md-6">
          <label>Rating</label>
          <div class="rate">
            <ngb-rating [(rate)]="rating_star_count" max="5"></ngb-rating>
          </div>
          <div class="range-value">
            Value : <span *ngIf="rating_star_count; else star_any"> {{rating_star_count}} </span> <ng-template #star_any>any</ng-template>
          </div>
        </div>

      </div>

      <div class="row padding-20">
        <div class="col-md-6">
          <label>Review</label>
          <ng5-slider [(value)]="slider_review_value_min" [(highValue)]="slider_review_value_max" [options]="slider_review_option"></ng5-slider>
        </div>
        <div class="col-md-6">
          <label>Estimate unit/month</label>
          <ng5-slider [(value)]="slider_estimate_unit_value_min" [(highValue)]="slider_estimate_unit_value_max" [options]="slider_estimate_unit_option"></ng5-slider>
        </div>
      </div>
      <div class="row padding-20">
        <div class="col-md-6">
          <label>Estimate cost/month</label>
          <ng5-slider [(value)]="slider_estimate_cost_value_min" [(highValue)]="slider_estimate_cost_value_max" [options]="slider_estimate_cost_option"></ng5-slider>
        </div>
        <div class="col-md-6">
          <label>Estimate Revenue/month</label>
          <ng5-slider [(value)]="slider_estimate_revenue_value_min" [(highValue)]="slider_estimate_revenue_value_max" [options]="slider_estimate_revenue_option"></ng5-slider>
        </div>
      </div>
    </div>

  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="onSubmit()">Filter</button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

