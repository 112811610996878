import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResourceProviderService {

  private _allOriginUrl = 'https://allorigins.me/get?url=';
  private _allOriginServer_1 = 'http://67.209.122.13:8081/get?url=';
  // private _allOriginServer_2 = 'https://shramikallahabad.org/get?url=';
  // private _allOriginServer_2 = 'https://spycom.icu/get?url=';
   private _allOriginServer_2 = 'https://api.allorigins.win/get?url=';
  private _url = 'https://server.spycom.io/api/';
  // private _url = ' https://b3d599cc.ngrok.io/api/';

  private _aliExpressUrl = '';

  constructor() { }

  /**
   * Get AllOriginUrl
   */
  get allOriginUrl(): string {
    // return this._allOriginUrl;
    // return this._allOriginServer_1;
    return this._allOriginServer_2;
  }

  /**
   * Get AliExpress URL
   */
  get aliExpressUrl(): string {
    return this._aliExpressUrl;
  }

  /**
   * Get AllOrigin Server 1 URL
   */
  get allOriginServer_1(): string {
    return this._allOriginServer_1;
  }

  /**
   * Get url
   */
  get url(): string {
    return this._url;
  }
}
