import { Component, OnInit } from '@angular/core';
import {FavouriteProductsService} from '../favourite-products.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {FavouriteProductData} from '../favourite-products.model';
import {ProductShippingModalComponent} from '../../scraper/product-shipping-modal/product-shipping-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ScraperService} from '../../scraper/scraper.service';
import {CreateLandingPageModalComponent} from '../../landing-page-builder/create-landing-page-modal/create-landing-page-modal.component';
import {ScraperUtilsService} from '../../scraper/scraper-utils.service';
import {
  CreateShopifyStoreModalComponent
} from '../../shopify-store-builder/create-shopify-store-modal/create-shopify-store-modal.component';
import {CreateFbAdModalComponent} from '../../fb-ads-builder/create-fb-ad-modal/create-fb-ad-modal.component';
import {ProductDescriptionModalComponent} from '../../scraper/product-description-modal/product-description-modal.component';
import {AuthService} from '../../auth/auth.service';
import {AssignToClientModalComponent} from '../../client-account/assign-to-client-modal/assign-to-client-modal.component';
import {ClientActionsService} from '../../client-account/client-actions.service';


@Component({
  selector: 'app-list-favourite-products',
  templateUrl: './list-favourite-products.component.html',
  styleUrls: ['./list-favourite-products.component.css']
})
export class ListFavouriteProductsComponent implements OnInit {

  favouriteProducts: Array<FavouriteProductData>;
  is_client = false;

  /**
   * Store the index of the products which are refreshing
   */
  refreshingSingleProductIndexList = [];
  downloadReviewsIndexList = [];
  downloadImagesIndexList = [];
  private decoding_json: boolean;

  constructor(
    private favouriteProductsService: FavouriteProductsService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private scraperService: ScraperService,
    private scraperUtilsService: ScraperUtilsService,
    private authService: AuthService,
    private clientActions: ClientActionsService
  ) { }

  ngOnInit() {
    // Check if logged in user is client or not
    this.is_client = this.authService.isClient();

    /**
     * Get favourite products
     */
    this._getFavouriteProducts();
  }

  private _getFavouriteProducts() {
    if (this.is_client) {
      // Get client accounts favourite products
      this.clientActions.listFavouriteProducts().subscribe(
        res => {
          this.favouriteProducts = res.data;
        }
      );
    } else {
      // Get for user
      this.favouriteProductsService.list().subscribe(
        res => {
            this.favouriteProducts = res.data;
        }
      );
    }
  }

  /**
   * Refresh single product
   * @param product_index
   */
  refreshSingleProduct(product_index: number) {
    this.refreshingSingleProductIndexList.push(product_index);

    const item_ = this.favouriteProducts[product_index];
    const item = JSON.parse(item_.content);

    this.scraperService.scrapSingle(item.info.url).subscribe(
      res => {
        if (res.status.http_code === 200) {
          const properties = this.scraperService.processSingleProduct(res.contents);

          const p_item = {};
          p_item['info'] = item.info;
          p_item['properties'] = properties;

          p_item['info']['product_id'] = this.scraperService.getProductIdFromUrl(item.info.url);

          p_item['properties']['shipping_new'] = '';

          this.favouriteProducts[product_index].content = JSON.stringify(p_item);

          this._updateFavouriteProductContent(this.favouriteProducts[product_index].id, JSON.stringify(p_item));

          // Remove product item index from the list
          const index_ = this.refreshingSingleProductIndexList.indexOf(product_index, 0);
          if (index_ > -1) {
            this.refreshingSingleProductIndexList.splice(index_, 1);
          }

        }
      }
    );
  }



  /**
   * Open shipping detail modal
   * @param product_id
   */
  openShippingDetailModal(product_id: number) {
    const shippingDetailModal = this.modalService.open(ProductShippingModalComponent, {size: 'lg'});
    shippingDetailModal.componentInstance.product_id = product_id;
    shippingDetailModal.result.then(() => {}, () => {});
  }

  /**
   * Download product images
   * @param product_index
   */
  downloadImages(product_index: number) {
    // add index to download image index list
    this.downloadImagesIndexList.push(product_index);

    // const images = this.processedItems[product_index].properties.images;
    const images = [];
    this.scraperService.downloadImages(images).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Download Images'
          });
        } else {
          const img_zip_url = res.data.url;
          // window.open(img_zip_url);
          const file_name = img_zip_url.split('/').pop();
          const a = document.createElement('a');
          a.href = img_zip_url;
          a.download = file_name;
          document.body.appendChild(a);
          a.click();
        }

        // Remove product item index from the list
        const index_ = this.downloadImagesIndexList.indexOf(product_index, 0);
        if (index_ > -1) {
          this.downloadImagesIndexList.splice(index_, 1);
        }
      }
    );
  }

  /**
   * Download Reviews
   * @param _index
   * @param product_id
   * @param seller_id
   */
  downloadReviews(_index: number, product_id: string | any, seller_id: string | any) {
    // add index to download review index list
    this.downloadReviewsIndexList.push(_index);

    this.scraperService.scrapReviews(product_id, seller_id).subscribe(
      res => {
        // console.log(res);
        // console.log(res.contents);
        const feeds = this.scraperService.scrapReviewItems(res.contents);

        this.scraperService.downloadReviews(feeds).subscribe(
          res2 => {
            /**
             * Notify user of the changed email successfully
             */
            if (res2.status === false) {
              const messages = res2.error.errors.message;
              this.notificationService.error({
                message: messages,
                title: 'Error: Download Reviews'
              });
            } else {
              const file_url = res2.data.url;
              // window.open(file_url);
              const file_name = file_url.split('/').pop();
              const a = document.createElement('a');
              a.href = file_url;
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
            }


            // Remove product item index from the list
            const index_ = this.downloadReviewsIndexList.indexOf(_index, 0);
            if (index_ > -1) {
              this.downloadReviewsIndexList.splice(index_, 1);
            }
          }
        );
      }
    );
  }

  /**
   * Remove product from favourite list
   * @param id
   */
  removeFromFavourite(id) {
    if (this.is_client) {
      // Delete for client
      this.clientActions.deleteFavouriteProduct(id).subscribe(
        res => {
          this._processResponse(res);
        }
      );
    } else {
      // Delete for the user
      this.favouriteProductsService.delete(id).subscribe(
        res => {
          this._processResponse(res);
        }
      );
    }
  }

  /**
   * Process response
   * @param res
   * @private
   */
  private _processResponse(res) {

    /**
     * Notify user of the changed email successfully
     */
    if (res.status === false) {
      const messages = res.error.errors.message;
      this.notificationService.error({
        message: messages,
        title: 'Error: Delete Favourite Product'
      });
    } else {
      this.notificationService.success({
        message: 'Product removed from favourite list',
        title: 'Favourite Product removed'
      });

      this._getFavouriteProducts();
    }
  }


  /**
   * Json Decode contents of item
   * @param content
   */
  jsonDecode(content) {
    this.decoding_json = true;
    return JSON.parse(content);
  }

  /**
   * Update Favourite Product content in server
   * @param id
   * @param content
   * @private
   */
  private _updateFavouriteProductContent(id: number, content: string) {
    this.favouriteProductsService.updateContent(id, content).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Update Favourite Product'
          });
        } else {
          this.notificationService.success({
            message: 'Favourite Product updated successfully',
            title: 'Favourite Product updated'
          });
        }
      }
    );
  }


  /**
   * Open create landing page builder modal
   * @param product_index
   */
  openLandingPageBuilderModal(product_index: number) {
    const landingPageBuilderModal = this.modalService.open(CreateLandingPageModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    landingPageBuilderModal.componentInstance.product = this.jsonDecode(this.favouriteProducts[product_index].content);
    landingPageBuilderModal.result.then(() => {}, () => {});
  }

  /**
   * Get profit percent calculated
   * @param product_index
   * @param value
   */
  getProfitPercent(product_index: number, value) {
    return this.scraperUtilsService.calculateRecommendedSellingPrice(
      value,
      this.jsonDecode(this.favouriteProducts[product_index].content)
    );
  }

  /**
   * Check if item already in query to refresh
   * @param product_index
   */
  ifRefreshInQueue(product_index: number) {
    const index_ = this.refreshingSingleProductIndexList.indexOf(product_index, 0);
    return index_ > -1;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadReviewsInQueue(id) {
    const index_ = this.downloadReviewsIndexList.indexOf(id, 0);
    return index_ > -1;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadImagesInQueue(id) {
    const index_ = this.downloadImagesIndexList.indexOf(id, 0);
    return index_ > -1;
  }

  openCreateShopifyModal(product_index) {
    const createShopifyStore = this.modalService.open(CreateShopifyStoreModalComponent, {size: 'lg'});
    createShopifyStore.componentInstance.product = this.jsonDecode(this.favouriteProducts[product_index].content);
    createShopifyStore.result.then(() => {}, () => {});
  }

  openFbAdBuilderModal(product_index) {
    const fbAdBuilderModal = this.modalService.open(CreateFbAdModalComponent, {size: 'lg'});
    fbAdBuilderModal.componentInstance.product = this.jsonDecode(this.favouriteProducts[product_index].content);
    fbAdBuilderModal.result.then(() => {}, () => {});
  }

  openViewDescriptionModal(product_index) {
    const product = this.jsonDecode(this.favouriteProducts[product_index].content);
    const viewDescriptionModal = this.modalService.open(ProductDescriptionModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    viewDescriptionModal.componentInstance.product = product;
    viewDescriptionModal.result.then(() => {}, () => {});
  }

  /**
   * Assign item to the client account
   * @param id
   */
  assignToClient(id: number) {
    const assignToClient = this.modalService.open(AssignToClientModalComponent, {size: 'lg'});
    assignToClient.componentInstance.itemId = id;
    assignToClient.componentInstance.type = 'favourite_products';
    assignToClient.result.then(() => {}, () => {});
  }
}
