import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../components/notification-service/notification.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from '../account.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-stop-processing-data-modal',
  templateUrl: './stop-processing-data-modal.component.html',
  styleUrls: ['./stop-processing-data-modal.component.css']
})
export class StopProcessingDataModalComponent implements OnInit {

  stopProcessingDataForm: FormGroup;
  stopProcessingDataFormSubmitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private accountService: AccountService,
    private router: Router,
  ) { }

  ngOnInit() {
    /**
     * Initialize stop processing data form
     */
    this.stopProcessingDataForm = this.formBuilder.group({
      confirm: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.stopProcessingDataForm.controls;
  }

  onSubmit() {
    this.stopProcessingDataFormSubmitted = true;

    if (this.stopProcessingDataForm.invalid) {
      this.stopProcessingDataFormSubmitted = false;
      return;
    }

    const data = this.stopProcessingDataForm.value;
    data.delete = true;

    this.accountService.stopProcessingData(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Stop processing data'
          });
        } else {
          this.notificationService.success({
            message: 'Your data has been stopped processing. Your account is getting logout',
            title: 'Data processing stopped'
          });
          this.router.navigate(['auth/logout']);
          this.activeModal.close();
        }
      }
    );
  }

}
