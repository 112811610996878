import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryDataService {
  countryList = [
    {
      'name': 'United States',
      'code': 'us',
      'flag': ''
    }, {
      'name': 'Russian Federation',
      'code': 'ru',
      'flag': ''
    }, {
      'name': 'Spain',
      'code': 'es',
      'flag': ''
    }, {
      'name': 'France',
      'code': 'fr',
      'flag': ''
    }, {
      'name': 'United Kingdom',
      'code': 'uk',
      'flag': ''
    }, {
      'name': 'Brazil',
      'code': 'br',
      'flag': ''
    }, {
      'name': 'Israel',
      'code': 'il',
      'flag': ''
    }, {
      'name': 'Netherlands',
      'code': 'nl',
      'flag': ''
    }, {
      'name': 'Canada',
      'code': 'ca',
      'flag': ''
    }, {
      'name': 'Italy',
      'code': 'it',
      'flag': ''
    }, {
      'name': 'Chile',
      'code': 'cl',
      'flag': ''
    }, {
      'name': 'Ukraine',
      'code': 'ua',
      'flag': ''
    }, {
      'name': 'Poland',
      'code': 'pl',
      'flag': ''
    }, {
      'name': 'Australia',
      'code': 'au',
      'flag': ''
    }, {
      'name': 'Germany',
      'code': 'de',
      'flag': ''
    }, {
      'name': 'Belgium',
      'code': 'be',
      'flag': ''
    }, {
      'name': 'Afghanistan',
      'code': 'af',
      'flag': ''
    }, {
      'name': 'Aland Islands',
      'code': 'ala',
      'flag': ''
    }, {
      'name': 'Albania',
      'code': 'al',
      'flag': ''
    }, {
      'name': 'Alderney',
      'code': 'gba',
      'flag': ''
    }, {
      'name': 'Algeria',
      'code': 'dz',
      'flag': ''
    }, {
      'name': 'American Samoa',
      'code': 'as',
      'flag': ''
    }, {
      'name': 'Andorra',
      'code': 'ad',
      'flag': ''
    }, {
      'name': 'Angola',
      'code': 'ao',
      'flag': ''
    }, {
      'name': 'Anguilla',
      'code': 'ai',
      'flag': ''
    }, {
      'name': 'Antigua and Barbuda',
      'code': 'ag',
      'flag': ''
    }, {
      'name': 'Argentina',
      'code': 'ar',
      'flag': ''
    }, {
      'name': 'Armenia',
      'code': 'am',
      'flag': ''
    }, {
      'name': 'Aruba',
      'code': 'aw',
      'flag': ''
    }, {
      'name': 'Ascension Island',
      'code': 'asc',
      'flag': ''
    }, {
      'name': 'Ascension Island',
      'code': 'asc',
      'flag': ''
    }, {
      'name': 'Australia',
      'code': 'au',
      'flag': ''
    }, {
      'name': 'Austria',
      'code': 'at',
      'flag': ''
    }, {
      'name': 'Azerbaijan',
      'code': 'az',
      'flag': ''
    }, {
      'name': 'Bahrain',
      'code': 'bh',
      'flag': ''
    }, {
      'name': 'Guernsey',
      'code': 'ggy',
      'flag': ''
    }, {
      'name': 'Bangladesh',
      'code': 'bd',
      'flag': ''
    }, {
      'name': 'Barbados',
      'code': 'bb',
      'flag': ''
    }, {
      'name': 'Belarus',
      'code': 'by',
      'flag': ''
    }, {
      'name': 'Belgium',
      'code': 'be',
      'flag': ''
    }, {
      'name': 'Belize',
      'code': 'bz',
      'flag': ''
    }, {
      'name': 'Benin',
      'code': 'bj',
      'flag': ''
    }, {
      'name': 'Bermuda',
      'code': 'bm',
      'flag': ''
    }, {
      'name': 'Bhutan',
      'code': 'bt',
      'flag': ''
    }, {
      'name': 'Bolivia',
      'code': 'bo',
      'flag': ''
    }, {
      'name': 'Bosnia and Herzegovina',
      'code': 'ba',
      'flag': ''
    }, {
      'name': 'Botswana',
      'code': 'bw',
      'flag': ''
    }, {
      'name': 'Brazil',
      'code': 'br',
      'flag': ''
    }, {
      'name': 'Virgin Islands (British)',
      'code': 'vg',
      'flag': ''
    }, {
      'name': 'Bulgaria',
      'code': 'bg',
      'flag': ''
    }, {
      'name': 'Burkina Faso',
      'code': 'bf',
      'flag': ''
    }, {
      'name': 'Burundi',
      'code': 'bi',
      'flag': ''
    }, {
      'name': 'Combodia',
      'code': 'kh',
      'flag': ''
    }, {
      'name': 'Cameroon',
      'code': 'cm',
      'flag': ''
    }, {
      'name': 'Canada',
      'code': 'ca',
      'flag': ''
    }, {
      'name': 'Cape Verde',
      'code': 'cv',
      'flag': ''
    }, {
      'name': 'Caribbean Netherlands',
      'code': 'bq',
      'flag': ''
    }, {
      'name': 'Cayman Islands',
      'code': 'ky',
      'flag': ''
    }, {
      'name': 'Central African Republic',
      'code': 'cf',
      'flag': ''
    }, {
      'name': 'Chad',
      'code': 'td',
      'flag': ''
    }, {
      'name': 'Chile',
      'code': 'cl',
      'flag': ''
    }, {
      'name': 'Christmas Island',
      'code': 'cx',
      'flag': ''
    }, {
      'name': 'Cocos (Keeling) Islands',
      'code': 'cc',
      'flag': ''
    }, {
      'name': 'Colombia',
      'code': 'co',
      'flag': ''
    }, {
      'name': 'Comoros',
      'code': 'km',
      'flag': ''
    }, {
      'name': 'Cook Islands',
      'code': 'ck',
      'flag': ''
    }, {
      'name': 'Costa Rica',
      'code': 'cr',
      'flag': ''
    }, {
      'name': 'Cote D Ivoire',
      'code': 'ci',
      'flag': ''
    }, {
      'name': 'Croatia (Hrvatska)',
      'code': 'hr',
      'flag': ''
    }, {
      'name': 'Curacao',
      'code': 'cw',
      'flag': ''
    }, {
      'name': 'Cyprus',
      'code': 'cy',
      'flag': ''
    }, {
      'name': 'Czech Republic',
      'code': 'cz',
      'flag': ''
    }, {
      'name': 'Denmark',
      'code': 'dk',
      'flag': ''
    }, {
      'name': 'Djibouti',
      'code': 'dj',
      'flag': ''
    }, {
      'name': 'Dominica',
      'code': 'dm',
      'flag': ''
    }, {
      'name': 'Dominican Republic',
      'code': 'do',
      'flag': ''
    }, {
      'name': 'Ecuador',
      'code': 'ec',
      'flag': ''
    }, {
      'name': 'Egypt',
      'code': 'eg',
      'flag': ''
    }, {
      'name': 'El Salvador',
      'code': 'sv',
      'flag': ''
    }, {
      'name': 'Equatorial Guinea',
      'code': 'gq',
      'flag': ''
    }, {
      'name': 'Eritrea',
      'code': 'er',
      'flag': ''
    }, {
      'name': 'Estonia',
      'code': 'ee',
      'flag': ''
    }, {
      'name': 'Ethiopia',
      'code': 'et',
      'flag': ''
    }, {
      'name': 'Falkland Islands (Malvinas)',
      'code': 'fk',
      'flag': ''
    }, {
      'name': 'Faroe Islands',
      'code': 'fo',
      'flag': ''
    }, {
      'name': 'Fiji',
      'code': 'fj',
      'flag': ''
    }, {
      'name': 'Finland',
      'code': 'fi',
      'flag': ''
    }, {
      'name': 'France',
      'code': 'fr',
      'flag': ''
    }, {
      'name': 'French Polynesia',
      'code': 'pf',
      'flag': ''
    }, {
      'name': 'Gabon',
      'code': 'ga',
      'flag': ''
    }, {
      'name': 'Gambia',
      'code': 'gm',
      'flag': ''
    }, {
      'name': 'Georgia',
      'code': 'ge',
      'flag': ''
    }, {
      'name': 'Germany',
      'code': 'de',
      'flag': ''
    }, {
      'name': 'Ghana',
      'code': 'gh',
      'flag': ''
    }, {
      'name': 'Gibraltar',
      'code': 'gi',
      'flag': ''
    }, {
      'name': 'Greece',
      'code': 'gr',
      'flag': ''
    }, {
      'name': 'Greenland',
      'code': 'gl',
      'flag': ''
    }, {
      'name': 'Grenada',
      'code': 'gd',
      'flag': ''
    }, {
      'name': 'Guadeloupe',
      'code': 'gp',
      'flag': ''
    }, {
      'name': 'Guam',
      'code': 'gu',
      'flag': ''
    }, {
      'name': 'Guatemala',
      'code': 'gt',
      'flag': ''
    }, {
      'name': 'Guinea',
      'code': 'gn',
      'flag': ''
    }, {
      'name': 'Guinea Bissau',
      'code': 'gw',
      'flag': ''
    }, {
      'name': 'Guyana',
      'code': 'gy',
      'flag': ''
    }, {
      'name': 'French Guiana',
      'code': 'gf',
      'flag': ''
    }, {
      'name': 'Haiti',
      'code': 'ht',
      'flag': ''
    }, {
      'name': 'Honduras',
      'code': 'hn',
      'flag': ''
    }, {
      'name': 'Hong Kong, China',
      'code': 'hk',
      'flag': ''
    }, {
      'name': 'Hungary',
      'code': 'hu',
      'flag': ''
    }, {
      'name': 'Iceland',
      'code': 'is',
      'flag': ''
    }, {
      'name': 'India',
      'code': 'in',
      'flag': ''
    }, {
      'name': 'Indonesia',
      'code': 'id',
      'flag': ''
    }, {
      'name': 'Iraq',
      'code': 'iq',
      'flag': ''
    }, {
      'name': 'Ireland',
      'code': 'ie',
      'flag': ''
    }, {
      'name': 'Israel',
      'code': 'il',
      'flag': ''
    }, {
      'name': 'Italy',
      'code': 'it',
      'flag': ''
    }, {
      'name': 'Jamaica',
      'code': 'jm',
      'flag': ''
    }, {
      'name': 'Japan',
      'code': 'jp',
      'flag': ''
    }, {
      'name': 'Jersey',
      'code': 'jey',
      'flag': ''
    }, {
      'name': 'Jordan',
      'code': 'jo',
      'flag': ''
    }, {
      'name': 'Kazakhstan',
      'code': 'kz',
      'flag': ''
    }, {
      'name': 'Kenya',
      'code': 'ke',
      'flag': ''
    }, {
      'name': 'Kiribati',
      'code': 'ki',
      'flag': ''
    }, {
      'name': 'Korea',
      'code': 'kr',
      'flag': ''
    }, {
      'name': 'Kosovo',
      'code': 'ks',
      'flag': ''
    }, {
      'name': 'Kuwait',
      'code': 'kw',
      'flag': ''
    }, {
      'name': 'Kyrgyzstan',
      'code': 'kg',
      'flag': ''
    }, {
      'name': 'Lao Peoples Democratic Republic',
      'code': 'la',
      'flag': ''
    }, {
      'name': 'Latvia',
      'code': 'lv',
      'flag': ''
    }, {
      'name': 'Lebanon',
      'code': 'lb',
      'flag': ''
    }, {
      'name': 'Lesotho',
      'code': 'ls',
      'flag': ''
    }, {
      'name': 'Liberia',
      'code': 'lr',
      'flag': ''
    }, {
      'name': 'Libya',
      'code': 'ly',
      'flag': ''
    }, {
      'name': 'Liechtenstein',
      'code': 'li',
      'flag': ''
    }, {
      'name': 'Lithuania',
      'code': 'lt',
      'flag': ''
    }, {
      'name': 'Luxembourg',
      'code': 'lu',
      'flag': ''
    }, {
      'name': 'Macau, China',
      'code': 'mo',
      'flag': ''
    }, {
      'name': 'Macedonia',
      'code': 'mk',
      'flag': ''
    }, {
      'name': 'Madagascar',
      'code': 'mg',
      'flag': ''
    }, {
      'name': 'Malawi',
      'code': 'mw',
      'flag': ''
    }, {
      'name': 'Malaysia',
      'code': 'my',
      'flag': ''
    }, {
      'name': 'Maldives',
      'code': 'mv',
      'flag': ''
    }, {
      'name': 'Mali',
      'code': 'ml',
      'flag': ''
    }, {
      'name': 'Malta',
      'code': 'mt',
      'flag': ''
    }, {
      'name': 'Martinique',
      'code': 'mq',
      'flag': ''
    }, {
      'name': 'Mauritania',
      'code': 'mr',
      'flag': ''
    }, {
      'name': 'Mauritius',
      'code': 'mu',
      'flag': ''
    }, {
      'name': 'Mayotte',
      'code': 'yt',
      'flag': ''
    }, {
      'name': 'Mexico',
      'code': 'mx',
      'flag': ''
    }, {
      'name': 'Micronesia',
      'code': 'fm',
      'flag': ''
    }, {
      'name': 'Monaco',
      'code': 'mc',
      'flag': ''
    }, {
      'name': 'Mongolia',
      'code': 'mn',
      'flag': ''
    }, {
      'name': 'Montenegro',
      'code': 'mne',
      'flag': ''
    }, {
      'name': 'Montserrat',
      'code': 'ms',
      'flag': ''
    }, {
      'name': 'Morocco',
      'code': 'ma',
      'flag': ''
    }, {
      'name': 'Mozambique',
      'code': 'mz',
      'flag': ''
    }, {
      'name': 'Myanmar',
      'code': 'mm',
      'flag': ''
    }, {
      'name': 'Namibia',
      'code': 'na',
      'flag': ''
    }, {
      'name': 'Nauru',
      'code': 'nr',
      'flag': ''
    }, {
      'name': 'Negara Brunei Barussalam',
      'code': 'bn',
      'flag': ''
    }, {
      'name': 'Nepal',
      'code': 'np',
      'flag': ''
    }, {
      'name': 'Netherlands',
      'code': 'nl',
      'flag': ''
    }, {
      'name': 'Netherlands Antilles',
      'code': 'an',
      'flag': ''
    }, {
      'name': 'New Caledonia',
      'code': 'nc',
      'flag': ''
    }, {
      'name': 'New Zealand',
      'code': 'nz',
      'flag': ''
    }, {
      'name': 'Nicaragua',
      'code': 'ni',
      'flag': ''
    }, {
      'name': 'Niger',
      'code': 'ne',
      'flag': ''
    }, {
      'name': 'Nigeria',
      'code': 'ng',
      'flag': ''
    }, {
      'name': 'Niue',
      'code': 'nu',
      'flag': ''
    }, {
      'name': 'Norfolk Island',
      'code': 'nf',
      'flag': ''
    }, {
      'name': 'Northern Mariana Islands',
      'code': 'mp',
      'flag': ''
    }, {
      'name': 'Norway',
      'code': 'no',
      'flag': ''
    }, {
      'name': 'Oman',
      'code': 'om',
      'flag': ''
    }, {
      'name': 'Pakistan',
      'code': 'pk',
      'flag': ''
    }, {
      'name': 'Palau',
      'code': 'pw',
      'flag': ''
    }, {
      'name': 'Palestine',
      'code': 'ps',
      'flag': ''
    }, {
      'name': 'Panama',
      'code': 'pa',
      'flag': ''
    }, {
      'name': 'Papua New Guinea',
      'code': 'pg',
      'flag': ''
    }, {
      'name': 'Paraguay',
      'code': 'py',
      'flag': ''
    }, {
      'name': 'Peru',
      'code': 'pe',
      'flag': ''
    }, {
      'name': 'Philippines',
      'code': 'ph',
      'flag': ''
    }, {
      'name': 'Poland',
      'code': 'pl',
      'flag': ''
    }, {
      'name': 'Portugal',
      'code': 'pt',
      'flag': ''
    }, {
      'name': 'Puerto Rico',
      'code': 'pr',
      'flag': ''
    }, {
      'name': 'Qatar',
      'code': 'qa',
      'flag': ''
    }, {
      'name': 'Moldova',
      'code': 'md',
      'flag': ''
    }, {
      'name': 'Reunion',
      'code': 're',
      'flag': ''
    }, {
      'name': 'Romania',
      'code': 'ro',
      'flag': ''
    }, {
      'name': 'Russian Federation',
      'code': 'ru',
      'flag': ''
    }, {
      'name': 'Rawanda',
      'code': 'rw',
      'flag': ''
    }, {
      'name': 'Saint Barthelemy',
      'code': 'blm',
      'flag': ''
    }, {
      'name': 'Saint Kitts and Nevis',
      'code': 'kn',
      'flag': ''
    }, {
      'name': 'Saint Lucia',
      'code': 'lc',
      'flag': ''
    }, {
      'name': 'Saint Martin',
      'code': 'maf',
      'flag': ''
    }, {
      'name': 'St. Pierre and Miquelon',
      'code': 'pm',
      'flag': ''
    }, {
      'name': 'Saint Vincent and the Grenadines',
      'code': 'vc',
      'flag': ''
    }, {
      'name': 'Samoa',
      'code': 'ws',
      'flag': ''
    }, {
      'name': 'San Marino',
      'code': 'sm',
      'flag': ''
    }, {
      'name': 'Sao Tome and Principe',
      'code': 'st',
      'flag': ''
    }, {
      'name': 'Saudi Arabia',
      'code': 'sa',
      'flag': ''
    }, {
      'name': 'Senegal',
      'code': 'sn',
      'flag': ''
    }, {
      'name': 'Serbia',
      'code': 'srb',
      'flag': ''
    }, {
      'name': 'Seychelles',
      'code': 'sc',
      'flag': ''
    }, {
      'name': 'Sierra Leone',
      'code': 'sl',
      'flag': ''
    }, {
      'name': 'Singapore',
      'code': 'sg',
      'flag': ''
    }, {
      'name': 'Sint Maarten (Netherlands)',
      'code': 'sx',
      'flag': ''
    }, {
      'name': 'Slovakia',
      'code': 'sk',
      'flag': ''
    }, {
      'name': 'Slovenia',
      'code': 'si',
      'flag': ''
    }, {
      'name': 'Solomon Islands',
      'code': 'sb',
      'flag': ''
    }, {
      'name': 'Somalia',
      'code': 'so',
      'flag': ''
    }, {
      'name': 'South Africa',
      'code': 'za',
      'flag': ''
    }, {
      'name': 'South Georgia and the South Sandwich Islands',
      'code': 'sgs',
      'flag': ''
    }, {
      'name': 'South Sudan',
      'code': 'ss',
      'flag': ''
    }, {
      'name': 'Spain',
      'code': 'es',
      'flag': ''
    }, {
      'name': 'Sri Lanka',
      'code': 'lk',
      'flag': ''
    }, {
      'name': 'Suriname',
      'code': 'sr',
      'flag': ''
    }, {
      'name': 'Swaziland',
      'code': 'sz',
      'flag': ''
    }, {
      'name': 'Sweden',
      'code': 'se',
      'flag': ''
    }, {
      'name': 'Switzerland',
      'code': 'ch',
      'flag': ''
    }, {
      'name': 'Taiwan, China',
      'code': 'tw',
      'flag': ''
    }, {
      'name': 'Tajikistan',
      'code': 'tj',
      'flag': ''
    }, {
      'name': 'Bahamas',
      'code': 'bs',
      'flag': ''
    }, {
      'name': 'Congo',
      'code': 'zr',
      'flag': ''
    }, {
      'name': 'Marshall Islands',
      'code': 'mh',
      'flag': ''
    }, {
      'name': 'Vatican City State',
      'code': 'va',
      'flag': ''
    }, {
      'name': 'Timor-Leste',
      'code': 'tls',
      'flag': ''
    }, {
      'name': 'Togo',
      'code': 'tg',
      'flag': ''
    }, {
      'name': 'Tonga',
      'code': 'to',
      'flag': ''
    }, {
      'name': 'Trinidad and Tobago',
      'code': 'tt',
      'flag': ''
    }, {
      'name': 'Tunisia',
      'code': 'tn',
      'flag': ''
    }, {
      'name': 'Turkey',
      'code': 'tr',
      'flag': ''
    }, {
      'name': 'Turkmenistan',
      'code': 'tm',
      'flag': ''
    }, {
      'name': 'Tuks and Caicos Islands',
      'code': 'tc',
      'flag': ''
    }, {
      'name': 'Tuvalu',
      'code': 'tv',
      'flag': ''
    }, {
      'name': 'Virgin Islands',
      'code': 'vi',
      'flag': ''
    }, {
      'name': 'Uganda',
      'code': 'ug',
      'flag': ''
    }, {
      'name': 'Ukraine',
      'code': 'ua',
      'flag': ''
    }, {
      'name': 'United Arab Emirates',
      'code': 'ae',
      'flag': ''
    }, {
      'name': 'United Kingdom',
      'code': 'uk',
      'flag': ''
    }, {
      'name': 'Tanzania',
      'code': 'tz',
      'flag': ''
    }, {
      'name': 'United States',
      'code': 'us',
      'flag': ''
    }, {
      'name': 'Uruguay',
      'code': 'uy',
      'flag': ''
    }, {
      'name': 'Uzbekistan',
      'code': 'uz',
      'flag': ''
    }, {
      'name': 'Vanuatu',
      'code': 'vu',
      'flag': ''
    }, {
      'name': 'Venezuela',
      'code': 've',
      'flag': ''
    }, {
      'name': 'Vietnam',
      'code': 'tn',
      'flag': ''
    }, {
      'name': 'Wallis and Futuna Islands',
      'code': 'wf',
      'flag': ''
    }, {
      'name': 'Yemen',
      'code': 'ye',
      'flag': ''
    }, {
      'name': 'Zambia',
      'code': 'zm',
      'flag': ''
    }, {
      'name': 'Zanzibar',
      'code': 'eaz',
      'flag': ''
    }, {
      'name': 'Zimbabwe',
      'code': 'zw',
      'flag': ''
    }, {
      'name': 'Other Country',
      'code': 'other',
      'flag': ''
    }
  ];
  constructor() { }

  public getCountryList() {
    return this.countryList;
  }
}
