import { Component, OnInit } from '@angular/core';
import {ShopifyCredentialData} from '../shopify-store-builder.model';
import {ShopifyStoreBuilderService} from '../shopify-store-builder.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteShopifyCredentialModalComponent} from '../delete-shopify-credential-modal/delete-shopify-credential-modal.component';
import {AddShopifyCredentialModalComponent} from '../add-shopify-credential-modal/add-shopify-credential-modal.component';
import {AuthService} from '../../auth/auth.service';
import {Location} from '@angular/common';
import {NotificationService} from '../../components/notification-service/notification.service';

@Component({
  selector: 'app-list-shopify-credentials',
  templateUrl: './list-shopify-credentials.component.html',
  styleUrls: ['./list-shopify-credentials.component.css']
})
export class ListShopifyCredentialsComponent implements OnInit {

  shopifyCredentials: Array<ShopifyCredentialData>;

  constructor(
    private shopifyStoreBuilderService: ShopifyStoreBuilderService,
    private modalService: NgbModal,
    private authService: AuthService,
    private location: Location,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {

    // If user is client, redirect back
    if (this.authService.isClient()) {
      this.notificationService.error({
        title: 'Search Error',
        message: 'You can not perform search on client account'
      });
      this.location.back();
    }

    /**
     * get shopify credentials
     */
    this._getShopifyCredentials();
  }

  /**
   * Get list of credentials added by the user
   * @private
   */
  private _getShopifyCredentials() {
    this.shopifyStoreBuilderService.getCredentials().subscribe(
      res => {
        this.shopifyCredentials = res.data;
      }
    );
  }

  /**
   * Delete the selected credentials modal
   * @param id
   */
  deleteShopifyCredentialModal(id: number) {
    const deleteShopifyCredentialModal = this.modalService.open(DeleteShopifyCredentialModalComponent, {size: 'lg'});
    deleteShopifyCredentialModal.componentInstance.credential_id = id;
    deleteShopifyCredentialModal.result.then(() => {
      this._getShopifyCredentials();
    }, () => {});
  }

  /**
   * Open add shopify credential modal
   */
  openAddCredentialModal() {
    const addCredentialModal = this.modalService.open(AddShopifyCredentialModalComponent, {size: 'lg'});
    addCredentialModal.result.then(
      () => {
        this._getShopifyCredentials();
      }, () => {}
    );
  }
}
