import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FbAdsBuilderService} from '../fb-ads-builder.service';
import {FbAdTemplatesService} from '../fb-ad-templates.service';
import {FbAdTemplateData} from '../fb-ad-template.model';

@Component({
  selector: 'app-fb-ad-templates-list-modal',
  templateUrl: './fb-ad-templates-list-modal.component.html',
  styleUrls: ['./fb-ad-templates-list-modal.component.css']
})
export class FbAdTemplatesListModalComponent implements OnInit {

  fbAdTemplates: Array<FbAdTemplateData>;
  @Input() selected_template: number;

  canvas_rendered_theme = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fbAdTemplatesService: FbAdTemplatesService
  ) { }

  ngOnInit() {
    this._getFbAdTemplates();
  }

  /**
   * Get all fb ad templates
   * @private
   */
  private _getFbAdTemplates() {
    this.fbAdTemplatesService.list().subscribe(
      res => {
        this.fbAdTemplates = res.data;

        this.canvas_rendered_theme = true;
      }
    );
  }

  /**
   * Set selected template
   * @param id
   */
  setSelected(id: number) {
    this.selected_template = id;
  }

}
