import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFavouriteProductsComponent } from './list-favourite-products/list-favourite-products.component';
import {RouterModule} from '@angular/router';
import {FavouriteProductsRoutes} from './favourite-products.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module';
import {DataTableModule} from 'angular-6-datatable';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(FavouriteProductsRoutes),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    DataTableModule
  ],
  declarations: [
    ListFavouriteProductsComponent,
  ]
})
export class FavouriteProductsModule { }
