import { Component, OnInit } from '@angular/core';
import {SearchHistoriesService} from '../../scraper/search-histories.service';
import {SearchHistoryCountData, SearchHistoryData} from '../../scraper/search-history.model';
import {PopularProductData} from '../../popular-products/popular-products.model';
import {PopularProductsService} from '../../popular-products/popular-products.service';
import {AuthService} from '../../auth/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductDetailModalComponent} from '../product-detail-modal/product-detail-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  myRecentSearches: Array<SearchHistoryData>;
  allTopSearches: Array<SearchHistoryData>;
  countData: SearchHistoryCountData;

  loadingPopularProducts = true;

  popularProducts: Array<PopularProductData>;

  is_client = false;

  /**
   * Constructor of DashboardComponent
   * @constructor
   * @param searchHistoryService
   * @param popularProductService
   * @param authService
   * @param modal
   */
  constructor(
    private searchHistoryService: SearchHistoriesService,
    private popularProductService: PopularProductsService,
    private authService: AuthService,
    private modal: NgbModal
  ) { }

  ngOnInit() {
    // If user is client
    this.is_client = this.authService.isClient();

    if (!this.is_client) {
      /**
       * Initialize myRecentSearches
       */
      this.searchHistoryService.myRecent().subscribe(
        res => {
          this.myRecentSearches = res.data;
        }
      );

      /**
       * Initialize allTopSearches
       */
      this.searchHistoryService.allTop().subscribe(
        res => {
          this.allTopSearches = res.data;
        }
      );

      // Get popular products
      this._getPopularProducts();
    }

    /**
     * Initialize countData
     */
    this.searchHistoryService.count().subscribe(
      res => {
        this.countData = res.data;
      }
    );
  }

  /**
   * Get popular products
   * @private
   */
  private _getPopularProducts() {
    this.popularProductService.list().subscribe(
      res => {
        this.popularProducts = res.data;
        this.loadingPopularProducts = false;
      }
    );
  }

  openProductDetailModal(product_url) {
    const productDetailModal = this.modal.open(ProductDetailModalComponent, {size: 'lg', backdrop: 'static'});
    productDetailModal.componentInstance.product_url = product_url;
    productDetailModal.result.then(() => {}, () => {});
  }

  /**
   * Generate random date
   */
  randomDate() {
    const min = 0;
    const max = 5;
    const num = Math.floor(Math.random() * (+max - +min)) + +min;

    const date = new Date();
    date.setDate(date.getDate() - num);

    return date;
  }
}
