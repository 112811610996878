import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {SavedSearchesResponse, SavedSearchSingleResponse} from './saved-searches.model';


@Injectable({
  providedIn: 'root'
})
export class SavedSearchesService {

  private url = 'saved_searches/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * Add saved results
   * @param keyword
   * @param content
   */
  add(keyword: string, content: string): Observable<SavedSearchSingleResponse> | any {
    const url = `${this.resource.url}${this.url}add`;

    const data = {
      'keyword': keyword,
      'content': content
    };

    return this.http.Post(url, data);
  }

  /**
   * List saved searches
   */
  list(): Observable<SavedSearchesResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get(url);
  }

  /**
   * Delete saved search
   * @param id
   */
  delete(id: number) {
    const url = `${this.resource.url}${this.url}delete/${id}`;

    return this.http.Delete(url);
  }

  /**
   * Get details of single saved search
   * @param saved_search_id
   */
  view(saved_search_id: number): Observable<SavedSearchSingleResponse> | any {
    const url = `${this.resource.url}${this.url}view/${saved_search_id}`;

    return this.http.Get(url);
  }
}
