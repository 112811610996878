import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {VirtualAccountResponse, VirtualAccountSingleResponse} from './virtual-account.model';

@Injectable({
  providedIn: 'root'
})
export class VirtualAccountService {

  url = 'virtual_accounts/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * List all virtual accounts
   */
  list(): Observable<VirtualAccountResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get<VirtualAccountResponse>(url);
  }

  /**
   * Add new virtual assistant
   * @param data
   */
  add(data: any): any {
    const url = `${this.resource.url}${this.url}add`;

    return this.http.Post(url, data);
  }

  /**
   * Get virtual assistant
   * @param virtual_account_id
   */
  get(virtual_account_id: number): Observable<VirtualAccountSingleResponse> {
    const url = `${this.resource.url}${this.url}view/${virtual_account_id}`;

    return this.http.Get<VirtualAccountSingleResponse>(url);
  }

  /**
   * Delete virtual account
   * @param virtual_account_id
   */
  delete(virtual_account_id: number): any {
    const url = `${this.resource.url}${this.url}delete/${virtual_account_id}`;

    return this.http.Delete(url);
  }
}
