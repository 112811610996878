import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../components/notification-service/notification.service';
import {VirtualAccountService} from '../virtual-account.service';
import {MembershipCheckService} from '../../account/membership-check.service';

@Component({
  selector: 'app-add-virtual-account-modal',
  templateUrl: './add-virtual-account-modal.component.html',
  styleUrls: ['./add-virtual-account-modal.component.css']
})
export class AddVirtualAccountModalComponent implements OnInit {

  addVirtualForm: FormGroup;
  addVirtualFormSubmitted = false;
  membership_checking = true;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private virtualAccountService: VirtualAccountService,
    private membershipCheckService: MembershipCheckService
  ) { }

  ngOnInit() {
    /**
     * Check membership
     */
    this.membershipCheckService.subAccount().subscribe(
      res => {
        if (!res.status) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Membership Error'
          });
          this.activeModal.close();
        } else {
          this.membership_checking = false;
        }
      }
    );

    this._initiateVirtualAccount();
  }

  /**
   * Initialize virtual account
   *
   * @private
   */
  private _initiateVirtualAccount() {
    /**
     * Initialize form
     */
    this.addVirtualForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.addVirtualForm.controls;
  }

  onSubmit() {
    this.addVirtualFormSubmitted = true;

    if (this.addVirtualForm.invalid) {
      this.addVirtualFormSubmitted = false;
      return;
    }

    const data = this.addVirtualForm.value;

    this.virtualAccountService.add(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Add account'
          });
        } else {
          this.notificationService.success({
            message: 'Virtual account added successfully',
            title: 'Virtual account added'
          });
          this.activeModal.close();
        }
      }
    );
  }

}
