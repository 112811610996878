<div class="modal-header">
  <h4 class="modal-title">Shopify Error</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="text-danger">
        {{ record.shopify_error }}
      </div>
    </div>
  </div>

</div>
