<footer class="footer">
  <div class="container-fluid">
    <nav class="float-left">
      <ul>
        <li>
          <a routerLink="/pages/terms" target="_blank">
            Terms
          </a>
        </li>
        <li>
          <a routerLink="/pages/privacy" target="_blank">
            Privacy
          </a>
        </li>
        <li>
          <a href="https://support.vega6.com/" target="_blank">
            Support
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright float-right" id="date">
      {{ copyrightYear }}, made with <i class="material-icons">favorite</i> by
      <a href="https://www.vega6.com" target="_blank">Vega6</a>. &nbsp;
      <a href="https://vineasx.com/" target="_blank">
        <img src="https://vineasx.com/img//logo.png" width="100" />
      </a>
    </div>
  </div>
</footer>
