
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <div class="row">
          <div class="col-md-6">
            <h4 class="card-title">Shopify Stores</h4>
            <p class="card-category">All created shopify stores</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead class="text-warning">
          <th>Product ID</th>
          <th>Product Name</th>
          <th>Store Name</th>
          <th>Created on</th>
          <th>Action</th>
          </thead>
          <tbody>

          <tr *ngFor="let c of shopifyProducts; let id = index">
            <td>{{ c.shopify_product_key }}</td>
            <td>{{ c.product_title }}</td>
            <td>{{ c.shopify_credential.app_name }}</td>
            <th>{{ c.created | date:"medium" }}</th>
            <td>

              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-warning btn-sm" type="button" *ngIf="!c.shopify_product_key && c.shopify_error" (click)="showShopifyError(c)">
                    <i class="fas fa-exclamation-triangle"></i>
                  </button>

                  <a href="{{ c.product_url }}" class="btn btn-info btn-sm" target="_blank" *ngIf="c.product_url" title="View Product">
                    <i class="fas fa-eye"></i>
                  </a>

                  <button type="button" class="btn btn-info btn-sm" (click)="assignToClient(c.id)" *ngIf="!is_client" title="Assign to client">
                    <i class="fas fa-user-plus"></i>
                  </button>

                  <button type="button" class="btn btn-danger btn-sm" (click)="deleteShopifyProductModal(c.id)" [disabled]="ifDeleting(c.id)" *ngIf="!is_client" title="Delete">
                <span *ngIf="!ifDeleting(c.id); else deleting_loader">
                  <i class="fas fa-trash"></i>
                </span>
                    <ng-template #deleting_loader>
                  <span>
                    <i class="fas fa-spin fa-spinner"></i>
                  </span>
                    </ng-template>
                  </button>
                </div>
              </div>

            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
