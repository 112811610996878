
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Favourite Products </h4>

      </div>
      <div class="card-body">

        <div class="table-responsive">
          <table class="table table-striped table-bar">
            <thead>

            <tr>
              <th class="text-center">
                <i class="fa fa-bars" aria-hidden="true"></i>
              </th>
              <th>Action</th>
              <th>Title</th>
              <th container="body" ngbPopover="Product ID" triggers="mouseenter:mouseleave">
                Product ID
              </th>
              <th container="body" ngbPopover="Number of orders placed in last 6 months" triggers="mouseenter:mouseleave">
                Total Sold
              </th>

              <th container="body" ngbPopover="View country wise shipping details of the product" triggers="mouseenter:mouseleave">
                Shipping
              </th>

              <th container="body" ngbPopover="View description of the product" triggers="mouseenter:mouseleave">
                Description
              </th>

              <th container="body" ngbPopover="Price of the product" triggers="mouseenter:mouseleave">
                Price
              </th>
              <th container="body" ngbPopover="Standard unit of the product" triggers="mouseenter:mouseleave">
                Unit
              </th>
              <th container="body" ngbPopover="Discount rate on product price" triggers="mouseenter:mouseleave">
                Discount rate
              </th>
              <th container="body" ngbPopover="Price info if order is placed in bulk" triggers="mouseenter:mouseleave">
                Bulk price info
              </th>

              <th container="body" ngbPopover="Recommended selling price based on profit percent" triggers="mouseenter:mouseleave">
                Recommended Selling Price
              </th>
              <th container="body" ngbPopover="Enter profit percent to calculate recommended selling price" triggers="mouseenter:mouseleave">
                Profit Percentage
              </th>

              <th container="body" ngbPopover="Unit per month sold" triggers="mouseenter:mouseleave">
                Est. unit/month
              </th>
              <th container="body" ngbPopover="Average cost per month" triggers="mouseenter:mouseleave">
                Est. cost/month
              </th>
              <th container="body" ngbPopover="Estimated revenue of the product" triggers="mouseenter:mouseleave">
                Est. revenue/month
              </th>

              <th container="body" ngbPopover="Rating of the product" triggers="mouseenter:mouseleave">
                Product Rating %
              </th>
              <th container="body" ngbPopover="Total number of reviews" triggers="mouseenter:mouseleave">
                Number of Reviews
              </th>

              <th container="body" ngbPopover="Packaging Size" triggers="mouseenter:mouseleave">
                Package Size
              </th>
              <th container="body" ngbPopover="Packaging Weight" triggers="mouseenter:mouseleave">
                Package Weight
              </th>
              <th container="body" ngbPopover="Packaging Unit Type" triggers="mouseenter:mouseleave">
                Package Unit Type
              </th>

              <th container="body" ngbPopover="Seller Store name" triggers="mouseenter:mouseleave">
                Seller Name
              </th>
              <th container="body" ngbPopover="Seller store address" triggers="mouseenter:mouseleave">
                Seller Address
              </th>
              <th container="body" ngbPopover="Seller id" triggers="mouseenter:mouseleave">
                Seller ID
              </th>
              <th container="body" ngbPopover="Seller store id" triggers="mouseenter:mouseleave" >
                Store ID
              </th>
              <th container="body" ngbPopover="Store active on aliexpress since" triggers="mouseenter:mouseleave">
                Store Time
              </th>

              <th container="body" ngbPopover="Top products from the seller" triggers="mouseenter:mouseleave">
                Top products from the seller
              </th>

              <th container="body" ngbPopover="Available attributes of the product" triggers="mouseenter:mouseleave">
                Attributes
              </th>
            </tr>

            </thead>
            <tbody>

            <ng-container *ngIf="favouriteProducts">

              <tr *ngFor="let f of favouriteProducts; let id = index;">

                <td>
                  {{ id+1 }}
                </td>

                <td class="text-center">
                  <div class="tooltip">
                    <i class="fa fa-plus-circle fa-3x" aria-hidden="true" appStickyPopover [popoverTitle]="additional" [autoClose]="true" data-placement="right"></i>
                    <ng-template #additional>

                      <button type="button" (click)="downloadReviews(id, jsonDecode(f.content).info.product_id, jsonDecode(f.content).properties.store_info.seller_id)" title="Download All Reviews" class="icon-option-style">
                        <span *ngIf="!ifDownloadReviewsInQueue(id); else reviewLoading">
                          <img src="../../../assets/img/rev.png" alt="review">
                        </span>
                        <ng-template #reviewLoading>
                          <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
                        </ng-template>
                      </button>

                      <button type="button" title="download All Images Per Product" (click)="downloadImages(id)" class="icon-option-style">
                        <span *ngIf="!ifDownloadImagesInQueue(id); else downloadImageLoading">
                          <img src="../../../assets/img/img-download.png" alt="download image">
                        </span>
                        <ng-template #downloadImageLoading>
                          <i class="fas fa-sync-alt fa-spin"></i>
                        </ng-template>
                      </button>

                      <button type="button" title="Create Fb Ads" class="icon-option-style" (click)="openFbAdBuilderModal(id)">
                        <img src="../../../assets/img/create-fb-ads.png" alt="create fb ads">
                      </button>

                      <button type="button" title="Assign to Client" class="icon-option-style" (click)="assignToClient(f.id)" *ngIf="!is_client">
                        <i class="fas fa-user-plus text-white"></i>
                      </button>

                      <button type="button" title="Add product to shopify store" class="icon-option-style" (click)="openCreateShopifyModal(id)">
                        <img src="../../../assets/img/create-shopify-stores.png" alt="Shopify">
                      </button>

                      <button type="button" title="Create Landing Page" class="icon-option-style" (click)="openLandingPageBuilderModal(id)">
                        <img src="../../../assets/img/create-landing-page.png" alt="landing page">
                      </button>

                      <button type="button" title="Refresh Product" class="icon-option-style" (click)="refreshSingleProduct(id)" [disabled]="ifRefreshInQueue(id)">
                        <span *ngIf="!ifRefreshInQueue(id); else refreshing">
                          <img src="../../../assets/img/refresh.svg" alt="refresh">
                        </span>
                        <ng-template #refreshing>
                          <i class="fas fa-sync-alt fa-spin"></i>
                        </ng-template>
                      </button>

                      <button type="button" title="Remove from Favorite" class="icon-option-style" (click)="removeFromFavourite(f.id)">
                        <img src="../../../assets/img/cancel.svg" alt="favorite" width="32">
                      </button>

                    </ng-template>
                  </div>
                </td>

                <td class="text-one" appStickyPopover [popoverTitle]="popTitle" [autoClose]="true" data-placement="right">
                  <ng-template #popTitle>
                    <a href="{{ jsonDecode(f.content).info.url }}" target="_blank">{{ jsonDecode(f.content).info.title }}</a>
                    <hr class="pop-line">
                    <img src="{{ jsonDecode(f.content).info.images.main }}" width="200" alt="{{ jsonDecode(f.content).info.title }}" />
                  </ng-template>
                  <ng-template #popContent>
                    <img src="{{ jsonDecode(f.content).info.images.main }}" width="200" alt="{{ jsonDecode(f.content).info.title }}">
                  </ng-template>
                  <a>
                    {{ jsonDecode(f.content).info.title }}
                  </a>
                </td>

                <td>
                  {{ jsonDecode(f.content).info.product_id }}
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.orders_num; else template_na">
                    {{ jsonDecode(f.content).properties.orders_num }}
                  </span>
                </td>

                <td>
                  <button type="button" (click)="openShippingDetailModal(jsonDecode(f.content).info.product_id)" title="Country wise shipping details" class="btn btn-link text-success">
                    View Shipping
                  </button>
                </td>

                <td>
                  <button (click)="openViewDescriptionModal(id)" title="View Description" class="btn btn-link">
                    View Description
                  </button>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.pricing.price; else template_na">
                    {{ jsonDecode(f.content).properties.pricing.currency }} {{ jsonDecode(f.content).properties.pricing.price }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.pricing.unit; else template_na">
                    {{ jsonDecode(f.content).properties.pricing.unit }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.pricing.discount_rate; else template_na">
                    {{ jsonDecode(f.content).properties.pricing.discount_rate }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.pricing.bulk_price_info; else template_na">
                    {{ jsonDecode(f.content).properties.pricing.bulk_price_info }}
                  </span>
                </td>

                <td>
                  <span class="badge badge-success" *ngIf="jsonDecode(f.content).properties.custom.recommended_selling_price; else template_na">
                    {{ jsonDecode(f.content).properties.custom.recommended_selling_price }}
                  </span>
                </td>

                <td>
                  <input type="number" title="profit percentage" (change)="getProfitPercent(id, profit_perc.value)" (keyup)="getProfitPercent(id, profit_perc.value)" [(ngModel)]="jsonDecode(f.content).properties.custom.profit_percentage" #profit_perc>
                </td>

                <td>
                  <span class="badge badge-success" *ngIf="jsonDecode(f.content).properties.unit_per_month; else template_na">
                    {{ jsonDecode(f.content).properties.unit_per_month }}
                  </span>
                </td>

                <td>
                  <span class="badge badge-success" *ngIf="jsonDecode(f.content).properties.pricing.cost_per_month; else template_na">
                    {{ jsonDecode(f.content).properties.pricing.cost_per_month }}
                  </span>
                </td>

                <td>
                  <span class="badge badge-success" *ngIf="jsonDecode(f.content).properties.custom.estimate_revenue; else template_na">
                    {{ jsonDecode(f.content).properties.custom.estimate_revenue }}
                  </span>
                </td>

                <td>
                  <div class="ratings">
                    <div class="empty-stars"></div>
                    <div class="full-stars" [ngStyle]="{'width': jsonDecode(f.content).properties.rating.percent + '%'}"></div>
                  </div>
                  ({{ jsonDecode(f.content).properties.rating.percent_value }})
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.rating.count; else template_na">
                    {{ jsonDecode(f.content).properties.rating.count }}
                    <i class="fas fa-thumbs-up text-success" *ngIf="jsonDecode(f.content).properties.rating.count"></i>
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.packaging_details.Package_Size; else template_na">
                    {{ jsonDecode(f.content).properties.packaging_details.Package_Size }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.packaging_details.Package_Weight; else template_na">
                    {{ jsonDecode(f.content).properties.packaging_details.Package_Weight }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.packaging_details.Unit_Type; else template_na">
                    {{ jsonDecode(f.content).properties.packaging_details.Unit_Type }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.store_info.link; else template_na">
                    <a href="{{ jsonDecode(f.content).properties.store_info.link }}" target="_blank">
                      {{ jsonDecode(f.content).properties.store_info?.name }}
                    </a>
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.store_info.address; else template_na">
                    {{ jsonDecode(f.content).properties.store_info.address }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.store_info.seller_id; else template_na">
                    {{ jsonDecode(f.content).properties.store_info.seller_id }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.store_info.store_id; else template_na">
                    {{ jsonDecode(f.content).properties.store_info.store_id }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.store_info.store_time; else template_na">
                    {{ jsonDecode(f.content).properties.store_info.store_time }}
                  </span>
                </td>

                <td>
                  <span class="" *ngIf="jsonDecode(f.content).properties.store_info.store_id; else template_na">
                    <a routerLink="/search/seller-products/{{ jsonDecode(f.content).properties.store_info.store_id }}" target="_blank">
                      Click Here <i class="fas fa-external-link-alt"></i>
                    </a>
                  </span>
                </td>

                <td>
                  <ng-container *ngIf="jsonDecode(f.content).properties.attributes; else template_na">
                    <div class="attributes" *ngFor="let at of jsonDecode(f.content).properties.attributes | keyvalue">
                      <span class="attribute-type">{{ at.key }}</span>
                      <span class="attribute-items" *ngFor="let v of at.value">
                        <span class="attribute-items color-pallet" *ngIf="at.key === 'color'; else default_attr" [style]="{'background-color': v}"></span>
                        <ng-template #default_attr>
                          <span class="attribute-items">{{ v }}</span>
                        </ng-template>
                      </span>
                    </div>
                  </ng-container>
                </td>

              </tr>

            </ng-container>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #template_na>
  <span class="text-danger">N/A</span>
</ng-template>
