import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {FbAdTemplateDataResponse, FbAdTemplatesResponse} from './fb-ad-template.model';

@Injectable({
  providedIn: 'root'
})
export class FbAdTemplatesService {

  url = 'fb_ad_templates/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * List all fb ad templates
   */
  list(): Observable<FbAdTemplatesResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get<FbAdTemplatesResponse>(url);
  }

  /**
   * Get particular template
   * @param fbAdTemplateId
   */
  get(fbAdTemplateId: number): Observable<FbAdTemplateDataResponse> | any {
    const url = `${this.resource.url}${this.url}view/${fbAdTemplateId}`;

    return this.http.Get(url);
  }

  /**
   * Get default template
   */
  getDefault(): Observable<FbAdTemplateDataResponse> | any {
    const url = `${this.resource.url}${this.url}defaultTemplate/`;

    return this.http.Get(url);
  }
}
