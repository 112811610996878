<div class="modal-header">
  <h4 class="modal-title">Delete All Data</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-danger">
        <strong>NOTE:</strong> This is non-reversal process. Once you delete all your data, they can not be recovered in any case.
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="deleteAllDataForm" (submit)="onSubmit()" id="delete-data-form" #ngForm="ngForm">

        <div class="form-group">
          <input formControlName="confirm" type="checkbox" class="form-control" id="input-confirm"> I confirm
        </div>

        <span class="error-msg text-danger" *ngIf="f['confirm'].errors?.required">Please accept that you confirm to stop processing data</span>

      </form>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="submit" form="delete-data-form" [disabled]="!ngForm.valid || deleteAllDataFormSubmitted">
    <span *ngIf="!deleteAllDataFormSubmitted; else deleting_loader">
      <i class="fa fa-save"></i> Delete all data
    </span>
    <ng-template #deleting_loader>
      <i class="fa fa-spinner fa-spin"></i> Deleting all data...
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

