
<div class="wrapper ">
  <div class="main-panel">
    <app-navbar></app-navbar>
    <div class="content">
      <div class="container-fluid">

        <router-outlet></router-outlet>

      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
