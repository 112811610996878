<div class="modal-header">
  <h4 class="modal-title">Product Description</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">

    <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="loading_description">
      <div class="loader-small"></div>
    </div>

    <div class="col-md-12 m-t-set" *ngIf="descriptionContent && !loading_description">
      <div class="html-content" [innerHTML]="descriptionContent"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

