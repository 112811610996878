<div class="modal-header">
  <h4 class="modal-title">Landing Page Builder</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="landingPageForm" (submit)="onSubmit()" #formDiv="ngForm" id="landing-page-form" *ngIf="!membership_checking">
    <div class="row">
      <div class="col-md-6">
        <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="!landing_page_preview">
          <div class="loader-small"></div>
        </div>
        <div class="landing-preview">
          <img src="{{ landingPageTemplate?.preview_image_url }}" class="img-fluid min-radius" alt="{{ landingPageTemplate?.title }}" />
        </div>
        <div class="row">
          <div class="col-md-12 p-t-20" align="center">
            <input type="hidden" value="{{ selected_template_id }}" formControlName="landing_page_template_id">
            <button type="button" class="btn btn-primary" (click)="openLandingPageTemplatesModal()">Choose Template</button>

          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <label for="input-logo">Logo Url : </label>
            <input formControlName="logo" type="text" class="form-control" id="input-logo" placeholder="https://example.com/logo.png">
            <span class="error-msg text-danger" *ngIf="f['logo'].touched && f['logo'].errors?.required || f['logo'].errors?.required">Please add logo URL</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="input-title">Title: </label>
            <input formControlName="title" type="text" class="form-control" id="input-title">
            <span class="error-msg text-danger" *ngIf="f['title'].touched && f['title'].errors?.required || f['title'].errors?.required">Please add Title</span>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label for="input-cost">Cost Price: </label>
            <input formControlName="cost_price" type="text" class="form-control" id="input-cost">
            <span class="error-msg text-danger" *ngIf="f['cost_price'].touched && f['cost_price'].errors?.required">Please add Cost Price</span>
          </div>
          <div class="col-md-6">
            <label for="input-sell">Sell Price: </label>
            <input formControlName="sell_price" type="text" class="form-control" id="input-sell">
            <span class="error-msg text-danger" *ngIf="f['sell_price'].touched && f['sell_price'].errors?.required">Please add Sell Price</span>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-12">
            <label for="input-buy-now-button">Buy Now Button: </label>
            <textarea formControlName="buy_now_button" class="form-control" rows="4" id="input-buy-now-button" placeholder="HTML code for buy now button"></textarea>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-12">
            <label for="input-description">Description: </label>
            <textarea formControlName="description" class="form-control" rows="4" id="input-description"></textarea>
            <span class="error-msg text-danger" *ngIf="f['description'].touched && f['description'].errors?.required">Please add Description</span>
          </div>
        </div>
        <input type="hidden" formControlName="reviews">
        <!--<div class="row m-t-10">-->
          <!--<div class="col-md-12">-->
            <!--<label for="input-reviews">Reviews: </label>-->
            <!--<textarea formControlName="reviews" class="form-control" rows="4" id="input-reviews"></textarea>-->
          <!--</div>-->
        <!--</div>-->

        <div class="row m-t-10">
          <div class="col-md-12">
            <h5 class="display-inline">Add FAQs</h5> <button type="button" class="btn btn-link btn-border" (click)="onClickAddFaqField()">Add More</button>

            <div class="row" formArrayName="faqs" *ngFor="let f of f['faqs']['controls']; let i=index;">
              <div class="col-md-12">
                <div class="form-group">
                  <div [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-12">
                        <input formControlName="question" id="input-phone" class="form-control" placeholder="Question">
                      </div>
                      <div class="col-md-12">
                        <textarea class="form-control" formControlName="answer" title="Answer" placeholder="Answer"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row m-t-10">
          <div class="col-md-12">
            <h5 class="display-inline">Gallery Image URLs</h5> <button type="button" class="btn btn-link btn-border" (click)="onClickAddGalleryImageUrlField()">Add More</button>

            <div class="row" formArrayName="gallery" *ngFor="let f of f['gallery']['controls']; let i=index;">
              <div class="col-md-12">
                <div class="form-group">
                  <div [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-12">
                        <input formControlName="url" id="input-gallery-url" class="form-control" placeholder="Image URL {{ i+1 }}">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row m-t-10">
          <div class="col-md-12">
            <h5 class="display-inline">Testimonials</h5>
            <button type="button" class="btn btn-link btn-border" (click)="onClickAddTestimonialField()">Add More</button>

            <div class="row" formArrayName="testimonials" *ngFor="let f of f['testimonials']['controls']; let i=index;">
              <div class="col-md-12">
                <div class="form-group">
                  <div [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-12">
                        <input formControlName="user_image" id="input-testimonial-user-image" class="form-control" placeholder="User Image URL {{ i+1 }}">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <input formControlName="user_name" id="input-testimonial-user-name" class="form-control" placeholder="User Name {{ i+1 }}">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <input formControlName="user_message" id="input-testimonial-user-message" class="form-control" placeholder="User Message {{ i+1 }}">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row m-t-10">
          <div class="col-md-12">
            <label for="input-contact-address">Contact Address</label>
            <textarea formControlName="contact_address" class="form-control" rows="4" id="input-contact-address"></textarea>
          </div>
        </div>

        <div class="row m-t-10">
          <div class="col-md-12">
            <!--<label for="input-contact-email">Contact Email</label>-->
            <input formControlName="contact_email" type="email" class="form-control" id="input-contact-email" placeholder="Contact Email">
          </div>
        </div>

        <div class="row m-t-10">
          <div class="col-md-12">
            <!--<label for="input-contact-phone">Contact Phone</label>-->
            <input formControlName="contact_phone" class="form-control" id="input-contact-phone" placeholder="Contact Phone">
          </div>
        </div>

      </div>
    </div>
  </form>

  <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="membership_checking">
    <div class="loader-small"></div>
  </div>

</div>

<div class="modal-footer" *ngIf="!membership_checking">
  <button class="btn btn-success" type="submit" form="landing-page-form" [disabled]="landingPageFormSubmitted">
    <span *ngIf="!landingPageFormSubmitted; else saving_loader">
      <i class="fa fa-save"></i> Create Landing Page
    </span>
    <ng-template #saving_loader>
      <i class="fa fa-spinner fa-spin"></i> Creating landing page...
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

