<div class="modal-header">
  <h4 class="modal-title">Assign to Client</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="assignForm" (submit)="onSubmit()" id="create-assign-to-client-form" #ngForm="ngForm">

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="input-client-id">Select Client Account</label>
              <select formControlName="client_account_id" class="form-control" id="input-client-id" *ngIf="!loading_clients; else loading_client" required>
                <option disabled selected>Select Shopify Store</option>
                <option *ngFor="let client of clients" [value]="client.id">{{ client.client.name }}</option>
              </select>
              <ng-template #loading_client>
                <div class="text-center">
                  <i class="fas fa-spin fa-spinner fa-3x"></i>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>

</div>
<div class="modal-footer">

  <button class="btn btn-success" type="submit" form="create-assign-to-client-form" [disabled]="!ngForm?.valid || submitted">
    <span *ngIf="!submitted; else saving_loader">
      <i class="fa fa-save"></i> Assign
    </span>
    <ng-template #saving_loader>
      <i class="fa fa-spinner fa-spin"></i> Assigning...
    </ng-template>
  </button>

  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>
