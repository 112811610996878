import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit {

  public copyrightYear;

  constructor() { }

  ngOnInit() {
    this.copyrightYear = new Date().getFullYear();
  }

}
