import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit() {
    /**
     * Initialize form
     */
    this.forgotPasswordForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.forgotPasswordForm.invalid) {
      this.submitted = false;
      return;
    }

    const data = this.forgotPasswordForm.value;

    this.authService.forgotPassword(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Forgot Password'
          });
        } else {
          this.notificationService.success({
            message: 'If provided email is registered with us, you will receive email with instructions to reset your password. ' +
              'Please check your inbox.',
            title: 'Forgot Password'
          });
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }
}
