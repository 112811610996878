import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {AccountResponse} from './account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  url = 'account/';

  /**
   * Constructor of AccountService
   * @constructor
   * @param http
   * @param resource
   */
  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * Get authenticated user profile information
   */
  me(): Observable<AccountResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get<AccountResponse>(url);
  }

  /**
   * Updated logged in user profile
   * @param data
   */
  updateProfile(data: any): Observable<AccountResponse> {
    const url = `${this.resource.url}${this.url}update`;

    return this.http.Put<AccountResponse>(url, data);
  }

  /**
   * Change password of the logged in user
   * @param data
   */
  changePassword(data: any): any {
    const url = `${this.resource.url}${this.url}changePassword`;

    return this.http.Put(url, data);
  }

  /**
   * Change email of the authenticated user
   * @param data
   */
  changeEmail(data: any): Observable<AccountResponse> | any {
    const url = `${this.resource.url}${this.url}changeEmail`;

    return this.http.Put<AccountResponse>(url, data);
  }

  /**
   * Stop processing data
   * @param data
   */
  stopProcessingData(data: any): any {
    const url = `${this.resource.url}${this.url}stopProcessingData`;

    return this.http.Put(url, data);
  }

  /**
   * Delete all data of the user
   * @param data
   */
  deleteAllData(data: any): any {
    const url = `${this.resource.url}${this.url}deleteAllData`;

    return this.http.Put(url, data);
  }

  /**
   * Change avatar of user
   * Send base64 data of the image to the server
   * @param data
   */
  changeAvatar(data): any {
    const url = `${this.resource.url}${this.url}changeAvatar`;

    return this.http.Post(url, data);
  }
}
