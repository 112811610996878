import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from './components/notification-service/notification.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SpyCom';

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {
      console.log('load');
    this.notificationService.notification$.subscribe(
      notification => {
        if (notification.message === null) {
          return;
        }

        let message;

        switch (notification.type) {
          case 'error':
            message = '<span class="now-ui-icons ui-1_simple-remove"></span> ' + notification.message;
            this.toastr.error(
              message,
              notification.title, {
                toastClass: 'alert alert-danger alert-with-icon'
              }
            );
            break;
          case 'success':
            message = '<span class="now-ui-icons ui-1_check-53"></span> ' + notification.message;
            this.toastr.success(
              message,
              notification.title, {
                toastClass: 'alert alert-success alert-with-icon',
              }
            );
            break;
          case 'info':
            message = '<span class="now-ui-icons business_bulb-63"></span> ' + notification.message;
            this.toastr.info(
              message,
              notification.title, {
                toastClass: 'alert alert-info alert-with-icon'
              }
            );
            break;
          case 'warning':
            message = '<span class="now-ui-icons travel_info"></span> ' + notification.message;
            this.toastr.warning(
              message,
              notification.title, {
                toastClass: 'alert alert-warning alert-with-icon',
              }
            );
        }
      }
    );
  }
}
