<div class="modal-header">
  <h4 class="modal-title">Change Email Address</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-warning">
        <strong>NOTE:</strong> You won't be able to login using your previous email. Also you won't be able to register new account using your previous email.
        For better support you may need to provide your previous email as well for better assistance.
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="changeEmailForm" (submit)="onSubmit()" id="change-email-form" #formDir="ngForm">

        <div class="form-group">
          <label class="bmd-label-floating" for="input-email">New Email</label>
          <input formControlName="email" type="email" class="form-control" id="input-email">
        </div>

        <span class="error-msg text-danger" *ngIf="f['email'].touched && f['email'].errors?.required">Email is required</span>

      </form>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="submit" form="change-email-form" [disabled]="!formDir.valid || changeEmailFormSubmitted">
    <span *ngIf="!changeEmailFormSubmitted; else saving_loader">
      <i class="fa fa-save"></i> Save
    </span>
    <ng-template #saving_loader>
      <i class="fa fa-spinner fa-spin"></i> Saving...
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

