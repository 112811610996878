import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopifyStoreBuilderService} from '../shopify-store-builder.service';
import {NotificationService} from '../../components/notification-service/notification.service';

@Component({
  selector: 'app-delete-shopify-store-modal',
  templateUrl: './delete-shopify-store-modal.component.html',
  styleUrls: ['./delete-shopify-store-modal.component.css']
})
export class DeleteShopifyStoreModalComponent implements OnInit {

  @Input() shopify_store_id: number;

  shopifyStore;
  deleting = false;

  constructor(
    public activeModal: NgbActiveModal,
    private shopifyStoreBuilderService: ShopifyStoreBuilderService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    /**
     * Get shopify store data using the builder service
     */
    this.shopifyStore = '';
  }

  deleteStoreNow() {
    /**
     * Delete the store and close the modal
     */
    this.deleting = true;
    this.activeModal.close();
  }
}
