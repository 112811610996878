import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../components/notification-service/notification.service';
import {ClientAccountService} from '../client-account.service';
import {MembershipCheckService} from '../../account/membership-check.service';

@Component({
  selector: 'app-add-client-account-modal',
  templateUrl: './add-client-account-modal.component.html',
  styleUrls: ['./add-client-account-modal.component.css']
})
export class AddClientAccountModalComponent implements OnInit {

  addClientForm: FormGroup;
  addClientFormSubmitted = false;
  membership_checking = true;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private clientAccountService: ClientAccountService,
    private membershipCheckService: MembershipCheckService
  ) { }

  ngOnInit() {
    /**
     * Check membership
     */
    this.membershipCheckService.subAccount().subscribe(
      res => {
        if (!res.status) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Membership Error'
          });
          this.activeModal.close();
        } else {
          this.membership_checking = false;
        }
      }
    );

    this._initiateClientAccount();
  }


  /**
   * Initialize Client Account
   *
   * @private
   */
  private _initiateClientAccount() {
    /**
     * Initialize form
     */
    this.addClientForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.addClientForm.controls;
  }

  onSubmit() {
    this.addClientFormSubmitted = true;

    if (this.addClientForm.invalid) {
      this.addClientFormSubmitted = false;
      return;
    }

    const data = this.addClientForm.value;

    this.clientAccountService.add(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Add account'
          });
        } else {
          this.notificationService.success({
            message: 'Client account added successfully',
            title: 'Client account added'
          });
          this.activeModal.close();
        }
      }
    );
  }

}
