import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {LandingPageTemplateResponse, LandingPageTemplateSingleResponse} from './landing-page-template.model';
import {tick} from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class LandingPageTemplateService {

  url = 'landing_page_templates/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * List landing page templates
   */
  list(): Observable<LandingPageTemplateResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get<LandingPageTemplateResponse>(url);
  }

  /**
   * Get landing page detail
   * @param selected_template_id
   */
  get(selected_template_id: number): Observable<LandingPageTemplateSingleResponse> {
    const url = `${this.resource.url}${this.url}view/${selected_template_id}`;

    return this.http.Get<LandingPageTemplateSingleResponse>(url);
  }

  /**
   * Get default template
   */
  getDefault(): Observable<LandingPageTemplateSingleResponse> | any {
    const url = `${this.resource.url}${this.url}defaultTemplate/`;

    return this.http.Get(url);
  }
}
