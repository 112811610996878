import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline/timeline.component';
import {RouterModule} from '@angular/router';
import {TimelineRouting} from './timeline.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(TimelineRouting)
  ],
  declarations: [
    TimelineComponent
  ]
})
export class TimelineModule { }
