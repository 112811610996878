import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../components/notification-service/notification.service';
import {AccountService} from '../account.service';

@Component({
  selector: 'app-delete-all-data-modal',
  templateUrl: './delete-all-data-modal.component.html',
  styleUrls: ['./delete-all-data-modal.component.css']
})
export class DeleteAllDataModalComponent implements OnInit {

  deleteAllDataForm: FormGroup;
  deleteAllDataFormSubmitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
    private accountService: AccountService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    /**
     * Initialize form
     */
    this.deleteAllDataForm = this.formBuilder.group({
      confirm: new FormControl(false, [
        Validators.required
      ])
    });
  }

  get f() {
    return this.deleteAllDataForm.controls;
  }

  onSubmit() {
    this.deleteAllDataFormSubmitted = true;

    if (this.deleteAllDataForm.invalid) {
      this.deleteAllDataFormSubmitted = false;
      return;
    }

    const data = this.deleteAllDataForm.value;
    data.delete = true;

    this.accountService.deleteAllData(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Delete all data'
          });
        } else {
          this.notificationService.success({
            message: 'All data deleted successfully',
            title: 'All data deleted'
          });
          this.activeModal.close();
        }
      }
    );
  }

}
