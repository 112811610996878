import { Injectable } from '@angular/core';

import 'fabric';
import {FbAdTemplateData} from './fb-ad-template.model';
declare const fabric: any;

@Injectable({
  providedIn: 'root'
})
export class CanvasTemplatesService {

  /**
   * Split words
   * @param sentence
   * @private
   */
  static _breakWords(sentence: string): string {
    const sent = sentence.split(' ');
    const newSent = [];
    for (let i = 0; i < sent.length; i++) {
      if (i > 0 && (i % 5) === 0) {
        newSent.push('\n');
      }
      newSent.push(sent[i]);
    }
    return newSent.join(' ');
  }

  /**
   *
   */
  constructor() { }

  /**
   * renderCanvas
   * @param canvas
   * @param fbTemplate
   * @param pImage
   * @param textContent
   */
  renderCanvas(canvas, fbTemplate: FbAdTemplateData, pImage: string, textContent: string) {

    /**
     * Patch for IText not editable
     */
    const _original_initHiddenTextarea   = fabric.IText.prototype.initHiddenTextarea;
    fabric.util.object.extend(fabric.IText.prototype, /** @lends fabric.IText.prototype */ {
      // fix for : IText not editable when canvas is in a fullscreen element on chrome
      // https://github.com/fabricjs/fabric.js/issues/5126
      initHiddenTextarea: function() {
        _original_initHiddenTextarea.call(this);
        this.canvas.wrapperEl.appendChild(this.hiddenTextarea);
      }
    });


    const cvBgImage = new fabric.Image.fromURL(fbTemplate.background_image_url, imgInstance => {
      imgInstance.lockMovementX = true;
      imgInstance.lockMovementY = true;
      imgInstance.lockScalingX = true;
      imgInstance.lockScalingY = true;
      imgInstance.lockRotation = true;
      imgInstance.set({
        width: canvas.width,
        height: canvas.height,
        originX: 'left',
        originY: 'top'
      });
      canvas.setBackgroundImage(imgInstance, canvas.renderAll.bind(canvas));
    });

    const cvTextString = new fabric.IText(CanvasTemplatesService._breakWords(textContent), JSON.parse(fbTemplate.text_config));
    canvas.add(cvTextString);
    canvas.setActiveObject(cvTextString);

    canvas.on('mouse:down', options => {
      canvas.setActiveObject(cvTextString);
      cvTextString.bringToFront();
    });

    const cvProductImage = new fabric.Image.fromURL(pImage, imgInstance => {
      imgInstance.set({
        left: fbTemplate.product_image_position_left,
        top: fbTemplate.product_image_position_top
      });
      imgInstance.scaleToHeight = fbTemplate.product_image_scale_height;
      imgInstance.scaleToWidth = fbTemplate.product_image_scale_width;

      canvas.add(imgInstance);
    });
  }

}
