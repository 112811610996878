<div class="modal-header">
  <h4 class="modal-title">Delete Shopify Credential</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-danger">
        <strong>NOTE:</strong> Are you sure want to delete shopify store: {{ shopifyStore?.app_name }}?
        <br>
        <br>
        All associated data will be lost. Once deleted, data lost can not be restored in any case.
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="deleteStoreNow()" [disabled]="deleting">
    <span *ngIf="!deleting; else deleting_loader">
      <i class="fa fa-trash-o"></i> Delete Shopify Store
    </span>
    <ng-template #deleting_loader>
      <i class="fa fa-spinner fa-spin"></i> Deleting Shopify Store...
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

