import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../components/notification-service/notification.service';
import {ClientAccountService} from '../client-account.service';
import {ClientAccountData} from '../client-account.model';
import {AddClientAccountModalComponent} from '../add-client-account-modal/add-client-account-modal.component';
import {DeleteClientAccountModalComponent} from '../delete-client-account-modal/delete-client-account-modal.component';
import {AuthService} from '../../auth/auth.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-list-client-account',
  templateUrl: './list-client-account.component.html',
  styleUrls: ['./list-client-account.component.css']
})
export class ListClientAccountComponent implements OnInit {

  clientAccounts: Array<ClientAccountData>;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private clientAccountService: ClientAccountService,
    private authService: AuthService,
    private location: Location
  ) { }

  ngOnInit() {

    // If user is client, redirect back
    if (this.authService.isClient()) {
      this.notificationService.error({
        title: 'Search Error',
        message: 'You can not perform search on client account'
      });
      this.location.back();
    }

    this.getClients();
  }

  /**
   * Get list of all clients
   */
  getClients() {
    this.clientAccountService.list().subscribe(
      res => {
        this.clientAccounts = res.data;
      }
    );
  }

  /**
   * Add client account
   */
  openAddClientModal() {
    const addClientModal = this.modalService.open(AddClientAccountModalComponent, {size: 'lg'});
    addClientModal.result.then( () => {
      this.getClients();
    }, () => {});
  }

  /**
   * Delete client account
   * @param client_id
   */
  openDeleteClientModal(client_id: number) {
    const deleteClientModal = this.modalService.open(DeleteClientAccountModalComponent, {size: 'lg'});
    deleteClientModal.componentInstance.client_account_id = client_id;
    deleteClientModal.result.then( () => {
      this.getClients();
    }, () => {});
  }
}
