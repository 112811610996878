import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Parse URL
   * @param url
   * @private
   */
  static _parseUrl(url: string) {
    return new URL(url);
  }

  constructor(
    public Auth: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(this.Auth.accessToken());
    // console.log('inside interceptor');
    const url_ = AuthInterceptor._parseUrl(request.url);
    // console.log(url_.hostname);
    if (url_.hostname === 'server.spycom.io' || url_.hostname.includes('ngrok.io')) {
      // console.log('host valid');
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Token': `${this.Auth.tokenType()} ${this.Auth.accessToken()}`
        }
      });
    } else {
        request = request.clone({
            setHeaders: {}
        });
    }

    return next.handle(request);
  }
}
