import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../components/notification-service/notification.service';
import {VirtualAccountService} from '../virtual-account.service';
import {VirtualAccountData} from '../virtual-account.model';

@Component({
  selector: 'app-delete-virtual-account-modal',
  templateUrl: './delete-virtual-account-modal.component.html',
  styleUrls: ['./delete-virtual-account-modal.component.css']
})
export class DeleteVirtualAccountModalComponent implements OnInit {

  @Input() virtual_account_id: number;

  virtualAccount: VirtualAccountData;

  deleting = false;

  constructor(
    public activeModal: NgbActiveModal,
    private virtualAccountService: VirtualAccountService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    /**
     * Get virtual accounts
     */
    this.virtualAccountService.get(this.virtual_account_id).subscribe(
      res => {
        this.virtualAccount = res.data;
      }
    );
  }

  deleteAssistantNow() {
    this.deleting = true;

    this.virtualAccountService.delete(this.virtual_account_id).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        let messages = '';
        if (res.status === false) {
          if (res.data) {
            if (res.data.message) {
              messages = res.data.message;
            }
          } else if (res.error) {
            messages = res.error.errors.message;
          } else {
            messages = 'Unknown error';
          }
          this.notificationService.error({
            message: messages,
            title: 'Error: Virtual account deleted'
          });

          this.deleting = false;
        } else {
          this.notificationService.success({
            message: 'Virtual account deleted successfully',
            title: 'Virtual account deleted'
          });
          this.activeModal.close();
        }
      }
    );
  }

}
