import {Component, Input, OnInit} from '@angular/core';
import {ShopifyProductData} from '../shopify-store-builder.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopifyStoreBuilderService} from '../shopify-store-builder.service';
import {NotificationService} from '../../components/notification-service/notification.service';

@Component({
  selector: 'app-delete-shopify-product-modal',
  templateUrl: './delete-shopify-product-modal.component.html',
  styleUrls: ['./delete-shopify-product-modal.component.css']
})
export class DeleteShopifyProductModalComponent implements OnInit {

  @Input() shopifyProductId: number;

  shopifyProduct: ShopifyProductData;
  deleting = false;

  constructor(
    public activeModal: NgbActiveModal,
    private shopifyBuilderService: ShopifyStoreBuilderService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.shopifyBuilderService.getShopifyProduct(this.shopifyProductId).subscribe(
      res => {
        this.shopifyProduct = res.data;
      }
    );
  }

  /**
   * Delete shopify product
   */
  deleteProductNow() {
    this.deleting = true;

    this.shopifyBuilderService.deleteShopifyProduct(this.shopifyProductId).subscribe(
      res => {
        this.notificationService.success({
          title: 'Shopify product deleted',
          message: 'Shopify product deleted successfully'
        });
        this.activeModal.close();
      }
    );
  }

}
