<div class="modal-header">
  <h4 class="modal-title">Product Shipping Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-6">
      <label>Shipping details for: </label>
    </div>
    <div class="col-md-6">
      <div class="input-group">
        <select class="form-control" title="country" (change)="getShippingForCountry($event.target.value)">
          <option *ngFor="let country of countryList" value="{{ country.code }}">{{ country.name }}</option>
        </select>
        <span class="input-group-addon border-left-0 position-select"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
      </div>
    </div>

    <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="loading_shipping">
      <div class="loader-small"></div>
    </div>

    <div class="col-md-12 m-t-set" *ngIf="shippingDetails.length > 0">
      <div class="table-responsive table-fix">
        <table class="table table-striped table-bordered table-ship">
          <thead>
          <th ngbPopover="Before Specified Time" triggers="mouseenter:mouseleave" container="body">Before Specified Time</th>
          <th ngbPopover="Shipping Company" triggers="mouseenter:mouseleave" container="body">Shipping Company</th>
          <th ngbPopover="Local Price" triggers="mouseenter:mouseleave" container="body">Local Price</th>
          <th ngbPopover="Local Save Money" triggers="mouseenter:mouseleave" container="body">Local Save Money</th>
          <th ngbPopover="Local Total Freight" triggers="mouseenter:mouseleave" container="body">Local Total Freight</th>
          <th ngbPopover="Logistic Delivery Time" triggers="mouseenter:mouseleave" container="body">Logistic Delivery Time</th>
          <th ngbPopover="Price" triggers="mouseenter:mouseleave" container="body">Price</th>
          <th ngbPopover="Processing Time" triggers="mouseenter:mouseleave" container="body">Processing Time</th>
          <th ngbPopover="Save Money" triggers="mouseenter:mouseleave" container="body">Save Money</th>
          <th ngbPopover="Send Goods Country" triggers="mouseenter:mouseleave" container="body">Send Goods Country</th>
          <th ngbPopover="Time" triggers="mouseenter:mouseleave" container="body">Time</th>
          <th ngbPopover="Total Freight" triggers="mouseenter:mouseleave" container="body">Total Freight</th>
          </thead>
          <tbody>
          <tr *ngFor="let ship of shippingDetails">
            <td>{{ ship.beforeSpecifiedTime }}</td>
            <td>{{ ship.companyDisplayName }}</td>
            <td>{{ ship.localPriceFormatStr }}</td>
            <td>{{ ship.localSaveMoneyFormatStr }}</td>
            <td>{{ ship.localTotalFreightFormatStr }}</td>
            <td>{{ ship.logisticsDeliveryTimeType }}</td>
            <td>{{ ship.priceFormatStr }}</td>
            <td>{{ ship.processingTime }}</td>
            <td>{{ ship.saveMoneyFormatStr }}</td>
            <td>{{ ship.sendGoodsCountryFullName }} ({{ ship.sendGoodsCountry }})</td>
            <td>{{ ship.time }}</td>
            <td>{{ ship.totalFreightFormatStr }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>

