
<hr class="seprater-theme">
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Saved Searches </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped table-bar">
            <thead>

            <tr>
              <th></th>
              <th>Keyword</th>
              <th>Searched On</th>
              <th>Action</th>
            </tr>

            </thead>
            <tbody>

            <ng-container *ngIf="savedSearches">

              <tr *ngFor="let f of savedSearches; let id = index;">

                <td>
                  {{ id+1 }}
                </td>

                <td>{{ f.keyword }}</td>

                <td>{{ f.created | date: 'mediumDate' }}</td>

                <td>

                  <a routerLink="/saved-searches/view/{{ f.id }}" class="btn btn-info">
                    <i class="fas fa-eye"></i> View
                  </a>

                  <button type="button" class="btn btn-info" (click)="assignToClient(f.id)" *ngIf="!is_client">
                    Assign to Client
                  </button>

                  <button class="btn btn-danger" [disabled]="ifDeleting(f.id)" (click)="deleteSearch(f.id)" *ngIf="!is_client">
                    <span *ngIf="!ifDeleting(f.id); else deleting_loader">
                      <i class="fas fa-trash"></i> Delete
                    </span>
                    <ng-template #deleting_loader>
                      <span><i class="fas fa-spin fa-spinner"></i> Deleting...</span>
                    </ng-template>
                  </button>
                </td>

              </tr>

            </ng-container>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
