import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from '../account.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-change-avatar-modal',
  templateUrl: './change-avatar-modal.component.html',
  styleUrls: ['./change-avatar-modal.component.css']
})
export class ChangeAvatarModalComponent implements OnInit {

  changeAvatarForm: FormGroup;
  changeAvatarFormSubmitted = false;

  selectedFile: File = null;

  @ViewChild('avatar') User_Avatar;
  userAvatarFile: File;
  avatarBinaryData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    /**
     * Initialize form
     */
    this.changeAvatarForm = this.formBuilder.group({
      avatar: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.changeAvatarForm.controls;
  }

  onSubmit() {
    this.changeAvatarFormSubmitted = true;

    if (this.changeAvatarForm.invalid) {
      this.changeAvatarFormSubmitted = false;
      return;
    }

    const data = {
      'avatar': this.avatarBinaryData
    };
    // console.log(data);

    this.accountService.changeAvatar(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Avatar change'
          });
        } else {
          this.notificationService.success({
            message: 'Avatar successfully changed',
            title: 'Avatar change'
          });
          this.activeModal.close();
        }
      }
    );
  }

  onFileChange($event) {
    const reader = new FileReader();

    if ($event.target.files && $event.target.files.length) {
      const [file] = $event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        // console.log(reader.result);
        this.avatarBinaryData = reader.result;

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

}
