<div class="modal-header">
  <h4 class="modal-title">Change Avatar</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="changeAvatarForm" id="avatar-form" #formDir="ngForm" (submit)="onSubmit()">
  <div class="row">
    <div class="col-md-12">

        <div class="form-group">
          <label class="bmd-label-floating" for="input-avatar">New Avatar</label>
          <input type="file" class="form-control" id="input-avatar" formControlName="avatar" #avatar (change)="onFileChange($event)">
        </div>

    </div>
  </div>
  </form>

</div>
<div class="modal-footer">
  <button class="btn btn-success" form="avatar-form" type="submit">
    <i class="fa fa-save"></i> Change Avatar
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>
