

<section class="pages sec1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h1 class="sec1head1">Privacy Policy of <strong>SpyCom.com</strong></h1>
          <p class="pad0 sec1para bdr">This Application & Websites collects some Personal Data from its Users.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="sec1head">
            <h2 class="sec1head2">Owner and Data Controller</h2>
            <p class="pad0 sec1para">VIneaSX Solutions LLC. Office 10, Level 1, Sharjah Media City, Sharjah, UAE. (http://support.vineasx.com)</p>
            <p class="pad0 sec1para bdr"><b>Owner contact email:</b> support@vineasx.com</p>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="sec1head">
            <h2 class="sec1head2">Types of Data collected</h2>
            <p class="pad0 sec1para">
              Among the types of Personal Data that this Application collects, by itself or through third parties, there are:
              Cookies, Usage Data, first name, last name, gender, phone number, VAT Number, company name, profession, address, country, email address, ZIP/Postal code, city, field of activity, User ID, website, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example), About Me, date of birth and Email.
            </p>
            <p class="pad0 sec1para">Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br>Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.<br>Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.<br>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br>Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
            <p class="pad0 sec1para bdr">Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</p>

          </div>
          <div class="sec1head">
            <h2  class="sec1head2">Mode and place of processing the Data</h2>
            <h3 class="sec1head3 mar0">Methods of processing</h3>
            <p class="mar0 sec1para">The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.<br>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>
            <h3 class="sec1head3 mar0">Legal basis of processing</h3>
            <p class="pad0 sec1para">The Owner may process Personal Data relating to Users if one of the following applies:</p>
            <ul class="sec1list">
              <li>Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</li>
              <li>provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</li>
              <li>processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>
              <li>processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</li>
              <li>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</li>
            </ul>
            <p class="pad0 sec1para">In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract. </p>

            <h3 class="sec1head3 mar0">Place</h3>

            <p class="pad0 mar0 sec1para">The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.<br><br>
              Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</p><br/>
            <p class="pad0 sec1para">Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.<br><br>
              If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</p>

            <h3 class="sec1head3 mar0">Retention time</h3>

            <p class="pad0 sec1para">Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
            <p class="pad0 sec1para">Therefore:</p>
            <ul class="sec1list">
              <li>Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</li>
              <li>Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of  this document or by contacting the Owner.</li>
            </ul>
            <p class="pad0 sec1para bdr">The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.<br><br>
              Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</p>

          </div>
          <div class="sec1head">

            <h2 class="sec1head2">The purposes of processing</h2>

            <p class="pad0 sec1para">
              The Data concerning the User is collected to allow the Owner to provide its Services, as well as for the following purposes:
              Access to third-party accounts, Interaction with external social networks and platforms, Contacting the User, Analytics, Managing contacts and sending messages, Interaction with data collection platforms and other third parties, User database management, Commercial affiliation, Handling payments, Remarketing and behavioral targeting, Advertising, Backup saving and management, Registration and authentication, Traffic optimization and distribution, Content commenting, Interaction with live chat platforms, Managing support and contact requests, Displaying content from external platforms, Hosting and backend infrastructure, Managing landing and invitation pages, Platform services and hosting and Interaction with support and feedback platforms.
            </p>

            <p class="pad0 sec1para bdr">Users can find further detailed information about such purposes of processing and about the specific Personal Data used for each purpose in the respective sections of this document.</p>

          </div>
          <div class="sec1head">

            <h2 class="sec1head2">Facebook permissions asked by this Application</h2>
            <p class="pad0 sec1para">This Application may ask for some Facebook permissions allowing it to perform actions with the User's Facebook account and to retrieve information, including Personal Data, from it. This service allows this Application to connect with the User's account on the Facebook social network, provided by Facebook Inc.</p>
            <p class="pad0 sec1para1">For more information about the following permissions, refer to the <a href="https://developers.facebook.com/docs/authentication/permissions/" target="_blank">Facebook permissions documentation</a> and to the <a href="https://www.facebook.com/about/privacy/" target="_blank">Facebook privacy policy</a>.</p>


            <p class="pad0 sec1para">The permissions asked are the following:</p>

            <h3 class="sec1head3 mar0">Basic information</h3>
            <p class="pad0 sec1para">By default, this includes certain User’s Data such as id, name, picture, gender, and their locale. Certain connections of the User, such as the Friends, are also available. If the User has made more of their Data public, more information will be available.</p>

            <h3 class="sec1head3 mar0">About Me</h3>
            <p class="pad0 sec1para">Provides access to the 'About Me' section of the profile.</p>

            <h3 class="sec1head3 mar0">Access Friend Lists</h3>
            <p class="pad0 sec1para">Provides access to any friend lists the User created.</p>

            <h3 class="sec1head3 mar0">Access Page CTA</h3>
            <p class="pad0 sec1para">Provides the access to manage call to actions on User-managed Pages.</p>

            <h3 class="sec1head3 mar0">Access Requests</h3>
            <p class="pad0 sec1para">Provides read access to the User's friend requests.</p>

            <h3 class="sec1head3 mar0">App Notifications</h3>
            <p class="pad0 sec1para">Send notifications.</p>



            <h3 class="sec1head3 mar0">Contact email</h3>
            <p class="pad0 sec1para">Access the User's contact email address.</p>



            <h3 class="sec1head3 mar0">Custom User Actions</h3>
            <p class="pad0 sec1para">Allows the app retrieve the actions published by another application as specified by the app namespace.</p>



            <h3 class="sec1head3 mar0">Email</h3>
            <p class="pad0 sec1para">Provides access to the User's primary email address.</p>



            <h3 class="sec1head3 mar0">Friend subscriptions</h3>




            <h3 class="sec1head3 mar0">Interests</h3>
            <p class="pad0 sec1para">Provides access to the User's list of interests.</p>



            <h3 class="sec1head3 mar0">Likes</h3>
            <p class="pad0 sec1para">Provides access to the list of all of the pages the User has liked.</p>



            <h3 class="sec1head3 mar0">List of Friends</h3>




            <h3 class="sec1head3 mar0">Manage Friend Lists</h3>
            <p class="pad0 sec1para">Enables the app to create, delete and edit the User's friend lists.</p>



            <h3 class="sec1head3 mar0">Manage Groups</h3>
            <p class="pad0 sec1para">Manage the User's groups.</p>



            <h3 class="sec1head3 mar0">Manage Pages</h3>
            <p class="pad0 sec1para">Enables the application to retrieve access_tokens for Pages and Applications that the User administrates.</p>



            <h3 class="sec1head3 mar0">Mobile Messaging on behalf of Page</h3>
            <p class="pad0 sec1para">Send and receive mobile messages on behalf of a Facebook Page.</p>



            <h3 class="sec1head3 mar0">News Feed and Wall</h3>
            <p class="pad0 sec1para">Provides access to all the posts in the User's News Feed and enables the application to perform searches against the User's News Feed.</p>



            <h3 class="sec1head3 mar0">Page Messaging</h3>
            <p class="pad0 sec1para">Send and receive messages through a Facebook Page.</p>



            <h3 class="sec1head3 mar0">Photos</h3>
            <p class="pad0 sec1para">Provides access to the photos the User has uploaded, and photos the User has been tagged in.</p>



            <h3 class="sec1head3 mar0">Publish as Page</h3>
            <p class="pad0 sec1para">Enables the ability to post, comment and like as any of the Pages managed by the User.</p>



            <h3 class="sec1head3 mar0">Publish to the Wall</h3>
            <p class="pad0 sec1para">Enables the app to post content, comments, and likes to a User's stream and to the streams of the User's friends.</p>



            <h3 class="sec1head3 mar0">Share</h3>
            <p class="pad0 sec1para">Share items on the User's behalf.</p>



            <h3 class="sec1head3 mar0">Show List of Managed Pages</h3>
            <p class="pad0 sec1para">Provides the access to show the list of the Pages that the User manages.</p>



            <h3 class="sec1head3 mar0">Status</h3>
            <p class="pad0 sec1para">Provides access to the User's status messages and checkins.</p>



            <h3 class="sec1head3 mar0">Status Updates</h3>
            <p class="pad0 sec1para">Update the User's status.</p>



            <h3 class="sec1head3 mar0">Text Messaging</h3>
            <p class="pad0 sec1para">Send SMS messages to the User's phone.</p>



            <h3 class="sec1head3 mar0">Upload Photos</h3>
            <p class="pad0 sec1para">Add or modify user's photos.</p>



            <h3 class="sec1head3 mar0">User subscriptions</h3>




            <h3 class="sec1head3 mar0">User Timeline Posts Access</h3>
            <p class="pad0 sec1para">Provides access to the posts on a Users's timeline.</p>



            <h3 class="sec1head3 mar0">Video upload</h3>
            <p class="pad0 sec1para">Add or modify User's videos.</p>



            <h3 class="sec1head3 mar0">video.watches&nbsp;Action</h3>
            <p class="pad0 sec1para">Allows the app to retrieve the actions published by all applications using the built-in&nbsp;video.watches&nbsp;action.</p>



            <h3 class="sec1head3 mar0">Videos</h3>
            <p class="pad0 sec1para">Provides access to the videos the User has uploaded and videos the User has been tagged in.</p>



            <h3 class="sec1head3 mar0">Website</h3>
            <p class="pad0 sec1para bdr">Provides access to the User's web site URL.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h2 class="sec1head2">Detailed information on the processing of Personal Data</h2>
          <p class="sec1para">Personal Data is collected for the following purposes and using the following services:</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="demo">
              <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingTwo">
                    <h4 class="panel-title">

                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                        <i class="more-less glyphicon glyphicon-chevron-left"></i>
                        <span class="sec1head3">Access to third-party accounts</span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div class="panel-body">
                      <p class="pad0 sec1para1">This type of service allows this Application to access Data from your account on a third-party service and perform actions with it.<br>
                        These services are not activated automatically, but require explicit authorization by the User.</p>
                      <h4 class="sec1head3 mar0">Facebook account access (this Application)</h4>
                      <p class="pad0 sec1para">This service allows this Application to connect with the User's account on the Facebook social network, provided by Facebook, Inc.</p>

                      <p class="pad0 sec1para">
                        Permissions asked:
                        About Me, Access Friend Lists, Access Page CTA, Access Requests, App Notifications, Contact email, Custom User Actions, Email, Friend subscriptions, Interests, Likes, List of Friends, Manage Friend Lists, Manage Groups, Manage Pages, Mobile Messaging on behalf of Page, News Feed and Wall, Page Messaging, Photos, Publish as Page, Publish to the Wall, Share, Show List of Managed Pages, Status, Status Updates, Text Messaging, Upload Photos, User subscriptions, User Timeline Posts Access, Video upload, video.watches&nbsp;Action, Videos and Website.</p>
                      <p class="pad0 sec1para">Place of processing:
                        United States – <a href="https://www.facebook.com/policy.php" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                      </p>
                      <h4 class="sec1head3 mar0">Stripe account access (Stripe Inc)</h4>

                      <p class="pad0 sec1para">This service allows this Application to connect with the User's account on Stripe, provided by Stripe, Inc.</p>

                      <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>
                      <p class="pad0 sec1para">Place of processing: United States – <a href="https://stripe.com/us/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                      <h4 class="sec1head3 mar0">Twitter account access (Twitter, Inc.)</h4>

                      <p class="pad0 sec1para">This service allows this Application to connect with the User's account on the Twitter social network, provided by Twitter, Inc.</p>

                      <p class="pad0 sec1para">
                        Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>
                      <p class="pad0 sec1para">Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="demo">
              <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingThree">
                    <h4 class="panel-title">

                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                        <i class="more-less glyphicon glyphicon-chevron-left"></i>
                        <span class="sec1head3">Advertising</span>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div class="panel-body">
                      <p class="pad0 sec1para">This type of service allows User Data to be utilized for advertising communication purposes displayed in the form of banners and other advertisements on this Application, possibly based on User interests.<br/>
                        This does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below.<br/>
                        Some of the services listed below may use Cookies to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside this Application. For more information, please check the privacy policies of the relevant services.<br/>
                        In addition to any opt out offered by any of the services below, the User may opt out of a third-party service's use of cookies by visiting the <a href="http://optout.networkadvertising.org/">Network Advertising Initiative opt-out page</a>.</p>
                      <h3 class="sec1head3 mar0">AdMob (AdMob Google Inc.)</h3>
                      <p class="pad0 sec1para">AdMob is an advertising service provided by AdMob Google Inc.<br/>
                        <strong>In order to understand Google's use of Data, consult <a href="https://www.google.com/policies/privacy/partners/">Google's partner policy</a></strong>.<br/><br/>Personal Data collected:
                        Cookies, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example) and Usage Data.</p>

                      <p class="sec1para pad0">Place of processing: United States – <a href="https://www.google.com/policies/technologies/ads/" target="_blank">Privacy Policy</a> &ndash; <a href="https://adssettings.google.com/authenticated" target="_blank">Opt Out</a>.</p>

                      <h3 class="sec1head3 mar0">AdKaora (AdKaora srl)</h3>
                      <p class="pad0 sec1para">AdKaora is an advertising service provided by AdKaora srl.</p>

                      <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>
                      <p class="pad0 sec1para">Place of processing: Italy &ndash;<a href="http://www.mondadori.it/privacy-policy" target="_blank">Privacy Policy</a>.</p>

                      <h4 class="sec1head3 mar0">Facebook Audience Network (Facebook, Inc.)</h4>

                      <p class="pad0 sec1para">Facebook Audience Network is an advertising service provided by Facebook, Inc. <strong>In order to understand Facebook's use of Data, consult <a href="https://www.facebook.com/about/privacy/">Facebook's data policy</a>.</strong>
                        This Application may use identifiers for mobile devices (including Android Advertising ID or Advertising Identifier for iOS, respectively) and technologies similar to cookies to run the Facebook Audience Network service. One of the ways Audience Network shows ads is by using the User's ad preferences. The User can control this in the <a href="https://www.facebook.com/ads/settings">Facebook ad settings</a>.
                        Users may opt-out of certain Audience Network targeting through applicable device settings, such as the device advertising settings for mobile phones or by following the instructions in other Audience Network related sections of this privacy policy, if available.<br/><br/>
                        Personal Data collected: Cookies, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example) and Usage Data.</p>

                      <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.facebook.com/settings?tab=ads">Opt Out</a>.</p>

                      <h3 class="sec1head3 mar0">Yahoo App Publishing Advertising (Yahoo! Inc.)</h3>
                      <p class="sec1para pad0">Yahoo App Publishing is an advertising service provided by Yahoo! Inc.<br/>
                        If the User chooses to opt-out, Yahoo App Publishing will stop tracking data for the device identified by the provided MAC address and/or device identifier going forward.<br/>
                        Yahoo App Publishing will stop providing ads and/or recommendations to that MAC address and/or device identifier across all publishers.<br/><br/>
                        Personal Data collected: Cookies and Usage Data.</p>

                      <p class="sec1para pad0">Place of processing: United States &ndash;<a href="https://info.yahoo.com/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://developer.yahoo.com/flurry/end-user-opt-out/">Opt Out</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingFour">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Analytics</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                  <div class="panel-body">
                    <p class="pad0 sec1para">The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.
                    </p>
                    <h4 class="sec1head3 mar0">Google Analytics with anonymized IP (Google Inc.)</h4>
                    <p class="pad0 sec1para">Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.<br/>
                      Google may use the Data collected to contextualize and personalize the ads of its own advertising network.<br/>
                      This integration of Google Analytics anonymizes your IP address. It works by shortening Users' IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the complete IP address be sent to a Google server and shortened within the US.</p>
                    <p class="pad0 sec1para">
                      Personal Data collected: Cookies and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing:United States &ndash;<a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">Opt Out</a>.
                    </p>
                    <h4 class="sec1head3 mar0">AdEspresso conversion tracking (Creative Web Srl)</h4>
                    <p class="pad0 sec1para">AdEspresso conversion tracking is an analytics service provided by Creative Web Srl that connects data from the Facebook advertising network with actions performed on this Application.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data</p>
                    <p class="pad0 sec1para">Place of processing: Italy – <a href="https://adespresso.com/privacy/" target="_blank">Privacy Policy</a>.</p>
                    <h4 class="sec1head3 mar0">Facebook Ads conversion tracking (Facebook, Inc.)</h4>

                    <p class="pad0 sec1para">Facebook Ads conversion tracking is an analytics service provided by Facebook, Inc. that connects data from the Facebook advertising network with actions performed on this Application.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected: Cookies and Usage Data.</p>
                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a>.</p>
                    <h3 class="sec1head3 mar0">Google Analytics (Google Inc.)</h3>
                    <p class="pad0 sec1para">Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.<br/>
                      Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
                    <p class="sec1para pad0">
                      Personal Data collected:
                      Cookies and Usage Data.
                    </p>
                    <p class="sec1para pad0">Place of processing: United States &ndash;<a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=en"  target="_blank">Opt Out</a>.
                    </p>

                    <h3 class="sec1head3 mar0">KISSmetrics (KISSmetrics)</h3>
                    <p class="pad0 sec1para">KISSmetrics is an analytics service provided by Kissmetrics Inc.<br/><br/>
                      Personal Data collected: Cookies and Usage Data</p>

                    <p class="pad0 sec1para">Place of processing:United States &ndash;<a href="https://www.kissmetrics.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.kissmetrics.com/privacy#controls">Opt Out</a>.</p>

                    <h3 class="sec1head3 mar0">Twitter Ads conversion tracking (Twitter, Inc.)</h3>
                    <p class="sec1para pad0">Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with actions performed on this Application.<br/><br/>
                      Personal Data collected:Cookies and Usage Data.
                    </p>
                    <p class="sec1para pad0">Place of processing:United States&ndash;<a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a>.
                    </p>

                    <h3 class="sec1head3 mar0">Wordpress Stats (Automattic Inc.)</h3>
                    <p class="sec1para pad0">Wordpress Stats is an analytics service provided by Automattic Inc.<br/><br/>
                      Personal Data collected: Cookies and Usage Data.</p>

                    <p class="sec1para pad0">Place of processing: United States &ndash;<a href="https://automattic.com/privacy/" target="_blank">Privacy Policy</a>.
                    </p>
                    <h3 class="sec1head3 mar0">Yahoo Advertising conversion tracking (Yahoo! Inc.)</h3>
                    <p class="sec1para pad0">Yahoo Advertising conversion tracking is an analytics service provided by Yahoo! Inc. that connects data from the Yahoo advertising network with actions performed on this Application.<br/><br/>
                      Personal Data collected:Cookies and Usage Data.</p>

                    <p class="sec1para pad0">Place of processing:United States &ndash;<a href="https://policies.yahoo.com/us/en/yahoo/privacy/index.htm" target="_blank">Privacy Policy</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingFive">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Backup saving and management</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows the Owner to save and manage backups of this Application on external servers managed by the service provider itself. The backups may include the source code and content as well as the data that the User provides to this Application.</p>

                    <h3 class="sec1head3 mar0">Backup on Dropbox (Dropbox, Inc.)</h3>
                    <p class="pad0 sec1para">Dropbox is a service to save and manage backups provided by Dropbox Inc.</p>

                    <p class="sec1para pad0">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="sec1para pad0">Place of processing:United States &ndash;<a href="https://www.dropbox.com/privacy#privacy" target="_blank">Privacy Policy</a>.
                    </p>

                    <h4 class="sec1head3 mar0">Backup on Google Drive (Google Inc.)</h4>

                    <p class="pad0 sec1para">Google Drive is a service to save and manage backups provided by Google Inc.<br/><br/>
                      Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://support.google.com/drive/answer/2450387?hl=en" target="_blank">Privacy Policy</a></p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSix">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Commercial affiliation</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows this Application to display advertisements for third-party products or services. Ads can be displayed either as advertising links or as banners using various kinds of graphics.<br/>
                      Clicks on the icon or banner posted on the Application are tracked by the third-party services listed below, and are shared with this Application.<br/>
                      For details of which data are collected, please refer to the privacy policy of each service.
                    </p>

                    <h4 class="sec1head3 mar0">JVZoo (BBC Systems Inc.)</h4>
                    <p class="pad0 sec1para">JVZoo is a banner commercial affiliation service provided by BBC Systems Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected: Cookies and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing:United States &ndash;<a href="https://www.jvzoo.com/privacy" target="_blank">Privacy Policy</a>.
                    </p>


                    <h4 class="sec1head3 mar0">Amazon Affiliation (Amazon)</h4>
                    <p class="pad0 sec1para">Amazon Affiliation is a commercial affiliation service provided by Amazon.com Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data</p>
                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.amazon.com/gp/help/customer/display.html/ref=hp_rel_topic?ie=UTF8&amp;nodeId=468496" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">AliExpress Affiliate (Alibaba.com Singapore E-Commerce Private Limited)</h4>

                    <p class="pad0 sec1para">AliExpress Affiliate is a commercial affiliation service provided by Alibaba.com Singapore E-Commerce Private Limited.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected: Cookies and Usage Data.</p>
                    <p class="pad0 sec1para">Place of processing: Singapore – <a href="http://rule.alibaba.com/rule/detail/2034.htm?spm=a2700.7787031.a271py.41.d12hNd" target="_blank">Privacy Policy</a>.</p>

                    <h3 class="sec1head3 mar0">ClickBank (Click Sales Inc.)</h3>
                    <p class="pad0 sec1para">ClickBank is a banner commercial affiliation service provided by Click Sales Inc.</p>
                    <p class="sec1para pad0">
                      Personal Data collected:
                      Cookies and Usage Data.
                    </p>
                    <p class="sec1para pad0">Place of processing: United States &ndash;<a href="https://accounts.clickbank.com/privacy.html" target="_blank">Privacy Policy</a>.
                    </p>

                    <h3 class="sec1head3 mar0">Zooplus Affiliate Programme (Zooplus AG)</h3>
                    <p class="pad0 sec1para">Zooplus Affiliate Programme is a commercial affiliation service provided by Zooplus AG.<br/><br/>
                      Personal Data collected: Cookies and Usage Data</p>

                    <p class="pad0 sec1para">Place of processing:United States &ndash;<a href="http://www.zooplus.com/content/privacy" target="_blank">Privacy Policy</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSeven">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Contacting the User</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                  <div class="panel-body">
                    <h3 class="sec1head3 mar0">Contact form (this Application)</h3>
                    <p class="pad0 sec1para">By filling in the contact form with their Data, the User authorizes this Application to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.</p>
                    <p class="sec1para pad0">Personal Data collected: address, city, company name, country, email address, field of activity, first name, gender, last name, phone number, profession, User ID, VAT Number, website and ZIP/Postal code.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingEight">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Content commenting</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                  <div class="panel-body">
                    <p class="pad0 sec1para">Content commenting services allow Users to make and publish their comments on the contents of this Application.<br/>
                      Depending on the settings chosen by the Owner, Users may also leave anonymous comments. If there is an email address among the Personal Data provided by the User, it may be used to send notifications of comments on the same content. Users are responsible for the content of their own comments.<br/>
                      If a content commenting service provided by third parties is installed, it may still collect web traffic data for the pages where the comment service is installed, even when Users do not use the content commenting service.
                    </p>

                    <h4 class="sec1head3 mar0">Disqus (Disqus)</h4>
                    <p class="pad0 sec1para">Disqus is a content commenting service provided by Big Heads Labs Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      Cookies, Usage Data and various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing:United States &ndash;<a href="https://help.disqus.com/customer/portal/articles/466259-privacy-policy" target="_blank">Privacy Policy</a> &ndash; <a href="https://help.disqus.com/customer/portal/articles/1657951">Opt out</a>
                    </p>

                    <h4 class="sec1head3 mar0">Facebook Comments (Facebook, Inc.)</h4>
                    <p class="pad0 sec1para">Facebook Comments is a content commenting service provided by Facebook, Inc. enabling the User to leave comments and share them on the Facebook platform.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.facebook.com/privacy/explanation" target="_blank">Privacy Policy</a>.</p>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingNine">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Displaying content from external platforms</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseNine" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows you to view content hosted on external platforms directly from the pages of this Application and interact with them.<br/>
                      This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.</p>


                    <h4 class="sec1head3 mar0">GitHub button and social widgets (GitHub Inc.)</h4>
                    <p class="pad0 sec1para">The GitHub button and social widgets are services allowing interaction with the GitHub and Gist services provided by GitHub, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Usage Data</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://help.github.com/articles/github-privacy-policy" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Google Fonts (Google Inc.)</h4>
                    <p class="pad0 sec1para">Google Fonts is a typeface visualization service provided by Google Inc. that allows this Application to incorporate content of this kind on its pages.</p>

                    <p class="pad0 sec1para">Personal Data collected: Usage Data and various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.google.it/intl/policies/privacy/" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">Google Site Search (Google Inc.)</h4>
                    <p class="pad0 sec1para">Google Site Search is a search engine embedding service provided by Google Inc. that allows this Application to incorporate content of this kind on its pages.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.google.com/policies/privacy/" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">Instagram widget (Instagram, Inc.)</h4>
                    <p class="pad0 sec1para">Instagram is an image visualization service provided by Instagram, Inc. that allows this Application to incorporate content of this kind on its pages.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.instagram.com/about/legal/privacy/" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">YouTube video widget (Google Inc.)</h4>
                    <p class="pad0 sec1para">YouTube is a video content visualization service provided by Google Inc. that allows this Application to incorporate content of this kind on its pages.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="http://www.google.it/intl/en/policies/privacy/" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">YouTube video widget without cookies (Google Inc.)</h4>
                    <p class="pad0 sec1para">YouTube is a video content visualization service provided by Google Inc. that allows this Application to incorporate content of this kind on its pages.<br/>
                      This widget is set up in a way that ensures that YouTube won't store information and cookies about Users on this Application unless they play the video.</p>

                    <p class="pad0 sec1para">Personal Data collected: Usage Data</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="http://www.google.it/intl/en/policies/privacy/" target="_blank">Privacy Policy</a>.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Handling payments</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen">
                  <div class="panel-body">
                    <p class="pad0 sec1para">Payment processing services enable this Application to process payments by credit card, bank transfer or other means. To ensure greater security, this Application shares only the information necessary to execute the transaction with the financial intermediaries handling the transaction.<br/>
                      Some of these services may also enable the sending of timed messages to the User, such as emails containing invoices or notifications concerning the payment.
                    </p>

                    <h4 class="sec1head3 mar0">2Checkout (2Checkout.com, Inc.)</h4>
                    <p class="pad0 sec1para">2Checkout is a payment service provided by 2Checkout.com, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing:United States &ndash;<a href="https://www.2checkout.com/policies/privacy-policy" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Amazon Payments (Amazon)</h4>
                    <p class="pad0 sec1para">Amazon Payments is a payment service provided by Amazon.com, Inc., which allows Users to make online payments using their Amazon credentials.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: See the Amazon privacy policy – <a href="https://payments.amazon.com/help/Personal-Accounts/Privacy-Security/Privacy-Notice" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Authorize.Net (Authorize.Net)</h4>
                    <p class="pad0 sec1para">Authorize.Net is a payment service provided by Authorize.Net.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.authorize.net/company/privacy/" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">PayPal (PayPal Inc.)</h4>
                    <p class="pad0 sec1para">PayPal is a payment service provided by PayPal Inc., which allows Users to make online payments.</p>

                    <p class="pad0 sec1para">Personal Data collected:various types of Data as specified in the privacy policy of the service.</p>

                    <h4 class="sec1head3 mar0">PayPal Carrier Payments (PayPal Inc.)</h4>
                    <p class="pad0 sec1para">PayPal Carrier Payments is a payment service provided by PayPal, Inc., which allows Users to make online payments using their mobile phone operator.</p>

                    <p class="pad0 sec1para">Personal Data collected: phone number and various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: See the PayPal privacy policy – <a href="https://www.paypal.com/cgi-bin/webscr?cmd=p/gen/ua/policy_privacy-outside" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">PayPal Payments Hub (PayPal Inc.)</h4>
                    <p class="pad0 sec1para">PayPal Payments Hub  is a payment service provided by PayPal Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: See the PayPal privacy policy – <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Braintree (PayPal Inc.)</h4>
                    <p class="pad0 sec1para">Braintree is a payment service provided by Braintree, a division of PayPal, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>



                    <h4 class="sec1head3 mar0">Google Wallet (Google Inc.)</h4>
                    <p class="pad0 sec1para">Google Wallet is a payment service provided by Google Inc., which allows users to make online payments using their Google credentials.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://wallet.google.com/files/privacy.html?hl=en" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Simplify (MasterCard International Inc.)</h4>
                    <p class="pad0 sec1para">Simplify is a payment service provided by MasterCard International Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.simplify.com/commerce/docs/misc/legal/privacy-notice" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Stripe (Stripe Inc)</h4>
                    <p class="pad0 sec1para">Stripe is a payment service provided by Stripe Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://stripe.com/terms/US" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Zooz (Zooz, Ltd.)</h4>
                    <p class="pad0 sec1para">Zooz is a payment service provided by Zooz, Ltd.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: Israel – <a href="http://www.zooz.com/privacy" target="_blank">Privacy Policy</a>.</p>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingEleven">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Hosting and backend infrastructure</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseEleven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEleven">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service has the purpose of hosting Data and files that enable this Application to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of this Application.
                      Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>


                    <h4 class="sec1head3 mar0">Google Cloud Storage (Google Inc.)</h4>
                    <p class="pad0 sec1para">Google Cloud Storage is a hosting service provided by Google Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.google.com/intl/policies/privacy/" target="_blank">Privacy Policy</a>.</p>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwelve">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Interaction with data collection platforms and other third parties</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwelve" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwelve">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows Users to interact with data collection platforms or other services directly from the pages of this Application for the purpose of saving and reusing data.
                      If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service.
                    </p>

                    <h4 class="sec1head3 mar0">GetResponse widget (Implix Sp. z o.o.)</h4>
                    <p class="pad0 sec1para">The GetResponse widget is a service for interacting with the GetResponse email address management and message sending service provided by Implix Sp. z o.o.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      Cookies, email address, first name and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing:Poland &ndash;<a href="https://www.getresponse.com/legal/privacy.html" target="_blank">Privacy Policy</a>
                    </p>



                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingThirteen">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Interaction with external social networks and platforms</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseThirteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirteen">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows interaction with social networks or other external platforms directly from the pages of this Application.<br/>
                      The interaction and information obtained through this Application are always subject to the User’s privacy settings for each social network.<br/>
                      This type of service might still collect traffic data for the pages where the service is installed, even when Users do not use it.</p>


                    <h4 class="sec1head3 mar0">Facebook Like button and social widgets (Facebook, Inc.)</h4>
                    <p class="pad0 sec1para">The Facebook Like button and social widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.facebook.com/privacy/explanation" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">PayPal button and widgets (PayPal Inc.)</h4>
                    <p class="pad0 sec1para">The PayPal button and widgets are services allowing interaction with the PayPal platform provided by PayPal Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: See the PayPal privacy policy – <a href="https://www.paypal.com/cgi-bin/webscr?cmd=p/gen/ua/policy_privacy-outside" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">Pinterest "Pin it" button and social widgets (Pinterest)</h4>
                    <p class="pad0 sec1para">The Pinterest "Pin it" button and social widgets are services allowing interaction with the Pinterest platform provided by Pinterest Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://about.pinterest.com/en/privacy-policy" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">Reddit button and widgets (reddit inc.)</h4>
                    <p class="pad0 sec1para">The reddit button and widgets are services allowing interaction with the reddit platform provided by reddit inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.reddit.com/help/privacypolicy" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">ShareThis (Sharethis Inc.)</h4>
                    <p class="pad0 sec1para">ShareThis is a service provided by ShareThis Inc., which displays a widget that allows interaction with social networks and external platforms as well as sharing the contents of this Application.<br/>
                      Depending on the configuration, this service can display widgets belonging to third parties such as the managers of social networks where interactions are shared. In this case, also the third parties that provide the widget will be informed of interactions and Usage Data on the pages where this service is installed.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="http://sharethis.com/privacy" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Tumblr sharing button and social widgets (Tumblr Inc.)</h4>
                    <p class="pad0 sec1para">The Tumblr sharing button and social widgets are services allowing interaction with the Tumblr social network provided by Tumblr Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.tumblr.com/policy/en/privacy" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Twitter Tweet button and social widgets (Twitter, Inc.)</h4>
                    <p class="pad0 sec1para">The Twitter Tweet button and social widgets are services allowing interaction with the Twitter social network provided by Twitter, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">YouTube button and social widgets (Google Inc.)</h4>
                    <p class="pad0 sec1para">The YouTube button and social widgets are services allowing interaction with the YouTube social network provided by Google Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy Policy</a>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingFifteen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Interaction with live chat platforms</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseFifteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFifteen">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows Users to interact with third-party live chat platforms directly from the pages of this Application, for contacting and being contacted by this Application support service.<br/>
                      If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service. Moreover, live chat conversations may be logged.
                    </p>

                    <h4 class="sec1head3 mar0">Facebook Messenger Customer Chat (Facebook, Inc.)</h4>
                    <p class="pad0 sec1para">The Facebook Messenger Customer Chat is a service for interacting with the Facebook Messenger live chat platform provided by Facebook, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      About Me, Cookies and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Freshchat Widget (Freshworks, Inc.)</h4>
                    <p class="pad0 sec1para">The Freshchat Widget is a service for interacting with the Freshchat live chat platform provided by Freshworks, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      Cookies, email address and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.freshworks.com/privacy/" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Tawk.to Widget (tawk.to ltd.)</h4>
                    <p class="pad0 sec1para">The Tawk.to Widget is a service for interacting with the Tawk.to live chat platform provided by tawk.to ltd.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      Cookies and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United Kingdom &ndash;<a href="https://www.tawk.to/privacy-policy/" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Zopim Widget (Zopim Technologies Pte Ltd)</h4>
                    <p class="pad0 sec1para">The Zopim Widget is a service for interacting with the Zopim live chat platform provided by  Zopim Technologies Pte Ltd.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      Cookies and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing: Singapore &ndash;<a href="https://www.zopim.com/privacy" target="_blank">Privacy Policy</a>
                    </p>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSixteen">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Interaction with support and feedback platforms</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseSixteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSixteen">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows Users to interact with third-party support and feedback platforms directly from the pages of this Application.<br/>
                      If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service.</p>


                    <h4 class="sec1head3 mar0">Zendesk Widget (Zendesk)</h4>
                    <p class="pad0 sec1para">The Zendesk Widget is a service for interacting with the Zendesk support and feedback platform provided by Zendesk Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.zendesk.com/company/privacy" target="_blank">Privacy Policy</a>.</p>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSeventeen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Managing contacts and sending messages</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseSeventeen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeventeen">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User.<br/>
                      These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.
                    </p>

                    <h4 class="sec1head3 mar0">GetResponse (Implix Sp. z o.o.)</h4>
                    <p class="pad0 sec1para">GetResponse is an email address management and message sending service provided by Implix Sp. z o.o.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address.
                    </p>
                    <p class="pad0 sec1para">Place of processing: Poland &ndash;<a href="https://www.getresponse.com/legal/privacy.html" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">AWeber (AWeber)</h4>
                    <p class="pad0 sec1para">AWeber is an email address management and message sending service provided by AWeber Systems Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.aweber.com/privacy.htm" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Sendgrid (Sendgrid)</h4>
                    <p class="pad0 sec1para">Sendgrid is an email address management and message sending service provided by Sendgrid Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://sendgrid.com/privacy" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Mailgun (Mailgun, Inc.)</h4>
                    <p class="pad0 sec1para">Mailgun is an email address management and message sending service provided by Mailgun, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.mailgun.com/privacy" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Drip (Numa Group LLC.)</h4>
                    <p class="pad0 sec1para">Drip is an email address management and message sending service provided by Numa Group LLC.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.getdrip.com/privacy" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">iContact (iContact LLC)</h4>
                    <p class="pad0 sec1para">iContact is an email address management and message sending service provided by iContact LLC.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address and Usage Data.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.icontact.com/legal/privacy" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">MailChimp (The Rocket Science Group, LLC.)</h4>
                    <p class="pad0 sec1para">MailChimp is an email address management and message sending service provided by The Rocket Science Group, LLC.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://mailchimp.com/legal/privacy/" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Twilio (Twilio, Inc.)</h4>
                    <p class="pad0 sec1para">Twilio is a phone numbers management and communication service provided by Twilio, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      phone number.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.twilio.com/legal/privacy" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">ZOHO Campaigns (Zoho Corporation Pvt. Ltd.)</h4>
                    <p class="pad0 sec1para">ZOHO Campaigns is an email address management and message sending service provided by Zoho Corporation Pvt. Ltd.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      email address.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.zoho.com/privacy.html" target="_blank">Privacy Policy</a>
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingEighteen">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Managing landing and invitation pages</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseEighteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEighteen">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service helps with building and managing landing and invitation pages, i.e., pages for presenting a product or service, where you may add your contact information such as an email address.<br/>
                      Managing these pages means that these services will handle the Personal Data collected through the pages, including Usage Data.</p>


                    <h4 class="sec1head3 mar0">Leadpages (Avenue 81, Inc.)</h4>
                    <p class="pad0 sec1para">Leadpages is a landing page management service provided by Avenue 81, Inc., that allows this Application to collect the email addresses of Users interested in its service. <br/>
                      Leadpages allows the Owner to track and analyze the User response concerning web traffic or behavior regarding changes to the structure, text or any other component of the created landing pages.</p>

                    <p class="pad0 sec1para">Personal Data collected: email address, Usage Data and various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.leadpages.net/privacy" target="_blank">Privacy Policy</a>.</p>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingNineteen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Managing support and contact requests</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseNineteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNineteen">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows this Application to manage support and contact requests received via email or by other means, such as the contact form.<br/>
                      The Personal Data processed depend on the information provided by the User in the messages and the means used for communication (e.g. email address).
                    </p>

                    <h4 class="sec1head3 mar0">Freshdesk (Freshworks, Inc.)</h4>
                    <p class="pad0 sec1para">Freshdesk is a support and contact request management service provided by Freshworks, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.freshworks.com/privacy/" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Zendesk (Zendesk)</h4>
                    <p class="pad0 sec1para">Zendesk is a support and contact request management service provided by Zendesk Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.zendesk.com/company/privacy" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">ZOHO CRM Email (Zoho Corporation Pvt. Ltd.)</h4>
                    <p class="pad0 sec1para">ZOHO CRM Email is a support and contact request management service provided by Zoho Corporation Pvt. Ltd.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.zoho.com/privacy.html" target="_blank">Privacy Policy</a>
                    </p>



                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwenty">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Platform services and hosting</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwenty" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwenty">
                  <div class="panel-body">
                    <p class="pad0 sec1para">These services have the purpose of hosting and running key components of this Application, therefore allowing the provision of this Application from within a unified platform. Such platforms provide a wide range of tools to the Owner  –  e.g. analytics, user registration, commenting, database management, e-commerce, payment processing – that imply the collection and handling of Personal Data.
                      Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>


                    <h4 class="sec1head3 mar0">Tumblr (Tumblr Inc.)</h4>
                    <p class="pad0 sec1para">Tumblr is a platform provided by Tumblr Inc. that allows the Owner to build, run and host this Application.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.tumblr.com/policy/en/privacy" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">WordPress.com (Automattic Inc.)</h4>
                    <p class="pad0 sec1para">WordPress.com is a platform provided by Automattic Inc. that allows the Owner to build, run and host this Application.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://automattic.com/privacy/" target="_blank">Privacy Policy</a>.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwentyone">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwentyone" aria-expanded="false" aria-controls="collapseTwentyone">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Registration and authentication</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwentyone" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyone">
                  <div class="panel-body">
                    <p class="pad0 sec1para">By registering or authenticating, Users allow this Application to identify them and give them access to dedicated services.<br/>
                      Depending on what is described below, third parties may provide registration and authentication services. In this case, this Application will be able to access some Data, stored by these third-party services, for registration or identification purposes.
                    </p>

                    <h4 class="sec1head3 mar0">Log In with PayPal (PayPal Inc.)</h4>
                    <p class="pad0 sec1para">Log In with PayPal is a registration and authentication service provided by PayPal Inc. and is connected to the PayPal network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: See the PayPal privacy policy &ndash;<a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Facebook Authentication (Facebook, Inc.)</h4>
                    <p class="pad0 sec1para">Facebook Authentication is a registration and authentication service provided by Facebook, Inc. and is connected to the Facebook social network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.facebook.com/help/405977429438260" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">GitHub OAuth (GitHub Inc.)</h4>
                    <p class="pad0 sec1para">GitHub OAuth is a registration and authentication service provided by GitHub Inc. and is connected to the GitHub network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://help.github.com/articles/github-terms-of-service#b-api-terms" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Google OAuth (Google Inc.)</h4>
                    <p class="pad0 sec1para">Google OAuth is a registration and authentication service provided by Google Inc. and is connected to the Google network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.google.com/policies/privacy/" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">Instagram Authentication (Instagram, Inc.)</h4>
                    <p class="pad0 sec1para">Instagram Authentication is a registration and authentication service provided by Instagram, Inc. and is connected to the Instagram social network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.instagram.com/about/legal/privacy/" target="_blank">Privacy Policy</a>
                    </p>


                    <h4 class="sec1head3 mar0">Login with Amazon (Amazon)</h4>
                    <p class="pad0 sec1para">Login with Amazon is a registration and authentication service provided by Amazon.com, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.amazon.com/gp/help/customer/display.html/ref=hp_rel_topic?ie=UTF8&amp;nodeId=468496" target="_blank">Privacy Policy</a>
                    </p>


                    <h4 class="sec1head3 mar0">OneDrive OAuth (Microsoft Corporation)</h4>
                    <p class="pad0 sec1para">OneDrive OAuth is a registration and authentication service provided by Microsoft Corporation and is connected to the OneDrive network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://msdn.microsoft.com/en-us/dn529288" target="_blank">Privacy Policy</a>
                    </p>


                    <h4 class="sec1head3 mar0">Pinterest OAuth (Pinterest)</h4>
                    <p class="pad0 sec1para">Pinterest OAuth is a registration and authentication service provided by Pinterest, Inc. and is connected to the Pinterest social network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://about.pinterest.com/en/privacy-policy" target="_blank">Privacy Policy</a>
                    </p>


                    <h4 class="sec1head3 mar0">Stripe OAuth (Stripe Inc)</h4>
                    <p class="pad0 sec1para">Stripe OAuth is a registration and authentication service provided by Stripe, Inc. and is connected to the Stripe network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://stripe.com/us/privacy" target="_blank">Privacy Policy</a>
                    </p>


                    <h4 class="sec1head3 mar0">Twitch.tv Authentication (Twitch Interactive, Inc.)</h4>
                    <p class="pad0 sec1para">Twitch.tv Authentication is a registration and authentication service provided by Twitch Interactive, Inc.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.twitch.tv/p/privacy-policy" target="_blank">Privacy Policy</a>
                    </p>


                    <h4 class="sec1head3 mar0">Twitter OAuth (Twitter, Inc.)</h4>
                    <p class="pad0 sec1para">Twitter Oauth is a registration and authentication service provided by Twitter, Inc. and is connected to the Twitter social network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://dev.twitter.com/terms/api-terms" target="_blank">Privacy Policy</a>
                    </p>


                    <h4 class="sec1head3 mar0">WordPress.com Single Sign On (Automattic Inc.)</h4>
                    <p class="pad0 sec1para">WordPress.com Single Sign On is a registration and authentication service provided by Automattic Inc. and is connected to the WordPress.com network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://automattic.com/privacy/" target="_blank">Privacy Policy</a>
                    </p>

                    <h4 class="sec1head3 mar0">YouTube OAuth (Google Inc.)</h4>
                    <p class="pad0 sec1para">YouTube OAuth is a registration and authentication service provided by Google Inc. and is connected to the YouTube network.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy Policy</a>
                    </p>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwentytwo">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Remarketing and behavioral targeting</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwentytwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentytwo">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows this Application and its partners to inform, optimize and serve advertising based on past use of this Application by the User.<br/>
                      This activity is performed by tracking Usage Data and by using Cookies, information that is transferred to the partners that manage the remarketing and behavioral targeting activity.<br/>
                      In addition to any opt out offered by any of the services below, the User may opt out of a third-party service's use of cookies by visiting the <a href="http://optout.networkadvertising.org/">Network Advertising Initiative opt-out page</a>.</p>


                    <h4 class="sec1head3 mar0">AdRoll (Semantic Sugar, Inc.)</h4>
                    <p class="pad0 sec1para">AdRoll is an advertising service provided by Semantic Sugar, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.adroll.com/about/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://info.evidon.com/pub_info/573?v=1&nt=1&nw=false">Opt Out</a>.</p>

                    <h4 class="sec1head3 mar0">Facebook Custom Audience (Facebook, Inc.)</h4>
                    <p class="pad0 sec1para">Facebook Custom Audience is a remarketing and behavioral targeting service provided by Facebook, Inc. that connects the activity of this Application with the Facebook advertising network.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and email address.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>.</p>

                    <h4 class="sec1head3 mar0">Facebook Remarketing (Facebook, Inc.)</h4>
                    <p class="pad0 sec1para">Facebook Remarketing is a remarketing and behavioral targeting service provided by Facebook, Inc. that connects the activity of this Application with the Facebook advertising network.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>.</p>


                    <h4 class="sec1head3 mar0">Perfect Audience (NowSpots, Inc.)</h4>
                    <p class="pad0 sec1para">Perfect Audience is an advertising service provided by NowSpots, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.perfectaudience.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.perfectaudience.com/privacy/index.html#opt-out">Opt Out</a>.</p>


                    <h4 class="sec1head3 mar0">Twitter Remarketing (Twitter, Inc.)</h4>
                    <p class="pad0 sec1para">Twitter Remarketing is a remarketing and behavioral targeting service provided by Twitter, Inc. that connects the activity of this Application with the Twitter advertising network.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and Usage Data.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://support.twitter.com/articles/20170405">Opt Out</a>.</p>


                    <h4 class="sec1head3 mar0">Twitter Tailored Audiences (Twitter, Inc.)</h4>
                    <p class="pad0 sec1para">Twitter Tailored Audiences is a remarketing and behavioral targeting service provided by Twitter, Inc. that connects the activity of this Application with the Twitter advertising network</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies and email address.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://support.twitter.com/articles/20170405">Opt Out</a>.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 bdr">
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwentythree">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwentythree" aria-expanded="false" aria-controls="collapseTwentythree">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Traffic optimization and distribution</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwentythree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentythree">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows this Application to distribute their content using servers located across different countries and to optimize their performance.<br/>
                      Which Personal Data are processed depends on the characteristics and the way these services are implemented. Their function is to filter  communications between this Application and the User's browser.<br/>
                      Considering the widespread distribution of this system, it is difficult to determine the locations to which the contents that may contain Personal Information User are transferred.
                    </p>

                    <h4 class="sec1head3 mar0">CloudFlare (Cloudflare)</h4>
                    <p class="pad0 sec1para">CloudFlare is a traffic optimization and distribution service provided by CloudFlare Inc.<br/>
                      The way CloudFlare is integrated means that it filters all the traffic through this Application, i.e., communication between this Application and the User's browser, while also allowing analytical data from this Application to be collected.</p>

                    <p class="pad0 sec1para">
                      Personal Data collected:
                      Cookies and various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p class="pad0 sec1para">Place of processing: United States &ndash;<a href="https://www.cloudflare.com/security-policy/" target="_blank">Privacy Policy</a>
                    </p>




                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwentyfour">
                  <h4 class="panel-title">

                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwentyfour" aria-expanded="false" aria-controls="collapseTwentyfour">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">User database management</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwentyfour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyfour">
                  <div class="panel-body">
                    <p class="pad0 sec1para">This type of service allows the Owner to build user profiles by starting from an email address, a personal name, or other information that the User provides to this Application, as well as to track User activities through analytics features. This Personal Data may also be matched with publicly available information about the User (such as social networks' profiles) and used to build private profiles that the Owner can display and use for improving this Application.<br/>
                      Some of these services may also enable the sending of timed messages to the User, such as emails based on specific actions performed on this Application.</p>


                    <h4 class="sec1head3 mar0">ActiveCampaign (ActiveCampaign, Inc.)</h4>
                    <p class="pad0 sec1para">ActiveCampaign is a User database management service provided by ActiveCampaign, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: email address and various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.activecampaign.com/help/privacy-policy/" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">FullContact (FullContact Inc.)</h4>
                    <p class="pad0 sec1para">FullContact is a User database management service provided by FullContact Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: company name, date of birth, Email, first name, gender, last name and various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.fullcontact.com/privacy/" target="_blank">Privacy Policy</a>.</p>

                    <h4 class="sec1head3 mar0">Infusionsoft (Infusion Software, Inc.)</h4>
                    <p class="pad0 sec1para">Infusionsoft is a User database management service provided by Infusion Software, Inc.</p>

                    <p class="pad0 sec1para">Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.infusionsoft.com/legal/privacy-policy" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">Intercom (Intercom Inc.)</h4>
                    <p class="pad0 sec1para">Intercom is a User database management service provided by Intercom Inc.
                      Intercom can also be used as a medium for communications, either through email, or through messages within this Application.</p>

                    <p class="pad0 sec1para">Personal Data collected: Cookies, email address, Usage Data and various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://docs.intercom.io/pricing-and-terms/privacy" target="_blank">Privacy Policy</a>.</p>


                    <h4 class="sec1head3 mar0">ZOHO CRM (Zoho Corporation Pvt. Ltd.)</h4>
                    <p class="pad0 sec1para">ZOHO CRM is a User database management service provided by Zoho Corporation Pvt. Ltd.</p>

                    <p class="pad0 sec1para">Personal Data collected: email address and various types of Data as specified in the privacy policy of the service.</p>

                    <p class="pad0 sec1para">Place of processing: United States – <a href="https://www.zoho.com/privacy.html" target="_blank">Privacy Policy</a>.</p>




                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 bdr">
          <div class="col-md-6">
            <h2 class="sec1head2">Further information about Personal Data</h2>
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwentyfive">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwentyTwentyfive" aria-expanded="false" aria-controls="collapseTwentyTwentyfive">
                      <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      <span class="sec1head3">Selling goods and services online</span>
                    </a>
                  </h4>
                </div>
                <div id="collapseTwentyTwentyfive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyTwentyfive">
                  <div class="panel-body">
                    <p class="pad0 sec1para">The Personal Data collected are used to provide the User with services or to sell goods, including payment and possible delivery.<br/>
                      The Personal Data collected to complete the payment may include the credit card, the bank account used for the transfer, or any other means of payment envisaged. The kind of Data collected by this Application depends on the payment system used.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h3 class="sec1head2">The rights of Users</h3>
          <p class="sec1para pad0">
            Users may exercise certain rights regarding their Data processed by the Owner.<br/><br/>
            In particular, Users have the right to do the following:
          </p>
          <ul class="sec1list">
            <li><strong>Withdraw their consent at any time.</strong> Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</li>
            <li><strong>Object to processing of their Data.</strong> Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</li>
            <li><strong>Access their Data.</strong> Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</li>
            <li><strong>Verify and seek rectification.</strong> Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</li>
            <li><strong>Restrict the processing of their Data.</strong> Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</li>
            <li><strong>Have their Personal Data deleted or otherwise removed.</strong> Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</li>
            <li><strong>Receive their Data and have it transferred to another controller.</strong> Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</li>
            <li><strong>Lodge a complaint.</strong> Users have the right to bring a claim before their competent data protection authority.</li>
          </ul>
          <h3 class="sec1head3">Details about the right to object to processing</h3>
          <p class="sec1para pad0">
            Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.<br/><br/>
            Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
          </p>
          <h3 class="sec1head3">How to exercise these rights</h3>
          <p class="sec1para pad0 bdr">
            Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
          </p>
          <h3 class="sec1head2">Cookie Policy</h3>
          <p class="sec1para pad0 bdr">
            This Application uses Cookies. To learn more and for a detailed cookie notice, the User may consult the <a routerLink="/pages/cookie">Cookie Policy</a>.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h2 class="sec1head2">Additional information about Data collection and processing</h2>

          <h3 class="sec1head3 mar0">Legal action</h3>
          <p class="sec1para pad0">
            The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.<br />The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.
          </p>

          <h3 class="sec1head3 mar0">Additional information about User's Personal Data</h3>
          <p class="sec1para pad0">
            In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.
          </p>

          <h3 class="sec1head3 mar0">System logs and maintenance</h3>
          <p class="sec1para pad0">
            For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.
          </p>

          <h3 class="sec1head3 mar0">Information not contained in this policy</h3>
          <p class="sec1para pad0">
            More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.
          </p>

          <h3 class="sec1head3 mar0">How “Do Not Track” requests are handled</h3>
          <p class="sec1para pad0">
            This Application does not support “Do Not Track” requests.<br />To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
          </p>

          <h3 class="sec1head3 mar0">Changes to this privacy policy</h3>
          <p class="sec1para pad0 bdr">
            The Owner reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any of the changes to the Policy, the User must cease using this Application and can request that the Owner remove the Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Owner has about Users.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 bdr">
          <div class="demo">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwentysix">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwentysix" aria-expanded="false" aria-controls="collapseTwentysix">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Definitions and legal references
                    </a>
                  </h4>
                </div>
                <div id="collapseTwentysix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentysix">
                  <div class="panel-body">
                    <strong>Personal Data (or Data)</strong><br/>
                    Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.<br/><br/>

                    <strong>Usage Data</strong><br/><br/>
                    Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.<br/><br/>

                    <strong>User</strong><br/><br/>
                    The individual using this Application who, unless otherwise specified, coincides with the Data Subject.<br/><br/>

                    <strong>Data Subject</strong><br/><br/>
                    The natural person to whom the Personal Data refers.<br/><br/>

                    <strong>Data Processor (or Data Supervisor)</strong><br/>
                    The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.<br/><br/>

                    <strong>Data Controller (or Owner)</strong><br/>
                    The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.<br/><br/>

                    <strong>This Application</strong><br/>
                    The means by which the Personal Data of the User is collected and processed.<br/><br/>

                    <strong>Service</strong><br/>
                    The service provided by this Application as described in the relative terms (if available) and on this site/application.<br/><br/>

                    <strong>European Union (or EU)</strong><br/>
                    Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.<br/><br/>

                    <strong>Cookies</strong><br/>
                    Small sets of data stored in the User's device.<br/><br/>

                    <strong>About Me</strong><br/>
                    Provides access to the 'About Me' section of the profile.<br/><br/>

                    <strong>Email</strong><br/>
                    Provides access to the User's primary email address.<br/><br/>

                    <strong>Legal information</strong><br/><br/>
                    This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).<br/><br/>

                    This privacy policy relates solely to this Application, if not stated otherwise within this document.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 pad0">
          <div class="col-md-6">
            <p class="sec3para">Latest update: May 09, 2018</p>
          </div>
          <div class="col-md-6">
            <p class="sec3para"><a href="http://SpyCom.com/privacy.html">Show the complete Privacy Policy</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
