import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {
  ShopifyCredentialSingleResponse,
  ShopifyCredentialsResponse,
  ShopifyProductSingleResponse,
  ShopifyProductsResponse
} from './shopify-store-builder.model';

@Injectable({
  providedIn: 'root'
})
export class ShopifyStoreBuilderService {

  url_credentials = 'shopify_credentials/';
  url_product = 'shopify_products/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * Get shopify credentials of the user
   */
  getCredentials(): Observable<ShopifyCredentialsResponse> {
    const url = `${this.resource.url}${this.url_credentials}`;

    return this.http.Get<ShopifyCredentialsResponse>(url);
  }

  /**
   * Get single shopify credential data
   * @param credential_id
   */
  getCredential(credential_id: number): Observable<ShopifyCredentialSingleResponse> {
    const url = `${this.resource.url}${this.url_credentials}view/${credential_id}`;

    return this.http.Get<ShopifyCredentialSingleResponse>(url);
  }

  /**
   * Delete shopify credential
   * @param credential_id
   */
  deleteCredential(credential_id: number): any {
    const url = `${this.resource.url}${this.url_credentials}delete/${credential_id}`;

    return this.http.Delete(url);
  }

  /**
   * Add shopify credential
   * @param data
   */
  addCredential(data: any): any {
    const url = `${this.resource.url}${this.url_credentials}add`;

    return this.http.Post(url, data);
  }

  /**
   * Add product to the shopify store
   * @param data
   */
  addProduct(data: any): Observable<ShopifyProductSingleResponse> | any {
    const url = `${this.resource.url}${this.url_product}add`;

    return this.http.Post(url, data);
  }

  /**
   * List all added shopify products
   */
  listProducts(): Observable<ShopifyProductsResponse> {
    const url = `${this.resource.url}${this.url_product}`;

    return this.http.Get(url);
  }

  /**
   * Get single shopify product
   * @param shopifyProductId
   */
  getShopifyProduct(shopifyProductId: number): Observable<ShopifyProductSingleResponse> {
    const url = `${this.resource.url}${this.url_product}view/${shopifyProductId}`;

    return this.http.Get(url);
  }

  /**
   * Delete particular shopify product
   * @param shopifyProductId
   */
  deleteShopifyProduct(shopifyProductId: number) {
    const url = `${this.resource.url}${this.url_product}delete/${shopifyProductId}`;

    return this.http.Delete(url);
  }
}
