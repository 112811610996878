import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../components/notification-service/notification.service';
import {LandingPageBuilderService} from '../landing-page-builder.service';
import {ListLandingPageTemplatesModalComponent} from '../list-landing-page-templates-modal/list-landing-page-templates-modal.component';
import {LandingPageTemplateService} from '../landing-page-template.service';
import {LandingPageTemplateData} from '../landing-page-template.model';
import {MembershipCheckService} from '../../account/membership-check.service';

@Component({
  selector: 'app-create-landing-page-modal',
  templateUrl: './create-landing-page-modal.component.html',
  styleUrls: ['./create-landing-page-modal.component.css']
})
export class CreateLandingPageModalComponent implements OnInit {

  @Input() product;

  landingPageForm: FormGroup;
  landingPageFormSubmitted = false;
  landing_page_preview = false;

  selected_template_id = 1;
  landingPageTemplate: LandingPageTemplateData;

  images = [];
  faqFields: FormArray;
  galleryFields: FormArray;
  testimonialFields: FormArray;
  membership_checking = true;


  /**
   * CreateLandingPageModal Constructor
   * @constructor
   * @param activeModal
   * @param formBuilder
   * @param notificationService
   * @param landingPageBuilderService
   * @param modalService
   * @param landingPageTemplateService
   * @param membershipCheckService
   */
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private landingPageBuilderService: LandingPageBuilderService,
    private modalService: NgbModal,
    private landingPageTemplateService: LandingPageTemplateService,
    private membershipCheckService: MembershipCheckService
  ) { }

  ngOnInit() {
    /**
     * Check membership
     */
    this.membershipCheckService.landingPageBuilder().subscribe(
      res => {
        if (!res.status) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Membership Error'
          });
          this.activeModal.close();
        } else {
          this.membership_checking = false;
        }
      }
    );

    this._initializeLandingPage();
  }

  /**
   * Initialize landing page builder
   * @private
   */
  private _initializeLandingPage() {

    /**
     * Initialize form
     */
    this.landingPageForm = this.formBuilder.group({
      logo: new FormControl('', [
        Validators.required
      ]),
      landing_page_template_id: new FormControl('', [
        Validators.required
      ]),
      title: new FormControl('', [
        Validators.required
      ]),
      cost_price: new FormControl('', [
        Validators.required
      ]),
      sell_price: new FormControl('', [
        Validators.required
      ]),
      buy_now_button: new FormControl(),
      description: new FormControl(),
      reviews: new FormControl(),
      faqs: this.formBuilder.array([this.createFaqFields()]),
      gallery: this.formBuilder.array([this.createImageGalleryUrlField()]),
      testimonials: this.formBuilder.array([this.createTestimonialFields()]),
      contact_address: new FormControl(),
      contact_phone: new FormControl(),
      contact_email: new FormControl()
    });

    // this.landingPageForm.get('faqs').push(this.createFaqFields());

    /**
     * Set product images to images array
     */
    this.images = this.product ? this.product.properties.images : '';

    this._setFormValue();

    this._getLandingPageTemplate();
  }

  createFaqFields(): FormGroup {
    return this.formBuilder.group({
      question: new FormControl(''),
      answer: new FormControl('')
    });
  }

  createImageGalleryUrlField(): FormGroup {
    return this.formBuilder.group({
      url: new FormControl('')
    });
  }

  createTestimonialFields(): FormGroup {
    return this.formBuilder.group({
      user_image: new FormControl(''),
      user_name: new FormControl(''),
      user_message: new FormControl('')
    });
  }

  onClickAddFaqField(): void {
    this.faqFields = this.landingPageForm.get('faqs') as FormArray;
    this.faqFields.push(this.createFaqFields());
  }

  onClickAddGalleryImageUrlField(): void {
    this.galleryFields = this.landingPageForm.get('gallery') as FormArray;
    this.galleryFields.push(this.createImageGalleryUrlField());
  }

  onClickAddTestimonialField(): void {
    this.testimonialFields = this.landingPageForm.get('testimonials') as FormArray;
    this.testimonialFields.push(this.createTestimonialFields());
  }

  get f() {
    return this.landingPageForm.controls;
  }

  onSubmit() {
    this.landingPageFormSubmitted = true;

    if (this.landingPageForm.invalid) {
      this.landingPageFormSubmitted = false;
      this.notificationService.error({
        title: 'Form error',
        message: 'Please fill all mandatory fields.'
      });
      return;
    }

    const data = this.landingPageForm.value;

    /**
     * Add images to the request
     */
    data['images'] = JSON.stringify(this.product.properties.images);
    data['rating_count'] = this.product.properties.rating.count ? this.product.properties.rating.count : 0;
    data['rating_point'] = this.product.properties.rating.percent ? this.product.properties.rating.percent : 0;
    data['attributes'] = JSON.stringify(this.product.properties.attributes);
    // data['content'] = JSON.stringify(this.product);

    /**
     * Submit form data
     */
    this.landingPageBuilderService.add(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Creating Landing page'
          });
        } else {
          if (res.url) {
            const file_url = res.url;
            // window.open(file_url);

            const img_name = file_url.split('/').pop();
            const a = document.createElement('a');
            a.href = file_url;
            a.download = img_name;
            document.body.appendChild(a);
            a.click();
          }
          this.notificationService.success({
            message: 'Landing page created successfully',
            title: 'Landing page created'
          });
          this.activeModal.close();
        }
      }
    );
  }

  /**
   * Set form values
   * @private
   */
  private _setFormValue() {
    if (this.product) {
      this.landingPageForm.setValue({
        logo: '',
        landing_page_template_id: this.selected_template_id,
        title: this.product.info.title,
        cost_price: this.product.properties.pricing.price,
        sell_price: this.product.properties.pricing.price,
        buy_now_button: '',
        description: this.product.properties.meta.description,
        reviews: '',
        faqs: [{
          question: '',
          answer: ''
        }],
        gallery: [{
          url: ''
        }],
        testimonials: [{
          user_image: '',
          user_name: '',
          user_message: ''
        }],
        contact_address: '',
        contact_email: '',
        contact_phone: '',
      });
    }
  }

  private _getLandingPageTemplate() {
    if (this.selected_template_id) {
      this._getTemplateById();
    } else {
      this._getTemplateDefault();
    }
  }

  /**
   * Get template by ID
   * @private
   */
  private _getTemplateById() {
    this.landingPageTemplateService.get(this.selected_template_id).subscribe(
      res => {
        this.landingPageTemplate = res.data;
        this.landing_page_preview = true;
        this.landingPageForm.patchValue({
          landing_page_template_id: this.selected_template_id
        });
      }
    );
  }

  /**
   * Get default template
   * @private
   */
  private _getTemplateDefault() {
    this.landingPageTemplateService.getDefault().subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Landing Page Template'
          });
        } else {
          this.landingPageTemplate = res.data;
          this.selected_template_id = this.landingPageTemplate.id;
        }
      }
    );
  }

  /**
   * Open landing page templates modal
   */
  openLandingPageTemplatesModal() {
    const templateModal = this.modalService.open(ListLandingPageTemplatesModalComponent, {size: 'lg'});
    templateModal.componentInstance.selected_template = this.selected_template_id;
    templateModal.result.then(response => {
      this.selected_template_id = response;
      this._getLandingPageTemplate();
    }, () => {});
  }

}
