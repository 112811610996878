<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <h4 class="card-title">Your Client Accounts</h4>
          </div>
          <div class="col-md-6 col-sm-6">
            <button type="button" (click)="openAddClientModal()" class="btn btn-primary pull-right">
              New Client
            </button>
          </div>
        </div>
      </div>
      <div class="card-body table-responsive">
        <table class="table table-striped">
          <thead class="text-warning">
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Created</th>
          <th>Action</th>
          </thead>
          <tbody>

          <ng-container *ngIf="clientAccounts">
          <tr *ngFor="let client of clientAccounts; let id = index">
            <td>{{ id+1 }}</td>
            <td>{{ client.client.name }}</td>
            <td>{{ client.client.email }}</td>
            <td>{{ client.created | date: "medium" }}</td>
            <td>
              <button type="button" class="btn btn-danger" (click)="openDeleteClientModal(client.id)">Delete</button>
            </td>
          </tr>
          </ng-container>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
