
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <div class="row">
          <div class="col-md-6">
            <h4 class="card-title">Shopify Credentials</h4>
            <p class="card-category">API Credentials for shopify</p>
          </div>
          <div class="col-md-6">
            <button type="button" (click)="openAddCredentialModal()" class="btn btn-primary pull-right">
              New Shopify Credential
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead class="text-warning">
          <th>App Name</th>
          <th>Store URL</th>
          <th>API Key</th>
          <th>Added on</th>
          <th>Action</th>
          </thead>
          <tbody>

          <tr *ngFor="let c of shopifyCredentials; let id = index">
            <td>{{ c.app_name }}</td>
            <td><a href="{{ c.store_url }}" target="_blank">{{ c.store_url }}</a> </td>
            <td>{{ c.api_key }}</td>
            <th>{{ c.created | date:"medium" }}</th>
            <td>
              <button type="button" class="btn btn-danger" (click)="deleteShopifyCredentialModal(c.id)">
                <i class="fas fa-trash"></i> Delete
              </button>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
