import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFbAdsComponent } from './list-fb-ads/list-fb-ads.component';
import { CreateFbAdModalComponent } from './create-fb-ad-modal/create-fb-ad-modal.component';
import {RouterModule} from '@angular/router';
import {FbAdsBuilderRouting} from './fb-ads-builder.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FbAdTemplatesListModalComponent } from './fb-ad-templates-list-modal/fb-ad-templates-list-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(FbAdsBuilderRouting),
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ListFbAdsComponent,
    CreateFbAdModalComponent,
    FbAdTemplatesListModalComponent
  ],
  entryComponents: [
    CreateFbAdModalComponent,
    FbAdTemplatesListModalComponent
  ]
})
export class FbAdsBuilderModule { }
