import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../account.service';
import {AccountData} from '../account.model';
import {NotificationService} from '../../components/notification-service/notification.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChangeEmailModalComponent} from '../change-email-modal/change-email-modal.component';
import {StopProcessingDataModalComponent} from '../stop-processing-data-modal/stop-processing-data-modal.component';
import {DeleteAllDataModalComponent} from '../delete-all-data-modal/delete-all-data-modal.component';
import {ChangeAvatarModalComponent} from '../change-avatar-modal/change-avatar-modal.component';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  editProfileForm: FormGroup;
  changePasswordForm: FormGroup;

  accountInfo: AccountData;

  editProfileSubmitted = false;
  changePasswordSubmitted = false;

  is_client = false;
  is_virtual = false;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.is_client = this.authService.isClient();
    this.is_virtual = this.authService.isVirtual();

    /**
     * Initialize edit profile form
     */
    this.editProfileForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required
      ])
    });

    /**
     * Initialize changePasswordForm
     * Change password equal comparison is using confirm-equal-validator.directive.ts
     */
    this.changePasswordForm = this.formBuilder.group({
      password: new FormControl('', [
        Validators.required
      ]),
      password_new: new FormControl('', [
        Validators.required
      ]),
      password_confirm: new FormControl('', [
        Validators.required
      ])
    });

    this.getProfile();
  }

  /**
   * Get user profile information
   */
  getProfile() {
    this.accountService.me().subscribe(
      res => {
        this.accountInfo = res.data;

        this._setFormData();
      }
    );
  }

  /**
   * Set form data
   * @private
   */
  private _setFormData() {
    this.editProfileForm.setValue({
      name: this.accountInfo.name
    });
  }

  get f() {
    return this.editProfileForm.controls;
  }

  get g() {
    return this.changePasswordForm.controls;
  }

  /**
   * Handle profile update
   */
  updateAccount() {
    this.editProfileSubmitted = true;

    if (this.editProfileForm.invalid) {
      this.editProfileSubmitted = false;
      return;
    }

    const data = this.editProfileForm.value;

    this.accountService.updateProfile(data).subscribe(
      res => {
        this.accountInfo = res.data;

        this.notificationService.success({
          message: 'Account details updated successfully',
          title: 'Account updated'
        });

        this.editProfileSubmitted = false;
      }
    );
  }

  /**
   * Handle change password submit form
   */
  changePassword() {
    this.changePasswordSubmitted = true;

    // console.log('form submitted');

    if (this.changePasswordForm.invalid) {
      this.changePasswordSubmitted = false;
      return false;
    }

    const data = this.changePasswordForm.value;

    // console.log(data);

    this.accountService.changePassword(data).subscribe(
      res => {

        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Password change failed'
          });
        } else {
          this.notificationService.success({
            message: 'Password changed successfully',
            title: 'Password Changed'
          });
        }

        this.changePasswordSubmitted = false;
      }
    );
  }

  /**
   * Open Change Email modal
   */
  openChangeEmailModal() {
    const modalChangeEmail = this.modalService.open(ChangeEmailModalComponent, {size: 'lg'});
    modalChangeEmail.result.then(() => {
      this.getProfile();
    }, () => {});
  }

  /**
   * Open stop processing data modal
   */
  openStopProcessingDataModal() {
    const modalStopProcessingData = this.modalService.open(StopProcessingDataModalComponent, {size: 'lg'});
    modalStopProcessingData.result.then(() => {}, () => {});
  }

  openDeleteAllData() {
    const modalDeleteAllData = this.modalService.open(DeleteAllDataModalComponent, {size: 'lg'});
    modalDeleteAllData.result.then(() => {}, () => {});
  }

  openChangeAvatarModal() {
    const changeAvatarModal = this.modalService.open(ChangeAvatarModalComponent, {size: 'lg'});
    changeAvatarModal.result.then(() => {
      this.getProfile();
    }, () => {});
  }
}
