import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateLandingPageModalComponent } from './create-landing-page-modal/create-landing-page-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ListLandingPageTemplatesModalComponent } from './list-landing-page-templates-modal/list-landing-page-templates-modal.component';
import {RouterModule} from '@angular/router';
import { ListLandingPageComponent } from './list-landing-page/list-landing-page.component';
import {LandingPageRoutes} from './landing-page-builder.routing';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(LandingPageRoutes)
  ],
  declarations: [
    CreateLandingPageModalComponent,
    ListLandingPageTemplatesModalComponent,
    ListLandingPageComponent
  ],
  entryComponents: [
    CreateLandingPageModalComponent,
    ListLandingPageTemplatesModalComponent
  ]
})
export class LandingPageBuilderModule { }
