import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../../account/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;

  constructor(
    private Auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private accountService: AccountService
  ) {
  }

  ngOnInit() {
    this.submitted = false;

    if (this.Auth.isAuthenticated()) {
      this.router.navigate(['dashboard']);
      return true;
    }

    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
  }

  /**
   * Method is called with click on submit of login form.
   * Used to authenticate the user with provided credentials username and password
   * and save server response access token credentials in local storage
   *
   */
  loginUser() {
    this.submitted = true;
    // Authenticate user with provided username and password combination
    // using Auth Service

    const data = this.loginForm.value;
    this.Auth.getAuthToken(data).subscribe(response => {

      // User is authenticated, save response access token credentials
      // in local storage using Auth Service
      this.Auth.setCredentials(response.data);

      // redirect user to dashboard, if authenticated
      if (this.Auth.isAuthenticated()) {
        this.accountService.me().subscribe(
          res => {
            this.Auth.setAuthUser(res);
            this.router.navigate(['dashboard']);
          }
        );
      }
    }, error => {

      // Console print the error returned from server
      const message = error.error.data.message;
      const title = 'Authentication Failed';
      console.log(title, message);
      this.toastr.error(message, title, {
        timeOut: 80000000
      });

      this.submitted = false;
    });



  }
}
