<div class="modal-header">
  <h4 class="modal-title">Stop Processing Data</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-danger">
        <strong>NOTE:</strong> Once you stop processing data, you won't be able to login to the application. All your data will be kept and will not be deleted.
        In order to restore access to your account, you need to contact our support.
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="stopProcessingDataForm" (submit)="onSubmit()" id="stop-processing-form" #ngForm="ngForm">

        <div class="form-group">
          <input formControlName="confirm" type="checkbox" class="form-control" id="input-confirm"> I confirm
        </div>

        <span class="error-msg text-danger" *ngIf="f['confirm'].touched && f['confirm'].errors?.required">Please accept that you confirm to stop processing data</span>

      </form>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-success" type="submit" form="stop-processing-form" [disabled]="!ngForm.valid || stopProcessingDataFormSubmitted">
    <span *ngIf="!stopProcessingDataFormSubmitted; else saving_loader">
      <i class="fa fa-save"></i> Stop Processing Data
    </span>
    <ng-template #saving_loader>
      <i class="fa fa-spinner fa-spin">Stopping Processing data...</i>
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>
