<div class="modal-header">
  <div class="col-md-12">
  <div class="row">
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-4 col-sm-4">
          <h4 class="modal-title">Create new FB Ad</h4>
        </div>
        <div class="col-md-4 col-sm-4">
          <select class="form-control" title="Change Canvas Resolution" (change)="changeCanvasResolution($event)">
            <option value="1080x1080">1080x1080</option>
            <option value="1200x628">1200x628</option>
          </select>
        </div>
        <div class="col-md-4 col-sm-4">
          <button type="button" class="btn btn-info btn-round" (click)="openAdTemplateModal()">Choose Template</button>
        </div>
      </div>

    </div>
    <div class="col-md-4">
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
</div>

<div class="modal-body">

  <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="!canvas_rendered || membership_checking">
    <div class="loader-small"></div>
  </div>

  <div class="row">
    <div class="col-md-12" *ngIf="!membership_checking">

      <canvas id="canvas" width="1200" height="628"></canvas>

    </div>
  </div>


</div>
<div class="modal-footer" *ngIf="!membership_checking">
  <button type="button" class="btn btn-warning" [disabled]="fbAdFormSubmitted" (click)="onSubmit()">
    <span *ngIf="!fbAdFormSubmitted; else downloading_image">
      <i class="fas fa-download"></i> Download Image
    </span>
    <ng-template #downloading_image>
      <span>
        <i class="fas fa-spin fa-spinner"></i> Downloading Image
      </span>
    </ng-template>
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>
