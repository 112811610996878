import { Component, OnInit } from '@angular/core';
import {VirtualAccountData} from '../virtual-account.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../components/notification-service/notification.service';
import {VirtualAccountService} from '../virtual-account.service';
import {AddVirtualAccountModalComponent} from '../add-virtual-account-modal/add-virtual-account-modal.component';
import {DeleteVirtualAccountModalComponent} from '../delete-virtual-account-modal/delete-virtual-account-modal.component';
import {AuthService} from '../../auth/auth.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-list-virtual-account',
  templateUrl: './list-virtual-account.component.html',
  styleUrls: ['./list-virtual-account.component.css']
})
export class ListVirtualAccountComponent implements OnInit {

  virtualAccounts: Array<VirtualAccountData>;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private virtualAccountService: VirtualAccountService,
    private authService: AuthService,
    private location: Location,
  ) { }

  ngOnInit() {
    // If user is client, redirect back
    if (this.authService.isClient()) {
      this.notificationService.error({
        title: 'Search Error',
        message: 'You can not perform search on client account'
      });
      this.location.back();
    }

    this.getVirtualAccounts();
  }

  getVirtualAccounts() {
    this.virtualAccountService.list().subscribe(
      res => {
        this.virtualAccounts = res.data;
      }
    );
  }

  /**
   * Add virtual account
   */
  openAddAssistantModal() {
    const addAssistantModal = this.modalService.open(AddVirtualAccountModalComponent, {size: 'lg'});
    addAssistantModal.result.then( () => {
      this.getVirtualAccounts();
    }, () => {});
  }

  /**
   * Delete virtual account
   * @param assistant_id
   */
  openDeleteAssistantModal(assistant_id: number) {
    const deleteAssistantModal = this.modalService.open(DeleteVirtualAccountModalComponent, {size: 'lg'});
    deleteAssistantModal.componentInstance.virtual_account_id = assistant_id;
    deleteAssistantModal.result.then( () => {
      this.getVirtualAccounts();
    }, () => {});
  }

}
