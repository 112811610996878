import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import {RouterModule} from '@angular/router';
import {AccountRoutes} from './account.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmEqualValidatorDirective} from './confirm-equal-validator.directive';
import { ChangeEmailModalComponent } from './change-email-modal/change-email-modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { StopProcessingDataModalComponent } from './stop-processing-data-modal/stop-processing-data-modal.component';
import { DeleteAllDataModalComponent } from './delete-all-data-modal/delete-all-data-modal.component';
import { ChangeAvatarModalComponent } from './change-avatar-modal/change-avatar-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(AccountRoutes),
    NgbModule
  ],
  declarations: [
    ProfileComponent,
    ConfirmEqualValidatorDirective,
    ChangeEmailModalComponent,
    StopProcessingDataModalComponent,
    DeleteAllDataModalComponent,
    ChangeAvatarModalComponent
  ],
  entryComponents: [
    ChangeEmailModalComponent,
    StopProcessingDataModalComponent,
    DeleteAllDataModalComponent,
    ChangeAvatarModalComponent
  ]
})
export class AccountModule { }
