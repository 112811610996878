import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {PagesModule} from './pages/pages.module';
import {ComponentsModule} from './components/components.module';
import {AuthInterceptor} from './auth/auth.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PagesLayoutComponent} from './layouts/pages-layout/pages-layout.component';
import {ErrorsModule} from './errors/errors.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Ng5SliderModule} from 'ng5-slider';
import {EscapeHtmlPipe} from './pipe/keep-html.pipe';
import {AdminLayoutModule} from './layouts/admin-layout/admin-layout.module';
import {AuthLayoutModule} from './layouts/auth-layout/auth-layout.module';
import {PagesLayoutModule} from './layouts/pages-layout/pages-layout.module';


@NgModule({
  declarations: [
    AppComponent,
    // AuthLayoutComponent,
    // AdminLayoutComponent,
    // PagesLayoutComponent,
    EscapeHtmlPipe,
  ],
  imports: [
    BrowserModule,
      AppRoutingModule,
    AdminLayoutModule,
    AuthLayoutModule,
    PagesLayoutModule,

    FormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    PagesModule,
    NgbModule.forRoot(),
    ComponentsModule,
    ErrorsModule,
    Ng5SliderModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-' + 'bottom' + '-' + 'center'
    }),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
