import { Component, OnInit } from '@angular/core';
import {SavedSearchesService} from '../saved-searches.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {SavedSearchData} from '../saved-searches.model';
import {AssignToClientModalComponent} from '../../client-account/assign-to-client-modal/assign-to-client-modal.component';
import {AuthService} from '../../auth/auth.service';
import {ClientActionsService} from '../../client-account/client-actions.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-saved-searches',
  templateUrl: './list-saved-searches.component.html',
  styleUrls: ['./list-saved-searches.component.css']
})
export class ListSavedSearchesComponent implements OnInit {

  savedSearches: Array<SavedSearchData>;
  deleting_list: Array<number> = [];

  is_client = false;

  /**
   * @constructor
   */
  constructor(
    private savedSearchesService: SavedSearchesService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private authService: AuthService,
    private clientActions: ClientActionsService
  ) { }

  ngOnInit() {
    // If user is client
    this.is_client = this.authService.isClient();

    this._getSavedSearches();
  }

  private _getSavedSearches() {
    if (this.is_client) {
      // Get for client
      this.clientActions.listSavedSearches().subscribe(
        res => {
          this.savedSearches = res.data;
        }
      );
    } else {
      // Get for user
      this.savedSearchesService.list().subscribe(
        res => {
          this.savedSearches = res.data;
        }
      );
    }
  }

  /**
   * Delete record
   * @param id
   */
  deleteSearch(id: number) {
    this.deleting_list.push(id);
    this.savedSearchesService.delete(id).subscribe(
      res => {
        this.notificationService.success({
          title: 'Saved Search Deleted',
          message: 'Saved search deleted successfully'
        });
        const index_ = this.deleting_list.indexOf(id, 0);
        if (index_ > -1) {
          this.deleting_list.splice(index_, 1);
        }
        this._getSavedSearches();
      }
    );
  }

  /**
   * Check if deleting
   * @param id
   */
  ifDeleting(id: number) {
    const index_ = this.deleting_list.indexOf(id, 0);
    return index_ > -1;
  }

  /**
   * Assign item to the client account
   * @param id
   */
  assignToClient(id: number) {
    const assignToClient = this.modalService.open(AssignToClientModalComponent, {size: 'lg'});
    assignToClient.componentInstance.itemId = id;
    assignToClient.componentInstance.type = 'saved_search';
    assignToClient.result.then(() => {}, () => {});
  }
}
