import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../components/notification-service/notification.service';
import {AccountService} from '../account.service';

@Component({
  selector: 'app-change-email-modal',
  templateUrl: './change-email-modal.component.html',
  styleUrls: ['./change-email-modal.component.css']
})
export class ChangeEmailModalComponent implements OnInit {

  changeEmailForm: FormGroup;
  changeEmailFormSubmitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private accountSerive: AccountService
  ) { }

  ngOnInit() {
    /**
     * Initialize form
     */
    this.changeEmailForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required
      ])
    });
  }

  get f() {
    return this.changeEmailForm.controls;
  }

  /**
   * Handle change email form submit
   */
  onSubmit() {
    this.changeEmailFormSubmitted = true;

    if (this.changeEmailForm.invalid) {
      this.changeEmailFormSubmitted = false;
      return;
    }

    const data = this.changeEmailForm.value;

    this.accountSerive.changeEmail(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Email change failed'
          });
        } else {
          this.notificationService.success({
            message: 'Email successfully changed',
            title: 'Email changed'
          });
          this.activeModal.close();
        }
      }
    );
  }

}
