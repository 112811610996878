import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListClientAccountComponent } from './list-client-account/list-client-account.component';
import { DeleteClientAccountModalComponent } from './delete-client-account-modal/delete-client-account-modal.component';
import {RouterModule} from '@angular/router';
import {ClientAccountRoutes} from './client-account.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AddClientAccountModalComponent } from './add-client-account-modal/add-client-account-modal.component';
import { AssignToClientModalComponent } from './assign-to-client-modal/assign-to-client-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ClientAccountRoutes),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ListClientAccountComponent,
    DeleteClientAccountModalComponent,
    AddClientAccountModalComponent,
    AssignToClientModalComponent
  ],
  entryComponents: [
    AddClientAccountModalComponent,
    DeleteClientAccountModalComponent,
    AssignToClientModalComponent
  ]
})
export class ClientAccountModule { }
