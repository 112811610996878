import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ScraperService} from '../scraper.service';
import {CountryDataService} from '../../country-data.service';
import {FreightData, FreightResponse} from '../shipping-freight.model';

@Component({
  selector: 'app-product-shipping-modal',
  templateUrl: './product-shipping-modal.component.html',
  styleUrls: ['./product-shipping-modal.component.css']
})
export class ProductShippingModalComponent implements OnInit {

  @Input() product_id: string;

  shipping_country = 'us';

  loading_shipping = false;

  public countryList;
  shippingDetailsResponse: FreightResponse;
  shippingDetails: Array<FreightData> = [];

  constructor(
    public activeModal: NgbActiveModal,
    private scraperService: ScraperService,
    private countryDataService: CountryDataService,
  ) { }

  ngOnInit() {
    this.countryList = this.countryDataService.getCountryList();

    this.getShippingForCountry(this.shipping_country);
  }

  getShippingForCountry(country_code) {
    this.loading_shipping = true;
    this.shippingDetails.length = 0;

    this.shipping_country = country_code;
    this.scraperService.frightCheck(this.shipping_country, this.product_id).subscribe(
      res => {
        let response_content = res.contents;
        response_content = response_content.substr(1).slice(0, -1);
        this.shippingDetailsResponse = <FreightResponse>JSON.parse(response_content);
        this.shippingDetails = this.shippingDetailsResponse.freight;
      }
    );
  }

}
