
<router-outlet></router-outlet>

<div class="container-fluid">
  <footer class="footer-account">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <ul class="auth-footer">
              <li>
                <a routerLink="/pages/terms" target="_blank">Terms</a>
              </li>
              <li>
                <a routerLink="/pages/privacy" target="_blank">Privacy</a>
              </li>
              <li>
                <a href="https://support.vega6.com/" target="_blank">Support</a>
              </li>
            </ul>

          </div>
          <div class="col-md-4">
            <p class="desclaimer"> ©{{ copyrightYear }} , Developed and maintained by <a href="https://www.vega6.com" style="color: #ccc; text-decoration: underline;" target="_blank">Vega6</a></p>
          </div>
          <div class="col-md-4">
            <span class="pull-right">&nbsp;
              <a href="https://vineasx.com/" target="_blank">
                <img src="http://app.clipsreel.io/assets/img/vineasx_logo.png" class="vinasx_logo">
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
