import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FbAdsBuilderService} from '../fb-ads-builder.service';
import {FormBuilder} from '@angular/forms';

import {NotificationService} from '../../components/notification-service/notification.service';
import {FbAdTemplatesListModalComponent} from '../fb-ad-templates-list-modal/fb-ad-templates-list-modal.component';

import 'fabric';
import {CanvasTemplatesService} from '../canvas-templates.service';
import {FbAdTemplatesService} from '../fb-ad-templates.service';
import {FbAdTemplateData} from '../fb-ad-template.model';
import {MembershipCheckService} from '../../account/membership-check.service';
declare const fabric: any;

@Component({
  selector: 'app-create-fb-ad-modal',
  templateUrl: './create-fb-ad-modal.component.html',
  styleUrls: ['./create-fb-ad-modal.component.css']
})
export class CreateFbAdModalComponent implements OnInit {

  @Input() product;

  fbAdFormSubmitted = false;

  fbAdTemplateId: number;
  fbAdTemplate: FbAdTemplateData;

  canvas: any;

  textString: string;
  backgroundImagePath: string;
  productImagePath: string;
  productImagePathAbsolute: string;

  canvas_rendered = false;
  membership_checking = true;

  constructor(
    public activeModal: NgbActiveModal,
    private fbAdsBuilderService: FbAdsBuilderService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private canvasTemplate: CanvasTemplatesService,
    private fbTemplateService: FbAdTemplatesService,
    private membershipCheckService: MembershipCheckService
  ) {
  }

  ngOnInit() {

    // initialize canvas
    this.canvas = new fabric.Canvas('canvas');

    /**
     * Set variables
     */
    this.textString = this.product ? this.product.info.title : '';
    this.productImagePath = this.product ? this.product.info.images.main : '';

    /**
     * Check for membership
     */
    this.membershipCheckService.fbAds().subscribe(
      res => {
        if (!res.status) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Membership Error'
          });
          this.activeModal.close();
        } else {
          this.membership_checking = false;
        }
      }
    );

    this._initiateFbAd();
  }

  /**
   * Initiate Fb Ad
   * @private
   */
  private _initiateFbAd() {
    this.fbAdsBuilderService.getImagePath(this.productImagePath).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Download file'
          });
        } else {
          this.productImagePathAbsolute = res.url;
          this._getTemplate();
        }
      }
    );
  }

  /**
   * Get canvas template
   * @private
   */
  private _getTemplate() {
    if (this.fbAdTemplateId) {
      this._getTemplateById();
    } else {
      this._getTemplateDefault();
    }
  }

  /**
   * Get ad canvas template by id
   * @private
   */
  private _getTemplateById() {
    // console.log('getting template with id: ', this.fbAdTemplateId);
    this.fbTemplateService.get(this.fbAdTemplateId).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        // console.log(res);
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Ad Template'
          });
        } else {
          this.fbAdTemplate = res.data;
          this._initializeCanvasTemplate();
        }
      }
    );
  }

  /**
   * Get default ad template
   * @private
   */
  private _getTemplateDefault() {
    this.fbTemplateService.getDefault().subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Ad Template'
          });
        } else {
          this.fbAdTemplate = res.data;
          this.fbAdTemplateId = this.fbAdTemplate.id;
          this._initializeCanvasTemplate();
        }
      }
    );
  }

  /**
   * Initialize ad canvas according to the template
   * @private
   */
  private _initializeCanvasTemplate() {
    /**
     * Set variable
     */
    this.backgroundImagePath = this.fbAdTemplate.background_image_url;
    this.canvas = new fabric.Canvas('canvas');
    // const template_name = 'template' + this.fbAdTemplate.id;
    // (this.canvasTemplate as any)[template_name](this.canvas, this.backgroundImagePath, this.productImagePathAbsolute, this.textString);
    // reset canvas
    // this.canvas.clear();
    this.canvasTemplate.renderCanvas(this.canvas, this.fbAdTemplate, this.productImagePathAbsolute, this.textString);

    this.canvas_rendered = true;
  }

  /**
   * Handle form data submit
   */
  onSubmit() {
    this.fbAdFormSubmitted = true;

    const data = {
      'product_title': this.textString,
      'fb_ad_template_id': this.fbAdTemplateId,
      'base64_data': this.canvas.toDataURL()
    };

    this.fbAdsBuilderService.add(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        // console.log(res);
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Generating Ad'
          });
        } else {
          if (res.url) {
            const file_url = res.url;
            // window.open(file_url);
            const file_name = file_url.split('/').pop();
            const a = document.createElement('a');
            a.href = file_url;
            a.download = file_name;
            document.body.appendChild(a);
            a.click();
          }
          this.notificationService.success({
            message: 'FB Ad created successfully',
            title: 'FB Ad created'
          });
          this.activeModal.close();
        }

        this.fbAdFormSubmitted = false;
      }
    );
  }

  /**
   * Change canvas resolution
   * @param $event
   */
  changeCanvasResolution($event) {
    const canvasSize = $event.target.value;
    // console.log(canvasSize);
    const sizeSplit = canvasSize.split('x');
    const canvasWidth = sizeSplit[0];
    const canvasHeight = sizeSplit[1];

    // console.log('canvas width: ', canvasWidth);
    // console.log('canvas height: ', canvasHeight);

    this.canvas.setHeight(canvasHeight);
    this.canvas.setWidth(canvasWidth);
    this.canvas.renderAll();
  }

  /**
   * Open fb ad templates modal
   */
  openAdTemplateModal() {
    const adTemplateModal = this.modalService.open(FbAdTemplatesListModalComponent, {size: 'lg', backdrop: 'static'});
    adTemplateModal.componentInstance.selected_template = this.fbAdTemplateId;
    adTemplateModal.result.then(response => {
      this.fbAdTemplateId = response;
      // console.log(this.fbAdTemplateId);
      this._getTemplate();
    }, () => {});
  }
}
