
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Tutorials </h4>

      </div>
      <div class="card-body">

        <ul class="tutorial-list">

          <li>
            <a href="../../../assets/tutorials/overview_on_spycom.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> SpyCom Walkthrough
            </a>
          </li>

          <li>
            <a href="../../../assets/tutorials/shopify_credentials.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> Shopify Credentials
            </a>
          </li>

          <li>
            <a href="../../../assets/tutorials/spycom_client_account.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> SpyCom Client Account
            </a>
          </li>

          <li>
            <a href="../../../assets/tutorials/spycom_virtual_account.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> SpyCom Virtual Account
            </a>
          </li>

        </ul>

      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Agency Tutorials </h4>

      </div>
      <div class="card-body">

        <ul class="tutorial-list">

          <li>
            <a href="../../../assets/tutorials/agency/how_to_customize_agency_website.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> How to Customize Agency Website
            </a>
          </li>

          <li>
            <a href="../../../assets/tutorials/agency/how_to_post_videos_in_agency_website.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> How to post videos in Agency website
            </a>
          </li>

          <li>
            <a href="../../../assets/tutorials/agency/import_agency_data_spycom.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> Import Agency Data (SpyCom)
            </a>
          </li>

          <li>
            <a href="../../../assets/tutorials/agency/install_wordpress_in_cpanel.pdf" target="_blank">
              <i class="fas fa-file-pdf"></i> Install Wordpress in CPanel
            </a>
          </li>

        </ul>

      </div>
    </div>
  </div>

</div>
