import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListVirtualAccountComponent } from './list-virtual-account/list-virtual-account.component';
import { DeleteVirtualAccountModalComponent } from './delete-virtual-account-modal/delete-virtual-account-modal.component';
import {RouterModule} from '@angular/router';
import {VirtualAccountRoutes} from './virtual-account.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AddVirtualAccountModalComponent } from './add-virtual-account-modal/add-virtual-account-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(VirtualAccountRoutes),
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  declarations: [
    ListVirtualAccountComponent,
    DeleteVirtualAccountModalComponent,
    AddVirtualAccountModalComponent
  ],
  entryComponents: [
    AddVirtualAccountModalComponent,
    DeleteVirtualAccountModalComponent
  ]
})
export class VirtualAccountModule { }
