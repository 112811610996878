
<div class="row">
  <div class="col-md-8">
    <div class="card" *ngIf="!is_client && !is_virtual">
      <div class="card-header card-header-info">
        <h4 class="card-title">Manage your data</h4>
      </div>
      <div class="card-body">

        <div class="row gdpr">
          <div class="col-md-3">
            <button type="button" class="btn btn-primary" (click)="openChangeEmailModal()" ngbPopover="Update Email" triggers="mouseenter:mouseleave">Update Email</button>
          </div>
          <div class="col-md-3">
            <button type="button" class="btn btn-info" data-toggle="tooltip" ngbPopover="Download all Data" triggers="mouseenter:mouseleave"> Download all Data</button>

          </div>
          <div class="col-md-3">
            <button type="button" class="btn btn-warning" (click)="openStopProcessingDataModal()" data-toggle="tooltip" ngbPopover="Stop processing my data" triggers="mouseenter:mouseleave">Stop processing data</button>
          </div>
          <div class="col-md-3">
            <button type="button" class="btn btn-danger" (click)="openDeleteAllData()" data-toggle="tooltip" ngbPopover="Delete all data" triggers="mouseenter:mouseleave">Delete all data</button>
          </div>
        </div>
        <div class="clearfix"></div>

      </div>
    </div>
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Edit Profile</h4>
      </div>
      <div class="card-body">
        <form [formGroup]="editProfileForm" (submit)="updateAccount()" #formDir="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="bmd-label-floating" for="input-name">Name</label>
                <input formControlName="name" autocomplete="false" type="text" class="form-control" id="input-name">
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary pull-right" [disabled]="!formDir.valid || editProfileSubmitted">
            <span *ngIf="!editProfileSubmitted; else edit_processing">
              Update
            </span>
            <ng-template #edit_processing>
              <i class="fa fa-spinner fa-spin"></i> Updating...
            </ng-template>
          </button>
          <div class="clearfix"></div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Update Password</h4>
      </div>
      <div class="card-body">
        <form [formGroup]="changePasswordForm" (submit)="changePassword()" #cPForm="ngForm">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="bmd-label-floating" for="input-password">Current Password</label>
                <input formControlName="password" autocomplete="false" type="password" class="form-control" id="input-password">
              </div>

              <span class="error-msg text-danger" *ngIf="g['password'].touched && g['password'].errors?.required">Current password is required</span>

            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="bmd-label-floating" for="input-password_new">New Password</label>
                <input formControlName="password_new" autocomplete="false" type="password" class="form-control" id="input-password_new">
              </div>

              <span class="error-msg text-danger" *ngIf="g['password_new'].touched && g['password_new'].errors?.required">New password is required</span>

            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="bmd-label-floating" for="input-password_confirm">Confirm New Password</label>
                <input formControlName="password_confirm" autocomplete="false" appConfirmEqualValidator="password_new" type="password" class="form-control" id="input-password_confirm">
              </div>

              <span class="error-msg text-danger" *ngIf="g['password_confirm'].touched && g['password_confirm'].errors?.required">Confirm password is required</span>
              <span class="error-msg text-danger" *ngIf="g['password_confirm'].touched && g['password_confirm'].errors?.noEqual">Password and confirm password fields are not equal</span>

            </div>
          </div>
          <button type="submit" class="btn btn-primary pull-right" [disabled]="!cPForm.valid || changePasswordSubmitted">
            <span *ngIf="!changePasswordSubmitted; else change_password_processing">
              Change Password
            </span>
            <ng-template #change_password_processing>
              <i class="fa fa-spinner fa-spin"></i> Changing Password...
            </ng-template>
          </button>
          <div class="clearfix"></div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card card-profile">
      <div class="card-avatar">
        <a (click)="openChangeAvatarModal()">
          <img class="img" src="{{ accountInfo?.avatar_url }}" />
        </a>
      </div>
      <div class="card-body">

        <h4 class="card-title">{{ accountInfo?.name }}</h4>
        <h6 class="card-category">{{ accountInfo?.email }}</h6>
        <button type="button" (click)="openChangeAvatarModal()" class="btn btn-primary btn-round">Change Avatar</button>
      </div>
    </div>
  </div>
</div>
