import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SavedSearchesService} from '../saved-searches.service';
import {SavedSearchData} from '../saved-searches.model';
import {NotificationService} from '../../components/notification-service/notification.service';
import {Location} from '@angular/common';
import {ScraperService} from '../../scraper/scraper.service';
import {FavouriteProductsService} from '../../favourite-products/favourite-products.service';
import {ScraperUtilsService} from '../../scraper/scraper-utils.service';
import {ProductShippingModalComponent} from '../../scraper/product-shipping-modal/product-shipping-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CreateLandingPageModalComponent} from '../../landing-page-builder/create-landing-page-modal/create-landing-page-modal.component';
import {CreateFbAdModalComponent} from '../../fb-ads-builder/create-fb-ad-modal/create-fb-ad-modal.component';
import {
  CreateShopifyStoreModalComponent
} from '../../shopify-store-builder/create-shopify-store-modal/create-shopify-store-modal.component';
import {ProductDescriptionModalComponent} from '../../scraper/product-description-modal/product-description-modal.component';
import {FilterScraperModalComponent} from '../../scraper/filter-scraper-modal/filter-scraper-modal.component';
import {GlobalProfitPercentageModalComponent} from '../../scraper/global-profit-percentage-modal/global-profit-percentage-modal.component';
import {FilterService} from '../../scraper/filter.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-view-saved-search',
  templateUrl: './view-saved-search.component.html',
  styleUrls: ['./view-saved-search.component.css']
})
export class ViewSavedSearchComponent implements OnInit {

  saved_search_id: number;
  savedSearchResponse: SavedSearchData;

  processedItems: Array<any> = [];
  totalProductItems: Array<any> = [];

  private downloadImagesIndexList: Array<any> = [];
  private downloadReviewsIndexList: Array<any> = [];
  private favouriteProductIndexList: Array<any> = [];

  filterData: any;
  keyword: string;
  totalSoldSum = 0;
  averageTotalSold = 0;
  totalReviews = 0;
  averageTotalReviews = 0;
  global_profit_percentage: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private savedSearchService: SavedSearchesService,
    private notificationService: NotificationService,
    private _location: Location,
    private scraperService: ScraperService,
    private favouriteProductService: FavouriteProductsService,
    private scraperUtilsService: ScraperUtilsService,
    private modalService: NgbModal,
    private filterService: FilterService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    /**
     * Get id from url
     */
    this.activatedRoute.params.subscribe(
      param => {
        this.saved_search_id = param['id'];
      }
    );

    /**
     * Get saved search data
     */
    this.savedSearchService.view(this.saved_search_id).subscribe(
      res => {
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Saved Search'
          });
          this._location.back();
        } else {
          this.savedSearchResponse = res.data;
          this.totalProductItems = JSON.parse(this.savedSearchResponse.content);
          this._applyFilter();
        }
      }
    );

  }

  /**
   * Download product images
   * @param product_index
   */
  downloadImages(product_index: number) {
    // add product_index to the index list
    this.downloadImagesIndexList.push(product_index);

    const images = this.processedItems[product_index].properties.images;
    this.scraperService.downloadImages(images).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Download Images'
          });
        } else {
          const img_zip_url = res.data.url;
          // window.open(img_zip_url);

          const img_name = img_zip_url.split('/').pop();
          const a = document.createElement('a');
          a.href = img_zip_url;
          a.download = img_name;
          document.body.appendChild(a);
          a.click();
        }


        // Remove product item index from the list
        const index = this.downloadImagesIndexList.indexOf(product_index, 0);
        if (index > -1) {
          this.downloadImagesIndexList.splice(index, 1);
        }
      }
    );
  }

  /**
   * Download Reviews
   * @param id
   * @param product_id
   * @param seller_id
   */
  downloadReviews(id: number, product_id: string | any, seller_id: string | any) {
    // add index to the index list
    this.downloadReviewsIndexList.push(id);

    this.scraperService.scrapReviews(product_id, seller_id).subscribe(
      res => {
        // console.log(res);
        // console.log(res.contents);
        const feeds = this.scraperService.scrapReviewItems(res.contents);

        this.scraperService.downloadReviews(feeds).subscribe(
          res2 => {
            /**
             * Notify user of the changed email successfully
             */
            if (res2.status === false) {
              const messages = res2.error.errors.message;
              this.notificationService.error({
                message: messages,
                title: 'Error: Download Reviews'
              });
            } else {
              const file_url = res2.data.url;
              // window.open(file_url);
              const file_name = file_url.split('/').pop();
              const a = document.createElement('a');
              a.href = file_url;
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
            }
          }
        );

        // Remove product item index from the list
        const index = this.downloadReviewsIndexList.indexOf(id, 0);
        if (index > -1) {
          this.downloadReviewsIndexList.splice(index, 1);
        }

      }
    );
  }

  /**
   * Add product to favourite
   * @param id
   */
  addToFavourite(id) {
    // add index to add to favourite index list
    this.favouriteProductIndexList.push(id);

    const item = this.processedItems[id];
    this.favouriteProductService.add(item).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Add Favourite'
          });
        } else {
          this.notificationService.success({
            message: 'Product added to favourite list',
            title: 'Product marked favourite'
          });
        }




        // Remove product item index from the list
        const index = this.favouriteProductIndexList.indexOf(id, 0);
        if (index > -1) {
          this.favouriteProductIndexList.splice(index, 1);
        }
      }
    );
  }


  /**
   * Get profit percent calculated
   * @param product_index
   * @param value
   */
  getProfitPercent(product_index: number, value) {
    const rsp = this.scraperUtilsService.calculateRecommendedSellingPrice(value, this.processedItems[product_index]);
    const estimate_revenue = this.scraperUtilsService.calculateEstimateRevenue(rsp, this.processedItems[product_index]);
    this.processedItems[product_index].properties.custom.profit_percentage = value;
    this.processedItems[product_index].properties.custom.recommended_selling_price = rsp;
    this.processedItems[product_index].properties.custom.estimate_revenue = estimate_revenue;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadReviewsInQueue(id) {
    const index = this.downloadReviewsIndexList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadImagesInQueue(id) {
    const index = this.downloadImagesIndexList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in add to favourite queue
   * @param id
   */
  ifAddToFavouriteInQueue(id) {
    const index = this.favouriteProductIndexList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Open shipping detail modal
   * @param product_id
   */
  openShippingDetailModal(product_id: number) {
    const shippingDetailModal = this.modalService.open(ProductShippingModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    shippingDetailModal.componentInstance.product_id = product_id;
    shippingDetailModal.result.then(() => {}, () => {});
  }

  /**
   * Open create landing page builder modal
   * @param product_index
   */
  openLandingPageBuilderModal(product_index: number) {
    const landingPageBuilderModal = this.modalService.open(CreateLandingPageModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    landingPageBuilderModal.componentInstance.product = this.processedItems[product_index];
    landingPageBuilderModal.result.then(() => {}, () => {});
  }
  /**
   * Open fbAdBuilderModal
   * @param id
   */
  openFbAdBuilderModal(id) {
    const fbAdBuilderModal = this.modalService.open(CreateFbAdModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    fbAdBuilderModal.componentInstance.product = this.processedItems[id];
    fbAdBuilderModal.result.then(() => {}, () => {});
  }

  openCreateShopifyModal(id) {
    const createShopifyStore = this.modalService.open(CreateShopifyStoreModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    createShopifyStore.componentInstance.product = this.processedItems[id];
    createShopifyStore.componentInstance.searchTerm = this.keyword;
    createShopifyStore.result.then(() => {}, () => {});
  }

  /**
   * Open view description modal
   * @param id
   */
  openViewDescriptionModal(id) {
    const product = this.processedItems[id];
    const viewDescriptionModal = this.modalService.open(ProductDescriptionModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    viewDescriptionModal.componentInstance.product = product;
    viewDescriptionModal.result.then(() => {}, () => {});
  }

  /**
   * Open filter modal
   */
  openFilterModal() {
    // console.log(this.totalProductItems);
    const filterModal = this.modalService.open(FilterScraperModalComponent, {size: 'lg'});
    // console.log(this.filterData);
    filterModal.componentInstance.productData = this.totalProductItems;
    filterModal.componentInstance.filterData = this.filterData;
    filterModal.result.then(
      res => {
        this.filterData = res;
        this._applyFilter();
      }, () => {}
    );
  }

  private _applyFilter() {

    if (!this.filterData) {
      this.processedItems = this.totalProductItems;

      this._calculateAverageMonthlySales(this.totalProductItems);
      this._calculateAverageReviews(this.totalProductItems);

    } else {

      /**
       * Apply filter on the total items
       */
      const product_filtered = this.filterService.filter(this.filterData, this.totalProductItems);

      this._calculateAverageMonthlySales(product_filtered);
      this._calculateAverageReviews(product_filtered);
      this.processedItems = product_filtered;

    }
    this.getFilterDataFormatted();
  }

  /**
   * Calculate average monthly sales of the displayed products.
   * Assuming sale data for 6 months
   * @param product_filtered
   * @private
   */
  private _calculateAverageMonthlySales(product_filtered: Array<any>) {
    // console.log(product_filtered);
    let sum = 0;
    let average = 0;
    for (const item in product_filtered) {
      if (product_filtered.hasOwnProperty(item)) {
        sum += parseInt(product_filtered[item].properties.orders_num, 10);
        average = Math.floor(sum / 6);
      }
    }
    if (sum > this.totalSoldSum) {
      this.totalSoldSum = sum;
    }
    if (average > this.averageTotalSold) {
      this.averageTotalSold = average;
    }
  }

  /**
   * Calculate average reviews
   * Assuming review data for 6 months
   * @param product_filtered
   * @private
   */
  private _calculateAverageReviews(product_filtered) {
    let sum = 0;
    let average = 0;
    for (const item in product_filtered) {
      if (product_filtered.hasOwnProperty(item)) {
        sum += parseInt(product_filtered[item].properties.rating.count, 10);
        average = Math.floor(sum / 6);
      }
    }
    if (sum > this.totalReviews) {
      this.totalReviews = sum;
    }
    if (average > this.averageTotalReviews) {
      this.averageTotalReviews = average;
    }
  }

  /**
   * Open global profit percentage modal
   */
  openGlobalProfitPercentageModal() {
    const modal = this.modalService.open(GlobalProfitPercentageModalComponent, {centered: true});
    modal.componentInstance.global_profit_percentage = this.global_profit_percentage;
    modal.result.then(res => {
      this.global_profit_percentage = res;
      this._calculateRecommendedSellingPrice();
    }, () => {});
  }

  /**
   * Calculate recommended selling price based on global profit percentage
   * @private
   */
  private _calculateRecommendedSellingPrice() {
    /**
     * Assign profit percentage and calculate RSP for each item
     */

    for (const item of this.totalProductItems) {
      const rsp = this.scraperUtilsService.calculateRecommendedSellingPrice(this.global_profit_percentage, item);
      const estimate_revenue = this.scraperUtilsService.calculateEstimateRevenue(rsp, item);
      item.properties.custom.recommended_selling_price = rsp;
      item.properties.custom.profit_percentage = this.global_profit_percentage;
      item.properties.custom.estimate_revenue = estimate_revenue;
    }

    this._applyFilter();

  }

  /**
   * Get filter data formatted by combining min-max under single key
   */
  getFilterDataFormatted() {
    const filter_dict = {};
    if (this.filterData) {
      for (const filter in this.filterData) {
        if (this.filterData.hasOwnProperty(filter)) {

          // add ratings who has min and max pair
          if (filter.includes('_min') || filter.includes('_max')) {
            const filter_dict_key = filter.replace('_min', '').replace('_max', '');
            if (!filter_dict.hasOwnProperty(filter_dict_key)) {
              filter_dict[filter_dict_key] = {
                'min': 0,
                'max': 0
              };
            }
            if (filter.includes('_min')) {
              filter_dict[filter_dict_key]['min'] = this.filterData[filter];
            }
            if (filter.includes('_max')) {
              filter_dict[filter_dict_key]['max'] = this.filterData[filter];
            }
          }

          // add star rating
          if (filter.includes('star_count')) {
            const filter_dict_key = 'rating';
            if (!filter_dict.hasOwnProperty(filter_dict_key)) {
              filter_dict[filter_dict_key] = 0;
            }
            filter_dict[filter_dict_key] = this.filterData[filter];
          }

        }
      }
    }
    return filter_dict;
  }

  /**
   * Format to human readable string
   * @param key
   */
  formatToReadableString(key: string) {
    return this.scraperUtilsService.formatToReadableString(key);
  }

}
