import {Meta} from '@angular/platform-browser';

export class AllOriginResponse {
  contents: string;
  status: StatusContent;
}

class StatusContent {
  content_length: number;
  content_type: string;
  http_code: number;
  response_time: number;
  url: string;
}

export class SearchScraperResponseData {
  title: string;
  meta: any;
  info: any;
  products: Array<ProductInfo>;
}

export class ScrapeProductData {
  info: ProductInfo;
  properties: ProductProperties;
}

/**
 * Info retrieved from the search list page
 */
class ProductInfo {
  title: string;
  pub_catid: string;
  url: string;
  qrdata: string;
  algo_exp_id: string;
}

/**
 * Properties retrieved from the product detail page
 */
export class ProductProperties {
  name: string;
  orders_num: string;
  delivery_in: string;
  meta: ProductMeta;
  pricing: ProductPricing;
  promotion: ProductPromotion;
  shipping: ProductShipping;
}

export class ProductMeta {
  data_spm: string;
  description: string;
  fb_admins: string;
  fb_app_id: string;
  fb_page_id: string;
  keywords: string;
  og_image: string;
  og_site_name: string;
  og_title: string;
  og_type: string;
  og_url: string;
  viewport: string;
}

export class ProductPricing {
  big_sale_discount_info: string;
  bulk_price_info: string;
  currency: string;
  discount_rate: string;
  price: string;
  separator: string;
  unit: string;
}

export class ProductPromotion {
  pay_promotion: string;
  shop_support: string;
  voucher: string;
}

export class ProductShipping {
  copy_shipping_to: string;
  delivery_day_tips: string;
  logistic_detail: string;
  logistics_cost: string;
  shipping_country: string;
  shipping_company: string;
  shipping_days: string;
}

export class TopSellerProduct {
  urls: Array<string>;
  title: string;
  meta: any;
  products: Array<any>;
}
