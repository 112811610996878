import { Component, OnInit } from '@angular/core';
import {ShopifyStoreBuilderService} from '../shopify-store-builder.service';
import {ShopifyProductData} from '../shopify-store-builder.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteShopifyProductModalComponent} from '../delete-shopify-product-modal/delete-shopify-product-modal.component';
import {AuthService} from '../../auth/auth.service';
import {ClientActionsService} from '../../client-account/client-actions.service';
import {tick} from '@angular/core/testing';
import {AssignToClientModalComponent} from '../../client-account/assign-to-client-modal/assign-to-client-modal.component';
import {ShopifyErrorModalComponent} from '../shopify-error-modal/shopify-error-modal.component';

@Component({
  selector: 'app-list-shopify-store',
  templateUrl: './list-shopify-store.component.html',
  styleUrls: ['./list-shopify-store.component.css']
})
export class ListShopifyStoreComponent implements OnInit {

  shopifyProducts: Array<ShopifyProductData>;
  deleting_list: Array<any> = [];

  is_client = false;

  constructor(
    private shopifyStoreBuilderService: ShopifyStoreBuilderService,
    private modalService: NgbModal,
    private authService: AuthService,
    private clientActions: ClientActionsService
  ) { }

  ngOnInit() {
    // Check if user is client
    this.is_client = this.authService.isClient();

    /**
     * Get shopify stores
     */
    this._getShopifyStores();
  }

  /**
   * Get all shopify stores created by the users
   * @private
   */
  private _getShopifyStores() {
    this.deleting_list.length = 0;
    if (this.is_client) {
      // List client products
      this.clientActions.listShopifyProducts().subscribe(
        res => {
          this.shopifyProducts = res.data;
        }
      );
    } else {
      // Delete for the user
      this.shopifyStoreBuilderService.listProducts().subscribe(
        res => {
          this.shopifyProducts = res.data;
        }
      );
    }
  }

  /**
   * Delete shopify store
   * @param id
   */
  deleteShopifyProductModal(id: any) {
    this.deleting_list.push(id);
    /**
     * Delete store here
     */
    const deleteShopifyProductModal = this.modalService.open(DeleteShopifyProductModalComponent);
    deleteShopifyProductModal.componentInstance.shopifyProductId = id;
    deleteShopifyProductModal.result.then(() => {
      this._getShopifyStores();
    }, () => {});
  }

  /**
   * Check if item is in deleting list
   * @param record_id
   */
  ifDeleting(record_id: number) {
    const index = this.deleting_list.indexOf(record_id, 0);
    return index > -1;
  }

  /**
   * Assign item to the client account
   * @param id
   */
  assignToClient(id: number) {
    const assignToClient = this.modalService.open(AssignToClientModalComponent, {size: 'lg'});
    assignToClient.componentInstance.itemId = id;
    assignToClient.componentInstance.type = 'shopify_store';
    assignToClient.result.then(() => {}, () => {});
  }

  showShopifyError(c: ShopifyProductData) {
    const shopifyErrorMessage = this.modalService.open(ShopifyErrorModalComponent, {centered: true});
    shopifyErrorMessage.componentInstance.record = c;
    shopifyErrorMessage.result.then(() => {}, () => {});
  }
}
