<!DOCTYPE html>
<html lang="en">
<head>
  <title>Cookie Policy of ClipsReel.io</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" type="text/css" />
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>

  <style>
    body{
      background:#fff;
      font-size:16px;
      font-weight:normal;
      color:#333;
    }
    a{
      color:#787878;
      text-decoration: none;
    }
    .pad0{
      padding:0;
    }
    .pdl10{
      padding-left:10px;
    }
    .bdr{
      border-bottom: 1px dotted #dfdfdf;
      padding-bottom: 25px;
      position: relative;
    }
    .sec1{
      padding:4%;
    }
    .sec1head1{
      font-size: 19px;
      font-weight: normal;
      line-height: 23px;
      margin-bottom: 15px;
      color: #141414;
    }
    .sec1para{
      font-size: 14px;
      font-weight: normal;
      line-height:1.6;
      color:#6b6b6b;
    }
    .sec2para{
      font-size: 13px;
      font-weight: normal;
      color: #787878;
    }
    .sec2para2{
      font-size: 13px;
      font-weight: normal;
      color: #787878;
      padding-top:20px;
    }
    .sec3para{
      font-size: 11px;
      font-weight: normal;
      color: #787878;
      padding-top:20px;
    }
    .sec1head2{
      font-size: 17px;
      font-weight: bold;
      line-height: 21px;
      color: #141414;

    }
    .sec1head3{
      font-size: 13px;
      line-height: 19px;
      font-weight: bold;
    }
    .sec1head4{
      font-size: 13px;
      line-height: 19px;
      font-weight: bold;
      color: #787878;
    }
    .icon{
      padding-top:20px;
    }
    .mar0{
      margin:0;
    }
    .pdl66{
      padding-left:66%;
    }
    .demo {
      padding-top: 34px;
      padding-bottom: 10px;
    }
    .panel-group .panel+.panel {
      margin-top: 0px;
    }
    .panel-group .panel {
      border-radius: 0;
      box-shadow: none;
      border-color: #EEEEEE;
    }
    .panel-default > .panel-heading {
      padding: 0;
      border-radius: 0;
      color: #212121;
      background-color: #FAFAFA;
      border-color: #EEEEEE;
    }
    .panel-title {
      font-size: 14px;
    }
    .panel-title > a {
      display: block;
      padding: 15px;
      text-decoration: none;
      background-color: #ffffff;
      border: 1px solid rgba(201, 207, 210, 0.98);
      font-weight: bold;
      font-size: 13px;
      color: #141414;
    }
    .more-less {
      float: right;
      color: #212121;
    }
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #ebebeb;
      font-size: 13px;
      font-weight: normal;
      color: #787878;
    }
  </style>
</head>
<body>
<section class="sec1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h1 class="sec1head1"><strong>Cookie Policy of ClipsReel.io</strong></h1>
          <p class="sec1para">
            Cookies consist of portions of code installed in the browser that assist the Owner in providing the<br/> Service according to the purposes described. Some of the purposes for which Cookies are <br/>installed may also require the User's consent.<br/><br/>
            Where the installation of Cookies is based on consent, such consent can be freely withdrawn at<br/> any time following the instructions provided in this document.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h1 class="sec1head2">Technical Cookies and Cookies serving aggregated statistical purposes</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 pad0">
          <div class="demo">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwo">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Activity strictly necessary for the functioning of the Service
                    </a>
                  </h4>
                </div>
                <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                  <div class="panel-body">
                    This Application uses Cookies to save the User's session and to carry out other activities that are strictly necessary for the operation of this Application, for example in relation to the distribution of traffic.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingThree">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Activity regarding the saving of preferences, optimization, and statistics
                    </a>
                  </h4>
                </div>
                <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                  <div class="panel-body">
                    This Application uses Cookies to save browsing preferences and to optimize the User's browsing experience. Among these Cookies are, for example, those used for the setting of language and currency preferences or for the management of first party statistics employed directly by the Owner of the site.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h1 class="sec1head2">Other types of Cookies or third parties that install Cookies</h1>
          <p class="sec1para">
            Some of the services listed below collect statistics in an anonymized and aggregated form and may not require the consent of the User or may be managed directly by the Owner - depending on how they are described - without the help of third parties.<br/><br/>
            If any third party operated services are listed among the tools below, these may be used to track Users’ browsing habits – in addition to the information specified herein and without the Owner’s knowledge. Please refer to the privacy policy of the listed services for detailed information.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 pad0">
          <div class="demo">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingFour">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Advertising
                    </a>
                  </h4>
                </div>
                <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                  <div class="panel-body">
                    This type of service allows User Data to be utilized for advertising communication purposes displayed in the form of banners and other advertisements on this Application, possibly based on User interests.<br/>
                    This does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below.<br/>
                    Some of the services listed below may use Cookies to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside this Application. For more information, please check the privacy policies of the relevant services.<br/>
                    In addition to any opt out offered by any of the services below, the User may opt out of a third-party service's use of cookies by visiting the <a href="http://optout.networkadvertising.org/">Network Advertising Initiative opt-out page</a>.<br/><br/>

                    <strong>AdMob (AdMob Google Inc.)</strong><br/>
                    AdMob is an advertising service provided by AdMob Google Inc.<br/>
                    <strong>In order to understand Google's use of Data, consult <a href="https://www.google.com/policies/privacy/partners/">Google's partner policy</a></strong><br/><br/>
                    Personal Data collected: Cookies, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example) and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.google.com/policies/technologies/ads/" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.google.com/settings/ads">Opt Out</a>.<br/><br/>

                    <strong>AdKaora (AdKaora srl)</strong><br/>
                    AdKaora is an advertising service provided by AdKaora srl.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: Italy – <a href="http://www.mondadori.it/privacy-policy" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Facebook Audience Network (Facebook, Inc.)</strong><br/>
                    Facebook Audience Network is an advertising service provided by Facebook, Inc. <strong>In order to understand Facebook's use of Data, consult <a href="https://www.facebook.com/about/privacy/">Facebook's data policy</a>.</strong>

                    <p>This Application may use identifiers for mobile devices (including Android Advertising ID or Advertising Identifier for iOS, respectively) and technologies similar to cookies to run the Facebook Audience Network service. One of the ways Audience Network shows ads is by using the User's ad preferences. The User can control this in the <a href="https://www.facebook.com/ads/settings">Facebook ad settings</a>.</p>

                    <p>Users may opt-out of certain Audience Network targeting through applicable device settings, such as the device advertising settings for mobile phones or by following the instructions in other Audience Network related sections of this privacy policy, if available.
                      <br/><br/>
                      Personal Data collected: Cookies, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example) and Usage Data.<br/><br/>

                      Place of processing: United States – <a href="https://www.facebook.com/about/privacy/ " target="_blank">Privacy Policy</a> &ndash; <a href="https://www.facebook.com/settings?tab=ads">Opt Out</a>.<br/><br/>


                      <strong>Yahoo App Publishing Advertising (Yahoo! Inc.)</strong><br/>
                      Yahoo App Publishing is an advertising service provided by Yahoo! Inc.<br/>
                      If the User chooses to opt-out, Yahoo App Publishing will stop tracking data for the device identified by the provided MAC address and/or device identifier going forward.<br/>
                      Yahoo App Publishing will stop providing ads and/or recommendations to that MAC address and/or device identifier across all publishers.<br/><br/>
                      Personal Data collected: Cookies and Usage Data.<br/><br/>

                      Place of processing: United States – <a href="https://info.yahoo.com/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://developer.yahoo.com/flurry/end-user-opt-out/">Opt Out</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingFive">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Analytics
                    </a>
                  </h4>
                </div>
                <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                  <div class="panel-body">
                    The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.<br/><br/>

                    <strong>Google Analytics with anonymized IP (Google Inc.)</strong><br/>
                    Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.<br/>
                    Google may use the Data collected to contextualize and personalize the ads of its own advertising network.<br/>
                    This integration of Google Analytics anonymizes your IP address. It works by shortening Users' IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the complete IP address be sent to a Google server and shortened within the US.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">Opt Out</a>.<br/><br/>

                    <strong>AdEspresso conversion tracking (Creative Web Srl)</strong><br/>
                    AdEspresso conversion tracking is an analytics service provided by Creative Web Srl that connects data from the Facebook advertising network with actions performed on this Application.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: Italy – <a href="https://adespresso.com/privacy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Facebook Ads conversion tracking (Facebook, Inc.)</strong><br/>
                    Facebook Ads conversion tracking is an analytics service provided by Facebook, Inc. that connects data from the Facebook advertising network with actions performed on this Application.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Google Analytics (Google Inc.)</strong><br/>
                    Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.<br/>
                    Google may use the Data collected to contextualize and personalize the ads of its own advertising network.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">Opt Out</a>.<br/><br/>

                    <strong>KISSmetrics (KISSmetrics)</strong><br/>
                    KISSmetrics is an analytics service provided by Kissmetrics Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.kissmetrics.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.kissmetrics.com/privacy#controls">Opt Out</a>.<br/><br/>

                    <strong>Twitter Ads conversion tracking (Twitter, Inc.)</strong><br/>
                    Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with actions performed on this Application.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Wordpress Stats (Automattic Inc.)</strong><br/>
                    Wordpress Stats is an analytics service provided by Automattic Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://automattic.com/privacy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Yahoo Advertising conversion tracking (Yahoo! Inc.)</strong><br/>
                    Yahoo Advertising conversion tracking is an analytics service provided by Yahoo! Inc. that connects data from the Yahoo advertising network with actions performed on this Application.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://policies.yahoo.com/us/en/yahoo/privacy/index.htm" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSix">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Commercial affiliation
                    </a>
                  </h4>
                </div>
                <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                  <div class="panel-body">
                    This type of service allows this Application to display advertisements for third-party products or services. Ads can be displayed either as advertising links or as banners using various kinds of graphics.<br/>
                    Clicks on the icon or banner posted on the Application are tracked by the third-party services listed below, and are shared with this Application.<br/>
                    For details of which data are collected, please refer to the privacy policy of each service.<br/><br/>

                    <strong>JVZoo (BBC Systems Inc.)</strong><br/>
                    JVZoo is a banner commercial affiliation service provided by BBC Systems Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.jvzoo.com/privacy" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Amazon Affiliation (Amazon)</strong><br/>
                    Amazon Affiliation is a commercial affiliation service provided by Amazon.com Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.amazon.com/gp/help/customer/display.html/ref=hp_rel_topic?ie=UTF8&amp;nodeId=468496" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>AliExpress Affiliate (Alibaba.com Singapore E-Commerce Private Limited)</strong><br/>
                    AliExpress Affiliate is a commercial affiliation service provided by Alibaba.com Singapore E-Commerce Private Limited.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: Singapore – <a href="http://rule.alibaba.com/rule/detail/2034.htm?spm=a2700.7787031.a271py.41.d12hNd" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>ClickBank (Click Sales Inc.)</strong><br/>
                    ClickBank is a banner commercial affiliation service provided by Click Sales Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://accounts.clickbank.com/privacy.html" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Zooplus Affiliate Programme (Zooplus AG)</strong><br/>
                    Zooplus Affiliate Programme is a commercial affiliation service provided by Zooplus AG.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: Germany – <a href="http://www.zooplus.com/content/privacy" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSeven">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Content commenting
                    </a>
                  </h4>
                </div>
                <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                  <div class="panel-body">
                    Content commenting services allow Users to make and publish their comments on the contents of this Application.<br/>
                    Depending on the settings chosen by the Owner, Users may also leave anonymous comments. If there is an email address among the Personal Data provided by the User, it may be used to send notifications of comments on the same content. Users are responsible for the content of their own comments.<br/>
                    If a content commenting service provided by third parties is installed, it may still collect web traffic data for the pages where the comment service is installed, even when Users do not use the content commenting service.<br/><br/>

                    <strong>Disqus (Disqus)</strong><br/>
                    Disqus is a content commenting service provided by Big Heads Labs Inc.<br/><br/>
                    Personal Data collected: Cookies, Usage Data and various types of Data as specified in the privacy policy of the service.<br/><br/>

                    Place of processing: United States – <a href="https://help.disqus.com/customer/portal/articles/466259-privacy-policy" target="_blank">Privacy Policy</a> &ndash; <a href="https://help.disqus.com/customer/portal/articles/1657951">Opt out</a>.<br/><br/>

                    <strong>Facebook Comments (Facebook, Inc.)</strong><br/>
                    Facebook Comments is a content commenting service provided by Facebook, Inc. enabling the User to leave comments and share them on the Facebook platform.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.facebook.com/privacy/explanation" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingEight">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Displaying content from external platforms
                    </a>
                  </h4>
                </div>
                <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                  <div class="panel-body">
                    This type of service allows you to view content hosted on external platforms directly from the pages of this Application and interact with them.<br/>
                    This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.<br/><br/>

                    <strong>Google Site Search (Google Inc.)</strong><br/>
                    Google Site Search is a search engine embedding service provided by Google Inc. that allows this Application to incorporate content of this kind on its pages.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.google.com/policies/privacy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Instagram widget (Instagram, Inc.)</strong><br/>
                    Instagram is an image visualization service provided by Instagram, Inc. that allows this Application to incorporate content of this kind on its pages.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.instagram.com/about/legal/privacy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>YouTube video widget (Google Inc.)</strong><br/>
                    YouTube is a video content visualization service provided by Google Inc. that allows this Application to incorporate content of this kind on its pages.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="http://www.google.it/intl/en/policies/privacy/" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingNine">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Interaction with data collection platforms and other third parties
                    </a>
                  </h4>
                </div>
                <div id="collapseNine" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
                  <div class="panel-body">
                    This type of service allows Users to interact with data collection platforms or other services directly from the pages of this Application for the purpose of saving and reusing data.<br/>
                    If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service.<br/><br/>

                    <strong>GetResponse widget (Implix Sp. z o.o.)</strong><br/>
                    The GetResponse widget is a service for interacting with the GetResponse email address management and message sending service provided by Implix Sp. z o.o.<br/><br/>
                    Personal Data collected: Cookies, email address, first name and Usage Data.<br/><br/>

                    Place of processing: Poland – <a href="https://www.getresponse.com/legal/privacy.html" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Interaction with external social networks and platforms
                    </a>
                  </h4>
                </div>
                <div id="collapseTen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen">
                  <div class="panel-body">
                    This type of service allows interaction with social networks or other external platforms directly from the pages of this Application.<br/>
                    The interaction and information obtained through this Application are always subject to the User’s privacy settings for each social network.<br/>
                    This type of service might still collect traffic data for the pages where the service is installed, even when Users do not use it.<br/><br/>

                    <strong>Facebook Like button and social widgets (Facebook, Inc.)</strong><br/>
                    The Facebook Like button and social widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.facebook.com/privacy/explanation" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>PayPal button and widgets (PayPal Inc.)</strong><br/>
                    The PayPal button and widgets are services allowing interaction with the PayPal platform provided by PayPal Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: See the PayPal privacy policy – <a href="https://www.paypal.com/cgi-bin/webscr?cmd=p/gen/ua/policy_privacy-outside " target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Pinterest “Pin it” button and social widgets (Pinterest)</strong><br/>
                    The Pinterest “Pin it” button and social widgets are services allowing interaction with the Pinterest platform provided by Pinterest Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://about.pinterest.com/en/privacy-policy" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Reddit button and widgets (reddit inc.)</strong><br/>
                    The reddit button and widgets are services allowing interaction with the reddit platform provided by reddit inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.reddit.com/help/privacypolicy" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>ShareThis (Sharethis Inc.)</strong>.<br/>
                    ShareThis is a service provided by ShareThis Inc., which displays a widget that allows interaction with social networks and external platforms as well as sharing the contents of this Application.<br/>
                    Depending on the configuration, this service can display widgets belonging to third parties such as the managers of social networks where interactions are shared. In this case, also the third parties that provide the widget will be informed of interactions and Usage Data on the pages where this service is installed.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="http://sharethis.com/privacy" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Tumblr sharing button and social widgets (Tumblr Inc.)</strong><br/>
                    The Tumblr sharing button and social widgets are services allowing interaction with the Tumblr social network provided by Tumblr Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.tumblr.com/policy/en/privacy" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Twitter Tweet button and social widgets (Twitter, Inc.)</strong><br/>
                    The Twitter Tweet button and social widgets are services allowing interaction with the Twitter social network provided by Twitter, Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingEleven">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Interaction with live chat platforms
                    </a>
                  </h4>
                </div>
                <div id="collapseEleven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEleven">
                  <div class="panel-body">
                    This type of service allows Users to interact with third-party live chat platforms directly from the pages of this Application, for contacting and being contacted by this Application support service.<br/>
                    If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service. Moreover, live chat conversations may be logged.<br/><br/>

                    <strong>Facebook Messenger Customer Chat (Facebook, Inc.)</strong><br/>
                    The Facebook Messenger Customer Chat is a service for interacting with the Facebook Messenger live chat platform provided by Facebook, Inc.<br/><br/>
                    Personal Data collected: About Me, Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Freshchat Widget (Freshworks, Inc.)</strong><br/>
                    The Freshchat Widget is a service for interacting with the Freshchat live chat platform provided by Freshworks, Inc.<br/><br/>
                    Personal Data collected: Cookies, email address and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.freshworks.com/privacy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Tawk.to Widget (tawk.to ltd.)</strong><br/>
                    The Tawk.to Widget is a service for interacting with the Tawk.to live chat platform provided by tawk.to ltd.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United Kingdom – <a href="https://www.tawk.to/privacy-policy/" target="_blank">Privacy Policy</a>.<br/><br/>

                    <strong>Zopim Widget (Zopim Technologies Pte Ltd)</strong><br/>
                    The Zopim Widget is a service for interacting with the Zopim live chat platform provided by Zopim Technologies Pte Ltd.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: Singapore – <a href="https://www.zopim.com/privacy" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwelve">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Interaction with support and feedback platforms
                    </a>
                  </h4>
                </div>
                <div id="collapseTwelve" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwelve">
                  <div class="panel-body">
                    This type of service allows Users to interact with third-party support and feedback platforms directly from the pages of this Application.<br/>
                    If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service.<br/><br/>

                    <strong>Zendesk Widget (Zendesk)</strong><br/>
                    The Zendesk Widget is a service for interacting with the Zendesk support and feedback platform provided by Zendesk Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.zendesk.com/company/privacy" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingThirteen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Remarketing and behavioral targeting
                    </a>
                  </h4>
                </div>
                <div id="collapseThirteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirteen">
                  <div class="panel-body">
                    This type of service allows this Application and its partners to inform, optimize and serve advertising based on past use of this Application by the User.<br/>
                    This activity is performed by tracking Usage Data and by using Cookies, information that is transferred to the partners that manage the remarketing and behavioral targeting activity.<br/>
                    In addition to any opt out offered by any of the services below, the User may opt out of a third-party service's use of cookies by visiting the Network Advertising Initiative opt-out page.<br/><br/>

                    <strong>AdRoll (Semantic Sugar, Inc.)</strong><br/>
                    AdRoll is an advertising service provided by Semantic Sugar, Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.adroll.com/about/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://info.evidon.com/pub_info/573?v=1&nt=1&nw=false">Opt Out</a>.<br/><br/>

                    <strong>Facebook Custom Audience (Facebook, Inc.)</strong><br/>
                    Facebook Custom Audience is a remarketing and behavioral targeting service provided by Facebook, Inc. that connects the activity of this Application with the Facebook advertising network.<br/><br/>
                    Personal Data collected: Cookies and email address.<br/><br/>

                    Place of processing: United States – <a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>.<br/><br/>

                    <strong>Facebook Remarketing (Facebook, Inc.)</strong><br/>
                    Facebook Remarketing is a remarketing and behavioral targeting service provided by Facebook, Inc. that connects the activity of this Application with the Facebook advertising network.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.facebook.com/about/privacy/" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.aboutads.info/choices/">Opt Out</a>.<br/><br/>

                    <strong>Perfect Audience (NowSpots, Inc.)</strong><br/>
                    Perfect Audience is an advertising service provided by NowSpots, Inc.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://www.perfectaudience.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://www.perfectaudience.com/privacy/index.html#opt-out">Opt Out</a>.<br/><br/>

                    <strong>Twitter Remarketing (Twitter, Inc.)</strong><br/>
                    Twitter Remarketing is a remarketing and behavioral targeting service provided by Twitter, Inc. that connects the activity of this Application with the Twitter advertising network.<br/><br/>
                    Personal Data collected: Cookies and Usage Data.<br/><br/>

                    Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://support.twitter.com/articles/20170405">Opt Out</a>.<br/><br/>

                    <strong>Twitter Tailored Audiences (Twitter, Inc.)</strong><br/>
                    Twitter Tailored Audiences is a remarketing and behavioral targeting service provided by Twitter, Inc. that connects the activity of this Application with the Twitter advertising network.<br/><br/>
                    Personal Data collected: Cookies and email address.<br/><br/>

                    Place of processing: United States – <a href="https://twitter.com/privacy" target="_blank">Privacy Policy</a> &ndash; <a href="https://support.twitter.com/articles/20170405">Opt Out</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingFifteen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Traffic optimization and distribution
                    </a>
                  </h4>
                </div>
                <div id="collapseFifteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFifteen">
                  <div class="panel-body">
                    This type of service allows this Application to distribute their content using servers located across different countries and to optimize their performance.<br/>
                    Which Personal Data are processed depends on the characteristics and the way these services are implemented. Their function is to filter communications between this Application and the User's browser.<br/>
                    Considering the widespread distribution of this system, it is difficult to determine the locations to which the contents that may contain Personal Information User are transferred.<br/><br/>

                    <strong>CloudFlare (Cloudflare)</strong><br/>
                    CloudFlare is a traffic optimization and distribution service provided by CloudFlare Inc.<br/><br/>
                    The way CloudFlare is integrated means that it filters all the traffic through this Application, i.e., communication between this Application and the User's browser, while also allowing analytical data from this Application to be collected.<br/><br/>
                    Personal Data collected: Cookies and various types of Data as specified in the privacy policy of the service.<br/><br/>

                    Place of processing: United States – <a href="https://www.cloudflare.com/security-policy/" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSixteen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      User database management
                    </a>
                  </h4>
                </div>
                <div id="collapseSixteen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSixteen">
                  <div class="panel-body">
                    This type of service allows the Owner to build user profiles by starting from an email address, a personal name, or other information that the User provides to this Application, as well as to track User activities through analytics features. This Personal Data may also be matched with publicly available information about the User (such as social networks' profiles) and used to build private profiles that the Owner can display and use for improving this Application.<br/>
                    Some of these services may also enable the sending of timed messages to the User, such as emails based on specific actions performed on this Application.<br/><br/>

                    <strong>Intercom (Intercom Inc.)</strong><br/>
                    Intercom is a User database management service provided by Intercom Inc. Intercom can also be used as a medium for communications, either through email, or through messages within this Application.<br/><br/>
                    Personal Data collected: Cookies, email address, Usage Data and various types of Data as specified in the privacy policy of the service.<br/><br/>

                    Place of processing: United States – <a href="https://docs.intercom.io/pricing-and-terms/privacy" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div><br/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8">
          <h1 class="sec1head2">How to provide or withdraw consent to the installation of Cookies</h1>
          <p class="sec1para">
            In addition to what is specified in this document, the User can manage preferences for Cookies directly from within their own browser and prevent – for example – third parties from installing Cookies.<br />Through browser preferences, it is also possible to delete Cookies installed in the past, including the Cookies that may have saved the initial consent for the installation of Cookies by this website.<br />Users can, for example, find information about how to manage Cookies in the most commonly used browsers at the following addresses: <a href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">Google Chrome</a>, <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a>, <a href="https://support.apple.com/kb/PH21411">Apple Safari</a> and <a href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies">Microsoft Internet Explorer</a>.<br/><br/>

            With regard to Cookies installed by third parties, Users can manage their preferences and withdrawal of their consent by clicking the related opt-out link (if provided), by using the means provided in the third party's privacy policy, or by contacting the third party.<br/><br/>

            Notwithstanding the above, the Owner informs that Users may follow the instructions provided on the subsequently linked initiatives by the <a href="http://www.youronlinechoices.eu/">EDAA</a> (EU), the <a href="https://www.networkadvertising.org/understanding-digital-advertising">Network Advertising Initiative</a> (US) and the <a href="https://www.aboutads.info/consumers/">Digital Advertising Alliance</a> (US), <a href="https://youradchoices.ca/understanding-online-advertising/">DAAC</a> (Canada), <a href="http://www.ddai.info/optout">DDAI</a> (Japan) or other similar services. Such initiatives allow Users to select their tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of these resources in addition to the information provided in this document.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 bdr">
          <h1 class="sec1head2">Owner and Data Controller</h1>
          <p class="sec1para">
            VIneaSX Solutions LLC. Office 10, Level 1, Sharjah Media City, Sharjah, UAE.<br/> (http://support.vineasx.com)<br/><br/>
            <strong>Owner contact email: </strong>support@vineasx.com<br/><br/>
            Since the installation of third-party Cookies and other tracking systems through the services<br/> used within this Application cannot be technically controlled by the Owner, any specific <br/>references to Cookies and tracking systems installed by third parties are to be considered <br/>indicative. In order to obtain complete information, the User is kindly requested to consult the<br/> privacy policy for the respective third-party services listed in this document.<br/><br/>

            Given the objective complexity surrounding the identification of technologies based on Cookies, Users are encouraged to contact the Owner should they wish to receive any further information on the use of Cookies by this Application.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 bdr">
          <div class="demo">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingSeventeen">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                      <i class="more-less glyphicon glyphicon-chevron-left"></i>
                      Definitions and legal references
                    </a>
                  </h4>
                </div>
                <div id="collapseSeventeen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeventeen">
                  <div class="panel-body">
                    <strong>Personal Data (or Data)</strong><br/>
                    Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.<br/><br/>

                    <strong>Usage Data</strong><br/><br/>
                    Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.<br/><br/>

                    <strong>User</strong><br/><br/>
                    The individual using this Application who, unless otherwise specified, coincides with the Data Subject.<br/><br/>

                    <strong>Data Subject</strong><br/><br/>
                    The natural person to whom the Personal Data refers.<br/><br/>

                    <strong>Data Processor (or Data Supervisor)</strong><br/>
                    The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.<br/><br/>

                    <strong>Data Controller (or Owner)</strong><br/>
                    The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.<br/><br/>

                    <strong>This Application</strong><br/>
                    The means by which the Personal Data of the User is collected and processed.<br/><br/>

                    <strong>Service</strong><br/>
                    The service provided by this Application as described in the relative terms (if available) and on this site/application.<br/><br/>

                    <strong>European Union (or EU)</strong><br/>
                    Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.<br/><br/>

                    <strong>Cookies</strong><br/>
                    Small sets of data stored in the User's device.<br/><br/>

                    <strong>About Me</strong><br/>
                    Provides access to the 'About Me' section of the profile.<br/><br/>

                    <strong>Email</strong><br/>
                    Provides access to the User's primary email address.<br/><br/>

                    <strong>Legal information</strong><br/><br/>
                    This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).<br/><br/>

                    This privacy policy relates solely to this Application, if not stated otherwise within this document.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-2 col-md-8 pad0">
          <div class="col-md-6">
            <p class="sec3para">Latest update: May 09, 2018</p>
          </div>
          <div class="col-md-6">
            <p class="sec3para"><a routerLink="/pages/detail-privacy">Show the complete Privacy Policy</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</body>
</html>

