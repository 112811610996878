import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {MembershipCheckResponse} from './membership-check.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipCheckService {

  private url = 'membership_check/';

  /**
   * @constructor
   * @param http
   * @param resource
   */
  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * Check membership for search
   */
  search(): Observable<MembershipCheckResponse> {
    const url = `${this.resource.url}${this.url}search`;

    return this.http.Get(url);
  }

  /**
   * Check for fb Ads limit
   */
  fbAds(): Observable<MembershipCheckResponse> {
    const url = `${this.resource.url}${this.url}fbAds`;

    return this.http.Get(url);
  }

  /**
   * Check permission for landing page builder
   */
  landingPageBuilder(): Observable<MembershipCheckResponse> {
    const url = `${this.resource.url}${this.url}landingPageBuilder`;

    return this.http.Get(url);
  }

  /**
   * Check permission for sub accounts (client account and virtual account)
   */
  subAccount(): Observable<MembershipCheckResponse> {
    const url = `${this.resource.url}${this.url}subAccount`;

    return this.http.Get(url);
  }
}
