import {Injectable, InjectionToken, Injector} from '@angular/core';
import * as Rollbar from 'rollbar';
import {AuthUserData} from '../auth/auth.model';
import {AuthService} from '../auth/auth.service';

/**
 * Rollback API configurations
 * Logging Dashboard: https://rollbar.com/anujsh/Vega6--SpyCom-Angular/
 * Application: spycom-angular
 * Login using: Google
 *
 * For more info visit: https://rollbar.com
 */
const rollbarConfig = {
  accessToken: 'c1bcc418c8fe412cb0117adc2b0105ae',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

/**
 * Rollbar Error handler class to log error to the rollbar logging server
 * @class
 */
@Injectable()
export class RollbarErrorHandlerService {

  /**
   * Constructor of RollbarErrorHandlerService class
   * @constructor
   * @param injector {Injector} - Inject services using Injector get() function
   * @param auth
   */
  constructor(
    private injector: Injector,
    private auth: AuthService
  ) {
    // Add auth user data to the configuration
    const userData: AuthUserData = this.auth.getAuthUser();
    // Rollbar
    // TODO: Add authData to the rollbar payload
  }

  /**
   * Post the error to the rollbar logging server
   * @param error
   */
  post(error) {
    // Inject RollbarService
    const rollbar = this.injector.get(RollbarService);
    // send error to the logging server
    rollbar.error(error);
  }
}

/**
 * Rollback factory to create factory provider and initialize rollbar API credentials
 */
export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

/**
 * Create RollbarService to inject in the class and
 * send error to the logging server using error() function
 */
export const RollbarService = new InjectionToken<Rollbar>('rollbar');
