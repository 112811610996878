import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ClientAccountService} from '../client-account.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {ClientAccountData} from '../client-account.model';

@Component({
  selector: 'app-delete-client-account-modal',
  templateUrl: './delete-client-account-modal.component.html',
  styleUrls: ['./delete-client-account-modal.component.css']
})
export class DeleteClientAccountModalComponent implements OnInit {

  @Input() client_account_id: number;

  clientAccount: ClientAccountData;

  deleting = false;

  constructor(
    public activeModal: NgbActiveModal,
    private clientAccountService: ClientAccountService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    /**
     * Get client
     */
    this.clientAccountService.get(this.client_account_id).subscribe(
      res => {
        this.clientAccount = res.data;
      }
    );
  }

  deleteClientNow() {
    this.deleting = true;

    this.clientAccountService.delete(this.client_account_id).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        let messages = '';
        if (res.status === false) {
          if (res.data) {
            if (res.data.message) {
              messages = res.data.message;
            }
          } else if (res.error) {
            messages = res.error.errors.message;
          } else {
            messages = 'Unknown error';
          }
          this.notificationService.error({
            message: messages,
            title: 'Error: Client Delete'
          });

          this.deleting = false;
        } else {
          this.notificationService.success({
            message: 'Client account delete successfully',
            title: 'Client account deleted'
          });
          this.activeModal.close();
        }
      }
    );
  }

}
