<div class="modal-header">
  <h4 class="modal-title">Add Product to Shopify Store</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="shopifyStoreBuilderForm" (submit)="onSubmit()" id="create-shopify-store-form" #ngForm="ngForm">

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="input-store-id">Select Shopify Store</label>
              <select formControlName="shopify_credential_id" class="form-control" id="input-store-id" *ngIf="storeLoaded; else loading_store" required>
                <option disabled selected>Select Shopify Store</option>
                <option *ngFor="let store of shopifyStoreCredentials" [value]="store.id">{{ store.app_name }}</option>
              </select>
              <ng-template #loading_store>
                <div class="text-center">
                  <i class="fas fa-spin fa-spinner fa-3x"></i>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="row product-info text-white">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="title">Product Info</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <img src="{{ product?.info.images.main }}" class="img-responsive" >
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="input-product-title">Product Title</label>
                      <input formControlName="product_title" class="form-control" id="input-product-title" required>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="input-product-description">Product Description</label>
                      <textarea formControlName="product_description" class="form-control" id="input-product-description"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="input-product-price">Product Price</label>
                      <input formControlName="product_price" class="form-control" id="input-product-price" required>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="input-product-vendor">Product Vendor</label>
                      <input formControlName="product_vendor" id="input-product-vendor" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="input-product-type">Product Type</label>
                      <input formControlName="product_type" class="form-control" id="input-product-type">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="input-product-tags">Product Tags</label>
                      <input formControlName="product_tags" class="form-control" id="input-product-tags">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="input-product-image">Product Image</label>
                      <input formControlName="product_image" class="form-control" id="input-product-image">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>

</div>
<div class="modal-footer">

  <button class="btn btn-success" type="submit" form="create-shopify-store-form" [disabled]="!ngForm?.valid || shopifyStoreBuilderFormSubmitted">
    <span *ngIf="!shopifyStoreBuilderFormSubmitted; else saving_loader">
      <i class="fa fa-save"></i> Send
    </span>
    <ng-template #saving_loader>
      <i class="fa fa-spinner fa-spin"></i> Sending...
    </ng-template>
  </button>

  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>


<ng-template #template_na>
  <span class="text-danger">N/A</span>
</ng-template>
