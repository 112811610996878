<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " id="navigation-example">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand" routerLink="/dashboard">
        <img src="../../../assets/img/spycom-logo-final-wt.png" alt="SpyCom Logo">
      </a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" data-target="#navigation-example">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/dashboard">
            <i class="material-icons">dashboard</i> Dashboard
          </a>
        </li>
        <li class="nav-item" *ngIf="!is_client">
          <a class="nav-link" routerLink="/search">
            <i class="material-icons">search</i> Search
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/saved-searches">
            <i class="material-icons">list</i> Saved Searches
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/favourite">
            <i class="material-icons">favorite</i> Favourites
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/fb-ads">
            <i class="material-icons">all_inbox</i> FB Ads Builder
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/shopify-store">
            <i class="material-icons">shopping_cart</i> Shopify Store
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/landing-page">
            <i class="material-icons">laptop_mac</i> Landing Page
          </a>
        </li>
        <li class="nav-item dropdown" *ngIf="!is_client">
          <a class="nav-link" href="javascript:void(0)" id="navbar-sub-users" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">supervised_user_circle</i> Sub-Users <i class="fas fa-caret-down"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="/client">
              <i class="material-icons">sentiment_satisfied_alt</i> Client Account
            </a>
            <a class="dropdown-item" routerLink="/virtual">
              <i class="material-icons">assignment_ind</i> Virtual Account
            </a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" href="javscript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">person</i>
            <p class="d-lg-none d-md-block">
              Some Actions
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="https://server.spycom.io" target="_blank" *ngIf="is_super_admin">Super Admin</a>
            <a class="dropdown-item" routerLink="/account/profile">Profile</a>
            <a class="dropdown-item" routerLink="/shopify-store/credentials" *ngIf="!is_client">Shopify Credentials</a>
            <a class="dropdown-item" href="https://support.vega6.com/" target="_blank">Support</a>
            <a class="dropdown-item" routerLink="/timeline" *ngIf="!is_client">Timeline</a>
            <a class="dropdown-item" routerLink="/tutorial">Tutorial</a>
            <a class="dropdown-item" href="https://server.spycom.io/core/agency/agencydemo.zip" target="_blank" *ngIf="!is_client">Download Agency Website</a>
            <a class="dropdown-item" routerLink="/auth/logout">Log Out</a>
          </div>
      </ul>
    </div>
  </div>
</nav>
