<div class="modal-header">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-4 col-sm-4">
            <h4 class="modal-title">Product Detail</h4>
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-body">


  <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="product_loading">
    <div class="loader-small"></div>
  </div>

  <div class="row" *ngIf="!product_loading">
    <div class="col-md-12 text-center">

      <button type="button" (click)="downloadReviews(productDetail.info.product_id, productDetail.properties.store_info.seller_id)" title="Download All Reviews" class="btn btn-info" [disabled]="downloading_reviews">
        <span *ngIf="!downloading_reviews; else reviewLoading">
          Download Reviews
        </span>
        <ng-template #reviewLoading>
          <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i> Downloading Reviews...
        </ng-template>
      </button>

      <button type="button" title="download All Images Per Product" (click)="downloadImages()" class="btn btn-info" [disabled]="downloading_images">
        <span *ngIf="!downloading_images; else downloadImageLoading">
          Download Images
        </span>
        <ng-template #downloadImageLoading>
          <i class="fas fa-sync-alt fa-spin"></i> Downloading Images...
        </ng-template>
      </button>

      <button type="button" title="Create Fb Ads" class="btn btn-info" (click)="openFbAdBuilderModal()" [disabled]="creating_fb_ad">
        <span *ngIf="!creating_fb_ad; else creating_fb_ad_loading">
          Create FB Ad
        </span>
        <ng-template #creating_fb_ad_loading>
          <i class="fas fa-sync-alt fa-spin"></i> Creating FB Ad...
        </ng-template>
      </button>

      <button type="button" title="Add product to shopify store" class="btn btn-info" (click)="openCreateShopifyModal()" [disabled]="creating_shopify">
        <span *ngIf="!creating_shopify; else shopify_loading">
          Add to Shopify Store
        </span>
        <ng-template #shopify_loading>
          <i class="fas fa-sync-alt fa-spin"></i> Adding to Shopify Store...
        </ng-template>
      </button>

      <button type="button" title="Create Landing Page" class="btn btn-info" (click)="openLandingPageBuilderModal()" [disabled]="creating_landing_page">
        <span *ngIf="!creating_landing_page; else landing_loader">
          Create Landing Page
        </span>
        <ng-template #landing_loader>
          <i class="fas fa-sync-alt fa-spin"></i> Creating Landing Page...
        </ng-template>
      </button>

    </div>
  </div>

  <div class="row" *ngIf="!product_loading">
    <div class="col-md-12">
      <table class="table table-bordered table-striped" *ngIf="productDetail">
        <tr>
          <th>Product ID</th>
          <td>{{ productDetail.info.product_id }}</td>
        </tr>
        <tr>
          <th>Product Name</th>
          <td>{{ productDetail.properties.name }}</td>
        </tr>
        <tr>
          <th>Similar Keywords</th>
          <td>{{ productDetail.properties.meta.keywords }}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{{ productDetail.properties.meta.description }}</td>
        </tr>
        <tr>
          <th>Total Sold</th>
          <td>{{ productDetail.properties.orders_num }}</td>
        </tr>
        <tr>
          <th>Price</th>
          <th>{{ productDetail.properties.pricing.currency }} {{ productDetail.properties.pricing.price }}</th>
        </tr>
        <tr>
          <th>Unit</th>
          <td>{{ productDetail.properties.pricing.unit }}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td><button type="button" class="btn btn-link" (click)="openViewDescriptionModal()">View Description</button> </td>
        </tr>
        <tr>
          <th>Shipping</th>
          <td><button type="button" class="btn btn-link" (click)="openShippingDetailModal()">View Shipping</button> </td>
        </tr>
        <tr>
          <th>Discount Rate</th>
          <td>{{ productDetail.properties.pricing.discount_rate }}</td>
        </tr>
        <tr>
          <th>Bulk price info</th>
          <td>{{ productDetail.properties.pricing.bulk_price_info }}</td>
        </tr>
        <tr>
          <th>Est. unit/month</th>
          <td>{{ productDetail.properties.unit_per_month }}</td>
        </tr>
        <tr>
          <th>Est. cost/month</th>
          <td>{{ productDetail.properties.cost_per_month }}</td>
        </tr>
        <tr>
          <th>Product Rating %</th>
          <td>
            <div class="ratings">
              <div class="empty-stars"></div>
              <div class="full-stars" [ngStyle]="{'width': productDetail.properties.rating.percent + '%'}"></div>
            </div>
            ({{ productDetail.properties.rating.percent_value }})
          </td>
        </tr>
        <tr>
          <th>Number of Reviews</th>
          <td>{{ productDetail.properties.rating.count }}</td>
        </tr>
        <tr>
          <th>Package Size</th>
          <td>{{ productDetail.properties.packaging_details.Package_Size }}</td>
        </tr>
        <tr>
          <th>Package Weight</th>
          <td>{{ productDetail.properties.packaging_details.Package_Weight }}</td>
        </tr>
        <tr>
          <th>Package Unit Type</th>
          <td>{{ productDetail.properties.packaging_details.Unit_Type }}</td>
        </tr>
        <tr>
          <th>Seller Name</th>
          <td>{{ productDetail.properties.store_info.name }}</td>
        </tr>
        <tr>
          <th>Seller Address</th>
          <td>{{ productDetail.properties.store_info.address }}</td>
        </tr>
        <tr>
          <th>Seller ID</th>
          <td>{{ productDetail.properties.store_info.seller_id }}</td>
        </tr>
        <tr>
          <th>Store ID</th>
          <td>{{ productDetail.properties.store_info.store_id }}</td>
        </tr>
        <tr>
          <th>Store Time</th>
          <td>{{ productDetail.properties.store_info.store_time }}</td>
        </tr>
        <tr>
          <th>Attributes</th>
          <td>
            <ng-container *ngIf="productDetail.properties.attributes">
              <div class="attributes" *ngFor="let at of productDetail.properties.attributes | keyvalue">
                <span class="attribute-type">{{ at.key }}</span>
                <span class="attribute-items" *ngFor="let v of at.value">
                        <span class="attribute-items color-pallet" *ngIf="at.key === 'color'; else default_attr" [style]="{'background-color': v}"></span>
                        <ng-template #default_attr>
                          <span class="attribute-items">{{ v }}</span>
                        </ng-template>
                    </span>
              </div>
            </ng-container>
          </td>
        </tr>
      </table>
    </div>
  </div>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>
