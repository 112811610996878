import { Component, OnInit } from '@angular/core';
import {FbAdsBuilderService} from '../fb-ads-builder.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {FbAdData} from '../fb-ads.model';
import {AssignToClientModalComponent} from '../../client-account/assign-to-client-modal/assign-to-client-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../auth/auth.service';
import {ClientActionsService} from '../../client-account/client-actions.service';

@Component({
  selector: 'app-list-fb-ads',
  templateUrl: './list-fb-ads.component.html',
  styleUrls: ['./list-fb-ads.component.css']
})
export class ListFbAdsComponent implements OnInit {

  fbAds: Array<FbAdData>;

  fbAdsDeletingList: Array<number> = [];
  fbAdsDownloadingList: Array<number> = [];

  is_client = false;

  constructor(
    private fbAdService: FbAdsBuilderService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private authService: AuthService,
    private clientActions: ClientActionsService
  ) { }

  ngOnInit() {
    // If user is client
    this.is_client = this.authService.isClient();

    this._getFbAds();
  }

  private _getFbAds() {
    if (this.is_client) {
      // Get for client
      this.clientActions.listFbAds().subscribe(
        res => {
          this.fbAds = res.data;
        }
      );
    } else {
      // Get for user
      this.fbAdService.list().subscribe(
        res => {
          this.fbAds = res.data;
        }
      );
    }
  }

  /**
   * Delete ad
   * @param id
   */
  deleteFbAd(id: number) {

    this.fbAdsDeletingList.push(id);

    this.fbAdService.delete(id).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Fb Ad Builder'
          });
        } else {
          this.notificationService.success({
            message: 'FB Ad deleted successfully',
            title: 'FB Ad deleted'
          });
          this._getFbAds();
        }

        // Remove product item index from the list
        const index = this.fbAdsDeletingList.indexOf(id, 0);
        if (index > -1) {
          this.fbAdsDeletingList.splice(index, 1);
        }
      }
    );
  }

  /**
   * Download fb ads
   * @param id
   */
  downloadAd(id: number) {
    this.fbAdsDownloadingList.push(id);

    this.fbAdService.download(id).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        // console.log(res);
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Generating Ad'
          });
        } else {
          if (res.url) {
            const file_url = res.url;
            // window.open(file_url);
            const file_name = file_url.split('/').pop();
            const a = document.createElement('a');
            a.href = file_url;
            a.download = file_name;
            document.body.appendChild(a);
            a.click();
          }
          this.notificationService.success({
            message: 'FB Ad downloaded successfully',
            title: 'FB Ad downloaded'
          });

        }

        // Remove product item index from the list
        const index = this.fbAdsDownloadingList.indexOf(id, 0);
        if (index > -1) {
          this.fbAdsDownloadingList.splice(index, 1);
        }
      }
    );
  }

  /**
   * Check if id is in the deleting list
   * @param id
   */
  ifDeleting(id: number) {
    const index = this.fbAdsDeletingList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Check if downloading
   * @param id
   */
  ifDownloading(id: number) {
    const index = this.fbAdsDownloadingList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Assign item to the client account
   * @param id
   */
  assignToClient(id: number) {
    const assignToClient = this.modalService.open(AssignToClientModalComponent, {size: 'lg'});
    assignToClient.componentInstance.itemId = id;
    assignToClient.componentInstance.type = 'fb_ads';
    assignToClient.result.then(() => {}, () => {});
  }
}
