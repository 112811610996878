import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../components/notification-service/notification.service';
import {ShopifyStoreBuilderService} from '../shopify-store-builder.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ShopifyCredentialData} from '../shopify-store-builder.model';

@Component({
  selector: 'app-create-shopify-store-modal',
  templateUrl: './create-shopify-store-modal.component.html',
  styleUrls: ['./create-shopify-store-modal.component.css']
})
export class CreateShopifyStoreModalComponent implements OnInit {

  @Input() product;
  @Input() searchTerm: string;

  storeLoaded = false;

  shopifyStoreBuilderForm: FormGroup;
  shopifyStoreBuilderFormSubmitted = false;

  shopifyStoreCredentials: Array<ShopifyCredentialData>;

  constructor(
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
    private shopifyStoreBuilderService: ShopifyStoreBuilderService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    /**
     * Initialize form
     */
    this.shopifyStoreBuilderForm = this.formBuilder.group({
      shopify_credential_id: new FormControl('', [
        Validators.required
      ]),
      product_title: new FormControl('', [
        Validators.required
      ]),
      product_description: new FormControl(),
      product_vendor: new FormControl(),
      product_type: new FormControl(),
      product_tags: new FormControl(),
      product_price: new FormControl(),
      product_image: new FormControl(),
    });

    this._getShopifyStores();

    this._setFormFields();
  }

  /**
   * Get list of shopify stores added by user
   * @private
   */
  private _getShopifyStores() {
    this.shopifyStoreBuilderService.getCredentials().subscribe(
      res => {
        this.shopifyStoreCredentials = res.data;

        this.storeLoaded = true;
      }
    );
  }

  /**
   * Handle form submit
   */
  onSubmit() {
    this.shopifyStoreBuilderFormSubmitted = true;

    if (this.shopifyStoreBuilderForm.invalid) {
      this.shopifyStoreBuilderFormSubmitted = false;
      return;
    }

    const data = this.shopifyStoreBuilderForm.value;

    this.shopifyStoreBuilderService.addProduct(data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Add shopify product'
          });
        } else {
          this.notificationService.success({
            message: 'Shopify product added successfully',
            title: 'Shopify product added'
          });

          this.activeModal.close();
        }
      }
    );
  }

  private _setFormFields() {
    this.shopifyStoreBuilderForm.setValue({
      shopify_credential_id: '',
      product_title: this.product ? this.product.info.title : '',
      product_description: this.product ? this.product.properties.meta.description : '',
      product_vendor: this.product ? this.product.properties.store_info.name : '',
      product_type: this.searchTerm ? this.searchTerm : '',
      product_tags: this.product ? this.product.properties.meta.keywords : '',
      product_price: this.product ? this.product.properties.pricing.price : '',
      product_image: this.product ? this.product.info.images.main : ''
    });
  }
}
