import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {Observable} from 'rxjs';
import {PopularProductsResponse} from './popular-products.model';

@Injectable({
  providedIn: 'root'
})
export class PopularProductsService {

  private url = 'popular_products/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * List all popular products
   */
  list(): Observable<PopularProductsResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get(url);
  }
}
