import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { DetailPrivacyComponent } from './detail-privacy/detail-privacy.component';
import {RouterModule} from '@angular/router';
import { CookieComponent } from './cookie/cookie.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    PageNotFoundComponent,
    PrivacyComponent,
    TermsComponent,
    DetailPrivacyComponent,
    CookieComponent
  ]
})
export class PagesModule { }
