<div class="row">
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-header card-header-warning card-header-icon">
        <div class="card-icon">
          <i class="material-icons">supervised_user_circle</i>
        </div>
        <p class="card-category">Total Fb Ads Generated</p>
        <h3 class="card-title text-white">
          <span *ngIf="countData; else c_t_zero">
            {{ countData.fb_ads }}
          </span>
          <ng-template #c_t_zero>
            0
          </ng-template>
          <small></small>
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons">date_range</i> Till date
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-header card-header-success card-header-icon">
        <div class="card-icon">
          <i class="material-icons">pages</i>
        </div>
        <p class="card-category">Total Landing Page Generated</p>
        <h3 class="card-title text-white">
          <span *ngIf="countData; else c_m_zero">
            {{ countData.landing_page }}
          </span>
          <ng-template #c_m_zero>0</ng-template>
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons">date_range</i> Till date
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-header card-header-danger card-header-icon">
        <div class="card-icon">
          <i class="material-icons">
            local_grocery_store
          </i>
        </div>
        <p class="card-category">Shopify Products added</p>
        <h3 class="card-title text-white">
          <span *ngIf="countData; else c_a_zero">{{ countData.shopify_products }}</span>
          <ng-template #c_a_zero>0</ng-template>
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons">date_range</i> Till date
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 text-center">
    <a class="btn btn-white btn-dashboard" href="https://abhidwivedi.clickfunnels.com/spycom-10ecomstrategies" target="_blank">Free Training Video: 100 Ecommerce Strategies to make $2000/Day</a>
  </div>
</div>


<div class="row" *ngIf="!is_client">
  <div class="col-lg-6 col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">My Recent Searches</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
        <table class="table table-striped">
          <thead class="text-warning">
          <th>Keyword</th>
          <th>Search count</th>
          <th>Date</th>
          </thead>
          <tbody>
          <tr *ngFor="let search of myRecentSearches">
            <td>{{ search.keyword }}</td>
            <td>{{ search.search_count }}</td>
            <td>{{ search.created | date:'medium' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
  <div class="col-lg-6 col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Top Searches</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
        <table class="table table-striped">
          <thead class="text-warning">
          <th>Keyword</th>
          <th>Search count</th>
          <th>Searched on</th>
          </thead>
          <tbody>
          <tr *ngFor="let search of allTopSearches">
            <td>{{ search.keyword }}</td>
            <td>{{ search.search_count }}</td>
            <td>{{ randomDate() | date:'medium' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>
  <div class="col-lg-12 col-md-12">
    <div class="card">
      <div class="card-header card-header-info">
        <h4 class="card-title">Popular Product</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-striped" [mfData]="popularProducts" #mf="mfDataTable" [mfRowsOnPage]="20" *ngIf="!loadingPopularProducts">
            <thead>
            <tr>
              <th class="text-center"><i class="fa fa-bars" aria-hidden="true"></i></th>
              <th>Action</th>
              <th container="body" ngbPopover="Name of the Product" triggers="mouseenter:mouseleave" >
                <mfDefaultSorter by="product_name"> Product Name </mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Category of the product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="category_name"> Category </mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Category ID" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="category_number">Category ID</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Original Price of the product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="original_price"> Original Price</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Sale price of the product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="sale_price">Sale Price</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Commission Rate" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="commission_rate">Commission Rate</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Date when product will be out of stock" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="out_of_stock_date">Out of stock date</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Discount rate on product price" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="discount">Discount rate</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Click link of the product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="click_link">Click Link</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Orders placed for this product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="orders">Orders</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="Hot Product" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="is_hot">Hot Product</mfDefaultSorter>
              </th>

              <th container="body" ngbPopover="Start Time" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="start_time">Start Time</mfDefaultSorter>
              </th>
              <th container="body" ngbPopover="End Time" triggers="mouseenter:mouseleave">
                <mfDefaultSorter by="end_time">End Time</mfDefaultSorter>
              </th>

            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let p of mf.data; let id = index">

              <td>
                {{ id+1 }}
              </td>
              <td class="text-center">
                <div class="ads-option">
                  <a (click)="openProductDetailModal(p.product_url)">
                    <i class="fa fa-info-circle fa-2x mouse-pointer" aria-hidden="true"></i>
                  </a>
                </div>
              </td>

              <td class="text-one" appStickyPopover [popoverTitle]="popTitle" [autoClose]="true" data-placement="right">
                <ng-template #popTitle>
                  <a href="{{ p.click_link }}" target="_blank">{{ p.product_name }}</a> <br>
                  <hr class="pop-line">
                  <img src="{{ p.product_image_url }}" width="200" alt="{{ p.product_name }}" />
                </ng-template>
                <ng-template #popContent>
                  <img src="{{ p.product_image_url }}" width="200" alt="{{ p.product_name }}" />
                </ng-template>
                <a>
                  {{ p.product_name }}
                </a>
              </td>

              <td>
                {{ p.category_name }}
              </td>

              <td>
                {{ p.category_number }}
              </td>

              <td>
                {{ p.original_price }}
              </td>

              <td>
                {{ p.sale_price }}
              </td>

              <td class="text-center">
                  <span class="badge badge-success" *ngIf="p.commission_rate">
                    {{ p.commission_rate }}
                  </span>
              </td>

              <td>
                  {{ p.out_of_stock_date }}
              </td>

              <td>
                  <span class="badge badge-success" *ngIf="p.discount">
                    {{ p.discount }}
                  </span>
              </td>

              <td>
                  <a href="{{ p.click_link }}" target="_blank">
                    {{ p.click_link }}
                  </a>
              </td>

              <td>
                  {{ p.orders }}
              </td>

              <td class="text-center">
                <span class="badge badge-success" *ngIf="p.is_hot === 'y' || p.is_hot === 'Y'">
                  Yes
                </span>
                <span class="badge badge-danger" *ngIf="p.is_hot === 'n' || p.is_hot === 'N'">
                  No
                </span>
              </td>

              <td>
                  {{ p.start_time }}
              </td>

              <td>
                  {{ p.end_time }}
              </td>

            </tr>

            <tr>
              <td colspan="15">
                <mfBootstrapPaginator></mfBootstrapPaginator>
              </td>
            </tr>
            </tbody>
          </table>


          <div class="col-md-12 m-t-set text-center shipping-loading" *ngIf="loadingPopularProducts">
            <div class="loader-small"></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
