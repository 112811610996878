import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';

import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {AuthGuardService} from './auth/auth-guard.service';
import {PagesLayoutComponent} from './layouts/pages-layout/pages-layout.component';
import {ErrorsComponent} from './errors/errors-component/errors.component';

const routes: Routes = [
  { path: 'auth', component: AuthLayoutComponent, children: [
      {path: '', loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'}
    ]},
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: '', component: AdminLayoutComponent, children: [
      {path: '', loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'}
    ],
  canActivate: [AuthGuardService]
  },
  { path: 'error', component: ErrorsComponent },
  { path: 'pages', component: PagesLayoutComponent, children: [
      {path: '', loadChildren: './layouts/pages-layout/pages-layout.module#PagesLayoutModule'}
    ]},
  { path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
