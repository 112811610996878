import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ScraperService} from '../scraper.service';
import {SearchScraperResponseData} from '../scraper.model';
import {Router} from '@angular/router';
import {CountryDataService} from '../../country-data.service';
import {SearchHistoriesService} from '../search-histories.service';
import {NotificationService} from '../../components/notification-service/notification.service';
import {RollbarErrorHandlerService} from '../../errors/rollbar-error-handler.service';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import * as StackTraceParser from 'error-stack-parser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductShippingModalComponent} from '../product-shipping-modal/product-shipping-modal.component';
import {FavouriteProductsService} from '../../favourite-products/favourite-products.service';
import {CreateLandingPageModalComponent} from '../../landing-page-builder/create-landing-page-modal/create-landing-page-modal.component';
import {ScraperUtilsService} from '../scraper-utils.service';
import {CreateFbAdModalComponent} from '../../fb-ads-builder/create-fb-ad-modal/create-fb-ad-modal.component';
import {
  CreateShopifyStoreModalComponent
} from '../../shopify-store-builder/create-shopify-store-modal/create-shopify-store-modal.component';
import {ProductDescriptionModalComponent} from '../product-description-modal/product-description-modal.component';
import {FilterScraperModalComponent} from '../filter-scraper-modal/filter-scraper-modal.component';
import {GlobalProfitPercentageModalComponent} from '../global-profit-percentage-modal/global-profit-percentage-modal.component';
import {FilterService} from '../filter.service';
import {SavedSearchesService} from '../../saved-searches/saved-searches.service';
import {MembershipCheckService} from '../../account/membership-check.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-scraper',
  templateUrl: './scraper.component.html',
  styleUrls: ['./scraper.component.css']
})
export class ScraperComponent implements OnInit {

  scrapeForm: FormGroup;
  exportForm: FormGroup;
  exportFormSubmitted = false;

  submitted = false;
  pageInfo: any;
  products = [];
  loading_content = false;
  // processedItems: Array<ScrapeProductData> = [];
  processedItems: Array<any> = [];
  totalProductItems: Array<any> = [];

  filterData: any;

  keyword: string;
  page_number = 1;

  /**
   * Store the index of the products which are refreshing
   */
  refreshingSingleProductIndexList = [];
  downloadImagesIndexList = [];
  downloadReviewsIndexList = [];
  favouriteProductIndexList = [];

  nextPageLoading = false;
  totalSoldSum = 0;
  averageTotalSold = 0;
  totalReviews = 0;
  averageTotalReviews = 0;
  global_profit_percentage: number;
  saving_search = false;
  showExport = false;

  /**
   * ScraperComponent Constructor
   *
   * @constructor
   * @param formBuilder
   * @param scraperService
   * @param favouriteProductService
   * @param router
   * @param countryDataService
   * @param searchHistoryService
   * @param notificationService
   * @param rollbarService
   * @param injector
   * @param modalService
   * @param scraperUtilsService
   * @param filterService
   * @param savedSearchService
   * @param membershipCheckService
   * @param authService
   * @param location
   */
  constructor(
    private formBuilder: FormBuilder,
    private scraperService: ScraperService,
    private favouriteProductService: FavouriteProductsService,
    private router: Router,
    private countryDataService: CountryDataService,
    private searchHistoryService: SearchHistoriesService,
    private notificationService: NotificationService,
    private rollbarService: RollbarErrorHandlerService,
    private injector: Injector,
    private modalService: NgbModal,
    private scraperUtilsService: ScraperUtilsService,
    private filterService: FilterService,
    private savedSearchService: SavedSearchesService,
    private membershipCheckService: MembershipCheckService,
    private authService: AuthService,
    private location: Location
  ) { }

  ngOnInit() {

    // If user is client, redirect back
    if (this.authService.isClient()) {
      this.notificationService.error({
        title: 'Search Error',
        message: 'You can not perform search on client account'
      });
      this.location.back();
    }

    /**
     * Initialize search form
     */
    this.scrapeForm = this.formBuilder.group({
      keyword: new FormControl('', [
        Validators.required
      ])
    });

    /**
     * Set default keyword to the keyword input field
     */
    this.scrapeForm.setValue({
      keyword: ''
    });

    /**
     * Initialize export form
     */
    this.exportForm = this.formBuilder.group({
      product_index: new FormControl([])
    });
  }


  /**
   * Handle form submission
   */
  onSubmit() {

    this.submitted = true;
    this.loading_content = true;

    if (this.scrapeForm.invalid) {
      this.submitted = false;
      this.loading_content = false;
      return;
    }

    const keyword = this.scrapeForm.value.keyword;

    this.membershipCheckService.search().subscribe(
      res => {
        if (!res.status) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Membership Error'
          });
          this.submitted = false;
          this.loading_content = false;
          return;
        } else {
          this._initiateSearch(keyword);
        }
      }
    );
  }

  /***************************************
   * Initiate searching of keyword
   * @param keyword
   * @private
   ***************************************/
  private _initiateSearch(keyword: string) {

    this.submitted = true;
    this.loading_content = true;
    /**
     * Cancel all pending requests
     */

    /**
     * reset values
     */
    this.products.length = 0;
    this.processedItems = [];
    this.totalProductItems = [];
    this.pageInfo = null;
    this.totalSoldSum = 0;
    this.averageTotalSold = 0;
    this.totalReviews = 0;
    this.averageTotalReviews = 0;


    this.keyword = keyword;

    // Log the searched keyword
    this.searchHistoryService.add(keyword);

    this.scraperService.search(keyword).subscribe(
      res => {
        this.submitted = false;
        // console.log(res);
        const response: SearchScraperResponseData = this.scraperService.processData(res.contents);

        if (response.products.length < 1) {
          this.notificationService.error({
            message: 'We could not retrieve any product for the given keyword. Try searching different keyword or try later',
            title: 'No products found'
          });

          // reset button
          this.loading_content = false;
          this.nextPageLoading = false;

          /**
           * Prepare error to send to rollback
           */
          const errorToSend = this._addContextInfo(
            'No products found for keyword: ' + keyword,
            res,
            response
          );

          // Log to rollbarService
          this.rollbarService.post(errorToSend);

          return false;
        }

        this.pageInfo = response.meta;
        // this.products = response.products;
        // console.log(this.pageInfo);

        // console.log(response.products);
        // console.log(this.products);

        this._processItem(response.products);

        // console.log(this.pageInfo);

        // console.log('products loaded');
        // this.loading_content = false;
      }, error => {
        console.log('error', error);
        this.loading_content = false;
      }
    );
  }


  nextPageSearch() {
    this.nextPageLoading = true;
    this.loading_content = true;

    this.page_number += 1;

    this.scraperService.search(this.keyword, this.page_number).subscribe(
      res => {
        const response: SearchScraperResponseData = this.scraperService.processData(res.contents);

        if (response.products.length < 1) {
          this.notificationService.error({
            message: 'We could not retrieve any product for the given keyword. Try searching different keyword or try later',
            title: 'No products found'
          });

          // reset button
          this.loading_content = false;
          this.nextPageLoading = false;

          /**
           * Prepare error to send to rollback
           */
          const errorToSend = this._addContextInfo(
            'No products found for keyword: ' + this.keyword,
            res,
            response
          );

          // Log to rollbarService
          this.rollbarService.post(errorToSend);

          return false;
        }

        this.products = response.products;

        // console.log(this.products);

        this._processItem(response.products);

        // console.log(this.pageInfo);

        // console.log('products loaded');
        // this.loading_content = false;
        this.nextPageLoading = false;
      }, error => {
        console.log('error', error);
        this.loading_content = false;
        this.nextPageLoading = false;
      }
    );
  }




  /**
   * Process each item from products
   *
   * @private
   */
  private _processItem($products: Array<any>) {

    // push products to this.products
    this.products = this.products.concat($products);

    // console.log(this.products);

    $products.forEach(async (e) => {
      // console.log(e.url);
      const res = await this.scraperService.scrapSingle(e.url).toPromise();
      if (res.status.http_code === 200) {
        const properties = this.scraperService.processSingleProduct(res.contents);

        const p_item = {};
        p_item['info'] = e;
        p_item['properties'] = properties;

        p_item['info']['product_id'] = this.scraperService.getProductIdFromUrl(e.url);

        p_item['properties']['shipping_new'] = '';

        this.totalProductItems.push(p_item);
        // this.processedItems.push(p_item);
        this._applyFilter();
      }

      if (this.products.length === this.processedItems.length) {
        this.loading_content = false;
      }
    });

    // console.log(this.processedItems);
    // console.log(this.products.length);
  }

  // private _processItem() {
  //   // this.processedItems = this.products;
  //
  //   for (let i = 0; i < this.products.length; i++) {
  //
  //     this.scraperService.scrapSingle(this.products[i].url).subscribe(
  //       res => {
  //         if (res.status.http_code === 200) {
  //           const properties = this.scraperService.processSingleProduct(res.contents);
  //
  //           const p_item = {};
  //           p_item['info'] = this.products[i];
  //           p_item['properties'] = properties;
  //
  //           this.processedItems.splice(p_item, 0, i);
  //
  //
  //           console.log(this.processedItems);
  //
  //         }
  //         console.log(res);
  //       }
  //     );
  //
  //
  //     break;
  //
  //   }
  //
  //
  //
  //
  //   console.log(this.products.length);
  // }

  /**
   * Refresh single product
   * @param product_index
   */
  refreshSingleProduct(product_index: number) {
    this.refreshingSingleProductIndexList.push(product_index);

    const item = this.processedItems[product_index];
    this.scraperService.scrapSingle(item.info.url).subscribe(
      res => {
        if (res.status.http_code === 200) {
          const properties = this.scraperService.processSingleProduct(res.contents);

          const p_item = {};
          p_item['info'] = item.info;
          p_item['properties'] = properties;

          p_item['info']['product_id'] = this.scraperService.getProductIdFromUrl(item.info.url);

          p_item['properties']['shipping_new'] = '';

          this.processedItems[product_index] = p_item;

          // Remove product item index from the list
          const index = this.refreshingSingleProductIndexList.indexOf(product_index, 0);
          if (index > -1) {
            this.refreshingSingleProductIndexList.splice(index, 1);
          }

        }
      }
    );
  }


  /**
   * Get total products length
   */
  getProductsLength() {
    if (this.products) {
      return this.products.length;
    } else {
      return 0;
    }
  }

  /**
   * Add extra context data with the error message to send to server
   *
   * @param title
   * @param error
   * @param response
   * @private
   */
  private _addContextInfo(title, error, response) {
    // You can include context details here (usually coming from other services: UserService...)

    console.log(error);
    const name = title || null;
    const appId = 'vega6: SpyCom-Angular';
    const user = this.authService.getAuthUser().email;
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const server_response = error.error || null;
    const stack = error instanceof HttpErrorResponse ? null : JSON.stringify(error);

    const response_data = response || null;

    return {name, appId, user, time, id, location, url, status, message, server_response, response_data, stack};
  }

  /**
   * Download product images
   * @param product_index
   */
  downloadImages(product_index: number) {
    // add product_index to the index list
    this.downloadImagesIndexList.push(product_index);

    const images = this.processedItems[product_index].properties.images;
    this.scraperService.downloadImages(images).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Download Images'
          });
        } else {
          const img_zip_url = res.data.url;
          // window.open(img_zip_url);

          const img_name = img_zip_url.split('/').pop();
          const a = document.createElement('a');
          a.href = img_zip_url;
          a.download = img_name;
          document.body.appendChild(a);
          a.click();
        }


        // Remove product item index from the list
        const index = this.downloadImagesIndexList.indexOf(product_index, 0);
        if (index > -1) {
          this.downloadImagesIndexList.splice(index, 1);
        }
      }
    );
  }

  /**
   * Download Reviews
   * @param id
   * @param product_id
   * @param seller_id
   */
  downloadReviews(id: number, product_id: string | any, seller_id: string | any) {
    // add index to the index list
    this.downloadReviewsIndexList.push(id);

    this.scraperService.scrapReviews(product_id, seller_id).subscribe(
      res => {
        // console.log(res.contents);
        const feeds = this.scraperService.scrapReviewItems(res.contents);

        this.scraperService.downloadReviews(feeds).subscribe(
          res2 => {
            /**
             * Notify user of the changed email successfully
             */
            if (res2.status === false) {
              const messages = res2.error.errors.message;
              this.notificationService.error({
                message: messages,
                title: 'Error: Download Reviews'
              });
            } else {
              const file_url = res2.data.url;
              // window.open(file_url);
              const file_name = file_url.split('/').pop();
              const a = document.createElement('a');
              a.href = file_url;
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
            }
          }
        );

        // Remove product item index from the list
        const index = this.downloadReviewsIndexList.indexOf(id, 0);
        if (index > -1) {
          this.downloadReviewsIndexList.splice(index, 1);
        }

      }
    );
  }

  /**
   * Add product to favourite
   * @param id
   */
  addToFavourite(id) {
    // add index to add to favourite index list
    this.favouriteProductIndexList.push(id);

    const item = this.processedItems[id];
    this.favouriteProductService.add(item).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Add Favourite'
          });
        } else {
          this.notificationService.success({
            message: 'Product added to favourite list',
            title: 'Product marked favourite'
          });
        }




        // Remove product item index from the list
        const index = this.favouriteProductIndexList.indexOf(id, 0);
        if (index > -1) {
          this.favouriteProductIndexList.splice(index, 1);
        }
      }
    );
  }

  /**
   * Get profit percent calculated
   * @param product_index
   * @param value
   */
  getProfitPercent(product_index: number, value) {
    const rsp = this.scraperUtilsService.calculateRecommendedSellingPrice(value, this.processedItems[product_index]);
    const estimate_revenue = this.scraperUtilsService.calculateEstimateRevenue(rsp, this.processedItems[product_index]);
    this.processedItems[product_index].properties.custom.profit_percentage = value;
    this.processedItems[product_index].properties.custom.recommended_selling_price = rsp;
    this.processedItems[product_index].properties.custom.estimate_revenue = estimate_revenue;
  }

  /**
   * Check if item already in query to refresh
   * @param product_index
   */
  ifRefreshInQueue(product_index: number) {
    const index = this.refreshingSingleProductIndexList.indexOf(product_index, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadReviewsInQueue(id) {
    const index = this.downloadReviewsIndexList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in the queue
   * @param id
   */
  ifDownloadImagesInQueue(id) {
    const index = this.downloadImagesIndexList.indexOf(id, 0);
    return index > -1;
  }

  /**
   * Check if passed index is in add to favourite queue
   * @param id
   */
  ifAddToFavouriteInQueue(id) {
    const index = this.favouriteProductIndexList.indexOf(id, 0);
    return index > -1;
  }


  /**
   * Open shipping detail modal
   * @param product_id
   */
  openShippingDetailModal(product_id: number) {
    const shippingDetailModal = this.modalService.open(ProductShippingModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    shippingDetailModal.componentInstance.product_id = product_id;
    shippingDetailModal.result.then(() => {}, () => {});
  }


  /**
   * Open create landing page builder modal
   * @param product_index
   */
  openLandingPageBuilderModal(product_index: number) {
    const landingPageBuilderModal = this.modalService.open(CreateLandingPageModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    landingPageBuilderModal.componentInstance.product = this.processedItems[product_index];
    landingPageBuilderModal.result.then(() => {}, () => {});
  }

  /**
   * Open fbAdBuilderModal
   * @param id
   */
  openFbAdBuilderModal(id) {
    const fbAdBuilderModal = this.modalService.open(CreateFbAdModalComponent, {size: 'lg', windowClass: 'modal-xl', backdrop: 'static'});
    fbAdBuilderModal.componentInstance.product = this.processedItems[id];
    fbAdBuilderModal.result.then(() => {}, () => {});
  }

  openCreateShopifyModal(id) {
    const createShopifyStore = this.modalService.open(CreateShopifyStoreModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    createShopifyStore.componentInstance.product = this.processedItems[id];
    createShopifyStore.componentInstance.searchTerm = this.keyword;
    createShopifyStore.result.then(() => {}, () => {});
  }

  /**
   * Open view description modal
   * @param id
   */
  openViewDescriptionModal(id) {
    const product = this.processedItems[id];
    const viewDescriptionModal = this.modalService.open(ProductDescriptionModalComponent, {size: 'lg', windowClass: 'modal-xl'});
    viewDescriptionModal.componentInstance.product = product;
    viewDescriptionModal.result.then(() => {}, () => {});
  }

  /**
   * Open filter modal
   */
  openFilterModal() {
    // console.log(this.totalProductItems);
    const filterModal = this.modalService.open(FilterScraperModalComponent, {size: 'lg'});
    // console.log(this.filterData);
    filterModal.componentInstance.productData = this.totalProductItems;
    filterModal.componentInstance.filterData = this.filterData;
    filterModal.result.then(
      res => {
        this.filterData = res;
        this._applyFilter();
      }, () => {}
    );
  }

  /**
   * Filter data
   * @private
   */
  private _applyFilter() {

    if (!this.filterData) {
      this.processedItems = this.totalProductItems;

      this._calculateAverageMonthlySales(this.totalProductItems);
      this._calculateAverageReviews(this.totalProductItems);

    } else {

      /**
       * Apply filter on the total items
       */
      const product_filtered = this.filterService.filter(this.filterData, this.totalProductItems);

      this._calculateAverageMonthlySales(product_filtered);
      this._calculateAverageReviews(product_filtered);
      this.processedItems = product_filtered;

    }
    this.getFilterDataFormatted();
  }

  /**
   * Process keywords
   * @param keywords
   */
  processedKeywords(keywords: string) {
    return this.scraperUtilsService.processedKeywords(keywords);
  }

  /**
   * Format to human readable string
   * @param key
   */
  formatToReadableString(key: string) {
    return this.scraperUtilsService.formatToReadableString(key);
  }

  /**
   * Handle export submit form
   */
  onSubmitExport() {
    this.exportFormSubmitted = true;
    // console.log(this.exportForm.value);
    this.exportForm.value.product_index
      .map((v, i) => v ? this.processedItems[i].value : null)
      .filter(v => v !== null);
    // console.log(selectedOrderValue);
  }

  /**
   * Calculate average monthly sales of the displayed products.
   * Assuming sale data for 6 months
   * @param product_filtered
   * @private
   */
  private _calculateAverageMonthlySales(product_filtered: Array<any>) {
    // console.log(product_filtered);
    let sum = 0;
    let average = 0;
    for (const item in product_filtered) {
      if (product_filtered.hasOwnProperty(item)) {
        sum += parseInt(product_filtered[item].properties.orders_num, 10);
        average = Math.floor(sum / 6);
      }
    }
    if (sum > this.totalSoldSum) {
      this.totalSoldSum = sum;
    }
    if (average > this.averageTotalSold) {
      this.averageTotalSold = average;
    }
  }

  /**
   * Calculate average reviews
   * Assuming review data for 6 months
   * @param product_filtered
   * @private
   */
  private _calculateAverageReviews(product_filtered) {
    let sum = 0;
    let average = 0;
    for (const item in product_filtered) {
      if (product_filtered.hasOwnProperty(item)) {
        sum += parseInt(product_filtered[item].properties.rating.count, 10);
        average = Math.floor(sum / 6);
      }
    }
    if (sum > this.totalReviews) {
      this.totalReviews = sum;
    }
    if (average > this.averageTotalReviews) {
      this.averageTotalReviews = average;
    }
  }

  /**
   * Open global profit percentage modal
   */
  openGlobalProfitPercentageModal() {
    const modal = this.modalService.open(GlobalProfitPercentageModalComponent, {centered: true});
    modal.componentInstance.global_profit_percentage = this.global_profit_percentage;
    modal.result.then(res => {
      this.global_profit_percentage = res;
      this._calculateRecommendedSellingPrice();
    }, () => {});
  }

  /**
   * Calculate recommended selling price based on global profit percentage
   * @private
   */
  private _calculateRecommendedSellingPrice() {
    /**
     * Assign profit percentage and calculate RSP for each item
     */
    // for (const item in this.processedItems) {
    //   if (this.processedItems.hasOwnProperty(item)) {
    //     this.getProfitPercent(parseInt(item, 10), this.global_profit_percentage);
    //   }
    // }

    for (const item of this.totalProductItems) {
      const rsp = this.scraperUtilsService.calculateRecommendedSellingPrice(this.global_profit_percentage, item);
      const estimate_revenue = this.scraperUtilsService.calculateEstimateRevenue(rsp, item);
      item.properties.custom.recommended_selling_price = rsp;
      item.properties.custom.profit_percentage = this.global_profit_percentage;
      item.properties.custom.estimate_revenue = estimate_revenue;
    }

    this._applyFilter();

  }

  /**
   * Initiate new search on click of keyword
   * @param key
   */
  searchKeyword(key: string) {
    /**
     * Check membership
     */
    this.membershipCheckService.search().subscribe(
      res => {
        if (!res.status) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Membership Error'
          });
          this.submitted = false;
          this.loading_content = false;
          return;
        } else {
          this._initiateSearch(key);
        }
      }
    );
  }

  /**
   * Get filter data formatted by combining min-max under single key
   */
  getFilterDataFormatted() {
    const filter_dict = {};
    if (this.filterData) {
      for (const filter in this.filterData) {
        if (this.filterData.hasOwnProperty(filter)) {

          // add ratings who has min and max pair
          if (filter.includes('_min') || filter.includes('_max')) {
            const filter_dict_key = filter.replace('_min', '').replace('_max', '');
            if (!filter_dict.hasOwnProperty(filter_dict_key)) {
              filter_dict[filter_dict_key] = {
                'min': 0,
                'max': 0
              };
            }
            if (filter.includes('_min')) {
              filter_dict[filter_dict_key]['min'] = this.filterData[filter];
            }
            if (filter.includes('_max')) {
              filter_dict[filter_dict_key]['max'] = this.filterData[filter];
            }
          }

          // add star rating
          if (filter.includes('star_count')) {
            const filter_dict_key = 'rating';
            if (!filter_dict.hasOwnProperty(filter_dict_key)) {
              filter_dict[filter_dict_key] = 0;
            }
            filter_dict[filter_dict_key] = this.filterData[filter];
          }

        }
      }
    }
    return filter_dict;
  }

  /**
   * Saved searched data
   */
  saveSearchResults() {
    this.saving_search = true;
    const json_data = JSON.stringify(this.totalProductItems);
    this.savedSearchService.add(this.keyword, json_data).subscribe(
      res => {
        /**
         * Notify user of the changed email successfully
         */
        if (res.status === false) {
          const messages = res.error.errors.message;
          this.notificationService.error({
            message: messages,
            title: 'Error: Saved Search'
          });
        } else {
          this.notificationService.success({
            message: 'Search result saved',
            title: 'Search Saved'
          });
        }
        this.saving_search = false;
      }
    );
  }
}
