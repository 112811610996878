import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScraperUtilsService {

  constructor() { }

  /**
   * Calculate cost per month assuming records for last 6 months
   * @param orders_num
   * @param price
   */
  getCostPerMonth(orders_num, price) {

    let cost_per_month = (price * orders_num) / 6;
    cost_per_month = Math.round(cost_per_month);

    return cost_per_month;
  }

  /**
   * Get unit per month
   * @param orders_num
   */
  getUnitPerMonth(orders_num: any) {

    if (orders_num <= 0) {
      return 0;
    }

    let unit_per_month = orders_num / 6;
    unit_per_month = Math.round(unit_per_month);

    return unit_per_month;
  }

  /**
   * Get profit percent
   * @param value
   * @param content
   */
  calculateRecommendedSellingPrice(value, content: any) {
    if (!value) {
      return 0;
    }

    // return value;

    let price = content.properties.pricing.price;
    let orders = content.properties.orders_num;

    price = parseFloat(price);
    orders = parseFloat(orders);

    if (isNaN(price)) {
      return 0;
    }

    const percent_price = (value / 100) * price;
    const recommended_selling_price = price + percent_price;

    if (!recommended_selling_price || isNaN(recommended_selling_price)) {
      return 0;
    }

    return Math.round(recommended_selling_price * 100) / 100;
  }

  /**
   * Calculate estimate revenue
   * formula: recommended selling price * estimated unit per month
   * @param rsp
   * @param content
   */
  calculateEstimateRevenue(rsp: number, content: any) {
    if (!rsp) {
      return false;
    }

    // return value;

    let estimated_unit_per_month = content.properties.unit_per_month;

    estimated_unit_per_month = parseInt(estimated_unit_per_month, 10);

    if (isNaN(estimated_unit_per_month)) {
      return false;
    }

    const estimated_revenue = rsp * estimated_unit_per_month;

    if (!estimated_revenue || isNaN(estimated_revenue)) {
      return false;
    }

    return Math.round(estimated_revenue);
  }

  /**
   * Format to readable string
   * @param key
   */
  formatToReadableString(key: string) {
    return key.replace('_', ' ');
  }

  /**
   * Separate keywords from , and form an array
   * @param keywords
   */
  processedKeywords(keywords: string) {
    return keywords.split(',');
  }
}
