import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {SearchHistoryCountResponse, SearchHistoryResponse} from './search-history.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchHistoriesService {

  url = 'search_histories/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * Add keyword log to user profile
   * @param keyword
   */
  add(keyword: string): void {
    const url = `${this.resource.url}${this.url}add`;
    const data = {'keyword': keyword};

    this.http.Post(url, data).subscribe(() => {});
  }

  /**
   * Get my recent 10 search histories.
   * @param limit - Optional, number of records to get per request
   */
  myRecent(limit?: number): Observable<SearchHistoryResponse> {
    let url = `${this.resource.url}${this.url}myRecentSearches`;

    if (limit) {
      url = `${url}?limit=${limit}`;
    }

    return this.http.Get<SearchHistoryResponse>(url);
  }

  /**
   * Get my top 10 search histories.
   * @param limit - Optional, number of records to get per request
   */
  myTop(limit?: number): Observable<SearchHistoryResponse> {
    let url = `${this.resource.url}${this.url}myTopSearches`;

    if (limit) {
      url = `${url}?limit=${limit}`;
    }

    return this.http.Get<SearchHistoryResponse>(url);
  }

  /**
   * Get all recent 10 search histories.
   * @param limit - Optional, number of records to get per request
   */
  allRecent(limit?: number): Observable<SearchHistoryResponse> {
    let url = `${this.resource.url}${this.url}allRecentSearches`;

    if (limit) {
      url = `${url}?limit=${limit}`;
    }

    return this.http.Get<SearchHistoryResponse>(url);
  }

  /**
   * Get all top 10 search histories.
   * @param limit - Optional, number of records to get per request
   */
  allTop(limit?: number): Observable<SearchHistoryResponse> {
    let url = `${this.resource.url}${this.url}allTopSearches`;

    if (limit) {
      url = `${url}?limit=${limit}`;
    }

    return this.http.Get<SearchHistoryResponse>(url);
  }

  /**
   * Get count statistics
   */
  count(): Observable<SearchHistoryCountResponse> {
    const url = `${this.resource.url}${this.url}count`;

    return this.http.Get<SearchHistoryCountResponse>(url);
  }
}
