import { Injectable } from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {ClientAccountResponse, ClientAccountSingleResponse} from './client-account.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientAccountService {

  url = 'client_accounts/';

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService
  ) { }

  /**
   * List all client accounts
   */
  list(): Observable<ClientAccountResponse> {
    const url = `${this.resource.url}${this.url}`;

    return this.http.Get<ClientAccountResponse>(url);
  }

  /**
   * Add new client
   * @param data
   */
  add(data: any): any {
    const url = `${this.resource.url}${this.url}add`;

    return this.http.Post(url, data);
  }

  /**
   * Get client
   * @param client_account_id
   */
  get(client_account_id: number): Observable<ClientAccountSingleResponse> {
    const url = `${this.resource.url}${this.url}view/${client_account_id}`;

    return this.http.Get<ClientAccountSingleResponse>(url);
  }

  /**
   * Delete client account
   * @param client_account_id
   */
  delete(client_account_id: number): any {
    const url = `${this.resource.url}${this.url}delete/${client_account_id}`;

    return this.http.Delete(url);
  }
}
