import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScraperComponent } from './scraper/scraper.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ScraperRoutes} from './scraper.routing';
import { ProductShippingModalComponent } from './product-shipping-modal/product-shipping-modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ProductDescriptionModalComponent } from './product-description-modal/product-description-modal.component';
import { FilterScraperModalComponent } from './filter-scraper-modal/filter-scraper-modal.component';
import {Ng5SliderModule} from 'ng5-slider';
import { GlobalProfitPercentageModalComponent } from './global-profit-percentage-modal/global-profit-percentage-modal.component';
import {DataTableModule} from 'angular-6-datatable';
import {SharedModule} from '../shared/shared.module';
import { SellerProductsComponent } from './seller-products/seller-products.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(ScraperRoutes),
    NgbModule,
    Ng5SliderModule,
    DataTableModule,
    SharedModule
  ],
  declarations: [
    ScraperComponent,
    ProductShippingModalComponent,
    ProductDescriptionModalComponent,
    FilterScraperModalComponent,
    GlobalProfitPercentageModalComponent,
    SellerProductsComponent
  ],
  entryComponents: [
    ProductShippingModalComponent,
    ProductDescriptionModalComponent,
    FilterScraperModalComponent,
    GlobalProfitPercentageModalComponent
  ]
})
export class ScraperModule { }
