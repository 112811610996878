<section class="pages sec1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-1 col-md-10">
          <h1 class="sec1head1">Privacy Policy of <strong>SpyCom.com</strong></h1>
          <p class="pad0 sec1para bdr">
            This Application & Website collects some Personal Data from its Users.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-1 col-md-10">
          <h1 class="sec1head2">Personal Data collected for the following purposes and using the following services:</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/user.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Access to third-party accounts</h3>
                <h3 class="sec1head4 mar0">Facebook account access</h3>
                <p class="sec2para">Permissions: About Me, Access Friend Lists, Access Page CTA, Access Requests, App Notifications, Contact email, Custom User Actions, Email, Friend subscriptions, Interests, Likes, List of Friends, Manage Friend Lists, Manage Groups, Manage Pages, Mobile Messaging on behalf of Page, News Feed and Wall, Page Messaging, Photos, Publish as Page, Publish to the Wall, Share, Show List of Managed Pages, Status, Status Updates, Text Messaging, Upload Photos, User subscriptions, User Timeline Posts Access, Video upload, video.watches&nbsp;Action, Videos and Website</p>

                <h3 class="sec1head4 mar0">Stripe account access and Twitter account access</h3>
                <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/bull.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Advertising</h3>
                <h3 class="sec1head4 mar0">AdMob and Facebook Audience Network</h3>
                <p class="sec2para">Personal Data: Cookies, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example) and Usage Data</p>
                <h3 class="sec1head4 mar0">AdKaora and Yahoo App Publishing Advertisingk</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/ana.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Analytics</h3>
                <h3 class="sec1head4 mar0">Google Analytics with anonymized IP, AdEspresso conversion tracking, Facebook Ads conversion tracking, Google Analytics, KISSmetrics, Twitter Ads conversion tracking, Wordpress Stats and Yahoo Advertising conversion tracking</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Backup saving and management</h3>
                <h3 class="sec1head4 mar0">Backup on Dropbox and Backup on Google Drive</h3>
                <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Commercial affiliation</h3>
                <h3 class="sec1head4 mar0">JVZoo & JVShare, Amazon Affiliation, AliExpress Affiliate, ClickBank and Zooplus Affiliate Programme</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/mail.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Contacting the User</h3>
                <h3 class="sec1head4 mar0">Contact form</h3>
                <p class="sec2para">Personal Data: address, city, company name, country, email address, field of activity, first name, gender, last name, phone number, profession, User ID, VAT Number, website and ZIP/Postal code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/comme.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Content commenting</h3>
                <h3 class="sec1head4 mar0">Disqus</h3>
                <p class="sec2para">Personal Data: Cookies, Usage Data and various types of Data as specified in the privacy policy of the service</p>
                <h3 class="sec1head4 mar0">Facebook Comments</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Displaying content from external platforms</h3>
                <h3 class="sec1head4 mar0">GitHub button and social widgets and YouTube video widget without cookies</h3>
                <p class="sec2para">Personal Data: Usage Data</p>
                <h3 class="sec1head4 mar0">Google Fonts</h3>
                <p class="sec2para">Personal Data: Usage Data and various types of Data as specified in the privacy policy of the service</p>
                <h3 class="sec1head4 mar0">Google Site Search, Instagram widget and YouTube video widget</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Handling payments</h3>
                <h3 class="sec1head4 mar0">2Checkout, Amazon Payments, Authorize.Net, PayPal, PayPal Payments Hub, Braintree, Google Wallet, Simplify, Stripe and Zooz</h3>
                <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
                <h3 class="sec1head4 mar0">PayPal Carrier Payments</h3>
                <p class="sec2para">Personal Data: phone number and various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Hosting and backend infrastructure</h3>
                <h3 class="sec1head4 mar0">Google Cloud Storage</h3>
                <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Interaction with data collection platforms and other third parties</h3>
                <h3 class="sec1head4 mar0">GetResponse widget</h3>
                <p class="sec2para">Personal Data: Cookies, email address, first name and Usage Data</p>

              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Interaction with external social networks and platforms</h3>
                <h3 class="sec1head4 mar0">Facebook Like button and social widgets, PayPal button and widgets, Pinterest “Pin it” button and social widgets, Reddit button and widgets, ShareThis, Tumblr sharing button and social widgets and Twitter Tweet button and social widgets</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
                <h3 class="sec1head4 mar0">YouTube button and social widgets</h3>
                <p class="sec2para">Personal Data: Usage Data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Interaction with live chat platforms</h3>
                <h3 class="sec1head4 mar0">Facebook Messenger Customer Chat</h3>
                <p class="sec2para">Personal Data: About Me, Cookies and Usage Data</p>
                <h3 class="sec1head4 mar0">Freshchat Widget</h3>
                <p class="sec2para">Personal Data: Cookies, email address and Usage Data</p>
                <h3 class="sec1head4 mar0">Tawk.to Widget and Zopim Widget</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Interaction with support and feedback platforms</h3>
                <h3 class="sec1head4 mar0">Zendesk Widget</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Managing contacts and sending messages</h3>
                <h3 class="sec1head4 mar0">GetResponse, AWeber, Sendgrid, Mailgun, Drip, MailChimp and ZOHO Campaigns</h3>
                <p class="sec2para">Personal Data: email address</p>
                <h3 class="sec1head4 mar0">iContact</h3>
                <p class="sec2para">Personal Data: email address and Usage Data</p>
                <h3 class="sec1head4 mar0">Twilio</h3>
                <p class="sec2para">Personal Data: phone number</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Managing landing and invitation pages</h3>
                <h3 class="sec1head4 mar0">Leadpages</h3>
                <p class="sec2para">Personal Data: email address, Usage Data and various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Managing support and contact requests</h3>
                <h3 class="sec1head4 mar0">Freshdesk, Zendesk and ZOHO CRM Email</h3>
                <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Platform services and hosting</h3>
                <h3 class="sec1head4 mar0">Tumblr and WordPress.com</h3>
                <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/regis.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Registration and authentication</h3>
                <h3 class="sec1head4 mar0">Log In with PayPal, Facebook Authentication, GitHub OAuth, Google OAuth, Instagram Authentication, Login with Amazon, OneDrive OAuth, Pinterest OAuth, Stripe OAuth, Twitch.tv Authentication, Twitter OAuth, WordPress.com Single Sign On and YouTube OAuth</h3>
                <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="row">
              <div class="col-md-1">
                <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
              </div>
              <div class="col-md-11 pad0 pdl10">
                <h3 class="sec1head3">Remarketing and behavioral targeting</h3>
                <h3 class="sec1head4 mar0">AdRoll, Facebook Remarketing, Perfect Audience and Twitter Remarketing</h3>
                <p class="sec2para">Personal Data: Cookies and Usage Data</p>
                <h3 class="sec1head4 mar0">Facebook Custom Audience and Twitter Tailored Audiences</h3>
                <p class="sec2para">Personal Data: Cookies and email address</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row bdr">
          <div class="col-md-6 pad0">
            <div class="col-md-1">
              <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
            </div>
            <div class="col-md-11 pad0 pdl10">
              <h3 class="sec1head3">Traffic optimization and distribution</h3>
              <h3 class="sec1head4 mar0">CloudFlare</h3>
              <p class="sec2para">Personal Data: Cookies and various types of Data as specified in the privacy policy of the service</p>
            </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="col-md-1">
              <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon">
            </div>
            <div class="col-md-11 pad0 pdl10">
              <h3 class="sec1head3">User database management</h3>
              <h3 class="sec1head4 mar0">ActiveCampaign and ZOHO CRM</h3>
              <p class="sec2para">Personal Data: email address and various types of Data as specified in the privacy policy of the service</p>
              <h3 class="sec1head4 mar0">FullContact</h3>
              <p class="sec2para">Personal Data: company name, date of birth, Email, first name, gender, last name and various types of Data as specified in the privacy policy of the service</p>
              <h3 class="sec1head4 mar0">Infusionsoft</h3>
              <p class="sec2para">Personal Data: various types of Data as specified in the privacy policy of the service</p>
              <h3 class="sec1head4 mar0">Intercom</h3>
              <p class="sec2para">Personal Data: Cookies, email address, Usage Data and various types of Data as specified in the privacy policy of the service</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-1 col-md-10 pad0">
          <h1 class="sec1head2">Further information about Personal Data</h1>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="bdr">
          <div class="col-md-1 pad0">
            <img src="https://s3-us-west-2.amazonaws.com/privacypol/sticky.png" class="icon pdl66">
          </div>
          <div class="col-md-11 pad0 pdl10">
            <h1 class="sec1head3">Selling goods and services online</h1>
            <p class="sec2para">The Personal Data collected are used to provide the User with services or to sell goods, including payment and possible delivery.</p>
            <p class="sec2para2">The Personal Data collected to complete the payment may include the credit card, the bank account used for the transfer, or any other means of payment envisaged. The kind of Data collected by this Application depends on the payment system used.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-offset-1 col-md-10 pad0">
          <h1 class="sec1head2">Contact information</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="bdr">
          <div class="col-md-1 pad0">
            <img src="https://s3-us-west-2.amazonaws.com/privacypol/download.png" class="icon pdl66">
          </div>
          <div class="col-md-11 pad0 pdl10">
            <h1 class="sec1head3">Owner and Data Controller</h1>
            <p class="sec2para mar0">VIneaSX Solutions LLC. Office 10, Level 1, Sharjah<br/> Media City, Sharjah, UAE. (http://support.vineasx.com)</p>
            <p class="sec2para"><strong>Owner contact email:</strong> support@vineasx.com</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <p class="sec3para">Latest update: May 09, 2018</p>
          </div>
          <div class="col-md-6">
              <p class="sec3para"><a routerLink="/pages/detail-privacy">Show the complete Privacy Policy</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
