import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesLayoutComponent } from './pages-layout.component';
import {RouterModule} from '@angular/router';
import {PagesLayoutRoutes} from './pages-layout.routing';
import {PagesModule} from '../../pages/pages.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(PagesLayoutRoutes),
    PagesModule
  ],
  declarations: [ PagesLayoutComponent ]
})
export class PagesLayoutModule { }
