import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSavedSearchesComponent } from './list-saved-searches/list-saved-searches.component';
import { ViewSavedSearchComponent } from './view-saved-search/view-saved-search.component';
import {RouterModule} from '@angular/router';
import {SavedSearchesRoutes} from './saved-searches.routing';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTableModule} from 'angular-6-datatable';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(SavedSearchesRoutes),
    NgbModule,
    DataTableModule,
    SharedModule
  ],
  declarations: [
    ListSavedSearchesComponent,
    ViewSavedSearchComponent,
  ]
})
export class SavedSearchesModule { }
