import {Injectable} from '@angular/core';
import {AppHttpClient} from '../app-http-client';
import {ResourceProviderService} from '../resource-provider.service';
import {AllOriginResponse, SearchScraperResponseData, TopSellerProduct} from './scraper.model';
import {Observable} from 'rxjs';
import {ScraperUtilsService} from './scraper-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ScraperService {

  private product_items: Array<any> = [];
  private searchedKeyword: string;
  private aliExpressSearchUrl: string;
  private proxySearchUrl: string;

  private topSellerProducts: Array<any> = [];

  private frightCheckUrl = 'https://freight.aliexpress.com/ajaxFreightCalculateService.htm?f=d';
  private sellerRatingUrl = 'https://feedback.aliexpress.com/display/evaluationDsrAjaxService.htm?ownerAdminSeq=233000777';

  /**
   * Format string content of textContent by remove extra white spaces and symbols
   * @param textContent
   */
  private static formatString(textContent: string) {
    return textContent.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim();
  }

  /**
   * Format currency symbol by removing strings and use symbols only
   * TODO: write regex to remove strings except symbols
   * @param currency
   */
  static getFormattedCurrencyCode(currency: string) {
    return currency.replace('US ', '').replace('US', '');
  }

  /**
   * format discount rate
   * @param rate
   */
  static formatDiscountRate(rate: string) {
    return rate.replace('-', '').replace('%', '');
  }

  constructor(
    private http: AppHttpClient,
    private resource: ResourceProviderService,
    private scraperUtilsService: ScraperUtilsService
  ) { }

  search(keyword: string, page = 1) {
    keyword = keyword.replace(' ', '+');

    const url = 'https://www.aliexpress.com/wholesale?SearchText='
      + keyword + '&initiative_id=SB_20180827232606&site=glo&needQuery=n&page=' + page;
    // console.log(url);

    const all_origin_url = this.resource.allOriginUrl + url;
    console.log(all_origin_url);

    // Save all values to the data member
    this.searchedKeyword = keyword;
    this.aliExpressSearchUrl = url;
    this.proxySearchUrl = all_origin_url;

    return this.http.Get<AllOriginResponse>(all_origin_url);
    // .subscribe(
    //   res => {
    //     console.log(res.status.http_code);
    //     console.log(res);
    //     if (res.status.http_code === 200) {
    //       const content = res.contents;
    //       // console.log(content);
    //       const item = this._processData(content);
    //       return item;
    //     }
    //   }, err => {
    //     console.log(err);
    //   }
    // );
  }


















  /***********************************
   * Parse html content
   * @param content
   ************************************/
  public processData(content): SearchScraperResponseData {
  // public processData(content) {
    this.product_items.splice(0);

    const item = new SearchScraperResponseData();

    /**
     * Initiate DOMParser()
     */
    const parser = new DOMParser();

    /**
     * Add content to the DOMParser
     */
    const $ = parser.parseFromString(content, 'text/html');

    /**
     * Extract title of the page
     */
    item.title = $.title;

    /**
     * Extract meta tags
     */
    const meta_tags = $.getElementsByTagName('meta');
    // Create variable to store meta content in dictionary
    const meta_items = {};
    // Loop over meta_tags
    for (const meta in meta_tags) {
      if (meta_tags.hasOwnProperty(meta)) {
        // Skip if meta name is null
        if (meta_tags[meta].getAttribute('name') == null) {
          continue;
        }
        // Add name and content of the meta tag in key => value format
        meta_items[meta_tags[meta].getAttribute('name').replace('-', '_')] = meta_tags[meta].getAttribute('content');
      }
    }
    // Append meta items to the item dictionary
    meta_items['title'] = item['title'];
    // item['meta'] = meta_items;
    item.meta = meta_items;

    /**
     * Extract product information
     */
    // console.log(content);
    const item_list = $.getElementsByClassName('list-item');
    this._processListItem(item_list);
    // const product_items = this._processListItem(item_list);

    /**
     * Check if content has list-item in script
     */
    const script_lazy_renderer = $.getElementById('lazy-render');
    if (script_lazy_renderer) {
      /**
       * Item links are in lazy-renderer,
       * Process each time
       */
      const script_lazy_items_parser = new DOMParser();
      const $_l = script_lazy_items_parser.parseFromString(script_lazy_renderer.innerHTML, 'text/html');

      const new_items = $_l.getElementsByClassName('list-item');
      // console.log(new_items);
      this._processListItem(new_items);
      // console.log(new_products_parsed);
      // product_items.concat(new_products_parsed);
    }

    // console.log(this.product_items);
    // item['products'] = product_items;
    item.products = this.product_items;

    // Console print item
    // console.log(item);

    // Add other info
    item.info = {
      'keyword': this.searchedKeyword,
      'aliExpressSearchUrl': this.aliExpressSearchUrl,
      'proxySearchUrl': this.proxySearchUrl
    };
    return item;
  }


  /**
   * Scrape single product
   */
  public scrapSingle(url: string) {
    if (!url.includes('http')) {
      url = 'http:' + url;
    }
    const all_origin_url = this.resource.allOriginUrl + encodeURIComponent(url);

    return this.http.Get<AllOriginResponse>(all_origin_url);
  }

  /**
   * Process single product
   * @param content
   */
  public processSingleProduct(content: string) {
    // const $properties = new ProductProperties();
    const $properties = {};

    /**
     * Initiate DOMParser()
     */
    const parser = new DOMParser();

    /**
     * Add content to the DOMParser
     */
    const $ = parser.parseFromString(content, 'text/html');

    /*****************************************
     * Extract data from the content dom
     ******************************************/
    const meta_tags = $.getElementsByTagName('meta');
    // Create variable to store meta content in dictionary
    const meta_items = {};
    // console.log(meta_items);
    // const meta_items = new ProductMeta();
    // Loop over meta_tags
    for (const meta in meta_tags) {
      if (meta_tags.hasOwnProperty(meta)) {
        // console.log(meta_tags[meta]);
        // Skip if meta name or property is null
        if (meta_tags[meta].getAttribute('name') == null && meta_tags[meta].getAttribute('property') == null) {
          continue;
        }

        // Add name and content of the meta tag in key => value format
        if (meta_tags[meta].getAttribute('name') !== null) {
          meta_items[meta_tags[meta]
            .getAttribute('name').replace('-', '_')] = meta_tags[meta].getAttribute('content');
        }
        // Add property and content of the meta tag in key => value format
        if (meta_tags[meta].getAttribute('property') !== null) {
          // console.log('inside property');
          meta_items[meta_tags[meta]
            .getAttribute('property').replace('-', '_').replace(':', '_')] = meta_tags[meta].getAttribute('content');
        }
      }
    }
    // console.log(meta_items);
    // $properties['meta'] = meta_items;
    $properties['meta'] = meta_items;

    /*****************************
     * Get Product properties
     *****************************/
    const $$$_product_name = $.getElementsByClassName('product-name');
    $properties['name'] = $$$_product_name && $$$_product_name.length > 0 ?
      $$$_product_name[0].innerHTML : '';

    const $$$_orders_num = $.getElementsByClassName('order-num');
    $properties['orders_num'] = $$$_orders_num && $$$_orders_num.length > 0 ?
      parseInt(ScraperService.formatString(
        $$$_orders_num[0].textContent
      ).replace('orders', '').replace('order', ''), 10) : 0;

    $properties['unit_per_month'] = this.scraperUtilsService.getUnitPerMonth($properties['orders_num']);

    /****************************
     * Get Product rating
     ****************************/
    const rating = {};
    const $$$_j_customer_reviews_trigger = $.getElementById('j-customer-reviews-trigger');
    if ($$$_j_customer_reviews_trigger) {
      // Parse rating
      const ratingParser = new DOMParser();
      const $ratingParser = ratingParser.parseFromString($$$_j_customer_reviews_trigger.innerHTML, 'text/html');
      const rating_percent_num = $ratingParser.getElementsByClassName('percent-num');
      if (rating_percent_num.length > 0) {
        const formattedPerc = ScraperService.formatString(rating_percent_num[0].textContent);
        rating['percent_value'] = isNaN(parseFloat(formattedPerc)) ? 0 : parseFloat(formattedPerc);
      } else {
        rating['percent_value'] = 0;
      }
      rating['percent'] = (rating['percent_value'] / 5) * 100;

      const rating_count = $ratingParser.getElementsByClassName('rantings-num');
      if (rating_count.length > 0) {
        rating['count'] = parseInt(ScraperService.formatString(
          rating_count[0].textContent
        ).replace('(', '')
          .replace(')', '')
          .replace('votes', '')
          .replace('vote', ''), 10);
      } else {
        rating['count'] = 0;
      }
    }
    $properties['rating'] = rating;

    // console.log($$$_product_name);
    /*********************************
     * Get Product pricing
     ********************************/
    const pricing = {};
    const $$$_p_symbol = $.getElementsByClassName('p-symbol');
    pricing['currency'] = $$$_p_symbol && $$$_p_symbol.length > 0 ?
      ScraperService.getFormattedCurrencyCode(ScraperService.formatString($$$_p_symbol[0].textContent)) : '';

    const $$$_p_price = $.getElementsByClassName('p-price');
    // console.log($$$_p_price);
    pricing['price'] = $$$_p_price && $$$_p_price.length > 0 ?
      parseFloat(ScraperService.formatString($$$_p_price[0].textContent)) : 0;
    // console.log(pricing['price']);

    pricing['cost_per_month'] = this.scraperUtilsService.getCostPerMonth($properties['unit_per_month'], pricing['price']);

    const $$$_p_splitter = $.getElementsByClassName('p-splitter');
    pricing['separator'] = $$$_p_splitter && $$$_p_splitter.length > 0 ?
      ScraperService.formatString($$$_p_splitter[0].textContent) : '';

    const $$$_p_unit = $.getElementsByClassName('p-unit');
    pricing['unit'] = $$$_p_unit && $$$_p_unit.length > 0 ?
      ScraperService.formatString($$$_p_unit[0].textContent) : '';

    const $$$_j_sku_discount_price = $.getElementById('j-sku-discount-price');
    pricing['discount_price'] = $$$_j_sku_discount_price ? $$$_j_sku_discount_price.innerText : '';

    const $$$_p_discount_rate = $.getElementsByClassName('p-discount-rate');
    pricing['discount_rate'] = $$$_p_discount_rate && $$$_p_discount_rate.length > 0 ?
      parseInt(ScraperService.formatDiscountRate(ScraperService.formatString($$$_p_discount_rate[0].textContent)), 10) : 0;

    const $$$_j_time_countdown = $.getElementById('j-time-countdown');
    pricing['discount_countdown'] = $$$_j_time_countdown ? $$$_j_time_countdown.innerHTML : '';

    const $$$_big_sale_discount_info = $.getElementsByClassName('bigsale-discount-info');
    pricing['big_sale_discount_info'] = $$$_big_sale_discount_info && $$$_big_sale_discount_info.length > 0 ?
      $$$_big_sale_discount_info[0].innerHTML : '';

    const $$$_bulk_price_tips = $.getElementsByClassName('bulk-price-tips');
    pricing['bulk_price_info'] = $$$_bulk_price_tips && $$$_bulk_price_tips.length > 0 ?
      ScraperService.formatString($$$_bulk_price_tips[0].textContent) : '';

    $properties['pricing'] = pricing;


    /********************************
     * Get Product attributes
     ********************************/
    const attributes = {};
    const $$$_j_product_info_sku = $.getElementById('j-product-info-sku');
    // If ID exists
    if ($$$_j_product_info_sku) {
      // Create DOMParser object to parse internal HTML
      const attributeParser = new DOMParser();
      const $attributeParser = attributeParser.parseFromString($$$_j_product_info_sku.innerHTML, 'text/html');

      // Find by class. It gives list of all attributes available for the product
      const property_list = $attributeParser.getElementsByClassName('p-property-item');

      // If object exists with p-property-item class
      if (property_list.length > 0) {
        // Variable to hold single attribute and values
        // with name as attribute name and value as an array of available options
        for (const property in property_list) {
          if (property_list.hasOwnProperty(property)) {
            // DOMParser object to parser property HTML content
            const propertyItemParser = new DOMParser();
            const $propertyItemParser = propertyItemParser.parseFromString(property_list[property].innerHTML, 'text/html');
            // Search for the attribute type/title
            const property_title = $propertyItemParser.getElementsByClassName('p-item-title');
            if (property_title.length > 0) {
              // Save title to a variable to use later
              const p_a_title = ScraperService.formatString(property_title[0].textContent);

              // Search for attribute items under p_title
              const p_item_main = $propertyItemParser.getElementsByClassName('p-item-main');
              const attributeValuesParser = new DOMParser();
              const $attributeValuesParser = attributeValuesParser.parseFromString(p_item_main[0].innerHTML, 'text/html');
              const attribute_values_li = $attributeValuesParser.getElementsByTagName('li');

              const attribute_items = [];
              if (attribute_values_li.length > 0) {
                for (const li in attribute_values_li) {
                  if (attribute_values_li.hasOwnProperty(li)) {
                    const li_item = attribute_values_li[li];

                    if (li_item.getAttribute('class') === null) {
                      attribute_items.push(ScraperService.formatString(li_item.textContent));
                    } else if (li_item.classList.contains('item-sku-image')) {
                      // Attribute type is image, get the title from the a attribute
                      const item_attribute_type_image_parser = new DOMParser();
                      const $item_attribute_type_image_parser = item_attribute_type_image_parser.parseFromString(
                        li_item.innerHTML, 'text/html'
                      );
                      const a_list = $item_attribute_type_image_parser.getElementsByTagName('a');
                      if (a_list.length > 0) {
                        // Push title of the first anchor tag to the attribute_items
                        attribute_items.push(a_list[0].getAttribute('title'));
                      }
                    }
                  }
                }
              }

              // Add to attribute with p_a_title as key
              attributes[p_a_title] = attribute_items;
            }
          }
        }
      }
    }
    $properties['attributes'] = attributes;

    /******************************
     * Get Product Images
     ******************************/
    const images = [];
    const $$$_img_thumb_item = $.getElementsByClassName('img-thumb-item');
    if ($$$_img_thumb_item.length > 0) {
      for (const img in $$$_img_thumb_item) {
        if ($$$_img_thumb_item.hasOwnProperty(img)) {
          // Parse image
          const img_parser = new DOMParser();
          const $img_parser = img_parser.parseFromString($$$_img_thumb_item[img].innerHTML, 'text/html');
          const image_item = $img_parser.getElementsByTagName('img');
          if (image_item.length > 0) {
            const img_src = image_item[0].getAttribute('src');
            images.push(img_src);
          }
        }
      }
    }
    $properties['images'] = images;

    /****************************************
     * Get Product Shipping details
     ****************************************/

    const $$$_p_logistics_info = $.getElementsByClassName('p-logistics-info');
    // console.log($$$_p_logistics_info);
    if ($$$_p_logistics_info.length > 0) {
      // Parse logistic
      const logisticParser = new DOMParser();
      const $logisticParser = logisticParser.parseFromString($$$_p_logistics_info[0].innerHTML, 'text/html');
      const logistic_cost = $logisticParser.getElementsByClassName('logistics-cost');
      // console.log(logistic_cost);
    }
    const shipping = {};
    const $$$_p_logistics_detail = $.getElementsByClassName('p-logistics-detail');
    shipping['logistic_detail'] = $$$_p_logistics_detail && $$$_p_logistics_detail.length > 0 ?
      ScraperService.formatString($$$_p_logistics_detail[0].textContent) : '';

    const $$$_logistics_cost = $.getElementsByClassName('logistics-cost');
    // console.log($$$_logistics_cost);
    shipping['logistics_cost'] = $$$_logistics_cost && $$$_logistics_cost.length > 0 ?
      $$$_logistics_cost[0].innerHTML : '';

    const $$$_copy_shipping_to = $.getElementsByClassName('copy-shipping-to');
    shipping['copy_shipping_to'] = $$$_copy_shipping_to && $$$_copy_shipping_to.length > 0 ?
      $$$_copy_shipping_to[0].innerHTML : '';

    const $$$_j_shipping_country = $.getElementById('j-shipping-country');
    shipping['shipping_country'] = $$$_j_shipping_country ? $$$_j_shipping_country.innerHTML : '';

    // console.log($.getElementsByClassName('shipping-via'));
    // const $$$_shipping_via = $.getElementsByClassName('shipping-via');
    // console.log($$$_shipping_via);
    // shipping['shipping_via'] = $$$_shipping_via !== null ? $$$_shipping_via[0].innerHTML : '';

    const $$$_j_shipping_company = $.getElementById('j-shipping-company');
    // console.log($$$_j_shipping_company);
    shipping['shipping_company'] = $$$_j_shipping_company !== null ? $$$_j_shipping_company.innerHTML : '';

    const $$$_p_delivery_day_tips = $.getElementsByClassName('p-delivery-day-tips');
    shipping['delivery_day_tips'] = $$$_p_delivery_day_tips && $$$_p_delivery_day_tips.length > 0 ?
      ScraperService.formatString($$$_p_delivery_day_tips[0].textContent) : '';

    const $$$_shipping_days = $.getElementsByClassName('shipping-days');
    shipping['shipping_days'] = $$$_shipping_days && $$$_shipping_days.length > 0 ?
      $$$_shipping_days[0].innerHTML : '';

    $properties['shipping'] = shipping;

    /*****************************
     * Get Product Stock
     *****************************/
    const stock = {};
    const $$$_p_available_stock = $.getElementsByClassName('p-available-stock');
    stock['available_stock'] = $$$_p_available_stock && $$$_p_available_stock.length > 0 ?
      ScraperService.formatString($$$_p_available_stock[0].textContent) : '';

    const $$$_j_sell_stock_num = $.getElementById('j-sell-stock-num');
    stock['sell_stock_num'] = $$$_j_sell_stock_num ? $$$_j_sell_stock_num.innerText : '';

    const $$$_j_sku_limitd_num = $.getElementById('j-sku-limitd-num');
    stock['limit_per_order'] = $$$_j_sku_limitd_num !== null ? $$$_j_sku_limitd_num.innerText : '';

    $properties['stock'] = stock;

    /*******************************
     * Get Product Promotions
     ******************************/
    const promotion = {};
    const $$$_j_p_pay_promotion = $.getElementById('j-p-payPromotion');
    promotion['pay_promotion'] = $$$_j_p_pay_promotion !== null ? $$$_j_p_pay_promotion.innerText : '';

    const $$$_j_p_voucher = $.getElementById('j-p-voucher');
    promotion['voucher'] = $$$_j_p_voucher !== null ? $$$_j_p_voucher.innerText : '';

    const $$$_j_p_shop_support = $.getElementById('j-p-shop-support');
    promotion['shop_support'] = $$$_j_p_shop_support !== null ? $$$_j_p_shop_support.innerText : '';

    $properties['promotion'] = promotion;

    /*****************************
     * Get Seller Coupons
     *****************************/
    const seller_coupons = [];
    const coupon_items = $.getElementsByClassName('store-coupon-item');
    if (coupon_items && coupon_items.length > 0) {
      for (const p_s_coupon in coupon_items) {
        if (coupon_items.hasOwnProperty(p_s_coupon)) {
          const coupon = {};
          const couponParser = new DOMParser();
          const $2 = couponParser.parseFromString(coupon_items[p_s_coupon].innerHTML, 'text/html');
          const p_tags = $2.getElementsByTagName('p');
          for (const p in p_tags) {
            if (p_tags.hasOwnProperty(p)) {
              if (p_tags[p].getAttribute('class') == null) {
                coupon['info'] = ScraperService.formatString(p_tags[p].textContent);
              } else {
                coupon[p_tags[p].getAttribute('class').replace('-', '_')] = ScraperService.formatString(p_tags[p].textContent);
              }
            }
          }
          seller_coupons.push(coupon);
        }
      }
    }
    $properties['seller_coupons'] = seller_coupons;

    /******************************
     * Get Product Delivery
     *******************************/
    const $$$_promise_time_cont = $.getElementsByClassName('promise-time-cont');
    $properties['delivery_in'] = $$$_promise_time_cont && $$$_promise_time_cont.length > 0 ?
      ScraperService.formatString($$$_promise_time_cont[0].textContent) : '';


    /********************************
     * Get product specifications
     ********************************/
    const specifications = {};
    const $$$_product_property_list = $.getElementsByClassName('product-property-list');
    if ($$$_product_property_list.length > 0) {
      // Get product specification items
      const productSpecificationParser = new DOMParser();
      const $productSpecificationParser = productSpecificationParser.parseFromString(
        $$$_product_property_list[0].innerHTML, 'text/html'
      );
      const li_items = $productSpecificationParser.getElementsByTagName('li');
      if (li_items.length > 0) {
        for (const li in li_items) {
          if (li_items.hasOwnProperty(li)) {
            // Process specifications
            const specParser = new DOMParser();
            const $specParser = specParser.parseFromString(li_items[li].innerHTML, 'text/html');
            const spec_title = $specParser.getElementsByClassName('propery-title');
            const spec_des = $specParser.getElementsByClassName('propery-des');

            // Add to specifications dictionary
            if (spec_title.length > 0 && spec_des.length > 0) {
              specifications[ScraperService.formatString(spec_title[0].textContent)] = ScraperService.formatString(spec_des[0].textContent);
            }
          }
        }
      }
    }
    $properties['specifications'] = specifications;

    /*********************************
     * Get seller ratings and reviews
     *********************************/
    const store_info = {};
    // Get store id and seller id
    const $$$_send_mail_btn = $.getElementsByClassName('send-mail-btn');
    if ($$$_send_mail_btn.length > 0) {
      const sendMailBtnItem = $$$_send_mail_btn[0];
      store_info['seller_id'] = sendMailBtnItem.getAttribute('data-id1');
      store_info['store_id'] = sendMailBtnItem.getAttribute('data-id2');
    } else {
      store_info['seller_id'] = store_info['store_id'] = '';
    }

    // Get store time
    const $$$_store_time = $.getElementsByClassName('store-time');
    if ($$$_store_time.length > 0) {
      const storeTime = $$$_store_time[0];
      store_info['store_time'] = ScraperService.formatString(storeTime.textContent).replace('This store has been open since', '');
    } else {
      store_info['store_time'] = '';
    }

    const $$$_j_store_info_wrap = $.getElementById('j-store-info-wrap');

    if ($$$_j_store_info_wrap) {
      /**
       * Store info scrapper
       */
      const storeInfoScrapper = new DOMParser();
      const $storeInfoScrapper = storeInfoScrapper.parseFromString($$$_j_store_info_wrap.innerHTML, 'text/html');

      // Store name
      const storeName = $storeInfoScrapper.getElementsByClassName('store-name');
      if (storeName.length > 0) {
        store_info['name'] = ScraperService.formatString(storeName[0].textContent);
      } else {
        store_info['name'] = '';
      }

      // Store address
      const storeAddress = $storeInfoScrapper.getElementsByClassName('store-address');
      if (storeAddress.length > 0) {
        store_info['address'] = ScraperService.formatString(storeAddress[0].textContent);
      } else {
        store_info['address'] = '';
      }

      // Store URL
      const storeURL = $storeInfoScrapper.getElementsByClassName('go-store-link');
      // console.log(storeURL);
      if (storeURL.length > 0) {
        // console.log(storeURL[0]);
        store_info['link'] = storeURL[0].getAttribute('href');
      } else {
        store_info['link'] = '';
      }

      // Store feedback
      // TODO: Feedback is loaded using ajax. Replace below script to get store feedback using ajax
      const storeFeedbackScore = $storeInfoScrapper.getElementsByClassName('seller-score-feedback');
      if (storeFeedbackScore.length > 0) {
        store_info['feedback_score'] = ScraperService.formatString(storeFeedbackScore[0].textContent);
      } else {
        store_info['feedback_score'] = '';
      }
    }
    $properties['store_info'] = store_info;

    /********************************
     * Get Product description
     ********************************/
    let description_content = '';
    const $$$_description_content = $.getElementsByClassName('description-content');
    if ($$$_description_content.length > 0) {
      description_content = $$$_description_content[0].innerHTML;
    }
    $properties['description_content'] = description_content;

    // console.log(content);

    /********************************
     * Scrap scripts
     *******************************/
    const scripts = $.getElementsByTagName('script');
    let descriptionPageUrl = '';
    if (scripts.length > 0) {
      /**
       * Loop over scripts to get detail page url
       */
      for (const sc in scripts) {
        if (scripts.hasOwnProperty(sc)) {
          if (scripts[sc].textContent.includes('detailDesc')) {
            // console.log('url found');
            const scText = ScraperService.formatString(scripts[sc].textContent);
            const descPageUrl = scText.substr(
              scText.lastIndexOf('detailDesc="') + 1,
              scText.lastIndexOf('";  window.runParams.transAbTest')
            );
            let descPageUrl_2 = descPageUrl.substr(
              0,
              descPageUrl.indexOf('";')
            );
            descPageUrl_2 = descPageUrl_2.replace('etailDesc="', '');
            descriptionPageUrl = descPageUrl_2;
          } else {
            // console.log('not found');
          }
        }
      }
    }
    $properties['description_page_url'] = descriptionPageUrl;


    /**********************************
     * Scrape Packaging Details
     *********************************/
    const packaging_details = {};
    const $$$_packaging_details = $.getElementsByClassName('product-packaging-list');
    if ($$$_packaging_details.length > 0) {
      // Get packaging details list
      const packagingDetailParser = new DOMParser();
      const $packagingDetailParser = packagingDetailParser.parseFromString(
        $$$_packaging_details[0].innerHTML, 'text/html'
      );
      const li_items = $packagingDetailParser.getElementsByTagName('li');
      if (li_items.length > 0) {
        for (const li in li_items) {
          if (li_items.hasOwnProperty(li)) {
            const specParser = new DOMParser();
            const $specParser = specParser.parseFromString(li_items[li].innerHTML, 'text/html');
            const item_title = $specParser.getElementsByClassName('packaging-title');
            const item_desc = $specParser.getElementsByClassName('packaging-des');

            if (item_title.length > 0 && item_desc.length > 0) {
              packaging_details[
                ScraperService.formatString(
                  item_title[0].textContent
                ).replace(' ', '_').replace('-', '_').replace(':', '')
                ] = ScraperService.formatString(item_desc[0].textContent);
            }
          }
        }
      }
    }
    $properties['packaging_details'] = packaging_details;

    $properties['custom'] = {
      'profit_percentage': 0,
      'recommended_selling_price': 0,
      'estimate_revenue': 0
    };

    /**************************
     * R E T U R N
     **************************/
    return $properties;
  }

  /**
   * Process List Item
   * @param item_list
   * @private
   */
  private _processListItem(item_list: HTMLCollectionOf<Element>) {

    for (const p_item in item_list) {
      if (item_list.hasOwnProperty(p_item)) {
        const product = {};

        /**
         * Extract item attributes
         */
        product['qrdata'] = item_list[p_item].getAttribute('qrdata');
        product['algo_exp_id'] = item_list[p_item].getAttribute('algo-exp-id');
        product['pub_catid'] = item_list[p_item].getAttribute('pub-catid');

        /**
         * Extract Product URL
         */
        const innerHtml = item_list[p_item].innerHTML;
        const parserInnerHtml = new DOMParser();
        const $2 = parserInnerHtml.parseFromString(innerHtml, 'text/html');
        const p_anchor = $2.getElementsByClassName('picRind');
        if (p_anchor.length < 1) {
          continue;
        }
        product['url'] = p_anchor[0].getAttribute('href');

        /**
         * Extract product main image
         */
        const p_image = $2.querySelector('img.picCore');
        const image = {};
        image['main'] = p_image.getAttribute('image-src');
        if (image['main'] == null) {
          image['main'] = p_image.getAttribute('src');
        }
        product['images'] = image;
        product['title'] = p_image.getAttribute('alt');

        /**
         * Push product to product_items
         */
        // product_items.push(product);
        this.product_items.push(product);
      }
    }

    // return product_items;
  }


  /************************************
   * F R I G H T  C H E C K
   ************************************/
  frightCheck(country_code: string, product_id: string) {
    let url = `${this.frightCheckUrl}&productid=${product_id}&country=${country_code.toUpperCase()}&province=&city=`;
    url = this.resource.allOriginUrl + encodeURIComponent(url);
    return this.http.Get<AllOriginResponse>(url);
  }

  /**
   * Return Product Id from URL
   * Example url type is:
   * "//www.aliexpress.com/store/product/Men-s-fitness-gloves-breathable-sports-gloves-female-gym-dumbbell-training-equipment-Half-Finger
   * -Bracers-antislip/1918435_32816892779.html?ws_ab_test=searchweb0_0,searchweb201602_4_10881_10882_10065_10068_10130_10547_10546_10059
   * _10548_10545_10696_100031_10084_10083_10103_451_452_10618_10307,searchweb201603_1,ppcSwitch_5&algo_expid=5d4230c1-2514-4e67-b2d3-
   * bb574f2a1895-1&algo_pvid=5d4230c1-2514-4e67-b2d3-bb574f2a1895&transAbTest=ae803_1&priceBeautifyAB=0"
   *
   * The product id is contained inside the url string like
   * 32816892779.html
   * first part before html is the product id
   * In case of 1918435_32816892779.html type of string,
   * product id is the number between _ and .html
   *
   * How to extract product id
   * 1. Slice url from ? delimiter
   * 2. Read first (0th Index) data
   * 3. Slice on / delimiter
   * 4. Read last index data
   * 5. Slice delimiter
   * 6. Read first (0th Index) data
   * 7. If it contains underscore (_), Jump to step 9
   * 8. Return first index data, this is the product id
   * 9. Slice on _ (underscore) delimiter
   * 10. Read last index data
   * 11. This is the Product Id, return
   *
   * @param url
   */
  getProductIdFromUrl(url: string) {
    // Step 1
    const step_1_url = url.split('?');
    // Step 2
    const step_2_url = step_1_url[0];
    // Step 3
    const step_3_url = step_2_url.split('/');
    // Step 4
    const step_4_url = step_3_url[step_3_url.length - 1];
    // Step 5
    const step_5_url = step_4_url.split('.');
    // Step 6
    const step_6_url = step_5_url[0];
    // Step 7
    const step_7_check = step_6_url.includes('_');
    // Step 8
    if (!step_7_check) {
      return step_6_url;
    }
    // Step 9
    const step_9_url = step_6_url.split('_');
    // Step 10
    // Step 11
    return step_9_url[step_9_url.length - 1];
  }

  /**
   * Download images by sending images urls to server
   * @param images
   */
  downloadImages(images: Array<any>): any {
    const url = `${this.resource.url}download/images`;

    const data = {
      'images': JSON.stringify(images)
    };
    return this.http.Post(url, data);
  }

  /**
   * Scrap Reviews
   * @param product_id
   * @param owner_id
   */
  scrapReviews(product_id, owner_id): Observable<AllOriginResponse> {
    const url = `https://feedback.aliexpress.com/display/productEvaluation.htm?productId=${product_id}&ownerMemberId=${owner_id}`;

    const allUrl = this.resource.allOriginUrl + encodeURIComponent(url);
    return this.http.Get<AllOriginResponse>(allUrl);
  }

  /**
   * Scrap review items
   * @param contents
   */
  scrapReviewItems(contents) {
    const $$$ = new DOMParser();
    const $_ = $$$.parseFromString(contents, 'text/html');

    const feedback_items = $_.getElementsByClassName('feedback-item');

    const feeds = [];

    if (feedback_items.length > 0) {
      for (const item in feedback_items) {
        if (feedback_items.hasOwnProperty(item)) {
          const content = feedback_items[item].innerHTML;

          const feed = {};
          const feedbackParser = new DOMParser();
          const $feedbackParser = feedbackParser.parseFromString(content, 'text/html');

          // user name
          const user_name = $feedbackParser.getElementsByClassName('user-name');
          if (user_name.length > 0) {
            feed['user_name'] = ScraperService.formatString(user_name[0].textContent);
          } else {
            feed['user_name'] = '';
          }

          // user country
          const user_country = $feedbackParser.getElementsByClassName('user-country');
          if (user_country.length > 0) {
            feed['user_country'] = ScraperService.formatString(user_country[0].textContent);
          } else {
            feed['user_country'] = '';
          }

          // star rating percent
          const start_view = $feedbackParser.getElementsByClassName('star-view');
          if (start_view.length > 0) {
            const starParser = new DOMParser();
            const $starParser = starParser.parseFromString(start_view[0].innerHTML, 'text/html');
            const span_tags = $starParser.getElementsByTagName('span');
            if (span_tags.length > 0) {
              const star_first = span_tags[0].getAttribute('style');
              // TODO: Parser string to remove width: and extract numeric value only
              feed['star'] = star_first;
            } else {
              feed['star'] = '';
            }
          } else {
            feed['star'] = '';
          }

          // user order info
          const order_info = $feedbackParser.getElementsByClassName('user-order-info');
          if (order_info.length > 0) {
            const orderInfoParser = new DOMParser();
            const $orderInfoParser = orderInfoParser.parseFromString(order_info[0].innerHTML, 'text/html');

            const order_info_spans = $orderInfoParser.getElementsByTagName('span');
            if (order_info_spans.length > 0) {
              const info_s = [];
              for (const i in order_info_spans) {
                if (order_info_spans.hasOwnProperty(i)) {
                  info_s.push(ScraperService.formatString(order_info_spans[i].textContent));
                }
              }
              feed['order_info'] = info_s;
            } else {
              feed['order_info'] = [];
            }
          } else {
            feed['order_info'] = [];
          }

          // buyer feedback
          const buyer_feedback = $feedbackParser.getElementsByClassName('buyer-feedback');
          if (buyer_feedback.length > 0) {
            feed['feedback'] = ScraperService.formatString(buyer_feedback[0].textContent);
          } else {
            feed['feedback'] = '';
          }

          // feedback images
          const feedback_images = $feedbackParser.getElementsByClassName('pic-view-item');
          if (feedback_images.length > 0) {
            const f_img = [];
            for (const i in feedback_images) {
              if (feedback_images.hasOwnProperty(i)) {
                f_img.push(feedback_images[i].getAttribute('data-src'));
              }
            }
            feed['images'] = f_img;
          } else {
            feed['images'] = [];
          }

          // feedback time
          const feedback_time = $feedbackParser.getElementsByClassName('r-time');
          if (feedback_time.length > 0) {
            feed['time'] = ScraperService.formatString(feedback_time[0].textContent);
          } else {
            feed['time'] = '';
          }

          feeds.push(feed);
        }
      }
    }

    return feeds;
  }

  /**
   * Download Reviews
   * @param feeds
   */
  downloadReviews(feeds: any[]): any {
    const url = `${this.resource.url}download/reviews`;
    const data = {
      'feeds': JSON.stringify(feeds)
    };
    return this.http.Post(url, data);
  }

  /**
   * Get description of the product
   * @param desc_page_url
   */
  getDescription(desc_page_url: any | string): Observable<AllOriginResponse> {
    const a_url_a = `${this.resource.allOriginUrl}${encodeURIComponent(desc_page_url)}`;

    return this.http.Get<AllOriginResponse>(a_url_a);
  }


  /****************************
   * TOP SELLER PRODUCTS
   ****************************/
  fetchTopSellerProducts(store_id: number) {

    let url = `https://aliexpress.com/store/top-rated-products/${store_id}.html`;
    url = this.resource.allOriginUrl + encodeURIComponent(url);

    return this.http.Get<AllOriginResponse>(url);
  }

  /**
   * Get product urls from the content
   * @param contents
   */
  processTopSellerProducts(contents): TopSellerProduct {
    this.topSellerProducts.splice(0);
    const item = new TopSellerProduct();

    const response_content = contents;
    const responseParser = new DOMParser();

    const $ = responseParser.parseFromString(response_content, 'text/html');


    /**
     * Extract title of the page
     */
    item.title = $.title;


    /**
     * Extract meta tags
     */
    const meta_tags = $.getElementsByTagName('meta');
    // Create variable to store meta content in dictionary
    const meta_items = {};
    // Loop over meta_tags
    for (const meta in meta_tags) {
      if (meta_tags.hasOwnProperty(meta)) {
        // Skip if meta name is null
        if (meta_tags[meta].getAttribute('name') == null) {
          continue;
        }
        // Add name and content of the meta tag in key => value format
        meta_items[meta_tags[meta].getAttribute('name').replace('-', '_')] = meta_tags[meta].getAttribute('content');
      }
    }
    // Append meta items to the item dictionary
    meta_items['title'] = item['title'];
    // item['meta'] = meta_items;
    item.meta = meta_items;



    const items_list = $.getElementsByClassName('items-list');
    this._processTopSellerListItems(items_list);

    item.products = this.topSellerProducts;


    return item;
  }

  /**
   * process each top seller product of the seller
   * @param items_list
   * @private
   */
  private _processTopSellerListItems(items_list: HTMLCollectionOf<Element>) {
    if (items_list.length > 0) {

      const itemsListParser = new DOMParser();
      const $itemsListParser = itemsListParser.parseFromString(items_list[0].innerHTML, 'text/html');
      const li_items = $itemsListParser.getElementsByTagName('li');

      // if li items found
      if (li_items.length > 0) {

        // loop over each item
        for (const li in li_items) {
          if (li_items.hasOwnProperty(li)) {

            // initialize variable to store product information
            const product = {};

            const pUrlParser = new DOMParser();
            const $pUrlParser = pUrlParser.parseFromString(li_items[li].innerHTML, 'text/html');
            const url_pic_rind = $pUrlParser.getElementsByClassName('pic-rind');
            if (url_pic_rind.length < 1) {
              continue;
            }

            product['url'] = url_pic_rind[0].getAttribute('href');

            /**
             * Extract product main image
             */
            const p_image = $pUrlParser.querySelector('img.picCore');
            const image = {};
            image['main'] = p_image.getAttribute('image-src');
            if (image['main'] == null) {
              image['main'] = p_image.getAttribute('src');
            }
            product['images'] = image;
            product['title'] = p_image.getAttribute('alt');

            // push product to array
            this.topSellerProducts.push(product);
          }
        }
      }
    }
  }
}
