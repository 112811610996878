import {Component, Input, OnInit} from '@angular/core';
import {LandingPageTemplateService} from '../landing-page-template.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LandingPageTemplateData} from '../landing-page-template.model';

@Component({
  selector: 'app-list-landing-page-templates-modal',
  templateUrl: './list-landing-page-templates-modal.component.html',
  styleUrls: ['./list-landing-page-templates-modal.component.css']
})
export class ListLandingPageTemplatesModalComponent implements OnInit {

  lPTemplates: Array<LandingPageTemplateData>;
  @Input() selected_template: number;
  landing_page_templates_loaded = false;

  /**
   * Constructor
   * @constructor
   * @param activeModal
   * @param landingPageTemplateService
   */
  constructor(
    public activeModal: NgbActiveModal,
    private landingPageTemplateService: LandingPageTemplateService
  ) { }

  ngOnInit() {
    this._getLandingPageTemplates();
  }

  /**
   * Get landing page templates
   * @private
   */
  private _getLandingPageTemplates() {
    this.landingPageTemplateService.list().subscribe(
      res => {
        this.lPTemplates = res.data;
        this.landing_page_templates_loaded = true;
      }
    );
  }

  /**
   * Set selected template
   * @param id
   */
  setSelected(id: number) {
    this.selected_template = id;
  }
}
